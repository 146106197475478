import axios from "axios";
import { localStorageKeys } from "./data/localStorageKeys";
const token = localStorage.getItem(localStorageKeys.AUTH_TOKEN);

const headers = () => {
    if (token) {
        return {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        };
    } else {
        return {
            Accept: "application/json",
            "Content-Type": "application/json",
        };
    }
};

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 60000,
    headers: headers(),
});

export default instance;
