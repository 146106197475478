import { FC, useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { useAuth } from "../../libs/auth/auth";
import Axios from "../../axios-instance";
import Loading from "../../components/loading/Loading";

const OnboardingCheck: FC<{ children: JSX.Element }> = ({ children }) => {
    const { isAuthenticated, onLogout, token } = useAuth();
    const location = useLocation();
    const [onboardingStatus, setOnboardingStatus] =
        useState<"loading" | "success" | "error">("loading");

    useEffect(() => {
        const checkOnboardingStatus = async () => {
            try {
                await Axios.get("/main/me");
                setOnboardingStatus("success");
            } catch {
                setOnboardingStatus("error");
            }
        };
        checkOnboardingStatus();
    }, []);

    useEffect(() => {
        Axios.interceptors.response.use(
            res => res,
            err => {
                const status = err?.response?.status;
                if (status === 401 && isAuthenticated) {
                    onLogout();
                } else {
                    return Promise.reject(err);
                }
            }
        );
        let timeoutId: any;
        if (token) {
            const jwt: any = jwtDecode(token);
            timeoutId = setTimeout(onLogout, jwt.exp * 1000 - Date.now());
        }
        return () => {
            if (timeoutId !== undefined) {
                clearTimeout(timeoutId);
            }
        };
    }, [isAuthenticated, onLogout, token]);

    if (!isAuthenticated) {
        return <Navigate to="/login" state={{ redirectTo: location }} />;
    }

    if (onboardingStatus === "loading") {
        return (
            <div className="flex justify-center">
                <Loading />
            </div>
        );
    } else if (onboardingStatus === "success") {
        return <Navigate to="/" />;
    }

    return children;
};

export default OnboardingCheck;
