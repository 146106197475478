import React, { FC } from "react";
import {
    Modal,
    Typography,
    Button,
    Divider,
    ProgressSteps,
} from "trinity-components-library";

export interface CRMModalProps {
    Icon: any;
    title: string;
    open?: boolean;
    onClose?: () => void;
    onConnect?: () => void;
    overview?: string;
    isConnected?: boolean;
}

const CRMModal: FC<CRMModalProps> = ({
    Icon,
    title,
    onClose,
    onConnect,
    open = false,
    isConnected = false,
    overview,
}) => {
    return (
        <Modal open={open} onClose={onClose}>
            <div className="w-[800px] flex flex-col gap-4 p-6 rounded-xl">
                <div className="flex flex-col rounded-xl border border-gray-200">
                    <div className="p-6 flex flex-col gap-6">
                        <div className="flex gap-3 items-center">
                            <Icon />
                            <Typography color="gray-700" className="flex-1">
                                {title}
                            </Typography>
                            <Button
                                label={isConnected ? "Connected" : "Connect"}
                                disabled={isConnected}
                                size="lg"
                                onClick={onConnect}
                            />
                        </div>
                        <Typography color="gray-500" size="sm">
                            Easily sync your opportunities in Salesforce with
                            Trinity. Reps can now trigger Win Loss reviews
                            without leaving Salesforce.
                        </Typography>
                    </div>
                    <Divider />
                    {overview && (
                        <>
                            <div className="p-6 flex flex-col gap-3">
                                <Typography color="gray-700">
                                    Overview
                                </Typography>
                                <Typography color="gray-500" size="sm">
                                    {overview}
                                </Typography>
                            </div>
                            <Divider />
                        </>
                    )}
                    <div className="p-6 flex flex-col gap-6">
                        <Typography color="gray-700">Usage guide</Typography>
                        <ProgressSteps
                            current={1}
                            steps={[
                                {
                                    title: "Step 1",
                                    description:
                                        "Install Salesforce integration by clicking on the connect button",
                                },
                                {
                                    title: "Step 2",
                                    description:
                                        "Connect your apps efficiently",
                                },
                                {
                                    title: "Step 3",
                                    description:
                                        "Start collaborating with your team",
                                },
                            ]}
                        />
                    </div>
                    <Divider />
                    <div className="p-6 flex flex-col gap-3">
                        <Typography color="gray-700">Terms</Typography>
                        <div className="flex gap-1">
                            <Typography color="gray-500" size="sm">
                                Refer to our
                            </Typography>
                            <Typography
                                color="primary-700"
                                size="sm"
                                className="underline"
                            >
                                Terms of conditions
                            </Typography>
                        </div>
                    </div>
                </div>
                <Button
                    label="Cancel"
                    variant="outlined"
                    size="xl"
                    color="gray"
                    fullWidth
                    onClick={onClose}
                />
            </div>
        </Modal>
    );
};

export default CRMModal;
