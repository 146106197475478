export enum MemberRole {
    ADMIN = "ADMIN",
    SENIOR_MANAGER = "SENIOR_MANAGER",
    SALES_INDIVIDUAL_CONTRIBUTOR = "SALES_INDIVIDUAL_CONTRIBUTOR",
    SALES_LEADER = "SALES_LEADER",
    MARKETING_INDIVIDUAL_CONTRIBUTOR = "MARKETING_INDIVIDUAL_CONTRIBUTOR",
    MARKETING_LEADER = "MARKETING_LEADER",
    MARKETING = "MARKETING",
    SALES = "SALES",
    FINANCE = "FINANCE",
    PROCUREMENT = "PROCUREMENT",
    PROJECT_MANAGER_DELIVERY = "PROJECT_MANAGER_DELIVERY",
    IT = "IT",
    C_SUITE = "C_SUITE",
    C_SUITE_LEADER = "C_SUITE_LEADER",
    BOARD = "BOARD",
}

export enum MemberScope {
    ADMIN = "ADMIN",
    SALES_LEADER = "SALES_LEADER",
    SALES_REP = "SALES_REP",
    TRINITY_ADMIN = "TRINITY_ADMIN",
}

export enum MemberSortKeys {
    NAME = "name",
    SCOPES = "scopes",
}

export enum MemberSortBy {
    SCOPE = "scope",
    NAME = "name",
}

export enum MemberSortOrder {
    ASCENDING = "asc",
    DESCENDING = "desc",
}

export enum MemberStatus {
    ACTIVE = "ACTIVE",
    INVITED = "INVITED",
    BLOCKED = "BLOCKED",
}


export interface TeamMember {

      id: string;
    
      user_id: string;
    
      email: string;
    
      name: string;

      scheduledReviews: number;
    
      completedReviews: number;
    
      scopes: string;
    
      company_id: string;
    
      status: string;

      role: MemberRole;

      profile_picture_url: string;
    

      created_at: Date;
    

      updated_at: Date;

      last_logged_in_at: Date;
    
}