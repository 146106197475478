import { FC, useMemo, useState } from "react";
import {
    Modal,
    Typography,
    CheckboxCircle,
    Button,
} from "trinity-components-library";
import { ReviewType } from "../../../apis/types/review-type";
import getLoggedInCompanyId from "../../../libs/getLoggedInCompanyId";
import SimpleHeaderModal from "./simple-header-modal";
import { Deal } from "../../../apis/types/deal";
import ScheduleReviewModalDoneState from "./schedule-review-modal-done-state";
import ScheduleReviewModalStartState from "./schedule-review-modal-start-state";
import ScheduleReviewModalErrorState from "./schedule-review-modal-error-state";
import { UserAvatar } from "../../../components";
import {
    useGetSurveyIdQuery,
    useSendDealsBulkReviewMutation,
} from "../../../apis/deal.api";
import { useCompanyQuery } from "../../../apis/company.api";
import { useUserInfoQuery } from "../../../apis/user.api";
import { useGetCompanyBrandingQuery } from "../../../apis/branding.api";

interface ScheduleReviewModalProps {
    open: boolean;
    onClose: () => void;
    deal?: Deal | null;
}

const ScheduleReviewModal: FC<ScheduleReviewModalProps> = ({
    open,
    onClose,
    deal,
}) => {
    const isRescheduling =
        deal?.review_type === ReviewType.DIGITAL ||
        deal?.review_type === ReviewType.MANUAL;
    const companyId = getLoggedInCompanyId() ?? "";
    const [state, setState] =
        useState<"start" | "contact" | "error" | "done">("start");
    const [selectedTemplateInfo, setSelectedTemplateInfo] =
        useState<{
            templateId: string;
            reviewType: ReviewType;
        } | null>(null);
    const [selectedReviewerIndices, setSelectedReviewerIndices] = useState<
        number[]
    >([]);
    const [scheduledReviews, setScheduledReviews] = useState<
        { reviewerName: string; reviewId: string }[]
    >([]);
    const [isLoading, setIsLoading] = useState(false);
    const { result: surveyIdQuery } = useGetSurveyIdQuery();
    const { result: companyResult } = useCompanyQuery({ companyId });
    const { result: userInfo } = useUserInfoQuery();
    const { result: companyBranding } = useGetCompanyBrandingQuery({
        companyId,
    });
    const bulkScheduleReviewMutation = useSendDealsBulkReviewMutation();

    const reviewers = useMemo(
        () => deal?.contact?.contact_persons ?? [],
        [deal]
    );
    const avatar = deal?.contact?.logo ?? "";
    const companyName = deal?.contact?.company_name ?? "";
    const dealName = deal?.name ?? "";

    const handleReview = async () => {
        try {
            setIsLoading(true);
            if (deal) {
                const selectedReviewers = selectedReviewerIndices.map(
                    reviewerIndex => reviewers[reviewerIndex]
                );
                const result = await bulkScheduleReviewMutation.mutateAsync({
                    bulkReviewRequests: selectedReviewers.map(reviewer => {
                        return {
                            deal_id: deal.id,
                            template_id:
                                selectedTemplateInfo?.templateId ||
                                deal.template_id,
                            survey_questionnaire_id: surveyIdQuery?.data,
                            report_id: "b2dc5a04-8a38-49b2-8803-1f1267139dc3",
                            reviewer_name: reviewer.name,
                            reviewer_email: reviewer.email,
                            reviewer_phone_number: reviewer.phone_number,
                            requester_info: {
                                user_name: deal?.opportunityOwner?.name || "",
                                user_email: deal?.opportunityOwner?.email || "",
                                company_name: companyResult.isSuccess
                                    ? companyResult.data.name
                                    : userInfo.data.companies[0].name,
                            },
                            branding: {
                                primaryColors: companyBranding.data
                                    ?.primary_colors as string[],
                                secondaryColors: companyBranding.data
                                    ?.secondary_colors as string[],
                                logoUrl: companyBranding.data
                                    ?.logo_url as string,
                                logoFileName: companyBranding.data
                                    ?.logo_file_name as string,
                                reviewWelcomeText: companyBranding.data
                                    ?.review_welcome_text as string,
                            },
                            review_type: isRescheduling
                                ? selectedTemplateInfo?.reviewType ||
                                  ReviewType.DIGITAL
                                : ReviewType.NOT_YET_REVIEWED,
                        };
                    }),
                    getByReview: true,
                });
                setScheduledReviews(
                    selectedReviewers.map((reviewer, index) => ({
                        reviewerName: reviewer.name,
                        reviewId:
                            result.data?.length > index
                                ? result.data[index]
                                : "",
                    }))
                );
                setIsLoading(false);
                setState("done");
            }
        } catch {
            setIsLoading(false);
            setState("error");
        }
    };

    if (state === "error") {
        return (
            <Modal open={open} onClose={onClose}>
                <ScheduleReviewModalErrorState
                    onCancel={onClose}
                    onTryAgain={() => setState("start")}
                />
            </Modal>
        );
    } else if (state === "start") {
        return (
            <SimpleHeaderModal
                open={open}
                onClose={onClose}
                title={"Schedule Review"}
                leftIcon="plus"
            >
                <ScheduleReviewModalStartState
                    deal={deal}
                    onTemplateSelect={templateInfo => {
                        setSelectedTemplateInfo(templateInfo);
                        setState("contact");
                    }}
                    onClose={onClose}
                    onError={() => setState("error")}
                />
            </SimpleHeaderModal>
        );
    } else if (state === "done") {
        return (
            <SimpleHeaderModal
                open={open}
                leftIcon="arrow-left"
                onLeftIconClick={() => setState("contact")}
                onClose={onClose}
                title={"Review Sent"}
            >
                <ScheduleReviewModalDoneState
                    reviews={scheduledReviews}
                    onDone={onClose}
                />
            </SimpleHeaderModal>
        );
    }

    return (
        <SimpleHeaderModal
            open={open}
            leftIcon="arrow-left"
            onLeftIconClick={() => setState("start")}
            onClose={onClose}
            title={"Review recipient"}
        >
            <div className="w-[754px] flex flex-col pt-6 gap-5">
                <div className="flex flex-col gap-12">
                    <div className="flex flex-col gap-4 items-center justify-center">
                        <div className="flex p-0 rounded-full bg-black">
                            <UserAvatar
                                src={avatar}
                                size="lg"
                                placeholderIcon="building-05"
                            />
                        </div>
                        <div className="flex flex-col gap-2 items-center">
                            <Typography
                                type="text"
                                variant="h6"
                                size="lg"
                                weight="medium"
                                color="gray-900"
                            >
                                {companyName}
                            </Typography>
                            <Typography
                                type="text"
                                variant="h6"
                                size="sm"
                                color="gray-500"
                            >
                                {dealName}
                            </Typography>
                        </div>
                    </div>
                    <div className="flex flex-col gap-5">
                        <div className="flex flex-col gap-3">
                            {/* group item */}
                            {reviewers?.map((contact, index) => (
                                <div
                                    className={`flex p-4 gap-1 ${
                                        selectedReviewerIndices.includes(index)
                                            ? "bg-primary-50 border border-primary-600"
                                            : "border border-gray-200 cursor-pointer"
                                    } rounded-lg`}
                                    onClick={() => {
                                        if (
                                            selectedReviewerIndices.includes(
                                                index
                                            )
                                        ) {
                                            setSelectedReviewerIndices(prev =>
                                                prev.filter(
                                                    val => val !== index
                                                )
                                            );
                                        } else {
                                            setSelectedReviewerIndices(prev => [
                                                ...prev,
                                                index,
                                            ]);
                                        }
                                    }}
                                >
                                    <div className="flex flex-1 gap-3">
                                        <UserAvatar src="" />
                                        <div className="flex-grow flex-col gap-[2px]">
                                            <Typography
                                                type="text"
                                                variant="h6"
                                                size="md"
                                                weight="medium"
                                                color="gray-700"
                                            >
                                                {contact.name}
                                            </Typography>
                                            <Typography
                                                type="text"
                                                variant="h6"
                                                size="md"
                                                color="primary-600"
                                            >
                                                {/* convert uppercase to title case */}
                                                {contact.title === "IT"
                                                    ? contact.title
                                                    : contact.title
                                                          .split("_")
                                                          .map(
                                                              (word: string) =>
                                                                  word
                                                                      .charAt(0)
                                                                      .toUpperCase() +
                                                                  word
                                                                      .slice(1)
                                                                      .toLowerCase()
                                                          )
                                                          .join(" ")}
                                            </Typography>
                                        </div>
                                    </div>
                                    <CheckboxCircle
                                        checked={selectedReviewerIndices.includes(
                                            index
                                        )}
                                        color="primary"
                                    />
                                </div>
                            ))}
                        </div>
                        <div className="flex flex-col gap-2 w-full">
                            <Button
                                label={isLoading ? "Sending" : "Send"}
                                variant="contained"
                                color="primary"
                                className="py-[10px] px-[18px]"
                                disabled={
                                    selectedReviewerIndices.length === 0 ||
                                    isLoading
                                }
                                onClick={() => handleReview()}
                            />
                        </div>
                    </div>
                </div>
                {/* pagination */}
                <div className="flex justify-center items-center gap-4">
                    <div className="w-2 h-2 rounded-md bg-gray-100"></div>
                    <div className="w-2 h-2 rounded-md bg-primary-600"></div>
                    <div className="w-2 h-2 rounded-md bg-gray-100"></div>
                </div>
            </div>
        </SimpleHeaderModal>
    );
};

export default ScheduleReviewModal;
