import React, { FC } from "react";
import { ColorType } from "../../../types/colors";

export interface SimpleStepsProps {
    length?: number;
    current?: number;
    color?: ColorType;
}

const SimpleSteps: FC<SimpleStepsProps> = ({
    length = 3,
    current = 0,
    color = "primary",
}) => {
    return (
        <div className="flex gap-4">
            {Array.from({ length }).map((_, index) => (
                <div
                    className={`inline-block w-2.5 h-2.5 rounded-full ${
                        current === index ? `bg-${color}-700` : "bg-gray-100"
                    }`}
                />
            ))}
        </div>
    );
};

export default SimpleSteps;
