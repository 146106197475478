import { useForm } from "react-hook-form";
import { useCompanyQuery, useCompanyMutation } from "../../../apis/company.api";

export interface UseCompanyFormParams {
    companyId: string;
}

export const useCompanyForm = ({ companyId }: UseCompanyFormParams) => {
    const company = useCompanyQuery({
        companyId,
    });
    const updateCompany = useCompanyMutation();

    const defaultValues = {
        name: "",
        location: "",
        industry: "",
    };
    const values = company.result.isSuccess
        ? {
              name: company.result.data.name,
              location: company.result.data.location,
              industry: company.result.data.industry,
          }
        : defaultValues;

    const form = useForm({
        defaultValues,
        values,
    });

    const submit = form.handleSubmit(async ({ name, location, industry }) => {
        await updateCompany.mutateAsync({
            ...company.result.data,
            name,
            location,
            industry,
        });
        await company.invalidate();
    });
    return {
        form,
        submit,
    };
};

export default useCompanyForm;
