import {
    Typography,
    MailInputField,
    PasswordInputField,
    Checkbox,
    Button,
    TextInputField,
    SocialButton,
} from "trinity-components-library";
import { useNavigate, useSearchParams } from "react-router-dom";
import { localStorageKeys } from "../../data/localStorageKeys";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import { useState, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import UserPool from "../../libs/auth/cognitoPool";
import PasswordRules from "../components/password-rules/password-rules";
import { TrinityLogoIcon } from "../../assets/images";

export function Signup() {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams({ d: "" });
    const queryParams = searchParams.get("d");
    const data = useMemo(() => {
        if (queryParams) {
            const qData = JSON.parse(atob(queryParams));
            localStorage.setItem(
                localStorageKeys.INVITED_USER,
                JSON.stringify({ ...qData, isInvitedUser: true })
            );
            return qData;
        }
    }, [queryParams]);

    const form = useForm({
        mode: "onChange",
        defaultValues: {
            email: (data?.user?.email as string) || "",
            password: "",
            fullName: (data?.user?.name as string) || "",
            termsAgreementCheck: false,
            receiveUpdatesCheck: false,
            serverError: "",
        },
    });
    const {
        formState: { isValid, isDirty },
    } = form;

    const onSignup = form.handleSubmit(
        ({
            fullName,
            email,
            password,
            termsAgreementCheck,
            receiveUpdatesCheck,
        }) => {
            setIsLoading(true);
            UserPool.signUp(
                email,
                password,
                [
                    new CognitoUserAttribute({
                        Name: "name",
                        Value: fullName,
                    }),
                    new CognitoUserAttribute({
                        Name: "custom:receiveUpdates",
                        Value: String(receiveUpdatesCheck),
                    }),
                    new CognitoUserAttribute({
                        Name: "custom:isTermsAccepted",
                        Value: String(termsAgreementCheck),
                    }),
                ],
                [],
                err => {
                    setIsLoading(false);
                    if (!err) {
                        window.sessionStorage.setItem("pass", password);
                        navigate(
                            `/email-verification?email=${encodeURIComponent(
                                email
                            )}`
                        );
                    }
                    if (err && err.message) {
                        form.setError("serverError", {
                            type: "value",
                            message: err.message,
                        });
                    }
                }
            );
        }
    );

    const handleGoogleSignup = () => {
        const redirectLink = `${process.env.REACT_APP_COGNITO_SOCIAL_DOMAIN}/oauth2/authorize?identity_provider=Google&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&response_type=CODE&client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}`;
        window.location.href = redirectLink;
    };

    return (
        <div className="w-screen h-screen m-0 p-0 flex">
            <div className="flex-1 w-full h-full overflow-auto">
                <div className="flex flex-col justify-around my-24 m-auto w-[360px] content-center">
                    <div className="flex flex-col justify-center items-center">
                        <TrinityLogoIcon className="w-32 mb-2" />
                    </div>
                    <div className="flex flex-col justify-center items-center py-2 my-2">
                        <Typography
                            variant="h1"
                            size="md"
                            type="display"
                            weight="bold"
                        >
                            Sign up
                        </Typography>
                    </div>
                    <div className="flex flex-col gap-5">
                        <Controller
                            name="fullName"
                            control={form.control}
                            rules={{
                                required: true,
                            }}
                            render={({ field, fieldState }) => (
                                <TextInputField
                                    label="Name"
                                    fullWidth
                                    placeholder="Enter your name"
                                    value={field.value}
                                    onChange={value => {
                                        field.onChange(value);
                                        form.clearErrors("serverError");
                                    }}
                                    error={
                                        fieldState.error?.type === "required"
                                            ? "Required"
                                            : ""
                                    }
                                />
                            )}
                        />
                        <Controller
                            name="email"
                            control={form.control}
                            rules={{
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: "Invalid email address",
                                },
                                required: true,
                            }}
                            render={({ field, fieldState }) => (
                                <MailInputField
                                    label="Email"
                                    fullWidth
                                    placeholder="Enter your email"
                                    hideIcon
                                    value={field.value}
                                    onChange={value => {
                                        field.onChange(value);
                                        form.clearErrors("serverError");
                                    }}
                                    error={
                                        fieldState.error?.type === "required"
                                            ? "Required"
                                            : fieldState.error?.message ?? ""
                                    }
                                    disabled={!!data?.user?.email}
                                />
                            )}
                        />
                        <Controller
                            name="password"
                            control={form.control}
                            rules={{
                                minLength: 8,
                                pattern:
                                    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
                                required: true,
                            }}
                            render={({ field, fieldState }) => {
                                const password = field.value;
                                const isPasswordDirty = fieldState.isDirty;
                                return (
                                    <div>
                                        <PasswordInputField
                                            label="Password"
                                            fullWidth
                                            placeholder="Create a password"
                                            onChange={value => {
                                                field.onChange(value);
                                                form.clearErrors("serverError");
                                            }}
                                            value={password}
                                            hasError={
                                                isPasswordDirty &&
                                                fieldState.invalid
                                            }
                                        />
                                        {isPasswordDirty && (
                                            <div className="mt-3">
                                                <PasswordRules
                                                    password={field.value}
                                                />
                                            </div>
                                        )}
                                    </div>
                                );
                            }}
                        />
                        <Controller
                            name="serverError"
                            control={form.control}
                            render={({ fieldState }) => (
                                <Typography size="sm" color="error-600">
                                    {fieldState.error?.message}
                                </Typography>
                            )}
                        />
                    </div>
                    <div className="flex flex-col gap-2 mt-4">
                        <Controller
                            name="termsAgreementCheck"
                            control={form.control}
                            rules={{
                                required: true,
                                value: true,
                            }}
                            render={({ field }) => (
                                <div className="flex gap-1">
                                    <Checkbox
                                        checked={field.value}
                                        onChange={value => {
                                            field.onChange(value);
                                            form.clearErrors("serverError");
                                        }}
                                    />
                                    <Typography
                                        variant="p"
                                        size="sm"
                                        weight="medium"
                                        className="pl-2"
                                    >
                                        I agree to Terms and Privacy Policy
                                    </Typography>
                                </div>
                            )}
                        />
                        <Controller
                            name="receiveUpdatesCheck"
                            control={form.control}
                            render={({ field }) => (
                                <div className="flex gap-1">
                                    <Checkbox
                                        checked={field.value}
                                        onChange={value => {
                                            field.onChange(value);
                                            form.clearErrors("serverError");
                                        }}
                                    />
                                    <Typography
                                        variant="p"
                                        size="sm"
                                        weight="medium"
                                        className="pl-2"
                                    >
                                        I agree to receive Trinity news and
                                        updates
                                    </Typography>
                                </div>
                            )}
                        />
                    </div>
                    <div className="flex flex-col gap-4 mt-6">
                        <Button
                            color="primary"
                            label="Get Started"
                            size="lg"
                            disabled={!isValid || !isDirty || isLoading}
                            onClick={onSignup}
                        />
                        {!data?.user && (
                            <SocialButton
                                variant="google"
                                className="!rounded-lg font-semibold !py-[10px]"
                                size="lg"
                                label="Sign up with Google"
                                onClick={handleGoogleSignup}
                            />
                        )}
                    </div>
                    <div className="flex justify-center gap-2 mt-8">
                        <Typography variant="span" size="sm" weight="normal">
                            Already have an account?
                        </Typography>
                        <Typography
                            variant="span"
                            size="sm"
                            color="primary-700"
                            weight="semibold"
                            onClick={() => navigate("/login")}
                            className="cursor-pointer"
                        >
                            Log in
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Signup;
