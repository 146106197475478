import axios from "../axios-instance";
import { useMutation } from "react-query";

export const useSalesforceAuthorizationMutation = () => {
    return useMutation(
        async ({ code, state }: { code: string; state: string }) => {
            const response = await axios.post(
                "/main/auth/salesforce/authorize",
                {
                    code,
                    state,
                }
            );
            return response.data;
        }
    );
};
