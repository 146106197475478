import { useEffect, useReducer, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    Button,
    Divider,
    IconButton,
    Badge,
    Typography,
} from "trinity-components-library";
import { useGetDealQuery } from "../../apis/deal.api";
import { DealRelations } from "../../apis/types/deal-relations";
import { capitalize } from "../../utils";
import { DetailItemProps } from "./components/detail-item";
import { QuestionSection } from "../components/question-section/question-section";
import ReportDetails from "./components/report-details";
import ShareReportModal from "./components/share-report-modal";
import { differenceInDays } from "date-fns";
import { useGetSurveyQuestionnaireQuery } from "../../apis/survey-questionnaire.api";
import { useGetCommonTemplateQuery } from "../../apis/template.api";
import UserProfile from "../../components/user-profile/user-profile";
import {
    useGetReportByDealIdQuery,
    usePostGenerateReportMutation,
} from "../../apis/reports.api";
import useToast from "../../hooks/useToast";
import NoDataState from "./components/no-data-state";
import FindingsAndRecommendations from "./components/findings-and-recommendations";
import Summary from "./components/summary";
import {
    ModeType,
    initialState,
    reducer,
    Actions,
} from "./reducers/summaryAndFindings";
import { TemplateCategoryKey } from "../../apis/types/template";
import { Review } from "../../apis/types/review";
import { SurveyStatus } from "../../apis/types/survey-status";
import { Loading } from "../../components";

interface ReportInfoProps {
    templateCategory: TemplateCategoryKey;
}

const ReportInfo = ({ templateCategory }: ReportInfoProps) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { summary, items, currentIndex, summaryMode, itemsMode } = state;
    const isEmpty =
        itemsMode === ModeType.EMPTY && summaryMode === ModeType.EMPTY;
    const { id: dealId = "" } = useParams();
    const navigate = useNavigate();
    const [shareModalOpen, setShareModalOpen] = useState(false);
    const { createToast } = useToast();
    const { result: report, invalidate: invalidateReport } = useGetDealQuery({
        id: dealId,
        include: [
            DealRelations.REVIEWS,
            DealRelations.CONTACT,
            DealRelations.CUSTOM_TEMPLATE,
            DealRelations.GLOBAL_TEMPLATE,
        ],
    });

    const { result: survey } = useGetSurveyQuestionnaireQuery(
        {
            id: report.data?.reviews?.length
                ? report.data.reviews[0].survey_questionnaire_id ?? ""
                : "",
        },
        { enabled: report.isSuccess }
    );

    const onlyHasSurveyQuestionnaires =
        report.data?.reviews?.every((review: Review) => {
            return !review?.template_id;
        }) ?? false;
    const surveyQuestionnaires =
        survey.data?.template?.section?.map((sec: { questions: any }): any => ({
            ...sec,
            templateQuestions: sec.questions,
        })) ?? [];

    const reviews =
        report.data?.reviews?.filter(review => {
            if (
                review?.survey_status?.toUpperCase() !== SurveyStatus.COMPLETED
            ) {
                return false;
            }
            if (
                review.customTemplate !== null &&
                review.customTemplate?.templateCategory.referenceKey ===
                    templateCategory
            ) {
                return review;
            }
            if (
                review.globalTemplate !== null &&
                review.globalTemplate?.templateCategory.referenceKey ===
                    templateCategory
            ) {
                return review;
            }
            if (onlyHasSurveyQuestionnaires) {
                return review;
            }
            return false;
        }) ?? [];

    const templateId = reviews && reviews.length ? reviews[0]?.template_id : "";

    const { result: generatedReport, invalidate: invalidateGeneratedReport } =
        useGetReportByDealIdQuery({
            dealId,
            templateId,
        });

    useEffect(() => {
        if (generatedReport.isSuccess) {
            if (
                generatedReport?.data.length > 0 &&
                generatedReport?.data[0].summary != null
            ) {
                dispatch({
                    type: Actions.SUMMARY_FETCH,
                    payload: generatedReport?.data[0].summary,
                });
            }

            if (
                generatedReport?.data.length > 0 &&
                generatedReport?.data[0].recommendations !== undefined &&
                generatedReport?.data[0].recommendations?.length > 0
            ) {
                dispatch({
                    type: Actions.ITEMS_FETCH,
                    payload: generatedReport.data[0].recommendations,
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [generatedReport.isSuccess]);

    const generatedReportFileUrl = generatedReport.data?.length
        ? generatedReport.data[0].reportFileUrl
        : "";
    const generateReportMutation = usePostGenerateReportMutation();
    const { result: commonTemplate } = useGetCommonTemplateQuery(
        {
            id: templateId,
        },
        {
            enabled: !!templateId,
        }
    );

    const reportCompany = report.data?.contact?.company_name ?? "";

    const sections = (
        !onlyHasSurveyQuestionnaires
            ? commonTemplate?.data?.templateSections ?? surveyQuestionnaires
            : surveyQuestionnaires
    )?.map(
        (item: {
            templateQuestions: { [x: string]: any; question: any }[];
        }) => ({
            ...item,
            templateQuestions: item.templateQuestions.map(
                ({ question, ...rest }) => ({
                    ...rest,
                    id: rest?.id ?? rest?.question_id,
                    question: question.replaceAll("[company]", reportCompany),
                })
            ),
        })
    );

    const recipients = report.isSuccess
        ? (report.data?.reviews ?? []).map(
              (answer: { survey_status: string; reviewer_name: any }) => {
                  const status =
                      answer.survey_status === "NOT_STARTED"
                          ? ("Sent" as const)
                          : ("Responded" as const);
                  return {
                      name: answer.reviewer_name,
                      status: status,
                  };
              }
          )
        : [];

    const details: DetailItemProps[] = [
        {
            icon: "target-04",
            name: "Opportunity Name",
            value: report.data?.name ?? "",
        },
        {
            icon: "bank-note-03",
            name: "Opportunity Value",
            value: report.data?.value
                ? `${Intl.NumberFormat().format(report.data.value)}`
                : "",
        },
        {
            icon: "calendar",
            name: "Sales Duration",
            value: report?.data?.reviews?.length
                ? report.data?.reviews[0]?.deal?.cycle_duration
                : "",
        },
        {
            icon: "marker-pin-01",
            name: "Location",
            value: report?.data?.reviews?.length
                ? report?.data?.reviews[0]?.deal?.location
                : "",
        },
        {
            icon: "user-01",
            name: "Opportunity Owner",
            value: report?.data?.reviews?.length
                ? report.data.reviews[0]?.requester_info?.user_name
                : "",
        },
    ];

    const finalReportFileUrl =
        report.data?.report_file || generatedReportFileUrl || "";

    return (
        <div className="h-full flex">
            <div className="flex-1 overflow-y-auto overflow-x-hidden">
                <div className="flex flex-col p-8">
                    <div className="flex flex-col gap-8">
                        <div className="flex gap-2">
                            <Button
                                label="Back"
                                icon="arrow-left"
                                variant="link"
                                color="gray"
                                className="!px-0"
                                onClick={() => navigate(-1)}
                            />
                        </div>
                        <div className="flex flex-col gap-3">
                            <div className="flex justify-between items-center flex-wrap gap-4 py-6">
                                <div className="flex gap-3 items-center">
                                    {report.data?.contact && (
                                        <UserProfile
                                            avatar={report.data.contact.logo}
                                            name={
                                                report.data.contact.company_name
                                            }
                                            placeholderType="placeholder"
                                            placeholderIcon="building-05"
                                        />
                                    )}
                                    {report.data?.review_type && (
                                        <Badge
                                            label={capitalize(
                                                report.data.review_type.replaceAll(
                                                    "_",
                                                    " "
                                                )
                                            )}
                                            color="primary"
                                            size="sm"
                                        />
                                    )}
                                </div>
                                {report.status === "loading" ||
                                generatedReport.status ===
                                    "loading" ? null : finalReportFileUrl ? (
                                    <div className="flex gap-3">
                                        <IconButton
                                            icon="download-01"
                                            variant="outlined"
                                            color="gray"
                                            onClick={() => {
                                                const anchorEle =
                                                    document.createElement("a");
                                                anchorEle.href =
                                                    finalReportFileUrl;
                                                anchorEle.target = "_blank";
                                                anchorEle.click();
                                            }}
                                        />
                                        <IconButton
                                            icon="mail-01"
                                            variant="contained"
                                            color="primary"
                                            onClick={() =>
                                                setShareModalOpen(true)
                                            }
                                        />
                                    </div>
                                ) : templateId ? (
                                    <Button
                                        label={
                                            generateReportMutation.isLoading
                                                ? "Generating PDF"
                                                : "Generate PDF"
                                        }
                                        disabled={
                                            generateReportMutation.isLoading
                                        }
                                        loading={
                                            generateReportMutation.isLoading
                                        }
                                        onClick={async () => {
                                            try {
                                                await generateReportMutation.mutateAsync(
                                                    {
                                                        dealId,
                                                        templateId,
                                                        initiate: true,
                                                    }
                                                );
                                                invalidateReport();
                                                invalidateGeneratedReport();
                                            } catch {
                                                createToast({
                                                    type: "error",
                                                    message:
                                                        "Failed to generate pdf.",
                                                    icon: "alert-circle",
                                                });
                                            }
                                        }}
                                    />
                                ) : null}
                            </div>
                            {recipients.length > 0 ? (
                                <div>
                                    <ReportDetails
                                        recipients={recipients}
                                        detailItems={details}
                                    />
                                    <QuestionSection
                                        sections={sections.map(
                                            ({
                                                name,
                                                templateQuestions: questions,
                                            }: any) => {
                                                return {
                                                    name,
                                                    questions: questions.map(
                                                        (question: {
                                                            id: any;
                                                            options: any;
                                                            type: string;
                                                            question: any;
                                                        }) => {
                                                            return {
                                                                key: question.id,
                                                                id: question.id,
                                                                options:
                                                                    question.options,
                                                                type: question.type,
                                                                question:
                                                                    question.question,
                                                                answers: reviews
                                                                    .filter(
                                                                        (review: {
                                                                            answers: any;
                                                                        }) =>
                                                                            !!review.answers
                                                                    )
                                                                    .map(
                                                                        (review: {
                                                                            answers: any;
                                                                            reviewer_name: any;
                                                                        }) => {
                                                                            const answerObj =
                                                                                (
                                                                                    review?.answers ??
                                                                                    []
                                                                                ).find(
                                                                                    (
                                                                                        ans: any
                                                                                    ) =>
                                                                                        ans.question_id ===
                                                                                        question.id
                                                                                );
                                                                            const rtf =
                                                                                new Intl.RelativeTimeFormat(
                                                                                    "en",
                                                                                    {
                                                                                        numeric:
                                                                                            "auto",
                                                                                        style: "long",
                                                                                    }
                                                                                );

                                                                            const days =
                                                                                differenceInDays(
                                                                                    answerObj?.timestamp ??
                                                                                        0,
                                                                                    Date.now()
                                                                                );
                                                                            const postedAt =
                                                                                rtf.format(
                                                                                    days,
                                                                                    "day"
                                                                                );

                                                                            return {
                                                                                reviewerName:
                                                                                    review.reviewer_name,
                                                                                postedAt,
                                                                                value:
                                                                                    answerObj
                                                                                        ?.answer
                                                                                        .value ??
                                                                                    (question.type ===
                                                                                    "TEXT"
                                                                                        ? ""
                                                                                        : []),
                                                                                attachments:
                                                                                    answerObj
                                                                                        ?.answer
                                                                                        .attachments ??
                                                                                    [],
                                                                            };
                                                                        }
                                                                    ),
                                                            };
                                                        }
                                                    ),
                                                };
                                            }
                                        )}
                                    />
                                </div>
                            ) : (
                                <div className="flex justify-center">
                                    <Loading />
                                </div>
                            )}
                            <ShareReportModal
                                open={shareModalOpen}
                                setOpen={setShareModalOpen}
                                dealId={dealId}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex flex-1  border-l border-l-gray-200 overflow-y-auto">
                <div className="w-full h-full flex-col px-6 pb-10 pt-[38px]">
                    <div className="flex items-center justify-between gap-3 mb-8">
                        <Typography color="gray-900" weight="medium">
                            Summary, Findings & Recommendations
                        </Typography>
                    </div>
                    <Divider className="mb-6" />
                    <div className="flex flex-col flex-1  ">
                        {isEmpty && (
                            <div className="flex flex-1 justify-center items-center mt-40">
                                <NoDataState
                                    title="No data found"
                                    description="Contact to admin for Findings and Recommendations."
                                />
                            </div>
                        )}
                        {!isEmpty && (
                            <div className="mb-6">
                                <Summary
                                    value={summary}
                                    mode={summaryMode}
                                    dispatch={dispatch}
                                />
                            </div>
                        )}

                        {!isEmpty && (
                            <FindingsAndRecommendations
                                items={items}
                                mode={itemsMode}
                                currentIndex={currentIndex}
                                dispatch={dispatch}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReportInfo;
