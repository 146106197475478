import { FC } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { Upload } from "trinity-components-library";
import { useGetCompanyBrandingQuery } from "../../../apis/branding.api";
import { useReadFileContent } from "../../../hooks/file";
import FormItem from "../../components/form-item";

export interface CompanyBrandingItemProps {
    form: UseFormReturn<any>;
    companyId: string;
}

const CompanyBrandingItem: FC<CompanyBrandingItemProps> = ({
    form,
    companyId,
}) => {
    const branding = useGetCompanyBrandingQuery({ companyId });
    const logoFileContent = useReadFileContent({
        file: form.getValues().logoFile,
        readAs: "data-url",
    });
    const imageSource = logoFileContent
        ? logoFileContent
        : branding.result.isSuccess
        ? branding.result.data.logo_url ?? ""
        : "";

    return (
        <FormItem
            title="Company Branding"
            helperText="Update your company logo and then choose where you want it to display."
        >
            <Controller
                name="logoFile"
                control={form.control}
                render={({ field }) => (
                    <div className="flex flex-col flex-wrap lg:flex-row gap-8">
                        <div>
                            <img
                                src={imageSource}
                                alt="selected logo"
                                className="w-32 object-contain"
                            />
                        </div>
                        <div className="grow">
                            <Upload
                                accept={{
                                    "image/jpeg": [".jpeg"],
                                    "image/png": [".png"],
                                }}
                                onChange={files => {
                                    if (files && files.length) {
                                        const selectedFile = files[0];
                                        field.onChange(selectedFile);
                                    }
                                }}
                            />
                        </div>
                    </div>
                )}
            />
        </FormItem>
    );
};

export default CompanyBrandingItem;
