import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Typography } from "trinity-components-library";
import {
    useGetCustomTemplateQuery,
    useGetGlobalTemplateQuery,
    usePatchCustomTemplateActivatedStatus,
    usePatchCustomTemplateDefaultStatus,
    usePatchGlobalTemplateActivatedStatus,
} from "../../apis/template.api";
import { CustomTemplate } from "../../apis/types/template";
import TemplateCard from "../../components/template-card/template-card";
import { useAuth } from "../../libs/auth/auth";
import getLoggedInCompanyId from "../../libs/getLoggedInCompanyId";
import { Scopes } from "../../types";
import { QuestionSection } from "../components/question-section/question-section";

export interface TemplateInfoProps {
    isCustom?: boolean;
}

const TemplateInfo: FC<TemplateInfoProps> = ({ isCustom = false }) => {
    const navigate = useNavigate();
    const { id = "" } = useParams();
    const { isTrinityAdmin, user } = useAuth();
    const [isActivated, setIsActivated] = useState(true);
    const [isDefault, setIsDefault] = useState(false);
    const { result: customTemplate, invalidate: invalidateCustomTemplate } =
        useGetCustomTemplateQuery({ id }, { enabled: isCustom });
    const { result: globalTemplate, invalidate: invalidateGlobalTemplate } =
        useGetGlobalTemplateQuery({ id }, { enabled: !isCustom });

    const template = isCustom ? customTemplate : globalTemplate;
    const companyId = getLoggedInCompanyId() ?? "";
    const currentCompany = user?.companies?.find(
        (company: any) => company.id === companyId
    );
    const allowedRoles = [Scopes.TRINITY_ADMIN, Scopes.ADMIN];
    const isAllowedRole = currentCompany?.scopes
        ? allowedRoles?.includes(currentCompany?.scopes)
        : false;

    useEffect(() => {
        if (template.status === "success") {
            setIsActivated(template.data.activated);
            if (isCustom) {
                setIsDefault((template.data as CustomTemplate).default);
            }
        }
    }, [isCustom, template.data, template.status]);

    const branding: any = (template.data as CustomTemplate)?.branding ?? {};
    const colorHashCode = branding?.primary_colors?.length
        ? branding.primary_colors[0]
        : branding?.secondary_colors?.length
        ? branding.secondary_colors[0]
        : "#201547";

    const updateCustomTemplateDefaultStatus =
        usePatchCustomTemplateDefaultStatus();
    const updateCustomTemplateActivatedStatus =
        usePatchCustomTemplateActivatedStatus();
    const updateGlobalTemplateActivatedStatus =
        usePatchGlobalTemplateActivatedStatus();

    const refreshData = () => {
        if (isCustom) {
            invalidateCustomTemplate();
        } else {
            invalidateGlobalTemplate();
        }
    };

    const toggleActiveStatus = async () => {
        const currentStatus = isActivated;
        try {
            setIsActivated(!currentStatus);
            const requestPayload = {
                activated: !currentStatus,
                id,
            };
            if (isCustom) {
                await updateCustomTemplateActivatedStatus.mutateAsync(
                    requestPayload
                );
            } else {
                await updateGlobalTemplateActivatedStatus.mutateAsync(
                    requestPayload
                );
            }
            refreshData();
        } catch {
            setIsActivated(currentStatus);
        }
    };

    const toggleDefaultStatus = async () => {
        if (isCustom) {
            const currentStatus = isDefault;
            try {
                setIsDefault(!currentStatus);
                await updateCustomTemplateDefaultStatus.mutateAsync({
                    id,
                    templateCategoryId:
                        template.data?.templateCategory?.id ?? "",
                });
                refreshData();
            } catch {
                setIsDefault(currentStatus);
            }
        }
    };

    return (
        <div className="flex flex-col gap-8 p-8">
            <div className="flex flex-col gap-8">
                <div className="flex gap-2">
                    <Button
                        label="Back"
                        icon="arrow-left"
                        variant="link"
                        color="gray"
                        className="!px-0"
                        onClick={() => navigate(-1)}
                    />
                </div>
                {template.isSuccess ? (
                    <div className="flex flex-col gap-3">
                        <TemplateCard
                            id={template.data.id}
                            categoryId={template.data.templateCategory.id}
                            type={
                                template.data.type === "digital"
                                    ? "digital"
                                    : "consulting"
                            }
                            name={
                                template.data.name ??
                                template.data.templateCategory.name
                            }
                            description="Provides an in-depth understanding of the feedback and learning’s provided by prospects against a variety of key criteria via a digital review."
                            isCustom={isCustom}
                            expanded
                            primaryColor={colorHashCode}
                            companyLogo={branding?.logo_url}
                            isActivated={isActivated}
                            isDefault={isDefault}
                            toggleActiveStatus={toggleActiveStatus}
                            toggleDefaultStatus={toggleDefaultStatus}
                            categoryType={
                                template.data?.templateCategory
                                    ?.referenceKey as
                                    | "win_loss"
                                    | "value_realisation"
                                    | "uncategorised"
                            }
                            hideActiveToggle={
                                (!isCustom && !isTrinityAdmin) ||
                                (isCustom && !isTrinityAdmin && !isAllowedRole)
                            }
                        />
                        <div className="flex flex-col gap-3">
                            <QuestionSection
                                sections={(
                                    template.data?.templateSections ?? []
                                ).map(({ name, templateQuestions }) => ({
                                    name,
                                    questions: templateQuestions.map(
                                        ({
                                            id,
                                            options,
                                            type,
                                            question,
                                            sampleAnswers,
                                        }) => ({
                                            id,
                                            options,
                                            type,
                                            question,
                                            answers: (sampleAnswers ?? []).map(
                                                answer => ({
                                                    reviewerName:
                                                        "Kristin Watson",
                                                    postedAt: "2 days ago",
                                                    ...answer,
                                                })
                                            ),
                                        })
                                    ),
                                }))}
                            />
                        </div>
                    </div>
                ) : (
                    <div className="flex justify-center">
                        <Typography>
                            {template.isError ? "Error" : "Loading..."}
                        </Typography>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TemplateInfo;
