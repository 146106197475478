import React, { FC, useState } from "react";
import { IconType } from "trinity-components-library";
import BulkFileUploadModal from "./bulk-file-upload-modal";
import DownloadBulkUploadTemplatesModal from "./download-bulk-upload-templates-modal";
import ModalCardOption, { ModalCardOptionProps } from "./modal-card-option";
import SimpleHeaderModal from "./simple-header-modal";

export interface BulkUploadModalProps {
    leftIcon?: IconType;
    onLeftIconClick?: () => void;
    open: boolean;
    onClose?: () => void;
}

const BulkUploadModal: FC<BulkUploadModalProps> = ({
    open,
    onClose,
    ...props
}) => {
    const [selected, setSelected] =
        useState<"none" | "bulkFileUpload" | "downloadBulkTemplates">("none");

    const cards: ModalCardOptionProps[] = [
        {
            title: "Select files to upload",
            description: "Quickly add single opportunities to be reviewed",
            color: "warning",
            onSelect: () => setSelected("bulkFileUpload"),
        },
        {
            title: "Download templates",
            description:
                "Importing large volumes of opportunities is easy using our CSV template.",
            color: "success",
            onSelect: () => setSelected("downloadBulkTemplates"),
            icon: "download-01",
        },
    ];

    return (
        <>
            <SimpleHeaderModal
                title="Bulk upload"
                open={open && selected === "none"}
                onClose={() => {
                    setSelected("none");
                    onClose?.();
                }}
                {...props}
            >
                <div className="w-[752px] flex gap-3">
                    {cards.map(props => (
                        <ModalCardOption fullWidth {...props} />
                    ))}
                </div>
            </SimpleHeaderModal>
            <BulkFileUploadModal
                open={open && selected === "bulkFileUpload"}
                onClose={onClose}
                leftIcon="arrow-left"
                onLeftIconClick={() => setSelected("none")}
            />
            <DownloadBulkUploadTemplatesModal
                open={open && selected === "downloadBulkTemplates"}
                onClose={onClose}
                leftIcon="arrow-left"
                onLeftIconClick={() => setSelected("none")}
            />
        </>
    );
};

export default BulkUploadModal;
