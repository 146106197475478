import { Deal } from "./deal";

export enum ContactStatus {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
}

export interface ContactPerson {
    email: string;
    name: string;
    phone_number: string;
    primary_contact: boolean;
    review_id: string;
    title: string;
}

export interface Contact {
    id: string;

    company_id: string;

    company_name: string;

    status: ContactStatus;

    contact_persons: ContactPerson[];

    logo: string;

    website?: string;

    created_at: Date;

    updated_at: Date;

    company: any;

    deals: Deal[];
}
