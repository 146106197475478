import React, { FC, useState } from "react";
import {
    Dropdown,
    Button,
    Typography,
    Checkbox,
    Divider,
} from "trinity-components-library";
import { DealOutcome } from "../../../apis/types/deal-outcome";
import { ReviewType } from "../../../apis/types/review-type";

export interface FilterSelectorValue {
    reportType: ReviewType[];
    result: DealOutcome[];
}

interface FilterSelectorProps {
    value: FilterSelectorValue;
    onChange: (v: FilterSelectorValue) => void;
}

const FilterSelector: FC<FilterSelectorProps> = ({ value, onChange }) => {
    const [open, setOpen] = useState(false);
    const [internalValue, setInternalValue] = useState<FilterSelectorValue>({
        reportType: [],
        result: [],
    });

    const onReportTypeChange = (reportType: ReviewType, check: boolean) => {
        const updated: FilterSelectorValue =
            check && !internalValue.reportType.includes(reportType)
                ? {
                      ...internalValue,
                      reportType: [...internalValue.reportType, reportType],
                  }
                : {
                      ...internalValue,
                      reportType: internalValue.reportType.filter(
                          val => val !== reportType
                      ),
                  };
        setInternalValue(updated);
    };

    const onResultChange = (resultType: DealOutcome, check: boolean) => {
        const updated: FilterSelectorValue =
            check && !internalValue.result.includes(resultType)
                ? {
                      ...internalValue,
                      result: [...internalValue.result, resultType],
                  }
                : {
                      ...internalValue,
                      result: internalValue.result.filter(
                          val => val !== resultType
                      ),
                  };
        setInternalValue(updated);
    };

    const isReportChecked = (reportType: ReviewType) =>
        internalValue.reportType.includes(reportType);
    const isResultChecked = (result: DealOutcome) =>
        internalValue.result.includes(result);

    return (
        <Dropdown
            position="bottom-left"
            onChange={v => {
                setInternalValue(value);
                setOpen(v);
            }}
            open={open}
        >
            <Dropdown.Button>
                <Button
                    label="Filter"
                    icon="filter-lines"
                    variant="outlined"
                    color="gray"
                />
            </Dropdown.Button>
            <Dropdown.Panel>
                <div className="bg-white w-72 my-2 shadow-xl rounded-lg border border-gray-200">
                    <div className="px-6 py-5 flex flex-col gap-4">
                        <Typography color="gray-500">Report Type</Typography>
                        <div className="flex flex-col gap-3">
                            <Checkbox
                                size="sm"
                                label="Consulting"
                                checked={isReportChecked(ReviewType.MANUAL)}
                                onChange={check =>
                                    onReportTypeChange(ReviewType.MANUAL, check)
                                }
                            />
                            <Checkbox
                                size="sm"
                                label="Digital"
                                checked={isReportChecked(ReviewType.DIGITAL)}
                                onChange={check =>
                                    onReportTypeChange(
                                        ReviewType.DIGITAL,
                                        check
                                    )
                                }
                            />
                        </div>
                        <Typography color="gray-500">Result</Typography>
                        <div className="flex flex-col gap-3">
                            <Checkbox
                                size="sm"
                                label="Win"
                                checked={isResultChecked(DealOutcome.ClosedWon)}
                                onChange={check =>
                                    onResultChange(DealOutcome.ClosedWon, check)
                                }
                            />
                            <Checkbox
                                size="sm"
                                label="Loss"
                                checked={isResultChecked(
                                    DealOutcome.ClosedLost
                                )}
                                onChange={check =>
                                    onResultChange(
                                        DealOutcome.ClosedLost,
                                        check
                                    )
                                }
                            />
                        </div>
                    </div>
                    <Divider />
                    <div className="flex gap-3 p-4">
                        <Button
                            label="Cancel"
                            color="gray"
                            variant="outlined"
                            className="flex-1"
                            onClick={() => {
                                setOpen(false);
                                setInternalValue(value);
                            }}
                        />
                        <Button
                            label="Apply"
                            className="flex-1"
                            onClick={() => {
                                setOpen(false);
                                onChange(internalValue);
                            }}
                        />
                    </div>
                </div>
            </Dropdown.Panel>
        </Dropdown>
    );
};

export default FilterSelector;
