import React, { FC, useState } from "react";
import {
    Dropdown,
    Button,
    Typography,
    Checkbox,
    Divider,
} from "trinity-components-library";

export interface FilterSectionItem {
    id: string;
    name: string;
}

export interface FilterSection {
    id: string;
    name: string;
    items: FilterSectionItem[];
}

export interface FilterSelectorValue {
    [sectionId: string]: {
        [sectionItemId: string]: boolean;
    };
}

interface FilterSelectorProps {
    sections: FilterSection[];
    value: FilterSelectorValue;
    onChange: (v: FilterSelectorValue) => void;
}

const FilterSelector: FC<FilterSelectorProps> = ({
    value,
    onChange,
    sections,
}) => {
    const [open, setOpen] = useState(false);
    const [internalValue, setInternalValue] = useState<FilterSelectorValue>(
        sections.reduce((acc, section) => {
            return {
                ...acc,
                [section.id]: section.items.reduce(
                    (secAcc, secItem) => ({ ...secAcc, [secItem.id]: false }),
                    {}
                ),
            };
        }, {})
    );

    const setSectionItemStatus = (
        sectionId: string,
        sectionItemId: string,
        val: boolean
    ) => {
        if (internalValue?.[sectionId]?.[sectionItemId] !== undefined) {
            setInternalValue(previous => ({
                ...previous,
                [sectionId]: { ...previous[sectionId], [sectionItemId]: val },
            }));
        }
    };

    return (
        <Dropdown
            position="bottom-left"
            onChange={v => {
                setInternalValue(value);
                setOpen(v);
            }}
            open={open}
        >
            <Dropdown.Button>
                <Button
                    label="Filter"
                    icon="filter-lines"
                    variant="outlined"
                    color="gray"
                />
            </Dropdown.Button>
            <Dropdown.Panel>
                <div className="bg-white w-72 my-2 shadow-xl rounded-lg border border-gray-200">
                    <div className="px-6 py-5 flex flex-col gap-4">
                        {sections.map(({ id, name, items }) => {
                            return (
                                <div className="flex flex-col gap-4" key={id}>
                                    <Typography color="gray-500">
                                        {name}
                                    </Typography>
                                    <div className="flex flex-col gap-3">
                                        {items.map(item => {
                                            return (
                                                <Checkbox
                                                    key={item.id}
                                                    size="sm"
                                                    label={item.name}
                                                    checked={
                                                        internalValue?.[id]?.[
                                                            item.id
                                                        ] ?? false
                                                    }
                                                    onChange={check =>
                                                        setSectionItemStatus(
                                                            id,
                                                            item.id,
                                                            check
                                                        )
                                                    }
                                                />
                                            );
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <Divider />
                    <div className="flex gap-3 p-4">
                        <Button
                            label="Cancel"
                            color="gray"
                            variant="outlined"
                            className="flex-1"
                            onClick={() => {
                                setOpen(false);
                                setInternalValue(value);
                            }}
                        />
                        <Button
                            label="Apply"
                            className="flex-1"
                            onClick={() => {
                                setOpen(false);
                                onChange(internalValue);
                            }}
                        />
                    </div>
                </div>
            </Dropdown.Panel>
        </Dropdown>
    );
};

export default FilterSelector;
