import {
    Button,
    DateSinglePicker,
    Divider,
    Dropdown,
    formatDatePickerInput,
    Icon,
    parseCalendarDate,
    Slider,
    Typography,
    useToast,
} from "trinity-components-library";
import { isToday } from "date-fns";
import { FC, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Toaster } from "react-hot-toast";
import {
    useGetSalesforceReviewsConfigQuery,
    useUpdateSalesforceReviewsConfigMutation,
} from "../../../apis/salesforce.api";
import { CheckboxCard } from "../../../components/checkbox-card";
import { RadioCard } from "../../../components/radio-card";
import getLoggedInCompanyId from "../../../libs/getLoggedInCompanyId";
import SimpleHeaderModal from "../../../pages/opportunities/components/simple-header-modal";

const getTodayAsISO = () => new Date().toISOString();

interface ConfigurationSalesforceIntegrationModalProps {
    open: boolean;
    onClose: () => void;
}

const ConfigureSalesforceIntegrationModal: FC<ConfigurationSalesforceIntegrationModalProps> =
    ({ open: modalOpen, onClose: onModalClose }) => {
        const { createToast } = useToast();
        const [datePickerOpen, setDatePickerOpen] = useState(false);
        const companyId = getLoggedInCompanyId() ?? "";
        const { result: configQuery } = useGetSalesforceReviewsConfigQuery({
            companyId,
            serviceProvider: "salesforce",
        });
        const configUpdateMutation = useUpdateSalesforceReviewsConfigMutation();
        const emptyConfig = useMemo(
            () => ({
                dealStatus: [],
                dateRange: getTodayAsISO(),
                valueThreshold: 0,
                schedulingReviews: [],
                defaultReviews: [],
            }),
            []
        );
        const config = configQuery.data?.reviews_config
            ? configQuery.data?.reviews_config
            : emptyConfig;
        const form = useForm<{
            dealStatus: {
                closedLost: boolean;
                closedWon: boolean;
                closedDoNothing: boolean;
            };
            dateRange: string;
            valueThreshold: number;
            schedulingReviews: {
                automated: boolean;
                manual: boolean;
                salesforceCRM: boolean;
            };
            defaultReviews: {
                winLoss: boolean;
                valueRealisation: boolean;
                marketResearch: boolean;
            };
        }>({
            values: {
                dealStatus: {
                    closedLost: config.dealStatus.includes(
                        dealStatusMap.closedLost
                    ),
                    closedWon: config.dealStatus.includes(
                        dealStatusMap.closedWon
                    ),
                    closedDoNothing: config.dealStatus.includes(
                        dealStatusMap.closedDoNothing
                    ),
                },
                dateRange: config.dateRange,
                valueThreshold: config.valueThreshold,
                schedulingReviews: {
                    automated: config.schedulingReviews.includes(
                        schedulingReviewsMap.automated
                    ),
                    manual: config.schedulingReviews.includes(
                        schedulingReviewsMap.manual
                    ),
                    salesforceCRM: config.schedulingReviews.includes(
                        schedulingReviewsMap.salesforceCRM
                    ),
                },
                defaultReviews: {
                    winLoss: config.defaultReviews.includes(
                        defaultReviewsMap.winLoss
                    ),
                    valueRealisation: config.defaultReviews.includes(
                        defaultReviewsMap.valueRealisation
                    ),
                    marketResearch: config.defaultReviews.includes(
                        defaultReviewsMap.marketResearch
                    ),
                },
            },
        });
        const onSubmit = form.handleSubmit(
            async ({
                dealStatus,
                defaultReviews,
                dateRange,
                schedulingReviews,
                valueThreshold,
            }) => {
                try {
                    await configUpdateMutation.mutateAsync({
                        companyId,
                        serviceProvider: "salesforce",
                        reviewsConfig: {
                            dealStatus: Object.keys(dealStatusMap)
                                .filter(
                                    key =>
                                        dealStatus[
                                            key as keyof typeof dealStatusMap
                                        ]
                                )
                                .map(
                                    key =>
                                        dealStatusMap[
                                            key as keyof typeof dealStatusMap
                                        ]
                                ),
                            defaultReviews: Object.keys(defaultReviewsMap)
                                .filter(
                                    key =>
                                        defaultReviews[
                                            key as keyof typeof defaultReviewsMap
                                        ]
                                )
                                .map(
                                    key =>
                                        defaultReviewsMap[
                                            key as keyof typeof defaultReviewsMap
                                        ]
                                ),
                            schedulingReviews: Object.keys(schedulingReviewsMap)
                                .filter(
                                    key =>
                                        schedulingReviews[
                                            key as keyof typeof schedulingReviewsMap
                                        ]
                                )
                                .map(
                                    key =>
                                        schedulingReviewsMap[
                                            key as keyof typeof schedulingReviewsMap
                                        ]
                                ),
                            dateRange,
                            valueThreshold,
                        },
                    });
                    onModalClose();
                } catch {
                    createToast({
                        icon: "alert-circle",
                        message: "Something went wrong",
                        type: "error",
                    });
                }
            }
        );
        return (
            <SimpleHeaderModal
                open={modalOpen}
                title="Configure integration"
                leftIcon="edit-05"
                onClose={onModalClose}
            >
                <div className="flex flex-col gap-6 w-[800px]">
                    <Typography color="gray-700">Deal Status</Typography>
                    <div className="flex gap-3">
                        <Controller
                            control={form.control}
                            name="dealStatus.closedLost"
                            render={({ field }) => (
                                <CheckboxCard
                                    title="Closed lost"
                                    checked={field.value}
                                    onChange={field.onChange}
                                    className="flex-1"
                                />
                            )}
                        />
                        <Controller
                            control={form.control}
                            name="dealStatus.closedWon"
                            render={({ field }) => (
                                <CheckboxCard
                                    title="Closed Won"
                                    checked={field.value}
                                    onChange={field.onChange}
                                    className="flex-1"
                                />
                            )}
                        />
                        <Controller
                            control={form.control}
                            name="dealStatus.closedDoNothing"
                            render={({ field }) => (
                                <CheckboxCard
                                    title="Closed do nothing"
                                    checked={field.value}
                                    onChange={field.onChange}
                                    className="flex-1"
                                />
                            )}
                        />
                    </div>
                </div>
                <Divider className="my-6 -mx-6" />
                <div className="flex flex-col gap-6 w-[800px]">
                    <Typography color="gray-700">Date range</Typography>

                    <Controller
                        control={form.control}
                        name="dateRange"
                        render={({ field }) => {
                            const isNow = isToday(new Date(field.value));
                            return (
                                <div className="flex gap-3">
                                    <div className="flex-1">
                                        <RadioCard
                                            title="Now on"
                                            checked={isNow}
                                            onChange={() => {
                                                field.onChange(getTodayAsISO());
                                                setDatePickerOpen(false);
                                            }}
                                            className="flex-1"
                                        />
                                    </div>
                                    <div className="flex-1">
                                        <Dropdown
                                            open={datePickerOpen}
                                            onChange={setDatePickerOpen}
                                            fullWidth
                                            position="bottom-left"
                                        >
                                            <Dropdown.Button>
                                                {isNow ? (
                                                    <div className="px-6 py-4 flex gap-2 justify-center items-center rounded-lg border cursor-pointer">
                                                        <Icon
                                                            name="calendar"
                                                            color="gray-700"
                                                        />
                                                        <Typography
                                                            color="gray-500"
                                                            size="sm"
                                                        >
                                                            Select date
                                                        </Typography>
                                                    </div>
                                                ) : (
                                                    <div className="px-6 py-4 flex gap-2 justify-center items-center rounded-lg border cursor-pointer bg-primary-50 border-primary-600">
                                                        <Typography
                                                            color="primary-800"
                                                            size="sm"
                                                        >
                                                            {formatDatePickerInput(
                                                                field.value
                                                            )}
                                                        </Typography>
                                                    </div>
                                                )}
                                            </Dropdown.Button>
                                            <Dropdown.Panel>
                                                <DateSinglePicker
                                                    className="float-right"
                                                    onApply={value => {
                                                        field.onChange(
                                                            parseCalendarDate(
                                                                value
                                                            ).toISOString()
                                                        );
                                                        setDatePickerOpen(
                                                            false
                                                        );
                                                    }}
                                                    onCancel={() =>
                                                        setDatePickerOpen(false)
                                                    }
                                                />
                                            </Dropdown.Panel>
                                        </Dropdown>
                                    </div>
                                </div>
                            );
                        }}
                    />
                </div>
                <Divider className="my-6 -mx-6" />
                <div className="flex flex-col gap-12 w-[800px]">
                    <Typography color="gray-700">Value threshold</Typography>
                    <Controller
                        control={form.control}
                        name="valueThreshold"
                        render={({ field }) => (
                            <Slider
                                value={field.value}
                                onChange={field.onChange}
                                max={30000}
                                tooltipText={new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                    maximumFractionDigits: 0,
                                }).format(field.value)}
                                type="tooltip"
                                position="top"
                            />
                        )}
                    />
                    <Typography size="xs" color="gray-400">
                        Deals over $15,000 will be synced
                    </Typography>
                </div>
                <Divider className="my-6 -mx-6" />
                <div className="flex flex-col gap-6 w-[800px]">
                    <Typography color="gray-700">Scheduling reviews</Typography>
                    <div className="flex gap-3">
                        <Controller
                            control={form.control}
                            name="schedulingReviews.automated"
                            render={({ field }) => (
                                <CheckboxCard
                                    title="Automated"
                                    description="7 days after opportunities status changes"
                                    checked={field.value}
                                    onChange={field.onChange}
                                    className="flex-1"
                                />
                            )}
                        />
                        <Controller
                            control={form.control}
                            name="schedulingReviews.manual"
                            render={({ field }) => (
                                <CheckboxCard
                                    title="Manual"
                                    checked={field.value}
                                    onChange={field.onChange}
                                    className="flex-1"
                                />
                            )}
                        />
                        <Controller
                            control={form.control}
                            name="schedulingReviews.salesforceCRM"
                            render={({ field }) => (
                                <CheckboxCard
                                    title="Allow reviews to be scheduled from within Salesforce CRM"
                                    checked={field.value}
                                    onChange={field.onChange}
                                    className="flex-1"
                                />
                            )}
                        />
                    </div>
                </div>
                <Divider className="my-6 -mx-6" />
                <div className="flex flex-col gap-6 w-[800px]">
                    <Typography color="gray-700">Default review</Typography>
                    <div className="flex gap-3">
                        <Controller
                            control={form.control}
                            name="defaultReviews.winLoss"
                            render={({ field }) => (
                                <CheckboxCard
                                    title="Win loss review"
                                    checked={field.value}
                                    onChange={field.onChange}
                                    className="flex-1"
                                />
                            )}
                        />
                        <Controller
                            control={form.control}
                            name="defaultReviews.valueRealisation"
                            render={({ field }) => (
                                <CheckboxCard
                                    title="Value realisation review"
                                    checked={field.value}
                                    onChange={field.onChange}
                                    className="flex-1"
                                />
                            )}
                        />
                    </div>
                </div>
                <div className="mt-14 flex gap-3 w-[800px]">
                    <Button
                        label="Back"
                        variant="outlined"
                        color="gray"
                        className="flex-1"
                        onClick={onModalClose}
                    />
                    <Button
                        label="Save"
                        color="primary"
                        className="flex-1"
                        onClick={onSubmit}
                        disabled={configUpdateMutation.isLoading}
                    />
                </div>
                <Toaster />
            </SimpleHeaderModal>
        );
    };

export default ConfigureSalesforceIntegrationModal;

const dealStatusMap = {
    closedWon: "Closed Won",
    closedLost: "Closed Lost",
    closedDoNothing: "Closed Do Nothing",
};
const defaultReviewsMap = {
    winLoss: "Win loss review",
    valueRealisation: "Value realisation review",
    marketResearch: "Market Research review",
};
const schedulingReviewsMap = {
    automated: "Automated",
    manual: "Manual",
    salesforceCRM: "CRM",
};
