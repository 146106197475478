import { useState } from "react";
import { useCalendarRange, getCalendarRange } from "trinity-components-library";
import { format } from "date-fns";
import { useDealsQuery } from "../../../apis/deal.api";
import { DealOutcome } from "../../../apis/types/deal-outcome";
import { ReviewType } from "../../../apis/types/review-type";
import getLoggedInCompanyId from "../../../libs/getLoggedInCompanyId";
import { FilterSelectorValue } from "../../reports/components/filter-selector";
import { TemplateCategoryKey } from "../../../apis/types/template";

const useReports = ({
    templateCategoryKey,
}: {
    templateCategoryKey: TemplateCategoryKey[];
}) => {
    const companyId = getLoggedInCompanyId() ?? "";
    const [company, setCompany] = useState("");
    const [page, setPage] = useState(0);
    const calendarRange = useCalendarRange({
        defaultValue: getCalendarRange("lastQuarter"),
    });
    const [filterValue, setFilterValue] = useState<FilterSelectorValue>({
        reportType: [],
        result: [],
    });
    const { result: deals, invalidate } = useDealsQuery({
        company,
        companyId,
        limit: 6,
        page: page + 1,
        reviewStatus: "complete",
        reviewTypes: filterValue.reportType.length
            ? filterValue.reportType
            : [ReviewType.DIGITAL, ReviewType.MANUAL],
        outcomes: filterValue.result.length
            ? filterValue.result
            : [DealOutcome.ClosedWon, DealOutcome.ClosedLost],
        ...(!calendarRange.isEmpty
            ? {
                  startDate: format(
                      calendarRange.rangeAsDates.from,
                      "yyyy-MM-dd"
                  ),
                  endDate: format(calendarRange.rangeAsDates.to, "yyyy-MM-dd"),
              }
            : {}),
        templateCategoryKey,
        includeTemplates: true,
    });
    return {
        deals,
        invalidate,
        company,
        setCompany,
        page,
        setPage,
        calendarRange,
        filterValue,
        setFilterValue,
    };
};

export default useReports;
