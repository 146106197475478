import { Button, Typography } from "trinity-components-library";
import axios from "../../axios-instance";
import { SalesforceLogo, HubspotLogo } from "../../assets/images";
import { useAuth } from "../../libs/auth/auth";
import CRMCard from "./components/crm-card";
import ConfigureSalesforceIntegrationModal from "./components/configure-salesforce-integration-modal";
import { useState } from "react";

export const Integrations = () => {
    const { user } = useAuth();
    const [modal, setModal] = useState<"salesforceConfig" | "none">("none");
    const salesforce = user?.integrations?.find(
        integration => integration.service_provider === "salesforce"
    );
    const isSalesforceConnected = salesforce?.is_connected;

    const handleSalesForce = async () => {
        const response = await axios("/main/auth/salesforce");
        if (response.data) {
            window.location.href = `${response.data.redirect_to}`;
        }
    };

    return (
        <div className="flex flex-col gap-5">
            <div className="flex flex-wrap justify-between gap-4">
                <div className="flex flex-col gap-1">
                    <Typography color="gray-900" size="lg">
                        CRM Integrations
                    </Typography>
                    <Typography color="gray-500">
                        Supercharge your Opportunity workflow by integrating
                        your CRM
                    </Typography>
                </div>
                <Button
                    icon="plus"
                    label="Request integration"
                    variant="outlined"
                    color="gray"
                />
            </div>
            <div className="flex flex-col lg:flex-row gap-6 ">
                <CRMCard
                    title="Salesforce"
                    Icon={SalesforceLogo}
                    description="Easily sync your opportunities in Salesforce with Trinity. Reps can now trigger Win Loss reviews without leaving Salesforce."
                    isConnected={isSalesforceConnected}
                    isActivated={isSalesforceConnected}
                    onClick={() => handleSalesForce()}
                    onEdit={() => setModal("salesforceConfig")}
                />
                <CRMCard
                    title="Hubspot"
                    Icon={HubspotLogo}
                    description="Easily sync your opportunities in Hubspot with Trinity. Reps can now trigger Win Loss reviews without leaving Hubspot."
                    comingSoon
                />
            </div>
            <ConfigureSalesforceIntegrationModal
                open={modal === "salesforceConfig"}
                onClose={() => setModal("none")}
            />
        </div>
    );
};

export default Integrations;
