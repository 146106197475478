import { FC } from "react";
import clsx from "clsx";
import { format } from "date-fns";
import {
    IconType,
    Badge,
    Divider,
    Icon,
    Typography,
    Button,
} from "trinity-components-library";
import { useAuth } from "../../../libs/auth/auth";
import { capitalize } from "../../../utils";
import { ReportItem } from "../types";
import { useNavigate } from "react-router-dom";
import useUploadReportPdf from "../../hooks/useUploadReportPdf";
import { ReviewType } from "../../../apis/types/review-type";
import UserProfile from "../../../components/user-profile/user-profile";
import { TemplateCategoryKey } from "../../../apis/types/template";

const ReportCard: FC<ReportItem> = ({
    id,
    resultType = "win",
    companyLogo = "",
    companyName = "",
    responder = "",
    respondedDate = "",
    reviewType,
    templateCategory,
}) => {
    const { isTrinityAdmin } = useAuth();
    const navigate = useNavigate();
    const { uploadPdfMutation, uploadReportPdf, cancelUpload } =
        useUploadReportPdf();

    const bodyItems: { icon: IconType; title: string }[] = [
        {
            icon: resultType === "win" ? "arrow-up" : "arrow-down",
            title: resultType === "win" ? "Win report" : "Loss report",
        },
        {
            icon: "user-01",
            title: responder,
        },
        {
            icon: "calendar-date",
            title: format(new Date(respondedDate), "d LLL yyyy"),
        },
    ];

    const isDigitalReviewType = reviewType === ReviewType.DIGITAL;

    let templateCategoryUrlPath = "win-loss";
    switch (templateCategory) {
        case TemplateCategoryKey.WIN_LOSS:
            templateCategoryUrlPath = "win-loss";
            break;

        case TemplateCategoryKey.VALUE_REALIZATION:
            templateCategoryUrlPath = "value-realisation";
            break;

        case TemplateCategoryKey.MARKET_RESEARCH:
            templateCategoryUrlPath = "market-research";
            break;
        case TemplateCategoryKey.EXIT_INTERVIEW:
            templateCategoryUrlPath = "exit-interview";
            break;
    }

    return (
        <div className="group min-w-[300px] rounded-lg border border-gray-200 shadow-sm">
            <div className="flex items-center px-6 py-3.5">
                <div className="flex-1">
                    <UserProfile
                        avatar={companyLogo}
                        name={companyName}
                        placeholderType="placeholder"
                        placeholderIcon="building-05"
                    />
                </div>
                <Badge
                    label={
                        reviewType === ReviewType.DIGITAL
                            ? "Digital"
                            : reviewType === ReviewType.MANUAL
                            ? "Consulting"
                            : reviewType
                                  .toLowerCase()
                                  .split("_")
                                  .map(val => capitalize(val))
                                  .join(" ")
                    }
                    color={isDigitalReviewType ? "primary" : "purple"}
                />
            </div>
            <Divider />
            <div className="flex flex-col gap-3 p-6">
                {bodyItems.map(({ icon, title }) => (
                    <div className="flex items-center gap-2">
                        <Icon name={icon} size={16} color="gray-700" />
                        <Typography color="gray-500" size="sm">
                            {title}
                        </Typography>
                    </div>
                ))}
            </div>
            <Divider />
            <div className="flex justify-end gap-3 p-4">
                {isTrinityAdmin && isDigitalReviewType && (
                    <Button
                        icon="upload-01"
                        label={
                            uploadPdfMutation.isLoading
                                ? "Uploading..."
                                : "Upload pdf"
                        }
                        variant="outlined"
                        color="gray"
                        loading={uploadPdfMutation.isLoading}
                        disabled={uploadPdfMutation.isLoading}
                        onClick={() => uploadReportPdf(id)}
                        className={clsx(
                            !uploadPdfMutation.isLoading &&
                                "invisible group-hover:visible"
                        )}
                    />
                )}
                {isTrinityAdmin &&
                isDigitalReviewType &&
                uploadPdfMutation.isLoading ? (
                    <Button
                        label="Cancel"
                        color="error"
                        onClick={cancelUpload}
                    />
                ) : (
                    <Button
                        label="View"
                        className={isDigitalReviewType ? "" : "invisible"}
                        onClick={() => {
                            navigate(
                                `/reports/${templateCategoryUrlPath}/${id}`
                            );
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default ReportCard;
