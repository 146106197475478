import { FC, useState } from "react";
import clsx from "clsx";
import { Typography, Dropdown, Icon } from "trinity-components-library";
import { MemberScope } from "../../../apis/types/team-members.api.types";

export interface UserRole {
    label: string;
    value: string;
}

interface Value {
    value: string;
    role: string;
}

export interface UserRoleInputProps {
    label?: string;
    placeholder?: string;
    hint?: string;
    error?: string;
    value?: Value;
    disabled?: boolean;
    onChange?: (value: Value) => void;
    fullWidth?: boolean;
}

export const UserRoleInputField: FC<UserRoleInputProps> = ({
    label,
    placeholder = "",
    hint,
    error = "",
    value: valueProp,
    disabled = false,
    onChange,
    fullWidth = false,
}) => {
    const [internalValue, setInternalValue] = useState<Value>({
        value: "",
        role: "",
    });
    const [open, setOpen] = useState(false);

    const value = valueProp ?? internalValue;
    const setValue = (v: Value) => {
        if (valueProp === undefined) {
            setInternalValue(v);
        }
        onChange?.(v);
    };
    const helperText = error || hint;

    const userRoles: { label: string; value: MemberScope }[] = [
        {
            label: "Admin",
            value: MemberScope.ADMIN,
        },
        {
            label: "Sales Leader",
            value: MemberScope.SALES_LEADER,
        },
        {
            label: "Sales Rep",
            value: MemberScope.SALES_REP,
        },
    ];

    const selectedUserRole = userRoles.find(
        ({ value: id }) => id === value.role
    );

    return (
        <div className="flex flex-col gap-1.5">
            <Typography size="sm" color="gray-700">
                {label}
            </Typography>
            <Dropdown open={open && !disabled} fullWidth>
                <Dropdown.Button>
                    <div
                        className={clsx(
                            "h-10 flex items-center rounded-lg border",
                            error
                                ? "border-error-300 focus-within:ring-4 focus-within:ring-error-100"
                                : "border-gray-300 focus-within:ring-4 focus-within:ring-primary-100",
                            disabled
                                ? "bg-gray-50 pointer-events-none hover:cursor-not-allowed"
                                : "bg-white",
                            fullWidth ? "w-full" : "w-80"
                        )}
                    >
                        <div
                            className={`flex-1 h-10 grow shrink pl-3 py-2 flex items-center gap-2`}
                        >
                            <div className="grow shrink">
                                <input
                                    className={clsx(
                                        `w-full text-t-md text-inter placeholder:text-gray-500 border-0 outline-0 focus-visible:outline-0 ring-0 p-0`,

                                        disabled
                                            ? "text-gray-500 bg-gray-50"
                                            : "text-gray-900 bg-white"
                                    )}
                                    placeholder={placeholder}
                                    value={value.value}
                                    onChange={e =>
                                        setValue({
                                            ...value,
                                            value: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            {helperText && (
                                <Icon
                                    name={
                                        error ? "alert-circle" : "help-circle"
                                    }
                                    size={16}
                                    color={error ? "error-500" : "gray-400"}
                                />
                            )}
                        </div>
                        <div
                            className={`h-10 pr-3 pl-2.5 py-2 flex items-center gap-2 cursor-pointer`}
                            onClick={() => setOpen(prev => !prev)}
                        >
                            <Typography
                                color={disabled ? "gray-500" : "gray-900"}
                            >
                                {selectedUserRole?.label
                                    ? selectedUserRole.label
                                    : "User role"}
                            </Typography>
                            <Icon
                                name={open ? "chevron-up" : "chevron-down"}
                                size={20}
                                color="gray-500"
                            />
                        </div>
                    </div>
                </Dropdown.Button>
                <Dropdown.Panel>
                    <div className="w-full my-1 bg-white border border-gray-100 shadow-lg rounded-lg overflow-auto">
                        {userRoles.map(role => {
                            return (
                                <div
                                    className="px-3 py-2 flex items-center hover:bg-gray-50 cursor-pointer"
                                    onClick={() => {
                                        setValue({
                                            ...value,
                                            role: role.value,
                                        });
                                        setOpen(false);
                                    }}
                                >
                                    <div className="grow">
                                        <Typography size="sm" color="gray-900">
                                            {role.label}
                                        </Typography>
                                    </div>
                                    {selectedUserRole?.value === role.value && (
                                        <Icon
                                            name="check"
                                            color="primary-600"
                                            size={20}
                                        />
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </Dropdown.Panel>
            </Dropdown>
            {helperText && (
                <Typography size="sm" color={error ? "error-500" : "gray-500"}>
                    {helperText}
                </Typography>
            )}
        </div>
    );
};

export default UserRoleInputField;
