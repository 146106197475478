import { useQuery, useQueryClient } from "react-query";
import Axios from "../axios-instance";
import { UseQueryReturnType } from "./api";

export interface UseAnalyticsQueryParams {
    startDate: string;
    endDate: string;
    companyId?: string;
}

export const useAnalyticsQuery = ({
    startDate,
    endDate,
    companyId,
}: UseAnalyticsQueryParams): UseQueryReturnType => {
    const queryKey = ["analytics", companyId, startDate, endDate];
    const queryClient = useQueryClient();
    const invalidate = () => queryClient.invalidateQueries(queryKey);
    const result = useQuery(
        queryKey,
        async () => {
            const resp = await Axios.get("/main/analytics", {
                params: {
                    "start-date": startDate,
                    "end-date": endDate,
                    "company-id": companyId,
                },
            });
            return resp.data;
        },
        { refetchOnWindowFocus: false }
    );
    return {
        result,
        invalidate,
    };
};
