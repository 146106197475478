import { ReportRecommendation } from "../../../apis/types/report";

export enum ModeType {
    VIEW,
    EMPTY,
}

export interface StateType {
    summary: string;
    previousSummary: string;
    summaryMode: ModeType;
    items: ReportRecommendation[];
    previousItems: ReportRecommendation[];
    itemsMode: ModeType;
    currentIndex: number;
}

export enum Actions {
    SUMMARY_FETCH,
    ITEMS_FETCH,
    ITEMS_INDEX_CHANGE,
}

export type ActionType =
    | {
          type: Actions.SUMMARY_FETCH;
          payload: string;
      }
    | {
          type: Actions.ITEMS_FETCH;
          payload: ReportRecommendation[];
      }
    | {
          type: Actions.ITEMS_INDEX_CHANGE;
          payload: number;
      };

export const initialState: StateType = {
    summary: ``,
    previousSummary: "",
    items: [],
    previousItems: [],
    currentIndex: 0,
    summaryMode: ModeType.EMPTY,
    itemsMode: ModeType.EMPTY,
};

export const reducer = (
    state = initialState,
    action: ActionType
): StateType => {
    switch (action.type) {
        case Actions.SUMMARY_FETCH:
            return {
                ...state,
                summary: action.payload,
                summaryMode: ModeType.VIEW,
            };
        case Actions.ITEMS_FETCH:
            return {
                ...state,
                items: action.payload,
                itemsMode: ModeType.VIEW,
            };
        case Actions.ITEMS_INDEX_CHANGE:
            return {
                ...state,
                currentIndex:
                    action.payload > state.items.length
                        ? state.items.length
                        : action.payload < 0
                        ? 0
                        : action.payload,
            };

        default:
            return state;
    }
};
