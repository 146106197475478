import { FC } from "react";
import {
    Typography,
    Divider,
    Button,
    Toggle,
} from "trinity-components-library";

export interface CRMCardProps {
    title: string;
    Icon: any;
    description: string;
    onEdit?: () => void;
    isConnected?: boolean;
    isActivated?: boolean;
    onActivationChange?: (val: boolean) => void;
    comingSoon?: boolean;
    onClick?: () => void;
}

const CRMCard: FC<CRMCardProps> = ({
    title,
    description,
    Icon,
    onEdit,
    comingSoon = false,
    isConnected = false,
    isActivated,
    onActivationChange,
    onClick,
}) => {
    return (
        <div
            onClick={onClick}
            className="rounded-lg border border-gray-200 shadow cursor-pointer"
        >
            <div className="p-6 flex flex-col gap-6">
                <div className="flex items-start justify-between gap-5">
                    <div className="flex items-center gap-3">
                        <Icon />
                        <Typography color="gray-900">{title}</Typography>
                    </div>
                    {!comingSoon && (
                        <div onClick={e => e.stopPropagation()}>
                            <Toggle
                                size="sm"
                                enabled={isActivated}
                                onChange={onActivationChange}
                            />
                        </div>
                    )}
                </div>
                <Typography size="sm" color="gray-500">
                    {description}
                </Typography>
            </div>
            <Divider color="gray-200" />
            <div
                onClick={e => e.stopPropagation()}
                className="px-6 py-4 flex justify-end gap-3"
            >
                {!comingSoon ? (
                    <Button
                        label="Edit"
                        disabled={!isConnected}
                        color="primary"
                        onClick={onEdit}
                        size="lg"
                    />
                ) : (
                    <Button
                        label="Coming soon"
                        color="gray"
                        variant="outlined"
                        size="lg"
                    />
                )}
            </div>
        </div>
    );
};

export default CRMCard;
