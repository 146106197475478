import { useNavigate } from "react-router-dom";
import { FeaturedIcon, Typography, Button } from "trinity-components-library";
import axios from "../../axios-instance";
import CRMCard from "./components/crm-card";
import { ReactComponent as HubspotLogo } from "../../assets/images/hubspot-logo.svg";
import { ReactComponent as SalesforceLogo } from "../../assets/images/salesforce-logo.svg";
import { useAuth } from "../../libs/auth/auth";

const ConnectCrm = () => {
    const navigate = useNavigate();

    const { user } = useAuth();
    const salesforce = user?.integrations?.find(
        integration => integration.service_provider === "salesforce"
    );
    const isSalesforceConnected = salesforce?.is_connected;

    const handleSalesForce = async () => {
        const response = await axios("/main/auth/salesforce");
        if (response.data) {
            window.location.href = `${response.data.redirect_to}`;
        }
    };

    return (
        <div className="pb-[80px]">
            <div className="flex flex-col items-center gap-8">
                <FeaturedIcon name="key-01" color="primary" size="lg" />
                <div className="flex flex-col items-center gap-3">
                    <Typography type="display" size="sm" color="gray-700">
                        Connect CRM
                    </Typography>
                    <Typography color="gray-600">
                        Connect your CRM to Trinity to setup automated reviews
                    </Typography>
                </div>
                <div className="flex gap-6">
                    <div className="flex-1">
                        <CRMCard
                            title="Salesforce"
                            description="Easily sync your opportunities from Salesforce. Reps can now trigger Win Loss reviews without leaving Salesforce."
                            Icon={SalesforceLogo}
                            onConnect={handleSalesForce}
                            overview="Use this quick one time integration to Salesforce, to pull all recently closed opportunities from your CRM system into the Trinity Platform. From there, you can choose which Opportunities to select for Digital Win Loss Reviews, which might be better suited to a Full Win Loss Review and if appropriate, which Opportunities to exclude completely from the customer feedback loop. New opportunities will automatically feed in, once the connection to Salesforce has been established via an overnight workflow."
                            isConnected={isSalesforceConnected}
                        />
                    </div>
                    <div className="flex-1">
                        <CRMCard
                            title="Hubspot"
                            description="Easily sync your opportunities from HubSpot. Reps can now trigger Win Loss reviews without leaving HubSpot."
                            comingSoon
                            Icon={HubspotLogo}
                        />
                    </div>
                </div>
                <Button
                    label="Next"
                    size="xl"
                    fullWidth
                    onClick={() => navigate("/onboarding/invite-team")}
                />
            </div>
        </div>
    );
};

export default ConnectCrm;
