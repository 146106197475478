import { FC } from "react";
import clsx from "clsx";
import { format } from "date-fns";
import { ColumnDef } from "@tanstack/react-table";
import { capitalize } from "../../utils";
import { ReportItem } from "../reports-grid-view/types";
import { ReviewType } from "../../apis/types/review-type";
import Actions from "./components/actions";
import {
    Table,
    Tooltip,
    Icon,
    Badge,
    Typography,
    Pagination,
} from "trinity-components-library";
import UserProfile from "../../components/user-profile/user-profile";
import { TemplateCategoryKey } from "../../apis/types/template";

interface ReportsTableProps {
    reports: ReportItem[];
    page: number;
    pageCount: number;
    onPageChange?: (page: number) => void;
    templateCategory: TemplateCategoryKey;
}

export const ReportsTable: FC<ReportsTableProps> = ({
    templateCategory,
    reports,
    page,
    pageCount,
    onPageChange,
}) => {
    const data = reports.map(
        ({
            companyLogo,
            companyName,
            reviewType: type,
            resultType,
            responder,
            id,
            respondedDate,
        }) => ({
            id,
            company: {
                name: companyName,
                avatar: companyLogo,
                subTitle: "",
            },
            type,
            result: resultType === "win" ? "Win Reports" : "Loss Reports",
            responder,
            dateRespond: respondedDate,
            actions: {
                type,
                dealId: id,
            } as { type: ReviewType; dealId: string },
        })
    );

    return (
        <Table
            columns={createColumns(templateCategory) as any}
            data={data}
            footer={() => (
                <div className="px-6 py-4">
                    <Pagination
                        count={pageCount}
                        page={page}
                        onChange={onPageChange}
                    />
                </div>
            )}
        />
    );
};

export default ReportsTable;

const createColumns = (templateCategory: TemplateCategoryKey) => {
    const columns: ColumnDef<any>[] = [
        {
            id: "company",
            header: "Company",
            accessorKey: "company",
            sortingFn: (row1: any, row2: any, columnId: any) => {
                const company1 = row1.getValue(columnId) ?? { name: "" };
                const company2 = row2.getValue(columnId) ?? { name: "" };
                const a = company1.name.toLowerCase();
                const b = company2.name.toLowerCase();
                return a > b ? 1 : a < b ? -1 : 0;
            },
            cell: ({ getValue, row, table }: any) => {
                const { avatar, name, subTitle } = getValue() ?? {};
                return (
                    <div className="flex gap-3 items-center w-32">
                        <div className="w-auto">
                            <UserProfile
                                avatar={avatar}
                                name={name}
                                subTitle={subTitle}
                                placeholderType="placeholder"
                                placeholderIcon="building-05"
                            />
                        </div>
                        <div
                            className={clsx(
                                !subTitle &&
                                    row.index ===
                                        table.options.meta.mouseOverRowIndex
                                    ? "visible"
                                    : "invisible"
                            )}
                        >
                            <Tooltip
                                title="Edit me"
                                position="right"
                                type="dark"
                                width={75}
                            >
                                <Icon
                                    name="help-circle"
                                    size={16}
                                    color="gray-500"
                                    className="cursor-pointer"
                                />
                            </Tooltip>
                        </div>
                    </div>
                );
            },
        },
        {
            id: "type",
            header: "Type",
            accessorKey: "type",
            sortingFn: (firstRow: any, secondRow: any, columnId: any) => {
                const firstType = firstRow.getValue(columnId) ?? "";
                const secondType = secondRow.getValue(columnId) ?? "";
                const a = firstType.toLowerCase();
                const b = secondType.toLowerCase();
                return a > b ? 1 : a < b ? -1 : 0;
            },
            cell: ({ getValue }: any) => {
                const reviewType = getValue() ?? "";
                return (
                    <div className="w-28">
                        <Badge
                            label={capitalize(reviewType.toLowerCase())}
                            color={
                                reviewType.toLowerCase() === "digital"
                                    ? "primary"
                                    : "purple"
                            }
                        />
                    </div>
                );
            },
        },
        {
            id: "result",
            header: "Result",
            accessorKey: "result",
            cell: ({ getValue }: any) => {
                return (
                    <div className="w-28">
                        <Typography size="sm" color="gray-500">
                            {getValue()}
                        </Typography>
                    </div>
                );
            },
        },
        {
            id: "responder",
            header: "Responder",
            accessorKey: "responder",
            enableSorting: false,
            cell: ({ getValue }: any) => {
                return (
                    <div className="w-28">
                        <Typography size="sm" color="gray-500">
                            {getValue()}
                        </Typography>
                    </div>
                );
            },
        },
        {
            id: "dateRespond",
            header: "Date respond",
            accessorFn: (row: any) => new Date(row.dateRespond).getTime(),
            cell: ({ getValue }: any) => {
                return (
                    <div className="w-28">
                        <Typography size="sm" color="gray-500">
                            {format(new Date(getValue()), "MMM dd, yyyy")}
                        </Typography>
                    </div>
                );
            },
        },
        {
            id: "actions",
            header: "",
            accessorKey: "actions",
            cell: ({ getValue, row, table }: any) => {
                const value: {
                    dealId: string;
                    type: ReviewType;
                } = getValue() ?? {
                    dealId: "",
                    type: ReviewType.DIGITAL,
                };
                const { dealId, type } = value;
                const isRowHovered =
                    row.index === table.options.meta.mouseOverRowIndex;
                return (
                    <Actions
                        templateCategory={templateCategory}
                        dealId={dealId}
                        reviewType={type}
                        isRowHovered={isRowHovered}
                    />
                );
            },
        },
    ];

    return columns;
};
