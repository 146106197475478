import {
    Typography,
    MailInputField,
    PasswordInputField,
    Checkbox,
    Button,
    SocialButton,
} from "trinity-components-library";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useOAuthLoginMutation } from "../../apis/oauth.api";
import { useAuth } from "../../libs/auth/auth";
import { TrinityLogoIcon } from "../../assets/images";

export function Login() {
    const [isLoading, setIsLoading] = useState(false);
    const form = useForm({
        defaultValues: {
            email: "",
            password: "",
            serverError: "",
        },
        mode: "onChange",
        criteriaMode: "all",
    });
    const {
        formState: { errors, isValid, isDirty },
    } = form;

    const location = useLocation();
    const navigate = useNavigate();

    const redirectBack = () => {
        if (location.state?.redirectTo) {
            const { redirectTo } = location.state;
            navigate(redirectTo.pathname + redirectTo.search, {
                state: redirectTo.state,
                replace: true,
            });
        } else {
            navigate("/");
        }
    };

    const { auth, onLoginSuccess } = useAuth();

    const oAuthMutation = useOAuthLoginMutation();
    const [searchParams] = useSearchParams({ code: "" });
    const code = searchParams.get("code");
    const handleOAuth = async () => {
        setIsLoading(true);
        try {
            const data = await oAuthMutation.mutateAsync({ code });
            onLoginSuccess({
                token: data.access_token,
            });
            redirectBack();
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (code) {
            handleOAuth();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [code]);

    const onLogin = form.handleSubmit(async ({ email, password }) => {
        setIsLoading(true);
        try {
            const data = await auth(email, password);
            onLoginSuccess({ token: data?.idToken?.jwtToken });
            redirectBack();
        } catch (err: any) {
            if (err) {
                if (err.name === "UserNotConfirmedException") {
                    window.sessionStorage.setItem("pass", password);
                    navigate(
                        `/email-verification?email=${encodeURIComponent(email)}`
                    );
                } else if (err.message) {
                    form.setError("serverError", {
                        type: "value",
                        message: err.message,
                    });
                }
            }
        } finally {
            setIsLoading(false);
        }
    });

    const handleGoogleLogin = () => {
        const redirectLink = `${process.env.REACT_APP_COGNITO_SOCIAL_DOMAIN}/oauth2/authorize?identity_provider=Google&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&response_type=CODE&client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}`;
        window.location.href = redirectLink;
    };

    return (
        <div className="w-screen h-screen m-0 p-0 flex">
            <div className="flex-1 w-full h-full overflow-auto">
                <div className="flex flex-col justify-around my-24 m-auto w-[360px] content-center">
                    <div className="flex flex-col justify-center items-center">
                        <TrinityLogoIcon className="w-32" />
                    </div>
                    <div className="flex flex-col items-center gap-3 mt-8">
                        <Typography
                            variant="h1"
                            size="md"
                            type="display"
                            weight="bold"
                        >
                            Log in
                        </Typography>
                        <Typography
                            variant="p"
                            size="md"
                            weight="normal"
                            color="gray-800"
                        >
                            Welcome back! Please enter your details.
                        </Typography>
                    </div>
                    <div className="flex flex-col gap-5 mt-8">
                        <Controller
                            name="email"
                            control={form.control}
                            rules={{
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: "Invalid email address",
                                },
                                required: true,
                            }}
                            render={({ field }) => (
                                <MailInputField
                                    label="Email"
                                    fullWidth
                                    hideIcon
                                    placeholder="Enter your email"
                                    onChange={value => {
                                        field.onChange(value);
                                        form.clearErrors("serverError");
                                    }}
                                    value={field.value}
                                    error={
                                        errors.email?.message ||
                                        (errors.email?.type === "required"
                                            ? "Required"
                                            : "")
                                    }
                                />
                            )}
                        />
                        <Controller
                            name="password"
                            control={form.control}
                            rules={{
                                required: true,
                            }}
                            render={({ field }) => (
                                <PasswordInputField
                                    label="Password"
                                    fullWidth
                                    placeholder="••••••••"
                                    onChange={value => {
                                        field.onChange(value);
                                        form.clearErrors("serverError");
                                    }}
                                    value={field.value}
                                    error={
                                        errors.password?.message ||
                                        (errors.password?.type === "required"
                                            ? "Required"
                                            : "")
                                    }
                                />
                            )}
                        />
                        <Controller
                            name="serverError"
                            control={form.control}
                            render={({ fieldState }) => (
                                <Typography size="sm" color="error-600">
                                    {fieldState.error?.message}
                                </Typography>
                            )}
                        />
                    </div>
                    <div className="flex flex-row mt-6">
                        <div className="flex grow items-center">
                            <Checkbox />
                            <Typography
                                variant="p"
                                size="sm"
                                weight="medium"
                                className="pl-1"
                            >
                                Remember me
                            </Typography>
                        </div>
                        <Button
                            variant="link"
                            size="sm"
                            label="Forgot password"
                            onClick={() => navigate("/reset-password")}
                        />
                    </div>
                    <div className="flex flex-col gap-4 mt-6">
                        <Button
                            color="primary"
                            label={!isLoading ? "Sign In" : "Signing In"}
                            size="lg"
                            disabled={!isValid || !isDirty || isLoading}
                            onClick={onLogin}
                        />
                        <SocialButton
                            variant="google"
                            className="!rounded-lg font-semibold !py-[10px]"
                            size="lg"
                            label="Sign in with Google"
                            onClick={handleGoogleLogin}
                        />
                    </div>
                    {/* Hiding the sign up button by commenting */}
                    {/* <div className="flex justify-center gap-2 mt-8">
                        <Typography variant="span" size="sm" weight="normal">
                            Don't have an account?
                        </Typography>
                        <Typography
                            variant="span"
                            size="sm"
                            color="primary-700"
                            weight="semibold"
                            onClick={() => navigate("/signup")}
                            className="cursor-pointer"
                        >
                            Sign up
                        </Typography>
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default Login;
