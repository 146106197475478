import React, { useContext, useState, useEffect } from "react";
import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import axios from "../../axios-instance";
import { useUserInfoQuery } from "../../apis/user.api";
import { localStorageKeys } from "../../data/localStorageKeys";
import { Scopes } from "../../types";
import { User } from "../../types";
import getLoggedInCompanyId from "../getLoggedInCompanyId";
import UserPool from "./cognitoPool";

export interface AuthContextType {
    auth: any;
    user: User | null;
    invalidateUser: () => void;
    token: string | null;
    isAuthenticated: boolean;
    isTrinityAdmin: boolean;
    loggedInCompanyId: string | null;
    setLoggedInCompanyId: (id: string) => void;
    getSession: any;
    changePassword: any;
    forgotPassword: any;
    confirmPassword: any;
    resentOtp: any;
    logout: any;
    confirmSignUp: any;
    onLoginSuccess: any;
    onLogout: any;
}

export const AuthContext = React.createContext<AuthContextType>(
    {} as AuthContextType
);

export const useAuth = () => useContext(AuthContext);

const TOKEN_KEY = localStorageKeys.AUTH_TOKEN;

export function AuthProvider({ children }: { children: React.ReactNode }) {
    const [token, setToken] = useState(localStorage.getItem(TOKEN_KEY));
    const [isAuthenticated, setIsAuthenticated] = useState(
        token ? true : false
    );

    const userQuery = useUserInfoQuery();
    const user: User = userQuery.result.isSuccess
        ? userQuery.result.data
        : null;
    const invalidateUser = () => userQuery.invalidate();
    const [loggedInCompanyId, setLoggedInCompanyId] = useState<string | null>(
        getLoggedInCompanyId()
    );

    const isTrinityAdmin = !!user?.companies?.find(
        company => company.scopes === Scopes.TRINITY_ADMIN
    );

    useEffect(() => {
        if (token) {
            setIsAuthenticated(true);
        }
    }, [token]);

    const updateToken = async (token: string | null) => {
        token
            ? window.localStorage.setItem(TOKEN_KEY, token)
            : window.localStorage.removeItem(TOKEN_KEY);

        axios.defaults.headers.common["Authorization"] =
            "Bearer " + localStorage.getItem("token");

        setToken(token);
        if (token) {
            invalidateUser();
        }
    };

    const removeCompany = () => {
        window.localStorage.removeItem(localStorageKeys.LOGGED_IN_COMPANY_ID);
    };

    const onLoginSuccess = ({ token }: { token: string }) => {
        updateToken(token);
        setIsAuthenticated(true);
        localStorage.setItem(localStorageKeys.IS_LOGGED_IN, "true");
    };

    const getUser = (Username: string) => {
        return new CognitoUser({
            Username: Username,
            Pool: UserPool,
        });
    };

    const auth = async (Username: string, Password: string) => {
        return await new Promise((resolve, reject) => {
            const authDetails = new AuthenticationDetails({
                Username,
                Password,
            });
            getUser(Username).authenticateUser(authDetails, {
                onSuccess: data => {
                    return resolve(data);
                },
                onFailure: err => {
                    return reject(err);
                },
                newPasswordRequired: data => {
                    return resolve(data);
                },
            });
        });
    };

    const getSession = async () => {
        return new Promise((resolve, reject) => {
            const user = UserPool.getCurrentUser();
            if (user) {
                user.getSession((err: any, session: any) => {
                    if (err) {
                        return reject(err);
                    } else {
                        return resolve(session);
                    }
                });
            } else {
                return reject();
            }
        });
    };

    const changePassword = async (oldpass: string, newpass: string) => {
        return new Promise((resolve, reject) => {
            const user = UserPool.getCurrentUser();
            if (user) {
                user.getSession((err: Error, data: any) => {
                    if (err) {
                        return reject(err);
                    }
                });

                user.changePassword(oldpass, newpass, (err, data) => {
                    if (err) {
                        return reject(err);
                    }
                    if (data) {
                        return resolve(data);
                    }
                });
            }
        });
    };

    const forgotPassword = (Username: string) => {
        return new Promise((resolve, reject) => {
            getUser(Username).forgotPassword({
                onSuccess: data => {
                    return resolve(data);
                },
                onFailure: err => {
                    return reject(err);
                },
            });
        });
    };
    const confirmPassword = (
        Username: string,
        otp: string,
        newpass: string
    ) => {
        return new Promise((resolve, reject) => {
            getUser(Username).confirmPassword(otp, newpass, {
                onSuccess: () => {
                    return resolve({ data: true });
                },
                onFailure: err => {
                    return reject(err);
                },
            });
        });
    };

    const resentOtp = (Username: string) => {
        return new Promise((resolve, reject) => {
            getUser(Username).resendConfirmationCode(
                (err: any, result: any) => {
                    if (err) {
                        return reject(err);
                    } else {
                        return resolve(result);
                    }
                }
            );
        });
    };
    const logout = () => {
        return new Promise((resolve, reject) => {
            const user = UserPool.getCurrentUser();
            if (user) {
                user.signOut();
                return resolve(true);
            } else {
                return reject();
            }
        });
    };

    const confirmSignUp = (Username: string, code: string) => {
        return new Promise((resolve, reject) => {
            getUser(Username).confirmRegistration(
                code,
                true,
                (err: any, result: any) => {
                    if (err) {
                        return reject(err);
                    } else {
                        return resolve(result);
                    }
                }
            );
        });
    };

    const onLogout = () => {
        updateToken(null);
        removeCompany();
        invalidateUser();
        setIsAuthenticated(false);
        localStorage.clear();
        sessionStorage.clear();
        window.location.reload();
    };

    return (
        <AuthContext.Provider
            value={{
                auth,
                user,
                invalidateUser,
                token,
                isAuthenticated,
                isTrinityAdmin,
                loggedInCompanyId,
                setLoggedInCompanyId,
                getSession,
                changePassword,
                forgotPassword,
                confirmPassword,
                resentOtp,
                logout,
                confirmSignUp,
                onLoginSuccess,
                onLogout,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}
