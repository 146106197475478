import { FC, useState } from "react";
import {
    Typography,
    Dropdown,
    Button,
    Badge,
} from "trinity-components-library";

const badgeColors: {
    [id: string]: string;
} = {
    Sent: "primary",
    Responded: "success",
};

interface TextDropdownProps {
    items: {
        name: string;
        status: "Sent" | "Responded";
    }[];
}

const TextDropdown: FC<TextDropdownProps> = ({ items }) => {
    const [openPanel, setOpenPanel] = useState(false);

    if (items.length === 0) {
        return null;
    }

    return (
        <div className="flex gap-1 items-center">
            <Typography type="text" size="sm">
                {items[0].name}
            </Typography>
            <Dropdown
                open={openPanel}
                position="bottom-right"
                onChange={setOpenPanel}
            >
                <Dropdown.Button>
                    <Button
                        variant="link"
                        label={`${
                            items.length === 1 ? "see" : `+${items.length - 1}`
                        } more`}
                        className="!p-0 !text-primary-600 !text-sm !font-normal !h-fit"
                        onClick={() => setOpenPanel(!openPanel)}
                    />
                </Dropdown.Button>
                <Dropdown.Panel>
                    <div
                        className={`my-1 overflow-auto bg-white border border-gray-100 shadow-lg rounded-lg w-[240px] max-h-80`}
                    >
                        {items.map(item => {
                            return (
                                <div
                                    className="px-3 py-2 flex items-center hover:bg-gray-50 cursor-pointer"
                                    onClick={() => {
                                        setOpenPanel(false);
                                    }}
                                >
                                    <div className="grow">
                                        <div className="flex items-center gap-2">
                                            <Typography
                                                color="gray-700"
                                                size="sm"
                                                className="grow"
                                            >
                                                {item.name}
                                            </Typography>
                                            <Badge
                                                label={item.status}
                                                color={
                                                    badgeColors[item.status] as
                                                        | "primary"
                                                        | "success"
                                                        | "error"
                                                }
                                                size="sm"
                                            />
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </Dropdown.Panel>
            </Dropdown>
        </div>
    );
};

export default TextDropdown;
