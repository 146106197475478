import React, { FC } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { TextInputField } from "trinity-components-library";
import FormItem from "../../components/form-item";

export interface FirstNameItemProps {
    form: UseFormReturn<any>;
}

const FirstNameItem: FC<FirstNameItemProps> = ({ form }) => {
    return (
        <FormItem title="First Name">
            <Controller
                name="firstName"
                control={form.control}
                render={({ field }) => (
                    <TextInputField
                        fullWidth
                        placeholder="Enter first name"
                        value={field.value}
                        onChange={field.onChange}
                    />
                )}
            />
        </FormItem>
    );
};

export default FirstNameItem;
