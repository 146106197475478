import React from "react";
import toast from "react-hot-toast";
import { Typography } from "trinity-components-library";
import { useUploadReportPdfMutation } from "../../apis/deal.api";
import useToast from "../../hooks/useToast";

const useUploadReportPdf = () => {
    const { mutation: uploadPdfMutation, abort: cancelUpload } =
        useUploadReportPdfMutation();

    const { createToast } = useToast();

    const handleChange = async (dealId: string, file: File) => {
        try {
            await uploadPdfMutation.mutateAsync({
                dealId,
                file,
            });
            createToast({
                icon: "check-circle",
                message: "File uploaded successfully",
                type: "success",
            });
        } catch (error: any) {
            if (error?.code !== "ERR_CANCELED") {
                createToast({
                    icon: "alert-circle",
                    message: t => (
                        <Typography size="sm" color="error-700">
                            Failed to upload.{" "}
                            <span
                                onClick={() => {
                                    handleChange(dealId, file);
                                    toast.dismiss(t.id);
                                }}
                                className="cursor-pointer underline"
                            >
                                Please try again.
                            </span>
                        </Typography>
                    ),
                    type: "error",
                });
            }
        }
    };

    const uploadReportPdf = (dealId: string) => {
        const inputEle = document.createElement("input");
        inputEle.type = "file";
        inputEle.accept = "application/pdf";
        inputEle.addEventListener("change", event => {
            const e = event as unknown as React.ChangeEvent<HTMLInputElement>;
            const { files } = e.target;
            if (files && files.length) {
                const file = files[0];
                const MAX_FILE_SIZE = 20; // in MB
                const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE * 1000 * 1000; // in bytes
                const { size, type } = file;
                if (size > MAX_FILE_SIZE_BYTES) {
                    createToast({
                        icon: "alert-triangle",
                        message: `File size should be less than ${MAX_FILE_SIZE} MB`,
                        type: "warning",
                    });
                    return;
                }

                if (type !== "application/pdf") {
                    createToast({
                        icon: "alert-triangle",
                        message: "Please select a PDF file",
                        type: "warning",
                    });
                    return;
                }
                handleChange(dealId, file);
            }
        });
        inputEle.click();
    };
    return {
        uploadPdfMutation,
        uploadReportPdf,
        cancelUpload,
    };
};

export default useUploadReportPdf;
