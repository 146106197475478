import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../libs/auth/auth";
import { Typography, MailInputField, Button } from "trinity-components-library";
import { TrinityLogoIcon } from "../../assets/images";

export const ResetPassword = () => {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { forgotPassword } = useAuth();
    const form = useForm({
        defaultValues: {
            email: "",
            serverError: "",
        },
        mode: "onChange",
    });
    const { isDirty, errors, isValid } = form.formState;
    const onNext = form.handleSubmit(async ({ email }) => {
        try {
            setIsLoading(true);
            await forgotPassword(email);
            navigate(`/create-password?email=${encodeURIComponent(email)}`);
        } catch (error: any) {
            form.setError(
                "serverError",
                error?.message ?? "Something went wrong"
            );
        } finally {
            setIsLoading(false);
        }
    });
    return (
        <div className="w-screen h-screen m-0 p-0 flex">
            <div className="flex-1 w-full h-full overflow-auto">
                <div className="flex flex-col justify-around my-24 m-auto w-[360px] content-center">
                    <div className="flex flex-col justify-center items-center">
                        <TrinityLogoIcon className="w-32" />
                    </div>
                    <div className="flex flex-col items-center gap-3 mt-8">
                        <Typography
                            variant="h1"
                            size="md"
                            type="display"
                            weight="bold"
                        >
                            Reset your password
                        </Typography>
                        <Typography
                            variant="p"
                            size="md"
                            weight="normal"
                            color="gray-800"
                            className="text-center"
                        >
                            Enter your user account's verified email address and
                            we will send you a verification code.
                        </Typography>
                    </div>
                    <div className="flex flex-col gap-5 mt-8">
                        <Controller
                            name="email"
                            control={form.control}
                            rules={{
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: "Invalid email address",
                                },
                                required: true,
                            }}
                            render={({ field }) => (
                                <MailInputField
                                    label="Email"
                                    fullWidth
                                    hideIcon
                                    placeholder="Enter your email"
                                    onChange={value => {
                                        field.onChange(value);
                                    }}
                                    value={field.value}
                                    error={
                                        errors.email?.message ||
                                        (errors.email?.type === "required"
                                            ? "Required"
                                            : "")
                                    }
                                />
                            )}
                        />
                        <Controller
                            name="serverError"
                            control={form.control}
                            render={({ fieldState }) => (
                                <Typography size="sm" color="error-600">
                                    {fieldState.error?.message}
                                </Typography>
                            )}
                        />
                    </div>
                    <div className="flex flex-col gap-4 mt-6">
                        <Button
                            color="primary"
                            label="Next"
                            size="lg"
                            disabled={!isValid || !isDirty || isLoading}
                            onClick={onNext}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
