import { useMutation } from "react-query";
import Axios from "../axios-instance";

export const useShareReport = () => {
    return useMutation(
        async (payload: { dealId: string; senderEmail: string }) => {
            const response = await Axios.post(
                "/main/deals/share-report",
                payload
            );
            return response.data;
        }
    );
};
