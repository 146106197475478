import { FC } from "react";
import { differenceInDays } from "date-fns";
import {
    CalendarRange,
    findDateRangeName,
    calendarRangeMap,
    parseCalendarDate,
} from "trinity-components-library";
import { useAnalyticsQuery } from "../../../apis/analytics.api";
import getLoggedInCompanyId from "../../../libs/getLoggedInCompanyId";
import { toShortNumber } from "../../../utils";
import Metric, { MetricProps } from "./metric";

interface AnalyticsProps {
    dateRange: CalendarRange;
}

const Analytics: FC<AnalyticsProps> = ({ dateRange }) => {
    const companyId = getLoggedInCompanyId() ?? "";
    const rangeName = findDateRangeName(dateRange);
    const dateRangeName = calendarRangeMap[rangeName].unit;
    let intervalInDays =
        differenceInDays(
            parseCalendarDate(dateRange.to),
            parseCalendarDate(dateRange.from)
        ) + 1;
    intervalInDays = isNaN(intervalInDays) ? 0 : intervalInDays;
    const { result: analytics } = useAnalyticsQuery({
        startDate: parseCalendarDate(dateRange.from).toISOString(),
        endDate: parseCalendarDate(dateRange.to).toISOString(),
        companyId,
    });
    const metricData: MetricProps[] =
        analytics.isSuccess && dateRange
            ? [
                  getTotalReviewMetricProps(
                      analytics.data.reviews,
                      dateRangeName,
                      intervalInDays
                  ),
                  getWinRateMetricProps(analytics.data.deals),
                  getDealValueMetrics(
                      analytics.data.deals,
                      dateRangeName,
                      intervalInDays
                  ),
              ]
            : [];
    return dateRange ? (
        <div className="grid gap-5 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
            {metricData.map(values => (
                <Metric {...values} />
            ))}
        </div>
    ) : null;
};

export default Analytics;

const getTotalReviewMetricProps = (
    data: any,
    dateRangeName: string,
    interval: number
): MetricProps => {
    const completedCount = data?.completed;
    const pendingCount = data?.pending;
    let lossGainPercentage: number =
        ((data?.total_count + 1 - (data?.previous_year?.total_count + 1)) /
            (data?.previous_year?.total_count + 1)) *
        100;
    let increase: boolean = lossGainPercentage >= 0 ? true : false;

    const description =
        dateRangeName === "custom"
            ? `Compared to ${data?.previous_year?.total_count} previous ${interval} days`
            : `Compared to ${data?.previous_year?.total_count} previous ${dateRangeName}`;
    return {
        title: "Total Reviews",
        description,
        value: data?.total_count,
        change: `${Math.abs(lossGainPercentage).toFixed(0)}%`,
        isIncreasing: increase,
        valueItems: [
            { label: `${completedCount} completed`, color: "success" },
            { label: `${pendingCount} pending`, color: "error" },
        ],
    };
};

const getWinRateMetricProps = (data: any): MetricProps => {
    const { total_count, won, live, previous_year } = data;

    const closed = total_count - live?.total_count;

    const winRate = (total_count === 0 ? 0 : won?.total_count / closed) * 100;

    const closedPrevious =
        previous_year?.total_count - previous_year?.live?.total_count;

    //previous win rate by default is 100%
    const previousWinRate =
        (previous_year?.total_count === 0
            ? 1
            : previous_year?.won?.total_count / closedPrevious) * 100;

    const lossGainPercentage =
        ((winRate - previousWinRate) / previousWinRate) * 100;

    const increase: boolean = lossGainPercentage >= 0 ? true : false;
    return {
        title: "Win Rate",
        description: `28% industry benchmark`,
        value: `${Math.round(winRate)}%`,
        change: `${Math.abs(lossGainPercentage).toFixed(0)}%`,
        isIncreasing: increase,
    };
};

const getDealValueMetrics = (
    data: any,
    dateRangeName: string,
    interval: number
): MetricProps => {
    const wonValue = "$" + toShortNumber(data?.won?.value);
    const lostValue = "$" + toShortNumber(data?.lost?.value);
    const totalThisYear = data?.won?.value + data?.lost?.value;
    const totalLastYear =
        data?.previous_year?.won?.value + data?.previous_year?.lost?.value;
    const lossGainPercentage: number =
        ((totalThisYear - totalLastYear + 1) / (totalLastYear + 1)) * 100;
    const increase: boolean = lossGainPercentage >= 0 ? true : false;
    const formatter = Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 0,
    });

    const description =
        dateRangeName === "custom"
            ? `Compared to ${formatter.format(
                  data?.previous_year?.won?.value
              )} previous ${interval} days`
            : `Compared to ${formatter.format(
                  data?.previous_year?.won?.value
              )} previous ${dateRangeName}`;
    return {
        title: "Total Opportunity Value",
        description,
        value: `${formatter.format(totalThisYear)}`,
        change: `${Math.abs(lossGainPercentage).toFixed(0)}%`,
        isIncreasing: increase,
        valueItems: [
            { label: `${wonValue} Closed Won`, color: "success" },
            { label: `${lostValue} Closed Lost`, color: "error" },
        ],
    };
};
