import { useForm } from "react-hook-form";
import {
    useGetCompanyBrandingQuery,
    usePatchCompanyBrandingMutation,
} from "../../../apis/branding.api";

interface UseBrandingFormParams {
    companyId: string;
}

export const useBrandingForm = ({ companyId }: UseBrandingFormParams) => {
    const branding = useGetCompanyBrandingQuery({ companyId });

    const defaultValues = {
        primaryColors: ["#000000"],
        secondaryColors: ["#000000"],
    };
    const values = branding.result.isSuccess
        ? {
              primaryColors: branding.result.data?.primary_colors,
              secondaryColors: branding.result.data?.secondary_colors,
          }
        : defaultValues;

    const form = useForm({
        defaultValues,
        values,
    });
    const updateBranding = usePatchCompanyBrandingMutation();

    const submit = form.handleSubmit(
        async ({ primaryColors, secondaryColors }) => {
            await updateBranding.mutateAsync({
                companyId,
                primaryColors,
                secondaryColors,
            });
            await branding.invalidate();
            await form.reset();
        }
    );

    return { form, submit };
};

export default useBrandingForm;
