import { useState } from "react";
import {
    useCalendarRange,
    getCalendarRange,
    isValidCalendarRange,
    parseCalendarDate,
} from "trinity-components-library";
import { SortingState } from "@tanstack/react-table";
import { useGetDealsMetaDataQuery } from "../../../apis/deal.api";
import getLoggedInCompanyId from "../../../libs/getLoggedInCompanyId";

const useOpportunities = () => {
    const [pageIndex, setPageIndex] = useState(0);
    const [sorting, setSorting] = useState<SortingState>([]);
    const { setValue: setDateRange, value: range } = useCalendarRange({
        defaultValue: getCalendarRange("lastQuarter"),
    });
    const [columnSort] = sorting;
    const defaultPageSize = 10;
    const companyId = getLoggedInCompanyId() || "";

    const { result: dealCounts, invalidate } = useGetDealsMetaDataQuery({
        companyId,
        ...(range &&
            isValidCalendarRange(range) && {
                startDate: parseCalendarDate(range.from).toISOString(),
                endDate: parseCalendarDate(range.to).toISOString(),
            }),
    });

    return {
        dealCounts,
        invalidate,
        columnSort,
        pageIndex,
        setPageIndex,
        sorting,
        setSorting,
        setDateRange,
        defaultPageSize,
        range,
    };
};

export default useOpportunities;
