import { FC } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../libs/auth/auth";

const AuthCheck: FC<{ children: JSX.Element }> = ({ children }) => {
    const { isAuthenticated } = useAuth();
    return isAuthenticated ? <Navigate to="/" replace={true} /> : children;
};

export default AuthCheck;
