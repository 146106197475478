import { useState } from "react";
import {
    Badge,
    IconButton,
    Table,
    Typography,
} from "trinity-components-library";
import {
    useDeleteTeamMemberMutation,
    useGetTeamMembersQuery,
} from "../../apis/team-members.api";
import {
    MemberScope,
    MemberSortKeys,
    MemberSortOrder,
    MemberStatus,
} from "../../apis/types/team-members.api.types";
import { useUserInfoQuery } from "../../apis/user.api";
import UserProfile from "../../components/user-profile/user-profile";
import getLoggedInCompanyId from "../../libs/getLoggedInCompanyId";
import { SecondaryColorType } from "../../types/colors";
import { snakeCaseToCapitalCase } from "../../utils";
import InviteUserModal from "./components/invite-user-modal";
import LastLoginDateFormat from "./components/last-login-date-format";
import TableHeader from "./components/table-header";
import UpdateUserRolesModal from "./components/update-user-roles-modal";

const UserTable = () => {
    const companyId = getLoggedInCompanyId() ?? "";
    const [memeberId, setmemeberId] = useState<string>("");
    const userQuery = useUserInfoQuery();
    const user =
        userQuery.result.isSuccess && userQuery.result.data
            ? userQuery.result.data
            : null;
    const selectedCompany = user?.companies.find(
        (company: any) => company.id === companyId
    );
    const [modal, setModal] = useState<"inviteUser" | "updateUser" | "">("");

    const users = useGetTeamMembersQuery({
        companyId,
        statuses: [MemberStatus.INVITED, MemberStatus.ACTIVE],
        sortBy: [MemberSortKeys.SCOPES, MemberSortKeys.NAME],
        sortOrder: MemberSortOrder.ASCENDING,
        scopes: [
            MemberScope.ADMIN,
            MemberScope.SALES_LEADER,
            MemberScope.SALES_REP,
        ],
    });
    const deleteUser = useDeleteTeamMemberMutation();
    const deleteUserHandler = async ({
        id,
        companyId,
    }: {
        id: string;
        companyId: string;
    }) => {
        await deleteUser.mutateAsync({
            id,
            companyId,
        });
        await users.invalidate();
    };
    const data = users.result.isSuccess
        ? users.result.data.map(
              ({
                  id,
                  company_id: companyId,
                  name,
                  email,
                  last_logged_in_at: lastLogin,
                  profile_picture_url: avatar,
                  status,
                  scopes,
                  scheduledReviews,
                  completedReviews,
              }: any) => ({
                  id,
                  companyId,
                  user: {
                      name,
                      email,
                      avatar,
                  },
                  role: snakeCaseToCapitalCase(scopes ?? ""),
                  status: {
                      label: snakeCaseToCapitalCase(status ?? ""),
                      color:
                          status === MemberStatus.ACTIVE ? "success" : "gray",
                  },
                  lastLogin,
                  scheduled: scheduledReviews,
                  completed: completedReviews,
                  actions: {
                      selectedCompany,
                      onUpdate: () => {
                          setmemeberId(id);
                          setModal("updateUser");
                      },
                      onDelete: () =>
                          deleteUserHandler({
                              id,
                              companyId,
                          }),
                  },
              })
          )
        : [];
    return (
        <div>
            <Table
                data={data}
                columns={columns}
                enableSorting
                defaultPageSize={8}
                header={table => (
                    <TableHeader
                        table={table as any}
                        onAddUserClick={() => setModal("inviteUser")}
                    />
                )}
                enablePagination
                enableRowSelection
            />
            <InviteUserModal
                open={modal === "inviteUser"}
                onClose={() => setModal("")}
            />
            <UpdateUserRolesModal
                open={modal === "updateUser"}
                memeberId={memeberId}
                onClose={() => setModal("")}
            />
        </div>
    );
};

export default UserTable;

const columns = [
    {
        id: "name",
        header: "Name",
        accessorKey: "user",
        sortingFn: (row1: any, row2: any, columnId: any) => {
            const user1 = row1.getValue(columnId) ?? { name: "" };
            const user2 = row2.getValue(columnId) ?? { name: "" };
            const a = user1.name.toLowerCase();
            const b = user2.name.toLowerCase();
            return a > b ? 1 : a < b ? -1 : 0;
        },
        cell: ({ getValue }: any) => (
            <div className="max-w-[320px]">
                <UserProfile
                    avatar={getValue().avatar}
                    name={getValue().name}
                    subTitle={getValue().email}
                />
            </div>
        ),
    },
    {
        id: "status",
        header: "Status",
        accessorKey: "status",
        sortingFn: (firstRow: any, secondRow: any, columnId: any) => {
            const firstStatus = firstRow.getValue(columnId) ?? { label: "" };
            const secondStatus = secondRow.getValue(columnId) ?? { label: "" };
            const a = firstStatus.label.toLowerCase();
            const b = secondStatus.label.toLowerCase();
            return a > b ? 1 : a < b ? -1 : 0;
        },
        cell: ({ getValue }: any) => {
            const value = getValue();
            return (
                <div className="w-24">
                    <Badge
                        label={value.label as string}
                        color={value.color as SecondaryColorType}
                        status="online"
                    />
                </div>
            );
        },
    },
    {
        id: "role",
        header: "Role",
        accessorKey: "role",
        cell: ({ getValue }: any) => {
            return (
                <div className="w-24">
                    <Typography size="sm" color="gray-500">
                        {getValue() || "-"}
                    </Typography>
                </div>
            );
        },
    },
    {
        id: "scheduled",
        header: "Scheduled",
        accessorKey: "scheduled",
        cell: ({ getValue }: any) => {
            return (
                <div className="w-24 flex justify-center">
                    <Typography size="sm" color="gray-500">
                        {getValue()}
                    </Typography>
                </div>
            );
        },
    },
    {
        id: "completed",
        header: "Completed",
        accessorKey: "completed",
        cell: ({ getValue }: any) => {
            return (
                <div className="w-24 flex justify-center">
                    <Typography size="sm" color="gray-500">
                        {getValue()}
                    </Typography>
                </div>
            );
        },
    },
    {
        id: "lastLogin",
        header: "Last Login",
        accessorFn: (row: any) => new Date(row.lastLogin).getTime(),
        cell: ({ getValue }: any) => {
            const iso = getValue() as string;
            return (
                <div className="w-24">
                    {iso ? (
                        <LastLoginDateFormat iso={iso} />
                    ) : (
                        <Typography size="sm" color="gray-500">
                            -
                        </Typography>
                    )}
                </div>
            );
        },
    },
    {
        id: "actions",
        header: "",
        accessorKey: "actions",
        cell: ({ getValue }: any) => {
            const { onDelete, onUpdate, selectedCompany } = getValue();
            const adminScopes = [MemberScope.TRINITY_ADMIN, MemberScope.ADMIN];
            const isAdmin = adminScopes?.includes(selectedCompany?.scopes);
            return (
                <>
                    {isAdmin && (
                        <div className="flex gap-1">
                            <IconButton
                                icon="trash-01"
                                variant="text"
                                color="gray"
                                onClick={onDelete}
                            />
                            <IconButton
                                icon="edit-01"
                                variant="text"
                                color="gray"
                                onClick={onUpdate}
                            />
                        </div>
                    )}
                </>
            );
        },
    },
];
