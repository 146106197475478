import React from "react";
import { Typography, Icon } from "trinity-components-library";

export const Footer = () => {
    return (
        <div className="flex justify-between p-10">
            <div className="flex items-center gap-1">
                <div className="w-3 h-3 flex items-center justify-center rounded-full border border-gray-600 text-inter text-[8px] text-gray-600">
                    C
                </div>
                <Typography color="gray-600" size="sm">
                    Trinity 2023
                </Typography>
            </div>
            <div className="flex items-center gap-1">
                <Icon size={12} name="mail-01" color="gray-600" />
                <Typography color="gray-600" size="sm">
                    help@trinity.com
                </Typography>
            </div>
        </div>
    );
};

export default Footer;
