import React, { FC } from "react";
import { Icon, Typography } from "trinity-components-library";

interface PasswordRulesProps {
    password: string;
}

export const PasswordRules: FC<PasswordRulesProps> = ({ password }) => {
    const passwordRules: {
        rule: string;
        checked: boolean;
    }[] = [
        {
            rule: "Must be at least 8 characters",
            checked: password.length >= 8,
        },
        {
            rule: "Must have at least a lowercase letter",
            checked: password.search(/[a-z]/) !== -1,
        },
        {
            rule: "Must have at least a uppercase letter",
            checked: password.search(/[A-Z]/) !== -1,
        },
        {
            rule: "Must have at least a special character",
            checked: password.search(/[!@#$%^&*]/) !== -1,
        },
        {
            rule: "Must have at least a number",
            checked: password.search(/\d/) !== -1,
        },
    ];
    return (
        <div>
            {passwordRules.map(({ rule, checked }) => (
                <div className="flex gap-2 items-center">
                    <Icon
                        size={14}
                        name="check-circle"
                        color={checked ? "success-600" : "error-600"}
                    />
                    <Typography size="xs" color="gray-500">
                        {rule}
                    </Typography>
                </div>
            ))}
        </div>
    );
};

export default PasswordRules;
