import { FC } from "react";
import {
    ColumnDef,
    OnChangeFn,
    RowSelectionState,
    SortingState,
} from "@tanstack/react-table";
import {
    IconType,
    Table,
    Tooltip,
    Icon,
    Typography,
    Pagination,
} from "trinity-components-library";
import clsx from "clsx";
import TableHeader from "./components/table-header";
import { ReviewType } from "../../apis/types/review-type";
import DropDown from "./components/drop-down";
import UserProfile from "../../components/user-profile/user-profile";

export interface OpportunityData {
    id: string;
    company: { name: string; avatar: string; subTitle: string };
    value: number;
    contact: string;
    lastUpdated: string;
    reviewType: ReviewType;
    open: boolean;
    actions: {
        id: string;
        title: string;
        icon: IconType;
        onClick: () => void;
        items: any;
        iconEnd: IconType;
    }[];
}

export interface OpportunitiesTableProps {
    status?: "scheduled" | "unscheduled" | "all";
    onStatusChange?: (status: "scheduled" | "unscheduled" | "all") => void;
    data?: OpportunityData[];
    sorting?: SortingState;
    onSortingChange?: (sorting: SortingState) => void;
    totalItems?: number;
    pageIndex?: number;
    onPageIndexChange?: (index: number) => void;
    defaultPageSize?: number;
    rowSelection?: RowSelectionState;
    onRowSelectionChange?: OnChangeFn<RowSelectionState>;
}

const OpportunitiesTable: FC<OpportunitiesTableProps> = ({
    status = "scheduled",
    onStatusChange,
    data = [],
    sorting,
    onSortingChange,
    totalItems = data.length,
    defaultPageSize = 10,
    pageIndex = 1,
    onPageIndexChange,
    rowSelection,
    onRowSelectionChange,
}) => {
    return (
        <div>
            <Table
                data={data}
                columns={columns as any}
                enableRowSelection
                enableSorting
                sorting={sorting}
                onSortingChange={onSortingChange}
                rowSelection={rowSelection}
                onRowSelectionChange={onRowSelectionChange}
                header={table => (
                    <TableHeader
                        table={table as any}
                        status={status}
                        onStatusChange={onStatusChange}
                        totalItems={totalItems}
                    />
                )}
                rowProps={({ table, row }) => ({
                    className: clsx({
                        "bg-gray-50":
                            (table.options.meta as any)?.mouseOverRowIndex ===
                            row.index,
                    }),
                })}
                footer={() => (
                    <div className="px-6 py-4">
                        <Pagination
                            page={pageIndex}
                            count={Math.ceil(totalItems / defaultPageSize)}
                            onChange={onPageIndexChange}
                        />
                    </div>
                )}
            />
        </div>
    );
};
export default OpportunitiesTable;
const columns: ColumnDef<any>[] = [
    {
        id: "company",
        header: "Company",
        accessorKey: "company",
        sortingFn: (row1: any, row2: any, columnId: any) => {
            const company1 = row1.getValue(columnId) ?? { name: "" };
            const company2 = row2.getValue(columnId) ?? { name: "" };
            const a = company1.name.toLowerCase();
            const b = company2.name.toLowerCase();
            return a > b ? 1 : a < b ? -1 : 0;
        },
        cell: ({ getValue, row, table }: any) => {
            const { avatar, name, subTitle } = getValue() ?? {};
            return (
                <div className="flex items-center gap-3 w-52">
                    <UserProfile
                        avatar={avatar}
                        name={name}
                        subTitle={subTitle}
                        placeholderType="placeholder"
                        placeholderIcon="building-05"
                    />
                    {!subTitle &&
                        row.index === table.options.meta.mouseOverRowIndex && (
                            <Tooltip
                                title="Click edit to add an opportunity name"
                                position="right"
                                type="dark"
                                width={155}
                            >
                                <Icon
                                    name="help-circle"
                                    size={16}
                                    color="gray-500"
                                    className="cursor-pointer"
                                />
                            </Tooltip>
                        )}
                </div>
            );
        },
    },
    {
        id: "value",
        header: "Value",
        accessorKey: "value",
        cell: ({ getValue }: any) => (
            <div className="w-32">
                <Typography color="gray-500" size="sm">
                    {`$${Intl.NumberFormat().format(getValue())}`}
                </Typography>
            </div>
        ),
    },
    {
        id: "contact",
        header: "Contact",
        accessorKey: "contact",
        accessorFn: (row: any) =>
            row?.contact?.length > 0 ? row.contact[0].name : "N/A",
        cell: ({ getValue }: any) => (
            <div className="w-36">
                <Typography color="gray-500" size="sm">
                    {getValue?.()}
                </Typography>
            </div>
        ),
        enableSorting: false,
    },
    {
        id: "lastUpdated",
        header: "Last Updated",
        accessorFn: (row: any) => new Date(row.lastUpdated).getTime(),
        cell: ({ getValue }: any) => {
            const d = new Date(getValue() as string);
            return (
                <div className="w-28">
                    <Typography
                        className="whitespace-nowrap"
                        size="sm"
                        color="gray-900"
                    >{`${d.toLocaleDateString("en-US", {
                        month: "short",
                    })} ${d.getDate()}, ${d.getFullYear()}`}</Typography>
                    <Typography
                        className="whitespace-nowrap"
                        size="sm"
                        color="gray-500"
                    >{`${d.toLocaleString("en-US", {
                        hour: "2-digit",
                        hour12: true,
                        minute: "2-digit",
                    })}`}</Typography>
                </div>
            );
        },
    },
    {
        id: "actions",
        header: "",
        accessorKey: "actions",
        cell: ({ getValue, row, table }: any) => {
            const actions = getValue() ?? [];
            return <DropDown table={table} row={row} actions={actions} />;
        },
    },
];
