import React, { FC } from "react";
import { Typography } from "trinity-components-library";
import TemplateCard, {
    CommonTemplateType,
    TemplateCardProps,
} from "../../components/template-card/template-card";
import { useAuth } from "../../libs/auth/auth";
import getLoggedInCompanyId from "../../libs/getLoggedInCompanyId";
import { Scopes } from "../../types";

interface TemplatesGridViewProps {
    templates: CommonTemplateType[];
    toggleTemplateActiveStatus: (template: CommonTemplateType) => void;
    toggleTemplateDefaultStatus: (template: CommonTemplateType) => void;
}

export const TemplatesGridView: FC<TemplatesGridViewProps> = ({
    templates = [],
    toggleTemplateActiveStatus,
    toggleTemplateDefaultStatus,
}) => {
    const { isTrinityAdmin, user } = useAuth();
    const winLossTemplates = templates.filter(
        ({ categoryType }) => categoryType === "win_loss"
    );
    const valueRealisationTemplates = templates.filter(
        ({ categoryType }) => categoryType === "value_realisation"
    );
    const marketResearchTemplates = templates.filter(
        ({ categoryType }) => categoryType === "market_research"
    );
    const exitInterviewTemplates = templates.filter(
        ({ categoryType }) => categoryType === "exit_interview"
    );
    const customTemplate = templates.filter(
        ({ categoryType }) => categoryType === "uncategorised"
    );

    const companyId = getLoggedInCompanyId() ?? "";
    const currentCompany = user?.companies?.find(
        (company: any) => company.id === companyId
    );
    const allowedRoles = [Scopes.TRINITY_ADMIN, Scopes.ADMIN];
    const isAllowedRole = currentCompany?.scopes
        ? allowedRoles?.includes(currentCompany?.scopes)
        : false;

    const getTemplateCardPropsFromTemplate = (
        template: CommonTemplateType
    ): TemplateCardProps => {
        return {
            ...template,
            toggleActiveStatus: () => toggleTemplateActiveStatus(template),
            toggleDefaultStatus: () => toggleTemplateDefaultStatus(template),
            description: "",
            hideActiveToggle:
                (!template.isCustom && !isTrinityAdmin) ||
                (template.isCustom && !isTrinityAdmin && !isAllowedRole),
        };
    };

    return (
        <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-3">
                <Typography color="gray-700">Win Loss Review</Typography>
                <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6">
                    {winLossTemplates.map(template => {
                        const props =
                            getTemplateCardPropsFromTemplate(template);
                        return (
                            <TemplateCard
                                key={props.id}
                                {...props}
                                description="Provides an in-depth understanding of the feedback and learning’s provided by prospects against a variety of key criteria via a digital review."
                                clickable
                            />
                        );
                    })}
                </div>
            </div>
            <div className="flex flex-col gap-3">
                <Typography color="gray-700">
                    Value Realisation Review
                </Typography>
                <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6">
                    {valueRealisationTemplates.map(template => {
                        const props =
                            getTemplateCardPropsFromTemplate(template);
                        return (
                            <TemplateCard
                                key={props.id}
                                {...props}
                                description="Provides an in-depth understanding of the feedback and learning’s provided by prospects against a variety of key criteria via a digital review."
                                clickable
                            />
                        );
                    })}
                </div>
            </div>
            <div className="flex flex-col gap-3">
                <Typography color="gray-700">Market Research</Typography>
                <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6">
                    {marketResearchTemplates.map(template => {
                        const props =
                            getTemplateCardPropsFromTemplate(template);
                        return (
                            <TemplateCard
                                key={props.id}
                                {...props}
                                description="Provides an in-depth understanding of the feedback and learning’s provided by prospects against a variety of key criteria via a digital review."
                                clickable
                            />
                        );
                    })}
                </div>
            </div>
            <div className="flex flex-col gap-3">
                <Typography color="gray-700">Exit Interview</Typography>
                <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6">
                    {exitInterviewTemplates.map(template => {
                        const props =
                            getTemplateCardPropsFromTemplate(template);
                        return (
                            <TemplateCard
                                key={props.id}
                                {...props}
                                description="Provides an in-depth understanding of the feedback and learning’s provided by prospects against a variety of key criteria via a digital review."
                                clickable
                            />
                        );
                    })}
                </div>
            </div>
            <div className="flex flex-col gap-3">
                <Typography color="gray-700">Custom Template</Typography>
                <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6">
                    {customTemplate.map(template => {
                        const props =
                            getTemplateCardPropsFromTemplate(template);
                        return (
                            <TemplateCard
                                key={props.id}
                                {...props}
                                description="Provides an in-depth understanding of the feedback and learning’s provided by prospects against a variety of key criteria via a digital review."
                                clickable
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default TemplatesGridView;
