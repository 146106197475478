import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { Icon, Typography, PinInput, Button } from "trinity-components-library";
import { useAuth } from "../../libs/auth/auth";
import { getInvitedUserData } from "../../libs/getInvitedUserData";

export const EmailVerification = () => {
    const { confirmSignUp, resentOtp, auth, onLoginSuccess } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const form = useForm({
        defaultValues: {
            verificationCode: "",
        },
    });

    const location = useLocation();
    const navigate = useNavigate();

    const params = new URLSearchParams(location.search);
    const email = decodeURIComponent(params.get("email") ?? "");

    if (!email) {
        navigate("/login");
    }
    const invitedUserData = getInvitedUserData();

    const confirmVerificationCode = form.handleSubmit(
        async ({ verificationCode }) => {
            setIsLoading(true);
            try {
                const data = await confirmSignUp(email, verificationCode);
                if (data === "SUCCESS") {
                    const { idToken } = await auth(
                        email,
                        window.sessionStorage.getItem("pass")
                    );
                    onLoginSuccess({
                        token: idToken.jwtToken,
                    });
                    if (invitedUserData?.isInvitedUser) {
                        // If invited user, no need to create a new team (company)
                        // Route to dashboard
                        navigate("/");
                    } else {
                        // If not invited user, need to create a new team (company)
                        navigate("/onboarding/create-company");
                    }
                }
            } catch (err: any) {
                if (err?.message) {
                    form.setError("verificationCode", {
                        message: err.message,
                        type: "value",
                    });
                }
            } finally {
                setIsLoading(false);
            }
        }
    );

    const resendVerificationCode = () => resentOtp(email);
    return (
        <div className="w-screen h-screen flex flex-col justify-center items-center p-4">
            <div className="flex flex-col">
                <div className="flex flex-col justify-center items-center gap-8 w-[360px]">
                    <div className="w-14 h-14 flex justify-center items-center bg-primary-100 border-[10px] border-primary-50 rounded-full">
                        <Icon name="mail-01" color="primary-600" size={24} />
                    </div>
                    <div className="flex flex-col gap-3 items-center">
                        <Typography
                            color="gray-700"
                            type="display"
                            size="sm"
                            weight="semibold"
                        >
                            Check your email
                        </Typography>
                        <div className="flex flex-col items-center">
                            <Typography color="gray-600" size="md">
                                We sent a verification link to
                            </Typography>
                            <Typography color="gray-700" size="md">
                                {email}
                            </Typography>
                        </div>
                    </div>
                    <Controller
                        name="verificationCode"
                        control={form.control}
                        render={({ field, fieldState }) => {
                            return (
                                <>
                                    <div className="flex flex-col gap-3">
                                        <PinInput
                                            length={6}
                                            value={field.value}
                                            onChange={value => {
                                                field.onChange(value);
                                                form.clearErrors(
                                                    "verificationCode"
                                                );
                                            }}
                                        />
                                        {fieldState.error?.message && (
                                            <Typography
                                                color="error-600"
                                                size="sm"
                                                className="text-center"
                                            >
                                                {fieldState.error.message}
                                            </Typography>
                                        )}
                                    </div>
                                    <Button
                                        label="Verify email"
                                        color="primary"
                                        fullWidth
                                        size="xl"
                                        onClick={confirmVerificationCode}
                                        disabled={
                                            isLoading ||
                                            field.value.length !== 6 ||
                                            !!fieldState.error?.message
                                        }
                                    />
                                </>
                            );
                        }}
                    />
                    <div className="flex flex-row items-center">
                        <Typography size="sm" color="gray-600">
                            Didn't receive the email?
                        </Typography>
                        <Button
                            label="Click to resend"
                            size="sm"
                            variant="link"
                            onClick={resendVerificationCode}
                        />
                    </div>
                    <Button
                        label="Back to log in"
                        icon="arrow-left"
                        color="gray"
                        size="sm"
                        variant="text"
                        onClick={() => navigate("/login")}
                    />
                </div>
            </div>
        </div>
    );
};

export default EmailVerification;
