import { Typography } from "trinity-components-library";
import { Dispatch, FC } from "react";
import { ActionType, ModeType } from "../reducers/summaryAndFindings";
import NoDataState from "./no-data-state";
import parse from "html-react-parser";

interface SummaryProps {
    value: string;
    mode: ModeType;
    dispatch: Dispatch<ActionType>;
}

export const Summary: FC<SummaryProps> = ({ value, mode }) => {
    return (
        <div className="flex flex-col gap-3">
            <div className="flex items-center">
                <div className="flex-1">
                    <Typography color="gray-900" weight="medium">
                        Summary
                    </Typography>
                </div>
            </div>
            {mode === ModeType.EMPTY ? (
                <div className="flex justify-center pt-6 pb-14">
                    <NoDataState
                        title="No Summary found"
                        description="Contact admin to include new summary."
                        showSummaryButton={false}
                    />
                </div>
            ) : (
                <>
                    {mode === ModeType.VIEW && (
                        <div className="w-full h-full">
                            <Typography color="gray-500">{parse(value)}</Typography>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default Summary;
