import { FC } from "react";
import { UseFormReturn } from "react-hook-form";
import { Button, Checkbox } from "trinity-components-library";
import { FormItemHeader } from "../../components/form-item";

export interface BrandingItemProps {
    form: UseFormReturn<any>;
}

const BrandingItem: FC<BrandingItemProps> = () => {
    return (
        <div className="flex flex-col lg:flex-row gap-4 lg:gap-8">
            <div className="w-72 flex flex-col gap-2">
                <FormItemHeader
                    title="Branding"
                    helperText="Add your logo to reports and emails."
                />
                <Button label="View examples" variant="link" className="px-0" />
            </div>
            <div className="flex flex-col gap-4">
                <div className="flex flex-row gap-2">
                    <Checkbox />
                    <div className="flex flex-col">
                        <FormItemHeader
                            title="Reports"
                            helperText="Include my logo in summary reports."
                        />
                    </div>
                </div>
                <div className="flex flex-row gap-2">
                    <Checkbox />
                    <div className="flex flex-col">
                        <FormItemHeader
                            title="Emails"
                            helperText="Include my logo in customer emails."
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BrandingItem;
