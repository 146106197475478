import {
    IconType,
    Icon,
    ColorVariantType,
    Typography,
} from "trinity-components-library";
import clsx from "clsx";
import React, { useCallback } from "react";
import toast, { Toast } from "react-hot-toast";

export const useToast = () => {
    const createToast = useCallback(
        ({
            icon,
            message,
            type,
            options,
        }: {
            icon?: IconType;
            message: string | ((toast: Toast) => React.ReactNode);
            type: "success" | "error" | "warning" | "info";
            options?:
                | Partial<
                      Pick<
                          Toast,
                          | "icon"
                          | "style"
                          | "className"
                          | "id"
                          | "duration"
                          | "ariaProps"
                          | "position"
                          | "iconTheme"
                      >
                  >
                | undefined;
        }) => {
            const colorMap = {
                success: "success",
                error: "error",
                warning: "warning",
                info: "gray",
            };
            const color = colorMap[type];
            const toastHandler = (t: Toast) => {
                return (
                    <div
                        className={clsx(
                            `w-[564px] border rounded-lg p-4 flex items-center gap-3`,
                            {
                                [`bg-${color}-25 border-${color}-300`]:
                                    type !== "info",
                                "bg-gray-900 border-gray-900": type === "info",
                            }
                        )}
                    >
                        {icon && (
                            <Icon
                                name={icon}
                                color={
                                    (type === "info"
                                        ? "white"
                                        : `${color}-500`) as ColorVariantType
                                }
                            />
                        )}
                        <div className="flex-1">
                            {typeof message === "string" ? (
                                <Typography
                                    color={
                                        (type === "info"
                                            ? "white"
                                            : `${color}-700`) as ColorVariantType
                                    }
                                >
                                    {message}
                                </Typography>
                            ) : (
                                message(t)
                            )}
                        </div>
                        <Icon
                            name="x-close"
                            color={
                                (type === "info"
                                    ? "white"
                                    : `${color}-700`) as ColorVariantType
                            }
                            onClick={() => toast.dismiss(t.id)}
                            className="cursor-pointer"
                        />
                    </div>
                );
            };
            toast.custom(toastHandler, options);
        },
        []
    );

    return {
        createToast,
    };
};

export default useToast;
