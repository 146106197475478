import React, { FC, useMemo } from "react";
import { VirtualizedSearchInputField } from "trinity-components-library";
import { useCountries } from "../../apis/utils.api";

export interface LocationInputFieldProps {
    label?: string;
    placeholder?: string;
    hint?: string;
    error?: string;
    value?: string;
    disabled?: boolean;
    onChange?: (value: string) => void;
    fullWidth?: boolean;
}

export const LocationInputField: FC<LocationInputFieldProps> = ({
    onChange,
    value,
    ...restProps
}) => {
    const { result: countriesQuery } = useCountries();
    const addressList = useMemo(() => {
        if (countriesQuery.status === "success") {
            return countriesQuery.data.reduce((acc: string[], country) => {
                return [
                    ...acc,
                    ...country.cities.map(
                        city => `${city}, ${country.country}`
                    ),
                ];
            }, []);
        } else {
            return [];
        }
    }, [countriesQuery.data, countriesQuery.status]);
    return (
        <VirtualizedSearchInputField
            items={addressList.map(id => ({ id, value: id }))}
            dropdownHeight={270}
            itemHeight={45}
            value={value}
            onChange={onChange}
            notFoundText="No options"
            status={
                countriesQuery.status === "idle"
                    ? "loading"
                    : countriesQuery.status
            }
            {...restProps}
        />
    );
};

export default LocationInputField;
