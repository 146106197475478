import Axios from "../axios-instance";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
    GeneratedReportResponse,
    Report,
    ReportRecommendation,
} from "./types/report";
import { UseQueryReturnType } from "./api";

export const usePostCreateInitialReportMutation = () => {
    return useMutation(
        async ({
            dealId,
            ...req
        }: {
            dealId: string;
            templateId: string;
            summary?: string;
            recommendations?: ReportRecommendation[];
        }) => {
            const response = await Axios.post(
                `/main/reports/deals/${dealId}/initiate`,
                req
            );
            return response.data as GeneratedReportResponse;
        }
    );
};

export const usePostGenerateReportMutation = () => {
    return useMutation(
        async ({
            dealId,
            templateId,
            initiate,
        }: {
            dealId: string;
            templateId: string;
            initiate: boolean;
        }) => {
            const response = await Axios.post(
                `/main/reports/deals/${dealId}/generate`,
                {
                    templateId,
                    initiate,
                }
            );
            return response.data as GeneratedReportResponse;
        }
    );
};

export const useGetReportByDealIdQuery = ({
    dealId,
    templateId,
}: {
    dealId: string;
    templateId: string;
}): UseQueryReturnType<Report[]> => {
    const queryKey = ["deal-report", dealId, templateId];
    const queryClient = useQueryClient();
    const invalidate = () => queryClient.invalidateQueries(queryKey);
    const result = useQuery(queryKey, async () => {
        const response = await Axios.get(`/main/reports/deals/${dealId}`, {
            params: {
                templateId,
            },
        });
        return response.data;
    });
    return { invalidate, result };
};
