import { useMemo, useState } from "react";
import AddOpportunityFormModal from "./add-opportunity-form-modal";
import BulkUploadModal from "./bulk-upload-modal";
import ModalCardOption, { ModalCardOptionProps } from "./modal-card-option";
import SimpleHeaderModal from "./simple-header-modal";

type AddOpportunityModalProps = {
    open: boolean;
    onClose: () => void;
};

const AddOpportunityModal = ({ open, onClose }: AddOpportunityModalProps) => {
    const [selected, setSelected] =
        useState<"none" | "addOpportunity" | "bulkUpload">("none");

    const cards: ModalCardOptionProps[] = useMemo(
        () => [
            {
                title: "Individual Opportunity",
                description: "Quickly add single opportunity to be reviewed.",
                onSelect: () => setSelected("addOpportunity"),
                color: "warning",
            },
            {
                title: "Bulk Upload",
                description:
                    "Importing large volumes of opportunities is easy using our CSV template.",
                onSelect: () => setSelected("bulkUpload"),
                color: "success",
                icon: "file-plus-02",
            },
            {
                title: "Connect CRM",
                description:
                    "Connect Trinity & Salesforce to send & receive sales data, insights & analysis.",
                onSelect: () => {},
                color: "fuchsia",
                icon: "zap",
            },
        ],
        []
    );

    return (
        <>
            <AddOpportunityFormModal
                isOpen={open && selected === "addOpportunity"}
                setIsOpen={(val: boolean) => {
                    setSelected(val ? "addOpportunity" : "none");
                    onClose?.();
                }}
                openPreviousModal={() => {
                    setSelected("none");
                }}
            />
            <BulkUploadModal
                open={open && selected === "bulkUpload"}
                onLeftIconClick={() => setSelected("none")}
                onClose={() => {
                    setSelected("none");
                    onClose?.();
                }}
                leftIcon="arrow-left"
            />
            <SimpleHeaderModal
                open={open && selected === "none"}
                onClose={onClose}
                title="Add opportunity"
                leftIcon="plus"
            >
                <div className="flex gap-4">
                    {cards.map(card => (
                        <ModalCardOption {...card} />
                    ))}
                </div>
            </SimpleHeaderModal>
        </>
    );
};

export default AddOpportunityModal;
