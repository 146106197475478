import React, { useEffect, useReducer, useState } from "react";
import {
    Typography,
    IconButtonGroup,
    TextInputField,
} from "trinity-components-library";
import {
    useGetCustomTemplatesQuery,
    useGetGlobalTemplatesQuery,
    usePatchCustomTemplateActivatedStatus,
    usePatchCustomTemplateDefaultStatus,
    usePatchGlobalTemplateActivatedStatus,
} from "../../apis/template.api";
import { Branding } from "../../apis/types/branding";
import {
    CustomTemplate,
    GlobalTemplate,
    TemplateCategoryKey,
    TemplateType,
} from "../../apis/types/template";
import getLoggedInCompanyId from "../../libs/getLoggedInCompanyId";
import TemplatesGridView from "../../sections/templates-grid-view/templates-grid-view";
import TemplatesTable from "../../sections/templates-table/templates-table";
import FilterSelector, {
    FilterSelectorValue,
} from "./components/filter-selector";

export interface CommonTemplateType {
    id: string;
    categoryId: string;
    categoryType:
        | "win_loss"
        | "value_realisation"
        | "market_research"
        | "exit_interview"
        | "uncategorised";
    name: string;
    type: "digital" | "consulting" | "uncategorised";
    isActivated: boolean;
    isDefault: boolean;
    isCustom: boolean;
    companyLogo: string;
    primaryColor: string;
}

interface StateType {
    templates: CommonTemplateType[];
}

type ActionType =
    | {
          type: "SET_TEMPLATES";
          payload: CommonTemplateType[];
      }
    | {
          type: "SET_TEMPLATE_ACTIVE_STATUS";
          payload: {
              id: string;
              isActivated: boolean;
          };
      }
    | {
          type: "SET_TEMPLATE_DEFAULT_STATUS";
          payload: {
              id: string;
              isDefault: boolean;
          };
      };

const initialState: StateType = {
    templates: [],
};

const reducer = (
    state: StateType = initialState,
    action: ActionType
): StateType => {
    switch (action.type) {
        case "SET_TEMPLATES":
            return { templates: action.payload };
        case "SET_TEMPLATE_ACTIVE_STATUS":
            return {
                templates: state.templates.map(template => {
                    const { id, isActivated } = action.payload;
                    return template.id === id
                        ? { ...template, isActivated }
                        : template;
                }),
            };
        case "SET_TEMPLATE_DEFAULT_STATUS":
            return {
                templates: state.templates.map(template => {
                    const { id, isDefault } = action.payload;
                    return template.id === id
                        ? { ...template, isDefault }
                        : template;
                }),
            };
        default:
            return state;
    }
};

export const Templates = () => {
    const [view, setView] = useState<"grid" | "list">("grid");
    const [company, setCompany] = useState("");
    const [state, dispatch] = useReducer(reducer, initialState);
    const [filterValue, setFilterValue] = useState<FilterSelectorValue>(
        filterSections.reduce((acc, section) => {
            return {
                ...acc,
                [section.id]: section.items.reduce(
                    (secAcc, secItem) => ({ ...secAcc, [secItem.id]: false }),
                    {}
                ),
            };
        }, {})
    );
    const companyId = getLoggedInCompanyId() ?? "";

    const templateType = filterValue["templateType"] ?? {
        digital: false,
        consulting: false,
    };
    const category = filterValue["category"] ?? {
        win_loss: false,
        value_realisation: false,
        market_research: false,
        exit_interview: false,
    };
    const type =
        templateType.digital && templateType.consulting
            ? undefined
            : templateType.digital
            ? TemplateType.DIGITAL
            : templateType.consulting
            ? TemplateType.CONSULTING
            : undefined;
    const categoryReferenceKey =
        category.win_loss && category.value_realisation
            ? undefined
            : category.win_loss
            ? TemplateCategoryKey.WIN_LOSS
            : category.value_realisation
            ? TemplateCategoryKey.VALUE_REALIZATION
            : category.market_research
            ? TemplateCategoryKey.MARKET_RESEARCH
            : category.exit_interview
            ? TemplateCategoryKey.EXIT_INTERVIEW
            : undefined;

    const { result: customTemplates, invalidate: invalidateCustomTemplates } =
        useGetCustomTemplatesQuery({
            companyId,
            type,
            categoryReferenceKey,
            name: company,
        });
    const { result: globalTemplates, invalidate: invalidateGlobalTemplates } =
        useGetGlobalTemplatesQuery({
            type,
            categoryReferenceKey,
        });

    useEffect(() => {
        if (
            customTemplates.status === "success" &&
            globalTemplates.status === "success"
        ) {
            const mapFn = (
                {
                    id,
                    activated: isActivated,
                    default: isDefault = false,
                    branding,
                    type,
                    templateCategory,
                    name = templateCategory.name,
                }:
                    | CustomTemplate
                    | (GlobalTemplate & {
                          default: boolean;
                          branding: Branding;
                      }),
                isCustom: boolean
            ) => {
                return {
                    id,
                    name,
                    isActivated,
                    isDefault,
                    isCustom,
                    type: type as "digital" | "consulting" | "uncategorised",
                    companyLogo: branding.logo_url ?? "",
                    categoryId: templateCategory.id,
                    categoryType: templateCategory.referenceKey as
                        | "win_loss"
                        | "value_realisation"
                        | "market_research"
                        | "exit_interview"
                        | "uncategorised",
                    primaryColor: branding.primary_colors?.length
                        ? branding.primary_colors[0]
                        : "#201547",
                };
            };
            dispatch({
                type: "SET_TEMPLATES",
                payload: [
                    ...customTemplates.data.map(template =>
                        mapFn(template, true)
                    ),
                    ...globalTemplates.data.map(template =>
                        mapFn(
                            {
                                ...template,
                                default: false,
                                branding: {
                                    id: "",
                                    primary_colors: ["#201547"],
                                    logo_url: "",
                                },
                            },
                            false
                        )
                    ),
                ],
            });
        }
    }, [
        customTemplates.data,
        customTemplates.status,
        globalTemplates.data,
        globalTemplates.status,
    ]);

    const updateCustomTemplateDefaultStatus =
        usePatchCustomTemplateDefaultStatus();
    const updateCustomTemplateActivatedStatus =
        usePatchCustomTemplateActivatedStatus();
    const updateGlobalTemplateActivatedStatus =
        usePatchGlobalTemplateActivatedStatus();

    const refreshData = () => {
        invalidateCustomTemplates();
        invalidateGlobalTemplates();
    };

    const toggleTemplateActiveStatus = async ({
        id,
        isActivated,
        isCustom,
    }: CommonTemplateType) => {
        try {
            dispatch({
                type: "SET_TEMPLATE_ACTIVE_STATUS",
                payload: { id, isActivated: !isActivated },
            });
            const requestPayload = {
                activated: !isActivated,
                id,
            };
            if (isCustom) {
                await updateCustomTemplateActivatedStatus.mutateAsync(
                    requestPayload
                );
            } else {
                await updateGlobalTemplateActivatedStatus.mutateAsync(
                    requestPayload
                );
            }
            refreshData();
        } catch {
            dispatch({
                type: "SET_TEMPLATE_ACTIVE_STATUS",
                payload: { id, isActivated },
            });
        }
    };

    const toggleTemplateDefaultStatus = async ({
        id,
        categoryId,
        isCustom,
        isDefault,
    }: CommonTemplateType) => {
        if (isCustom) {
            try {
                dispatch({
                    type: "SET_TEMPLATE_DEFAULT_STATUS",
                    payload: { id, isDefault: !isDefault },
                });
                await updateCustomTemplateDefaultStatus.mutateAsync({
                    id,
                    templateCategoryId: categoryId,
                });
                refreshData();
            } catch {
                dispatch({
                    type: "SET_TEMPLATE_DEFAULT_STATUS",
                    payload: { id, isDefault },
                });
            }
        }
    };

    return (
        <div className="p-8 flex flex-col gap-8">
            <div className="flex items-start gap-5 flex-wrap">
                <div className="flex-1 flex flex-col gap-1">
                    <Typography
                        type="display"
                        size="sm"
                        color="gray-900"
                        className="flex-1"
                    >
                        Templates
                    </Typography>
                    <Typography size="sm" color="gray-500">
                        Select the review templates from the Trinity library to
                        utilise with your customers
                    </Typography>
                </div>
                <div className="flex gap-3 items-start flex-wrap">
                    <div className="w-44">
                        <TextInputField
                            icon="search-sm"
                            fullWidth
                            placeholder="Search"
                            onChange={setCompany}
                            value={company}
                        />
                    </div>
                    <FilterSelector
                        sections={filterSections}
                        value={filterValue}
                        onChange={setFilterValue}
                    />
                    <IconButtonGroup
                        color="gray"
                        variant="outlined"
                        items={[
                            {
                                id: "list",
                                icon: "list",
                                selected: view === "list",
                                onClick: () => setView("list"),
                            },
                            {
                                id: "grid",
                                icon: "grid-01",
                                selected: view === "grid",
                                onClick: () => setView("grid"),
                            },
                        ]}
                    />
                </div>
            </div>
            {view === "grid" ? (
                <TemplatesGridView
                    templates={state.templates}
                    toggleTemplateActiveStatus={toggleTemplateActiveStatus}
                    toggleTemplateDefaultStatus={toggleTemplateDefaultStatus}
                />
            ) : (
                <TemplatesTable templates={state.templates} />
            )}
        </div>
    );
};

export default Templates;

const filterSections = [
    {
        id: "templateType",
        name: "Template Type",
        items: [
            {
                id: "digital",
                name: "Digital",
            },
            {
                id: "consulting",
                name: "Consulting",
            },
        ],
    },
    {
        id: "category",
        name: "Category",
        items: [
            {
                id: "win_loss",
                name: "Win Loss",
            },
            {
                id: "value_realisation",
                name: "Value Realisation",
            },
            {
                id: "market_research",
                name: "Market Research",
            },
            {
                id: "exit_interview",
                name: "Exit Interview",
            },
        ],
    },
];
