import React, { FC, useState } from "react";
import {
    CalendarRange,
    CalendarRangeName,
    calendarRangeMap,
    Dropdown,
    Button,
    Typography,
    DatePickerDropdown,
    useCalendarRange,
} from "trinity-components-library";

export type RangeIdType =
    | "lastWeek"
    | "lastMonth"
    | "lastQuarter"
    | "lastYear"
    | "custom";

interface DateRangeSelectorProps {
    value: CalendarRange;
    onChange: (value: CalendarRange) => void;
}

const DateRangeSelector: FC<DateRangeSelectorProps> = ({ value, onChange }) => {
    const options: CalendarRangeName[] = [
        "custom",
        "lastWeek",
        "lastMonth",
        "lastQuarter",
        "lastYear",
    ];
    const [open, setOpen] = useState(false);
    const calendarRange = useCalendarRange({ value, onChange });
    const selectedOption = calendarRangeMap[calendarRange.rangeName];

    return (
        <>
            <Dropdown
                open={open}
                onChange={value => setOpen(value)}
                position="bottom-left"
            >
                <Dropdown.Button>
                    <Button
                        label={selectedOption.title}
                        icon="calendar"
                        variant="outlined"
                        color="gray"
                    />
                </Dropdown.Button>
                <Dropdown.Panel>
                    <div className="flex flex-col bg-white w-40 my-2 rounded-md border border-gray-100 overflow-hidden shadow-xl">
                        {options.map(name => {
                            const { title, id } = calendarRangeMap[name];
                            return (
                                <div
                                    key={id}
                                    onClick={() => {
                                        calendarRange.setRangeByName(name);
                                        setOpen(false);
                                    }}
                                    className={`px-3 py-2 ${
                                        id === selectedOption.id
                                            ? "bg-gray-200"
                                            : ""
                                    } hover:bg-gray-100 cursor-pointer`}
                                >
                                    <Typography color="gray-900">
                                        {title}
                                    </Typography>
                                </div>
                            );
                        })}
                    </div>
                </Dropdown.Panel>
            </Dropdown>
            <DatePickerDropdown
                type="range"
                value={value}
                buttonVariant="text"
                hidePresetRanges
                buttonSelected
                onChange={value => {
                    if (typeof value !== "string") {
                        calendarRange.setValue(value);
                    }
                }}
                position="bottom-left"
            />
        </>
    );
};

export default DateRangeSelector;
