import axios from "axios";
import qs from "qs";
import { useMutation } from "react-query";

export const useOAuthLoginMutation = () => {
    return useMutation(async ({ code }: { code?: string | null }) => {
        const response = await axios({
            method: "POST",
            url: `${process.env.REACT_APP_COGNITO_SOCIAL_DOMAIN}/oauth2/token`,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            data: qs.stringify({
                grant_type: "authorization_code",
                client_id: process.env.REACT_APP_COGNITO_CLIENT_ID,
                code,
                redirect_uri: process.env.REACT_APP_REDIRECT_URI,
            }),
        });
        return response.data;
    });
};
