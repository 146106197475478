/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useGetCompanyBrandingQuery } from "../../../apis/branding.api";
import { useCompanyQuery } from "../../../apis/company.api";
import {
    useSendDealsBulkReviewMutation,
    useDealsReviewProgressQuery,
    useGetSurveyIdQuery,
} from "../../../apis/deal.api";
import { Deal } from "../../../apis/types/deal";
import { ReviewType } from "../../../apis/types/review-type";
import { useUserInfoQuery } from "../../../apis/user.api";

export interface BulkReviewRequest {
    deal_id?: string;
    survey_questionnaire_id?: string;
    template_id?: string;
    report_id?: string;
    reviewer_name?: string;
    reviewer_email?: string;
    reviewer_phone_number?: string;
    requester_info?: {
        user_name: string;
        user_email: string;
        company_name: string;
    };
    branding?: {
        primaryColors: string[];
        secondaryColors: string[];
        logoUrl: string;
        logoFileName: string;
        reviewWelcomeText?: string;
    };
    review_type?: ReviewType;
}

export interface ReviewRequest extends BulkReviewRequest {}

export interface BulkProgressReport {
    failed: [];
    inProgress: [];
    success: [];
    alreadyScheduled: [];
    total: number;
}

const useDeals = (companyId: string) => {
    const { result: userInfo, invalidate } = useUserInfoQuery();
    const { result: companyBranding } = useGetCompanyBrandingQuery({
        companyId,
    });
    const { result: companyResult } = useCompanyQuery({ companyId });
    const { result: surveyIdQuery } = useGetSurveyIdQuery();
    const [bulkReviewId, setBulkReviewId] = useState<string>("");
    const { result: bulkReviewProgress, invalidate: invalidateReviewProgress } =
        useDealsReviewProgressQuery({ bulkReviewId });
    const [bulkProgressReport, setBulkProgressReport] =
        useState<BulkProgressReport>({
            success: [],
            failed: [],
            inProgress: [],
            alreadyScheduled: [],
            total: 0,
        });
    const [progress, setProgress] = useState<number>(0);
    const { mutateAsync: sendDealsBulkReview } =
        useSendDealsBulkReviewMutation();

    const sendBulkReviewRequest = async (selectedDeals: Deal[]) => {
        let dealsBulkReview: BulkReviewRequest[] = selectedDeals.reduce(
            (acc, row) => {
                return [
                    ...acc,
                    ...(row.contact?.contact_persons ?? []).map(subItem => {
                        let obj: BulkReviewRequest = {
                            deal_id: row.id,
                            template_id: row.template_id,
                            survey_questionnaire_id: surveyIdQuery?.data,
                            report_id: "b2dc5a04-8a38-49b2-8803-1f1267139dc3",
                            reviewer_name: subItem.name,
                            reviewer_email: subItem.email,
                            reviewer_phone_number: subItem.phone_number,
                            requester_info: {
                                user_name: row?.opportunityOwner?.name || "",
                                user_email: row?.opportunityOwner?.email || "",
                                company_name: companyResult.isSuccess
                                    ? companyResult.data.name
                                    : userInfo.data.companies[0].name,
                            },
                            branding: {
                                primaryColors: companyBranding.data
                                    ?.primary_colors as string[],
                                secondaryColors: companyBranding.data
                                    ?.secondary_colors as string[],
                                logoUrl: companyBranding.data
                                    ?.logo_url as string,
                                logoFileName: companyBranding.data
                                    ?.logo_file_name as string,
                                reviewWelcomeText: companyBranding.data
                                    ?.review_welcome_text as string,
                            },
                            review_type: row.review_type,
                        };
                        return obj;
                    }),
                ];
            },
            [] as BulkReviewRequest[]
        );
        dealsBulkReview = dealsBulkReview.flat();
        return sendDealsBulkReview({
            bulkReviewRequests: dealsBulkReview,
        }).then(res => {
            setBulkReviewId(res.data);
        });
    };

    useEffect(() => {
        if (bulkReviewId) {
            bulkReviewProgress.data &&
                setBulkProgressReport(bulkReviewProgress.data);
        }
    }, [bulkReviewProgress]);

    useEffect(() => {
        if (bulkReviewId) {
            if (bulkProgressReport.total > 0) {
                setProgress(
                    Math.floor(
                        ((bulkProgressReport.success.length +
                            bulkProgressReport.failed.length) /
                            bulkProgressReport.total) *
                            100 || 0
                    )
                );
            } else {
                if (bulkProgressReport.alreadyScheduled.length > 0) {
                    setProgress(100);
                } else {
                    setProgress(0);
                }
            }
            if (progress === 100) {
                invalidate();
                invalidateReviewProgress();
            }
        }
    }, [bulkReviewId, bulkProgressReport]);

    return {
        progress,
        bulkProgressReport,
        bulkReviewProgress,
        sendBulkReviewRequest,
    };
};

export default useDeals;
