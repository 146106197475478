import { FC } from "react";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import {
    BadgeProps,
    IconType,
    Icon,
    Typography,
    Badge,
    Button,
    Divider,
    Toggle,
} from "trinity-components-library";
import TemplateCardBackground from "./template-card-background";

export interface CommonTemplateType {
    id: string;
    categoryId: string;
    categoryType:
        | "win_loss"
        | "value_realisation"
        | "market_research"
        | "exit_interview"
        | "uncategorised";
    name: string;
    type: "digital" | "consulting" | "uncategorised";
    isActivated: boolean;
    isDefault: boolean;
    isCustom: boolean;
    companyLogo: string;
    primaryColor: string;
}

export interface TemplateCardProps extends CommonTemplateType {
    description: string;
    expanded?: boolean;
    toggleDefaultStatus?: () => void;
    toggleActiveStatus?: () => void;
    hideActiveToggle?: boolean;
    clickable?: boolean;
    showUseButton?: boolean;
    onUseClick?: () => void;
    showContactUsButton?: boolean;
    onContactUsClick?: () => void;
    disableContactUs?: boolean;
    className?: string;
}

export const TemplateCard: FC<TemplateCardProps> = ({
    id,
    type,
    name,
    description,
    isCustom = false,
    isActivated,
    isDefault,
    expanded = false,
    primaryColor = "#201547",
    companyLogo,
    toggleActiveStatus,
    toggleDefaultStatus,
    hideActiveToggle = false,
    clickable = false,
    showUseButton = false,
    onUseClick,
    showContactUsButton = false,
    onContactUsClick,
    disableContactUs = false,
    className,
}) => {
    const navigate = useNavigate();

    const color: BadgeProps["color"] =
        type === "digital"
            ? "success"
            : type === "consulting"
            ? "purple"
            : "gray";

    const values: { icon: IconType; text: string }[] =
        type === "digital"
            ? [
                  { icon: "mail-01", text: "Email or SMS" },
                  { icon: "clock", text: "6-8 minutes" },
              ]
            : [
                  { icon: "user-01", text: "Zoom" },
                  { icon: "clock", text: "30-45 minutes" },
              ];

    return (
        <TemplateCardBackground
            colorHashCode={primaryColor}
            isCustom={isCustom}
            expanded={expanded}
        >
            <div
                className={clsx(
                    "shadow-sm border border-gray-200 rounded-lg flex flex-col",
                    clickable &&
                        !expanded &&
                        id &&
                        type !== "consulting" &&
                        "cursor-pointer",
                    "w-full",
                    className
                )}
                onClick={() => {
                    if (clickable && !expanded && id && type !== "consulting") {
                        navigate(
                            `/templates/${isCustom ? "custom" : "global"}/${id}`
                        );
                    }
                }}
            >
                <div className="flex flex-col gap-3.5 px-6 pb-6 pt-3.5">
                    <div className="flex items-center gap-3">
                        <div
                            className={clsx(
                                "min-w-[48px] min-h-[48px] flex items-center justify-center rounded-full",
                                isCustom ? "bg-white" : `bg-${color}-50`
                            )}
                        >
                            <Icon name="phone-01" color={`${color}-700`} />
                        </div>
                        <div
                            className={clsx(
                                "overflow-hidden relative",
                                !expanded && "flex-1"
                            )}
                        >
                            {expanded && (
                                <div
                                    className="absolute w-full h-full"
                                    style={{
                                        background: `linear-gradient(90deg, ${
                                            isCustom
                                                ? `${primaryColor}`
                                                : "rgba(255,255,255,0)"
                                        } 75%, ${
                                            isCustom
                                                ? `${primaryColor}`
                                                : "rgba(255,255,255,1)"
                                        } 100%, filter: brightness(1.5))`,
                                    }}
                                />
                            )}
                            <Typography
                                color={isCustom ? "white" : "gray-700"}
                                className={clsx(
                                    expanded && "whitespace-nowrap"
                                )}
                            >
                                {name}
                            </Typography>
                        </div>
                        <Badge
                            label={
                                type === "digital" ? "Digital" : "Consulting"
                            }
                            color={color}
                        />
                        {expanded && isCustom && (
                            <div className="flex-1 flex justify-end items-center">
                                <Button
                                    label={
                                        isDefault ? "Default" : "Make default"
                                    }
                                    variant="outlined"
                                    color="gray"
                                    onClick={toggleDefaultStatus}
                                />
                            </div>
                        )}
                    </div>
                    <Typography
                        size="sm"
                        color={isCustom ? "white" : "gray-500"}
                    >
                        {description}
                    </Typography>
                </div>
                <Divider
                    style={
                        isCustom
                            ? {
                                  backgroundColor: primaryColor,
                                  filter: "brightness(1.75)",
                              }
                            : {}
                    }
                />
                <div className="p-6 flex flex-col gap-3">
                    {values.map(({ icon, text }) => {
                        return (
                            <div className="flex gap-2">
                                <Icon
                                    name={icon}
                                    size={20}
                                    color={isCustom ? "white" : "gray-500"}
                                />
                                <Typography
                                    size="sm"
                                    color={isCustom ? "white" : "gray-500"}
                                >
                                    {text}
                                </Typography>
                            </div>
                        );
                    })}
                </div>
                <Divider
                    style={
                        isCustom
                            ? {
                                  backgroundColor: primaryColor,
                                  filter: "brightness(1.75)",
                              }
                            : {}
                    }
                />
                <div className={clsx("flex items-center px-6 py-4")}>
                    <div className="flex-1">
                        {companyLogo && (
                            <img
                                src={companyLogo}
                                alt="company-logo"
                                style={{
                                    minWidth: "90px",
                                    height: "28px",
                                    objectFit: "fill",
                                }}
                            />
                        )}
                    </div>
                    <div
                        className="flex items-center"
                        onClick={e => e.stopPropagation()}
                    >
                        <Toggle
                            enabled={isActivated}
                            onChange={toggleActiveStatus}
                            className={clsx(hideActiveToggle && "invisible")}
                        />
                        {showUseButton && (
                            <Button label="Use" onClick={onUseClick} />
                        )}
                        {showContactUsButton && (
                            <Button
                                label="Contact Us"
                                onClick={onContactUsClick}
                                variant="outlined"
                                color="gray"
                                disabled={disableContactUs}
                            />
                        )}
                    </div>
                </div>
            </div>
        </TemplateCardBackground>
    );
};

export default TemplateCard;
