import { FC } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { Typography, ColorPicker, Radio } from "trinity-components-library";
import FormItem from "../../components/form-item";

export interface ColorPaletteItemProps {
    form: UseFormReturn<any>;
}

const ColorPaletteItem: FC<ColorPaletteItemProps> = ({ form }) => {
    return (
        <FormItem
            title="Color Palette"
            helperText="Update your company logo and then choose where you want it to display."
        >
            <div className="flex flex-col gap-6">
                <div className="flex flex-col gap-3 items-start">
                    <Typography size="sm" color="gray-700">
                        Primary Color
                    </Typography>
                    <Controller
                        name="primaryColors"
                        control={form.control}
                        render={({ field }) => {
                            const [firstPrimary, secondPrimary] = field.value;
                            const isSolid = !secondPrimary;
                            return (
                                <div className="flex flex-col gap-3">
                                    <div className="flex gap-4">
                                        <div
                                            className="flex gap-2 items-center cursor-pointer"
                                            onClick={() => {
                                                field.onChange([firstPrimary]);
                                            }}
                                        >
                                            <Radio
                                                name="primary-color"
                                                checked={isSolid}
                                            />
                                            <Typography
                                                size="sm"
                                                color="gray-700"
                                            >
                                                Solid
                                            </Typography>
                                        </div>
                                        <div
                                            className="flex gap-2 items-center cursor-pointer"
                                            onClick={() => {
                                                field.onChange([
                                                    firstPrimary,
                                                    "#000000",
                                                ]);
                                            }}
                                        >
                                            <Radio
                                                name="primary-color"
                                                checked={!isSolid}
                                            />
                                            <Typography
                                                size="sm"
                                                color="gray-700"
                                            >
                                                Gradient
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className="flex gap-3">
                                        <ColorPicker
                                            onChange={color => {
                                                field.onChange(
                                                    secondPrimary
                                                        ? [color, secondPrimary]
                                                        : [color]
                                                );
                                            }}
                                            value={firstPrimary}
                                        />
                                        {secondPrimary && (
                                            <ColorPicker
                                                onChange={color => {
                                                    field.onChange([
                                                        firstPrimary,
                                                        color,
                                                    ]);
                                                }}
                                                value={secondPrimary}
                                            />
                                        )}
                                    </div>
                                </div>
                            );
                        }}
                    />
                </div>
                <div className="flex flex-col gap-3">
                    <Typography size="sm" color="gray-700">
                        Secondary Color
                    </Typography>
                    <Controller
                        name="secondaryColors"
                        control={form.control}
                        render={({ field }) => {
                            const [secondaryColor] = field.value;
                            return (
                                <ColorPicker
                                    value={secondaryColor}
                                    onChange={color => field.onChange([color])}
                                />
                            );
                        }}
                    />
                </div>
            </div>
        </FormItem>
    );
};

export default ColorPaletteItem;
