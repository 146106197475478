import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
    Typography,
    getCalendarRange,
    IconButtonGroup,
    Button,
    TextInputField,
} from "trinity-components-library";
import ReportsGridView from "../../sections/reports-grid-view/reports-grid-view";
import { ReportItem } from "../../sections/reports-grid-view/types";
import FilterSelector from "../reports/components/filter-selector";
import DateRangeSelector from "./date-range-selector.tsx/dater-range-selector";
import { ReviewType } from "../../apis/types/review-type";
import { ReactComponent as CominSoonInsightsSvg } from "../../assets/images/coming-soon.svg";
import { ReactComponent as CominSoonTemplatesSvg } from "../../assets/images/coming-soon-1.svg";

type ComingSoonProps = {
    variant?: "gray" | "vibrant";
    description?: string;
    goBackAction?: Function;
};

const ComingSoon = ({
    variant = "vibrant",
    description,
    goBackAction,
}: ComingSoonProps) => {
    const navigate = useNavigate();

    const handleBack = () => {
        if (goBackAction) {
            goBackAction();
        }
        navigate(-1);
    };

    const reports: ReportItem[] = useMemo(
        () => [
            {
                id: "a249a3b8-d16a-4f80-9b76-d0a52d5f9ae3",
                companyName: "WhatAgain",
                companyLogo: "",
                reviewType: ReviewType.DIGITAL,
                responder: "John Doe",
                respondedDate: "2023-03-16T20:24:18.000Z",
                resultType: "win",
            },
            {
                id: "ba1d29d3-8e55-4ca2-ba8f-ef5b70894689",
                companyName: "Disaster Kits Limited",
                companyLogo: "",
                reviewType: ReviewType.DIGITAL,
                responder: "Paul Faust",
                respondedDate: "2023-03-09T03:46:21.000Z",
                resultType: "loss",
            },
            {
                id: "8036d849-4968-481a-9438-736c67d6ad4a",
                companyName: "multiple reviews",
                companyLogo: "",
                reviewType: ReviewType.DIGITAL,
                responder: "test1",
                respondedDate: "2023-03-01T02:40:42.000Z",
                resultType: "loss",
            },
            {
                id: "212e8369-d2c6-42b6-9a58-6d9252f80b7e",
                companyName: "techwondoe",
                companyLogo:
                    "https://test-trinity-public-bucket.s3.ap-southeast-2.amazonaws.com/contacts/b3a58b2e-6d91-4ca4-af76-a1940b015fa2/https://techwondoe.com",
                reviewType: ReviewType.DIGITAL,
                responder: "Reenu Abraham",
                respondedDate: "2023-02-14T22:14:52.000Z",
                resultType: "win",
            },
        ],
        []
    );

    return (
        <div className="w-full h-full items-center overflow-hidden relative">
            <div className="absolute top-0 left-0 w-full h-full z-10 bg-[#c3c3c3cc] blur-sm"></div>
            <div className="absolute top-0 left-0 w-full h-full">
                <div className="p-8 flex flex-col gap-8">
                    <div className="flex items-start gap-5 flex-wrap">
                        <Typography
                            type="display"
                            size="sm"
                            color="gray-900"
                            className="flex-1"
                        >
                            Win loss reports
                        </Typography>
                        <div className="flex gap-3 items-start flex-wrap">
                            <div className="w-44">
                                <TextInputField
                                    icon="search-sm"
                                    fullWidth
                                    placeholder="Search"
                                    onChange={() => {}}
                                    value={""}
                                />
                            </div>
                            <FilterSelector
                                value={{
                                    reportType: [],
                                    result: [],
                                }}
                                onChange={() => {}}
                            />
                            <DateRangeSelector
                                value={getCalendarRange("lastYear")}
                                onChange={() => {}}
                            />
                            <IconButtonGroup
                                color="gray"
                                variant="outlined"
                                items={[
                                    {
                                        id: "list",
                                        icon: "list",
                                        selected: false,
                                        onClick: () => {},
                                    },
                                    {
                                        id: "grid",
                                        icon: "grid-01",
                                        selected: true,
                                        onClick: () => {},
                                    },
                                ]}
                            />
                        </div>
                    </div>
                    <div>
                        <ReportsGridView
                            reports={reports}
                            page={0}
                            pageCount={1}
                            onPageChange={() => {}}
                        />
                    </div>
                </div>
            </div>
            <div className="flex items-center justify-center h-screen">
                <div className="px-16 py-20 z-20 w-[512px] justify-center rounded-xl bg-white blur-none">
                    <div className="flex items-center mx-auto justify-center">
                        {variant === "gray" ? (
                            <CominSoonTemplatesSvg />
                        ) : (
                            <CominSoonInsightsSvg />
                        )}
                    </div>
                    <Typography
                        variant="h2"
                        className="text-center mt-4"
                        size="xl"
                        color="gray-900"
                        weight="medium"
                        type="text"
                    >
                        Coming Soon
                    </Typography>
                    {description && (
                        <Typography
                            variant="p"
                            type="text"
                            color="gray-500"
                            className="text-center mt-2"
                            size="md"
                            weight="normal"
                        >
                            {description}
                        </Typography>
                    )}
                    <div className="flex">
                        <Button
                            variant="outlined"
                            color="gray"
                            label="Go back"
                            className="mt-8 mx-auto"
                            onClick={handleBack}
                            icon="chevron-left"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ComingSoon;
