import { FC, useState } from "react";
import {
    IconType,
    Typography,
    Button,
    UploadItem,
    Upload,
} from "trinity-components-library";
import { useDealsQuery, useUploadDealsMutation } from "../../../apis/deal.api";
import { CheckCircleBaseIcon } from "../../../assets/misc-icons";
import getLoggedInCompanyId from "../../../libs/getLoggedInCompanyId";
import SimpleHeaderModal from "./simple-header-modal";

export interface BulkFileUploadModalProps {
    open: boolean;
    onClose?: () => void;
    leftIcon?: IconType;
    onLeftIconClick?: () => void;
}

const BulkFileUploadModal: FC<BulkFileUploadModalProps> = ({
    open,
    onClose,
    ...props
}) => {
    const [file, setFile] = useState<File | null>(null);
    const [progress, setProgress] = useState(0);
    const [{ successCount, status }, setUploadedCounts] = useState<{
        successCount: number;
        failedCount: number;
        status: "pending" | "success" | "failed";
    }>({
        successCount: 0,
        failedCount: 0,
        status: "pending",
    });
    const uploadMutation = useUploadDealsMutation();
    const companyId = getLoggedInCompanyId() ?? "";
    const { invalidate: invalidateAllDeals } = useDealsQuery(
        { companyId },
        { enabled: false }
    );

    const uploadFile = async (file: File) => {
        try {
            setProgress(0);
            setUploadedCounts({
                successCount: 0,
                failedCount: 0,
                status: "pending",
            });
            const response: { status: "success" | "failed" }[] =
                await uploadMutation.mutateAsync({
                    file,
                    companyId,
                    onUploadProgress: event => {
                        const { loaded, total = 1 } = event;
                        const percentage = Number(
                            ((loaded * 100) / total).toFixed(0)
                        );
                        setProgress(percentage);
                    },
                });
            const getCount = (status: "success" | "failed") =>
                response.filter(
                    (item: { status: "success" | "failed" }) =>
                        item.status === status
                ).length;

            const success = getCount("success");
            const failed = getCount("failed");
            setUploadedCounts({
                successCount: success,
                failedCount: failed,
                status: response.length
                    ? success
                        ? "success"
                        : failed
                        ? "failed"
                        : "pending"
                    : "success",
            });
            invalidateAllDeals();
        } catch {}
    };

    return (
        <SimpleHeaderModal
            open={open}
            onClose={() => {
                onClose?.();
                setFile(null);
            }}
            title={
                file === null
                    ? "Select files to upload"
                    : uploadMutation.isLoading
                    ? "Hold on, uploading..."
                    : status === "success"
                    ? "Uploaded"
                    : "Uploading failed"
            }
            {...(file === null ? props : {})}
        >
            <div className="w-[752px]">
                <div className="flex flex-col gap-6">
                    {file ? (
                        status === "success" ? (
                            <div className="w-full h-[262px] flex flex-col gap-6 items-center justify-center">
                                <div className="flex flex-col gap-4 items-center">
                                    <CheckCircleBaseIcon />
                                    <Typography
                                        className="text-center"
                                        color="gray-700"
                                    >{`${successCount} Opportunities successfully uploaded`}</Typography>
                                </div>
                                <Button label="Done" onClick={onClose} />
                            </div>
                        ) : (
                            <UploadItem
                                filename={file.name ?? ""}
                                fileSize={file.size ?? 0}
                                progress={progress}
                                isFailed={
                                    uploadMutation.isError ||
                                    status === "failed"
                                }
                                onDelete={() => setFile(null)}
                                onTryAgain={() => {
                                    if (file) {
                                        uploadFile(file);
                                    }
                                }}
                                expanded
                            />
                        )
                    ) : (
                        <Upload
                            accept={{ "text/csv": [".csv"] }}
                            className="h-[258px]"
                            onChange={files => {
                                if (files.length) {
                                    setFile(files[0]);
                                    uploadFile(files[0]);
                                }
                            }}
                        />
                    )}
                </div>
            </div>
        </SimpleHeaderModal>
    );
};

export default BulkFileUploadModal;
