/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
    Typography,
    VirtualizedSearchInputField,
    SiteInputField,
    Button,
    TextInputField,
    SelectInputField,
    countryCodesMap,
    ISO2Code,
} from "trinity-components-library";
import ContactFormPart from "./add-contact-form-part";
import {
    useGetTeamMembersQuery,
    UseGetTeamMembersQueryParams,
} from "../../../apis/team-members.api";
import { useUserInfoQuery } from "../../../apis/user.api";
import {
    MemberScope,
    MemberSortKeys,
    MemberSortOrder,
    MemberStatus,
} from "../../../apis/types/team-members.api.types";
import { CycleDuration } from "../../../apis/types/cycle-duration";
import { DealOutcome } from "../../../apis/types/deal-outcome";
import {
    useCreateDealMutation,
    useDealsQuery,
    usePutDealMutation,
} from "../../../apis/deal.api";
import { ReviewType } from "../../../apis/types/review-type";
import { SalesProcess } from "../../../apis/types/sales-process";
import { DealSource } from "../../../apis/types/deal-source";
import SimpleHeaderModal from "./simple-header-modal";
import LocationInputField from "../../../components/location-input/location-input";
import getLoggedInCompanyId from "../../../libs/getLoggedInCompanyId";
import {
    useGetCompanyContactsQuery,
    useUpdateContactMutation,
} from "../../../apis/contact.api";
import { Deal } from "../../../apis/types/deal";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import { ContactStatus } from "../../../apis/types/contact";

type Company = {
    name: string;
    website: string;
};

type Opportunity = {
    name: string;
    value: string;
    details?: string;
    location: string;
    ownerId: string;
    salesDuration: CycleDuration;
    outcome: string;
};

type ContactDetails = {
    name: string;
    title: string;
    email: string;
    phone: {
        code: ISO2Code;
        value: string;
    };
};

export type OpportunityForm = {
    company: Company;
    contactDetails: Array<ContactDetails>;
    opportunity: Opportunity;
};

type AddOpportunityFormModalProps = {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    openPreviousModal: () => void;
    defaultFormValues?: OpportunityForm;
    type?: "create" | "edit";
    deal?: Deal | null;
};

const AddOpportunityFormModal = ({
    isOpen,
    setIsOpen,
    openPreviousModal,
    type = "create",
    deal = null,
}: AddOpportunityFormModalProps) => {
    const companyId = getLoggedInCompanyId() ?? "";
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { invalidate: invalidateDeals } = useDealsQuery(
        { companyId },
        { enabled: false }
    );

    const { result: userQuery } = useUserInfoQuery();
    const params = useMemo<UseGetTeamMembersQueryParams>(
        () => ({
            companyId,
            statuses: [MemberStatus.INVITED, MemberStatus.ACTIVE],
            scopes: [
                MemberScope.ADMIN,
                MemberScope.SALES_LEADER,
                MemberScope.SALES_REP,
            ],
            sortBy: [MemberSortKeys.NAME],
            sortOrder: MemberSortOrder.ASCENDING,
        }),
        [userQuery?.data]
    );
    const { result: teamQuery } = useGetTeamMembersQuery(params);

    const [opportunityOwnerArr, setOpportunityOwnerArr] = useState<
        Array<{ id: string; owner: string }>
    >([]);

    useEffect(() => {
        if (teamQuery?.data) {
            const opportunityOwnerArr = teamQuery.data.map(member => {
                return {
                    id: member.id,
                    owner: member.name,
                };
            });
            setOpportunityOwnerArr(opportunityOwnerArr);
        }
    }, [teamQuery?.data]);

    const opportunitySalesDurationArr = useMemo(() => {
        let keys = Object.keys(CycleDuration);
        let values = Object.values(CycleDuration);
        let arr = [];
        for (let i = 0; i < keys.length; i++) {
            arr.push({
                id: keys[i],
                salesDuration: values[i],
            });
        }
        return arr;
    }, []);

    const opportunityOutcomeArr = useMemo(() => {
        let keys = Object.keys(DealOutcome);
        let values = Object.values(DealOutcome);
        let arr = [];
        for (let i = 0; i < keys.length; i++) {
            arr.push({
                id: keys[i],
                outcome: values[i],
            });
        }
        return arr;
    }, []);

    const form = useForm<OpportunityForm>({
        defaultValues:
            type === "edit" && deal
                ? {
                      company: {
                          name: deal?.contact?.company_name ?? "",
                          website: deal?.contact?.website ?? "",
                      },
                      contactDetails: (
                          deal?.contact?.contact_persons ?? []
                      ).map(({ email, name, phone_number = "", title }) => {
                          let phoneNumber: any = {
                              country: "AU",
                              formatNational: () => "",
                          };
                          try {
                              if (isValidPhoneNumber(phone_number)) {
                                  phoneNumber = parsePhoneNumber(phone_number);
                              }
                          } catch {}
                          return {
                              email,
                              name,
                              phone: {
                                  code: phoneNumber.country as ISO2Code,
                                  value: phoneNumber.formatNational(),
                              },
                              title,
                          };
                      }),
                      opportunity: {
                          location: deal.location,
                          name: deal.name,
                          outcome: deal.outcome,
                          ownerId: deal.owner_id,
                          salesDuration: deal.cycle_duration,
                          value: `${deal.value}`,
                      },
                  }
                : {
                      company: {
                          name: "",
                          website: "",
                      },
                      contactDetails: [
                          {
                              name: "",
                              title: "",
                              email: "",
                              phone: {
                                  code: "AU",
                                  value: "",
                              },
                          },
                      ],
                      opportunity: {
                          name: "",
                          value: "",
                          location: "",
                          ownerId: "",
                          salesDuration: CycleDuration.BETWEEN_30_TO_90_DAYS,
                          outcome: "",
                      },
                  },
        criteriaMode: "all",
        mode: "onChange",
    });
    const { result: contact } = useGetCompanyContactsQuery({
        forCompanyId: companyId,
        companyName: "",
        likeSearch: true,
    });

    const companies = useMemo(() => {
        if (contact.status === "success") {
            const companyMap = ((contact.data as any)?.items ?? []).reduce(
                (acc: any, item: any) => {
                    acc[item.company_name] = acc[item.company_name]
                        ? [...acc[item.company_name], ...item.contact_persons]
                        : item.contact_persons;
                    return acc;
                },
                {}
            );
            return Object.keys(companyMap).map(name => {
                return {
                    name,
                    contactPersons: companyMap[name],
                };
            });
        }
        return [];
    }, [contact.status]);

    const {
        formState: { errors, isValid, isDirty },
    } = form;

    const onSubmit = form.handleSubmit(async values => {
        setIsSubmitting(true);
        if (type === "create") {
            await handleAddOppportunity(values);
        } else {
            await handleEditOpportunity(values);
        }
        setIsSubmitting(false);
    });

    const handleClose = (callback?: Function) => {
        if (callback) {
            callback();
        }
        form.reset();
        setIsOpen(false);
    };

    const createDealQuery = useCreateDealMutation();
    const updateDealMutation = usePutDealMutation();
    const updateContactMutation = useUpdateContactMutation();

    const handleAddOppportunity = (values: OpportunityForm) => {
        createDealQuery
            .mutateAsync({
                source: DealSource.MANUAL,
                company_id: companyId,
                name: values.opportunity.name,
                value: parseInt(values.opportunity.value),
                location: values.opportunity.location,
                owner_id: values.opportunity.ownerId,
                cycle_duration: values.opportunity.salesDuration,
                outcome: values.opportunity.outcome as DealOutcome,
                review_type: ReviewType.NOT_YET_REVIEWED,
                sales_process: SalesProcess.Formal_RFP,
                contact: {
                    company_name: values.company.name,
                    website: "https://" + values.company.website,
                    contact_persons: JSON.stringify(
                        values.contactDetails.map((contact, index) => {
                            const countryCode =
                                countryCodesMap[contact.phone.code];

                            return {
                                name: contact.name,
                                title: contact.title,
                                email: contact.email,
                                phone_number: contact.phone.value
                                    ? `+${
                                          countryCode.code
                                      } ${contact.phone.value?.replaceAll(
                                          " ",
                                          ""
                                      )}`
                                    : "",
                                primary_contact: index === 0,
                            };
                        })
                    ),
                    for_company_id: companyId,
                    contact_logo: "",
                },
            })
            .then(res => {
                form.reset();
                invalidateDeals();
                handleClose();
            })
            .catch(() => {});
    };

    const handleEditOpportunity = async ({
        company,
        contactDetails,
        opportunity,
    }: OpportunityForm) => {
        try {
            if (deal) {
                await updateContactMutation.mutateAsync({
                    companyName: company.name,
                    website: company.website,
                    contactPersons: contactDetails.map(
                        (
                            { email, name, phone: { code, value }, title },
                            index
                        ) => {
                            const countryCode = countryCodesMap[code];
                            return {
                                email,
                                name,
                                phone_number: `+${
                                    countryCode.code
                                } ${value?.replaceAll(" ", "")}`,
                                title,
                                primary_contact: index === 0,
                            };
                        }
                    ),
                    id: deal.contact_id,
                    status: deal?.contact?.status ?? ContactStatus.ACTIVE,
                });
                await updateDealMutation.mutateAsync({
                    id: deal.id,
                    contactId: deal.contact_id,
                    cycleDuration: opportunity.salesDuration,
                    location: opportunity.location,
                    name: opportunity.name,
                    outcome: opportunity.outcome,
                    ownerId: opportunity.ownerId,
                    value: Number(opportunity.value),
                });
            }
            form.reset();
            invalidateDeals();
            handleClose();
        } catch {}
    };

    return (
        <SimpleHeaderModal
            title="Add opportunity"
            leftIcon="plus"
            open={isOpen}
            onClose={() => handleClose()}
        >
            {/* <!-- Modal body --> */}
            <div className="flex flex-col border-b">
                {/* Company Details */}
                <div className="flex flex-col p-6 border-b">
                    <Typography
                        type="text"
                        variant="h6"
                        size="md"
                        color="gray-800"
                        className="mb-6"
                    >
                        Company Details
                    </Typography>
                    <div className="grid grid-cols-2 gap-3">
                        <Controller
                            name="company.name"
                            control={form.control}
                            rules={{
                                required: true,
                            }}
                            render={({ field }) => (
                                <VirtualizedSearchInputField
                                    label="Company name"
                                    placeholder="Trinity"
                                    items={companies.map(
                                        ({ name }: { name: string }) => ({
                                            id: name,
                                            value: name,
                                        })
                                    )}
                                    dropdownHeight={270}
                                    itemHeight={45}
                                    value={field.value}
                                    onChange={field.onChange}
                                    hideNotFoundText
                                    status={
                                        contact.status === "idle"
                                            ? "loading"
                                            : contact.status
                                    }
                                    error={
                                        errors.company?.name?.type ===
                                        "required"
                                            ? "Required"
                                            : ""
                                    }
                                />
                            )}
                        />
                        <Controller
                            name="company.website"
                            control={form.control}
                            rules={{
                                pattern: {
                                    value: /^(https?:\/\/)?([\w\d-_]+)\.([\w\d-_.]+)\/?\??([^#\n\r]*)?#?([^\n\r]*)/i,
                                    message: "Invalid website URL",
                                },
                                required: true,
                            }}
                            render={({ field }) => (
                                <SiteInputField
                                    label="Company website"
                                    placeholder="www.trinity.com"
                                    value={field.value}
                                    onChange={value => {
                                        value.trim();
                                        if (value.includes("https://")) {
                                            field.onChange(value.slice(8));
                                        } else {
                                            field.onChange(value);
                                        }
                                    }}
                                    error={
                                        errors.company?.website?.message ||
                                        (errors.company?.website?.type ===
                                        "required"
                                            ? "Required"
                                            : "")
                                    }
                                />
                            )}
                        />
                    </div>
                </div>
                {/* Opportunity Details */}
                <div className="flex flex-col p-6 border-b">
                    <Typography
                        type="text"
                        variant="h6"
                        size="md"
                        color="gray-800"
                        className="mb-6"
                    >
                        Opportunity details
                    </Typography>
                    <div className="grid grid-cols-2 gap-3">
                        <div className="grid grid-rows-3 gap-6">
                            <Controller
                                name="opportunity.name"
                                control={form.control}
                                rules={{
                                    required: true,
                                }}
                                render={({ field }) => (
                                    <TextInputField
                                        label="Opportunity name"
                                        placeholder="First opportunity"
                                        value={field.value}
                                        onChange={value => {
                                            field.onChange(value);
                                        }}
                                        error={
                                            errors.opportunity?.name?.type ===
                                            "required"
                                                ? "Required"
                                                : ""
                                        }
                                    />
                                )}
                            />
                            <Controller
                                name="opportunity.ownerId"
                                control={form.control}
                                rules={{
                                    required: true,
                                }}
                                render={({ field }) => (
                                    <SelectInputField
                                        label="Opportunity owner"
                                        placeholder="Select"
                                        items={opportunityOwnerArr}
                                        disabled={
                                            type === "edit" &&
                                            teamQuery.isLoading
                                        }
                                        renderItem={item => (
                                            <Typography>
                                                {item.owner}
                                            </Typography>
                                        )}
                                        onChange={val => field.onChange(val.id)}
                                        value={
                                            opportunityOwnerArr.find(
                                                ({ id }) => id === field.value
                                            ) ?? { id: "", owner: "" }
                                        }
                                        error={
                                            errors.opportunity?.ownerId
                                                ?.type === "required"
                                                ? "Required"
                                                : ""
                                        }
                                    />
                                )}
                            />
                            <Controller
                                name="opportunity.salesDuration"
                                control={form.control}
                                rules={{
                                    required: true,
                                }}
                                render={({ field }) => (
                                    <SelectInputField
                                        label="Sales duration"
                                        placeholder="Select"
                                        items={opportunitySalesDurationArr}
                                        renderItem={item => (
                                            <Typography>
                                                {item.salesDuration}
                                            </Typography>
                                        )}
                                        onChange={({ id }) =>
                                            field.onChange(id as CycleDuration)
                                        }
                                        value={
                                            opportunitySalesDurationArr.find(
                                                ({ id }) => id === field.value
                                            ) ?? opportunitySalesDurationArr[0]
                                        }
                                        error={
                                            errors.opportunity?.salesDuration
                                                ?.type === "required"
                                                ? "Required"
                                                : ""
                                        }
                                    />
                                )}
                            />
                        </div>
                        <div className="grid grid-rows-3 gap-6">
                            <Controller
                                name="opportunity.value"
                                control={form.control}
                                rules={{
                                    required: true,
                                    pattern: {
                                        value: /^[0-9]*$/,
                                        message: "Invalid value",
                                    },
                                }}
                                render={({ field }) => (
                                    <TextInputField
                                        label="Opportunity value"
                                        placeholder="$ 100,000"
                                        value={
                                            (field.value ? "$ " : "") +
                                            field.value
                                        }
                                        onChange={value => {
                                            value = value.replace(
                                                /[^0-9]/g,
                                                ""
                                            );
                                            field.onChange(value);
                                        }}
                                        error={
                                            errors.opportunity?.value
                                                ?.message ||
                                            (errors.opportunity?.value?.type ===
                                            "required"
                                                ? "Required"
                                                : "")
                                        }
                                    />
                                )}
                            />
                            <Controller
                                name="opportunity.location"
                                control={form.control}
                                rules={{
                                    required: true,
                                }}
                                render={({ field }) => (
                                    <LocationInputField
                                        label="Opportunity location"
                                        placeholder="Select"
                                        value={field.value}
                                        onChange={field.onChange}
                                        error={
                                            errors.opportunity?.location
                                                ?.type === "required"
                                                ? "Required"
                                                : ""
                                        }
                                    />
                                )}
                            />
                            <Controller
                                name="opportunity.outcome"
                                control={form.control}
                                rules={{
                                    required: true,
                                }}
                                render={({ field }) => (
                                    <SelectInputField
                                        label="Opportunity outcome"
                                        placeholder="Select"
                                        items={opportunityOutcomeArr}
                                        value={opportunityOutcomeArr.find(
                                            item => item.outcome === field.value
                                        )}
                                        renderItem={item => (
                                            <Typography>
                                                {/* camel case to title case */}
                                                {item.outcome
                                                    .split(" ")
                                                    .map(
                                                        word =>
                                                            word
                                                                .charAt(0)
                                                                .toUpperCase() +
                                                            word
                                                                .slice(1)
                                                                .toLowerCase()
                                                    )
                                                    .join(" ")}
                                            </Typography>
                                        )}
                                        onChange={value => {
                                            field.onChange(value.outcome);
                                        }}
                                        error={
                                            errors.opportunity?.outcome
                                                ?.type === "required"
                                                ? "Required"
                                                : ""
                                        }
                                    />
                                )}
                            />
                        </div>
                    </div>
                </div>
                {/* Contact Details */}
                <ContactFormPart
                    form={form}
                    contactPersons={
                        (
                            companies.find(
                                ({ name }) =>
                                    name === form.getValues().company.name
                            ) ?? { contactPersons: [] }
                        ).contactPersons
                    }
                    status={
                        contact.status === "idle" ? "loading" : contact.status
                    }
                />
            </div>
            <div className="flex justify-around w-full gap-3 p-6">
                <Button
                    label="Back"
                    color="gray"
                    variant="outlined"
                    fullWidth
                    onClick={() => handleClose(openPreviousModal)}
                    disabled={isSubmitting}
                />
                <Button
                    label="Save opportunity"
                    color="primary"
                    variant="contained"
                    fullWidth
                    disabled={!isValid || !isDirty || isSubmitting}
                    onClick={onSubmit}
                />
            </div>
        </SimpleHeaderModal>
    );
};

export default AddOpportunityFormModal;
