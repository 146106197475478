import { useQuery, useQueryClient } from "react-query";
import axios from "axios";
import { UseQueryReturnType } from "./api";

interface Country {
    country: string;
    iso2: string;
    iso3: string;
    cities: string[];
}

export const useCountries = (): UseQueryReturnType<Country[]> => {
    const queryKey = "countries-now";
    const queryClient = useQueryClient();
    const invalidate = () => queryClient.invalidateQueries(queryKey);
    const result = useQuery(
        "",
        async () => {
            const resp = await axios.get(
                "https://countriesnow.space/api/v0.1/countries"
            );
            return resp.data.data;
        },
        { refetchOnWindowFocus: false }
    );
    return {
        result,
        invalidate,
    };
};
