import React, { FC } from "react";
import { Icon, Typography, Button } from "trinity-components-library";

interface ScheduleReviewModalErrorStateProps {
    onCancel?: () => void;
    onTryAgain?: () => void;
}

export const ScheduleReviewModalErrorState: FC<ScheduleReviewModalErrorStateProps> =
    ({ onCancel, onTryAgain }) => {
        return (
            <div className="w-[400px] bg-white rounded-xl p-6 flex flex-col gap-8">
                <div className="flex flex-col items-center gap-5">
                    <div className="w-12 h-12 flex items-center justify-center bg-error-100 border-8 border-error-50 rounded-full">
                        <Icon name="alert-triangle" color="error-600" />
                    </div>
                    <div className="flex flex-col items-center gap-2">
                        <Typography size="lg" color="gray-900" weight="medium">
                            Review Scheduling Failed
                        </Typography>
                        <Typography
                            className="text-center"
                            size="sm"
                            color="gray-500"
                        >
                            We're sorry, but we were unable to schedule your
                            individual review at this time. Please try again
                            later or contact our support team for assistance.
                        </Typography>
                    </div>
                </div>
                <div className="flex gap-3">
                    <Button
                        label="Cancel"
                        variant="outlined"
                        color="gray"
                        fullWidth
                        size="lg"
                        onClick={onCancel}
                    />
                    <Button
                        label="Try Again"
                        color="error"
                        fullWidth
                        size="lg"
                        onClick={onTryAgain}
                    />
                </div>
            </div>
        );
    };

export default ScheduleReviewModalErrorState;
