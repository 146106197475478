import React, { FC } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { MailInputField } from "trinity-components-library";
import FormItem from "../../components/form-item";

export interface EmailItemProps {
    form: UseFormReturn<any>;
}

const EmailItem: FC<EmailItemProps> = ({ form }) => {
    return (
        <FormItem
            title="Email Address"
            helperText="Enter an email if you’d like to be contacted via email."
        >
            <Controller
                name="email"
                control={form.control}
                render={({ field }) => (
                    <MailInputField
                        fullWidth
                        placeholder="Enter email address"
                        value={field.value}
                        onChange={field.onChange}
                        disabled
                    />
                )}
            />
        </FormItem>
    );
};

export default EmailItem;
