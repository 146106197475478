import {
    SidebarMenuItem,
    SidebarItemProps,
    SideBar,
} from "trinity-components-library";
import { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDownloadAttachmentQuery } from "../../apis/attachment.api";
import { useUserInfoQuery } from "../../apis/user.api";
import { useAuth } from "../../libs/auth/auth";
import { capitalize } from "../../utils";

export interface CustomSidebarProps {
    opportunities: {
        scheduled?: number;
        unscheduled?: number;
        all?: number;
    };
    reports: {
        winLoss?: number;
        valueRealisation?: number;
        marketResearch?: number;
        exitInterview?: number;
    };
    onPageChange?: () => void;
}

enum SidebarMenuId {
    HOME = "home",
    OPPORTUNITIES = "opportunities",
    TEMPLATES = "templates",
    REPORTS = "reports",
    INSIGHTS = "insights",
    SUPPORT = "support",
    SETTINGS = "settings",
}
enum SidebarItemId {
    SCHEDULED = "scheduled",
    UNSCHEDULED = "unscheduled",
    ALL = "all",
    WIN_LOSS = "win-loss",
    VALUE_REALISATION = "value-realisation",
    MARKET_RESEARCH = "market-research",
    EXIT_INTERVIEW = "exit-interview",
    TREND = "trend",
}

const CustomSidebar: FC<CustomSidebarProps> = ({
    opportunities,
    reports,
    onPageChange,
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { onLogout } = useAuth();
    const defaultSelection = (
        location.pathname === "/"
            ? ["home"]
            : location.pathname.split("/").slice(1)
    ) as [SidebarMenuId] | [SidebarMenuId, SidebarItemId];
    const onMenuItemClick = (menuId: SidebarMenuId, itemId?: SidebarItemId) => {
        if (menuId) {
            if (menuId === "home") {
                navigate("/");
            } else if (menuId === "support") {
                window.open("https://intercom.help/usetrinity/en", "_blank");
            } else {
                navigate(itemId ? `/${menuId}/${itemId}` : `/${menuId}`);
            }
        } else {
            navigate("/");
        }
        onPageChange?.();
    };
    const menuItems: SidebarMenuItem<SidebarMenuId, SidebarItemId>[] = [
        {
            id: SidebarMenuId.HOME,
            title: "Home",
            icon: "home-line",
            onClick: () => onMenuItemClick(SidebarMenuId.HOME),
        },
        {
            id: SidebarMenuId.OPPORTUNITIES,
            title: "Opportunities",
            icon: "lightbulb-03",
            dropdown: true,
            items: [
                {
                    id: SidebarItemId.SCHEDULED,
                    title: "Scheduled",
                    badgeValue: `${opportunities.scheduled ?? 0}`,
                    onClick: () =>
                        onMenuItemClick(
                            SidebarMenuId.OPPORTUNITIES,
                            SidebarItemId.SCHEDULED
                        ),
                },
                {
                    id: SidebarItemId.UNSCHEDULED,
                    title: "Unscheduled",
                    badgeValue: `${opportunities.unscheduled ?? 0}`,
                    onClick: () =>
                        onMenuItemClick(
                            SidebarMenuId.OPPORTUNITIES,
                            SidebarItemId.UNSCHEDULED
                        ),
                },
                {
                    id: SidebarItemId.ALL,
                    title: "All",
                    badgeValue: `${opportunities.all ?? 0}`,
                    onClick: () =>
                        onMenuItemClick(
                            SidebarMenuId.OPPORTUNITIES,
                            SidebarItemId.ALL
                        ),
                },
            ],
        },
        {
            id: SidebarMenuId.TEMPLATES,
            title: "Templates",
            icon: "file-07",
            onClick: () => onMenuItemClick(SidebarMenuId.TEMPLATES),
        },
        {
            id: SidebarMenuId.REPORTS,
            title: "Reports",
            icon: "pie-chart-03",
            dropdown: true,
            items: [
                {
                    id: SidebarItemId.WIN_LOSS,
                    title: "Win Loss",
                    badgeValue: `${reports?.winLoss ?? 0}`,
                    onClick: () =>
                        onMenuItemClick(
                            SidebarMenuId.REPORTS,
                            SidebarItemId.WIN_LOSS
                        ),
                },
                {
                    id: SidebarItemId.VALUE_REALISATION,
                    title: "Value Realisation",
                    badgeValue: `${reports?.valueRealisation ?? 0}`,
                    onClick: () =>
                        onMenuItemClick(
                            SidebarMenuId.REPORTS,
                            SidebarItemId.VALUE_REALISATION
                        ),
                },
                {
                    id: SidebarItemId.MARKET_RESEARCH,
                    title: "Market Research",
                    badgeValue: `${reports?.marketResearch ?? 0}`,
                    onClick: () =>
                        onMenuItemClick(
                            SidebarMenuId.REPORTS,
                            SidebarItemId.MARKET_RESEARCH
                        ),
                },
                {
                    id: SidebarItemId.EXIT_INTERVIEW,
                    title: "Exit Interview",
                    badgeValue: `${reports?.exitInterview ?? 0}`,
                    onClick: () =>
                        onMenuItemClick(
                            SidebarMenuId.REPORTS,
                            SidebarItemId.EXIT_INTERVIEW
                        ),
                },
                {
                    id: SidebarItemId.TREND,
                    title: "Trend",
                    onClick: () =>
                        onMenuItemClick(
                            SidebarMenuId.REPORTS,
                            SidebarItemId.TREND
                        ),
                },
            ],
        },
        {
            id: SidebarMenuId.INSIGHTS,
            title: "Insights",
            icon: "eye",
            badgeValue: "Coming Soon",
            badgeColor: "indigo",
            onClick: () => onMenuItemClick(SidebarMenuId.INSIGHTS),
        },
    ];

    const bottomMenuItems: SidebarMenuItem<SidebarMenuId, SidebarItemId>[] = [
        {
            id: SidebarMenuId.SUPPORT,
            title: "Support",
            icon: "life-buoy-01",
            onClick: () => onMenuItemClick(SidebarMenuId.SUPPORT),
        },
        {
            id: SidebarMenuId.SETTINGS,
            title: "Company Settings",
            icon: "settings-01",
            onClick: () => onMenuItemClick(SidebarMenuId.SETTINGS),
        },
    ];

    const popUpPanelMenuItems: SidebarItemProps[][] = [
        [
            {
                title: "My Settings",
                icon: "settings-01",
                onClick: () => {
                    navigate("/profile-settings");
                },
            },
            {
                title: "Support",
                icon: "help-circle",
                onClick: () => {
                    window.open(
                        "https://intercom.help/usetrinity/en ",
                        "_blank"
                    );
                },
            },
        ],
        [
            {
                title: "Log out",
                icon: "log-out-01",
                onClick: onLogout,
            },
        ],
    ];
    const { result: user } = useUserInfoQuery();
    const imageSource = user.isSuccess
        ? user.data?.profile_picture_url ?? ""
        : "";
    const avatarImage = useDownloadAttachmentQuery(
        {
            url: imageSource,
        },
        {
            enabled: !!imageSource,
        }
    );
    const userRole = (
        user?.data?.companies?.length
            ? user.data.companies[0]?.role?.title ?? ""
            : ""
    )
        .split("_")
        .map(capitalize)
        .join(" ");

    return (
        <SideBar
            defaultSelection={defaultSelection}
            menuItems={menuItems.map(item => ({ ...item }))}
            bottomMenuItems={bottomMenuItems}
            popupPanelMenuSections={popUpPanelMenuItems}
            user={{
                avatar: avatarImage.result.isSuccess
                    ? avatarImage.result.data
                    : "",
                subTitle: userRole,
                name: user.data?.name ?? "",
            }}
        />
    );
};

export default CustomSidebar;
