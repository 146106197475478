import React, { useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Icon, ProgressSteps, SidebarItem } from "trinity-components-library";
import { useUserInfoQuery } from "../../apis/user.api";
import { TrinityLogo } from "../../assets/images";
import UserProfile from "../../components/user-profile/user-profile";
import { useAuth } from "../../libs/auth/auth";
import Footer from "./components/footer";
import SimpleSteps from "./components/SimpleSteps";

const Onboarding = () => {
    const location = useLocation();
    const [openUserSettings, setOpenUserSettings] = useState(false);

    const { result: user } = useUserInfoQuery();
    const { onLogout } = useAuth();
    const imageSource = user.isSuccess ? user.data.profile_picture_url : "";

    const userProfileRightIcon = openUserSettings ? (
        <Icon name="x-close" size={20} color="gray-500" />
    ) : (
        <Icon name="chevron-right" size={20} color="gray-500" />
    );

    const stepIndex = pathToCurrentStepMap[location.pathname];

    const steps = [
        {
            title: "Company information",
            description: "Please provide your company information",
        },
        {
            title: "Connect CRM",
            description: "Easily connect your CRM",
        },
        {
            title: "Invite your team",
            description: "Start collaborating with your team",
        },
    ];

    return (
        <div className="flex w-screen h-screen">
            <div className="w-[432px] relative h-full flex flex-col bg-gray-50">
                <div className="p-12 flex-1">
                    <TrinityLogo />
                    <div className="mt-20">
                        <ProgressSteps
                            steps={steps}
                            size="sm"
                            current={stepIndex}
                        />
                    </div>
                </div>
                {stepIndex === 0 ? (
                    <Footer />
                ) : (
                    <>
                        {user.isSuccess && (
                            <div className="px-8 py-6">
                                <div className="my-4 border-t border-t-gray-100 cursor-pointer" />
                                <div
                                    className="flex p-2 hover:bg-gray-50 cursor-pointer rounded-md"
                                    onClick={() =>
                                        setOpenUserSettings(prev => !prev)
                                    }
                                >
                                    <UserProfile
                                        name={user.data.name}
                                        subTitle={
                                            user.data.companies.length
                                                ? user.data.companies[0].role
                                                      .title
                                                : ""
                                        }
                                        avatar={imageSource}
                                    />
                                    <div>{userProfileRightIcon}</div>
                                </div>
                            </div>
                        )}
                        {openUserSettings && (
                            <div className="absolute -right-[270px] bottom-12 z-20 w-[256px] bg-white rounded-md shadow-lg border border-gray-100">
                                <SidebarItem
                                    title="My Profile"
                                    icon="user-01"
                                />
                                <SidebarItem
                                    title="Company Settings"
                                    icon="settings-01"
                                    className="mb-0"
                                />
                                <div className="border-b border-gray-100" />
                                <SidebarItem
                                    title="Log out"
                                    icon="log-out-01"
                                    className="mb-0"
                                    onClick={onLogout}
                                />
                            </div>
                        )}
                    </>
                )}
            </div>
            <div className="flex-1 flex flex-col bg-white py-12 px-8 overflow-auto">
                <Outlet />
                <div className="pt-5 flex flex-col items-center">
                    <SimpleSteps current={stepIndex} length={3} />
                </div>
            </div>
        </div>
    );
};

export default Onboarding;

const pathToCurrentStepMap: { [key: string]: number } = {
    "/onboarding": 0,
    "/onboarding/create-company": 0,
    "/onboarding/connect-crm": 1,
    "/onboarding/invite-team": 2,
};
