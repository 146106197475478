import React, { FC, useState } from "react";
import { Typography, Divider, Button } from "trinity-components-library";
import CRMModal from "./crm-modal";

export interface CRMCardProps {
    title: string;
    Icon: any;
    description: string;
    comingSoon?: boolean;
    onConnect?: () => void;
    overview?: string;
    isConnected?: boolean;
}

const CRMCard: FC<CRMCardProps> = ({
    title,
    description,
    Icon,
    comingSoon = false,
    onConnect,
    overview,
    isConnected = false,
}) => {
    const [open, setOpen] = useState(false);

    return (
        <div className="rounded-lg border border-gray-200 shadow">
            <div className="p-6 flex flex-col gap-6">
                <div className="flex items-center gap-3">
                    <Icon />
                    <Typography color="gray-900">{title}</Typography>
                </div>
                <Typography size="sm" color="gray-500">
                    {description}
                </Typography>
            </div>
            <Divider color="gray-200" />
            <div className="px-6 py-4 flex justify-end gap-3">
                {comingSoon ? (
                    <Typography color="gray-700">Coming Soon!</Typography>
                ) : (
                    <>
                        <Button
                            label="More Info"
                            disabled={isConnected}
                            variant="outlined"
                            color="gray"
                            onClick={() => setOpen(true)}
                            size="lg"
                        />
                        <Button
                            label={isConnected ? "Connected" : "Connect"}
                            disabled={isConnected}
                            color="primary"
                            onClick={onConnect}
                            size="lg"
                        />
                    </>
                )}
            </div>
            <CRMModal
                Icon={Icon}
                title={title}
                onClose={() => setOpen(false)}
                open={open}
                overview={overview}
            />
        </div>
    );
};

export default CRMCard;
