import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useUserInfoQuery } from "../../apis/user.api";
import { localStorageKeys } from "../../data/localStorageKeys";
import { useAuth } from "../../libs/auth/auth";
import Intercom from "../../components/intercom/intercom";
import { Toaster } from "react-hot-toast";
import useOpportunities from "./hooks/useOpportunities";
import useReports from "./hooks/useReports";
import { TemplateCategoryKey } from "../../apis/types/template";
import CustomSidebar from "../../components/custom-sidebar/custom-sidebar";

const Root = () => {
    const { result: user } = useUserInfoQuery();
    const { isTrinityAdmin } = useAuth();
    const opportunities = useOpportunities();
    const winLossReports = useReports({
        templateCategoryKey: [TemplateCategoryKey.WIN_LOSS],
    });
    const valueRealisationReports = useReports({
        templateCategoryKey: [TemplateCategoryKey.VALUE_REALIZATION],
    });
    const marketResearchReports = useReports({
        templateCategoryKey: [TemplateCategoryKey.MARKET_RESEARCH],
    });
    const exitInterviewReports = useReports({
        templateCategoryKey: [TemplateCategoryKey.EXIT_INTERVIEW],
    });
    const { dealCounts } = opportunities;
    useEffect(() => {
        if (user.isSuccess && !isTrinityAdmin) {
            localStorage.setItem(
                localStorageKeys.LOGGED_IN_COMPANY_ID,
                user.data.companies[0].id
            );
        }
    }, [user.data?.companies, user.isSuccess, isTrinityAdmin]);

    const onPageChange = () => {
        opportunities.setPageIndex(0);
        winLossReports.setPage(0);
        valueRealisationReports.setPage(0);
        marketResearchReports.setPage(0);
        exitInterviewReports.setPage(0);
    };

    return (
        <div className="w-screen h-screen m-0 p-0 flex">
            <CustomSidebar
                opportunities={{
                    scheduled: dealCounts?.data?.scheduled,
                    unscheduled: dealCounts?.data?.unscheduled,
                    all: dealCounts?.data?.all,
                }}
                reports={{
                    winLoss: winLossReports.deals.data?.meta?.totalItems,
                    valueRealisation:
                        valueRealisationReports.deals.data?.meta?.totalItems,
                    marketResearch:
                        marketResearchReports.deals.data?.meta?.totalItems,
                    exitInterview:
                        exitInterviewReports.deals.data?.meta?.totalItems,
                }}
                onPageChange={onPageChange}
            />
            <div className="flex-1 w-full h-full overflow-auto">
                <Outlet
                    context={{
                        opportunities,
                        winLossReports,
                        valueRealisationReports,
                        marketResearchReports,
                        exitInterviewReports,
                    }}
                />
            </div>
            <Intercom />
            <Toaster
                position="top-center"
                containerClassName="mt-20"
                reverseOrder={true}
            />
        </div>
    );
};

export default Root;
