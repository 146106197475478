import React, { FC, HTMLAttributes } from "react";
import { Typography } from "trinity-components-library";
import { UserAvatar } from "../../../components";

interface ResponseProps extends HTMLAttributes<HTMLDivElement> {
    avatar: string;
    author: string;
    postedAt?: string;
    label?: string;
}

const Response: FC<ResponseProps> = ({
    avatar = "",
    author,
    children,
    label,
    postedAt = "",
}) => {
    return (
        <div className="flex gap-2">
            <div className="flex">
                <UserAvatar
                    src={avatar}
                    placeholderType="name"
                    name={author}
                    size="sm"
                />
            </div>
            <div className="flex flex-col gap-2 flex-grow">
                <div className="flex justify-between">
                    <Typography type="text" color="gray-700" size="sm">
                        {author}
                    </Typography>
                    {postedAt && (
                        <Typography color="gray-600" size="xs">
                            {postedAt}
                        </Typography>
                    )}
                </div>
                <div className="flex flex-col bg-gray-100 py-[10px] px-[14px] rounded-lg rounded-tl-none">
                    {label && (
                        <div className="flex">
                            <Typography type="text" weight="normal" size="xs">
                                {label}
                            </Typography>
                        </div>
                    )}
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Response;
