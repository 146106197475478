import { FC, useState } from "react";
import {
    IconType,
    Typography,
    Button,
    DownloadItem,
} from "trinity-components-library";
import { CheckCircleBaseIcon, MSExcelIcon } from "../../../assets/misc-icons";
import SimpleHeaderModal from "./simple-header-modal";
import { useDownloadTemplateMutation } from "../../../apis/template.api";

export interface DownloadBulkUploadTemplatesModalProps {
    open?: boolean;
    onClose?: () => void;
    leftIcon?: IconType;
    onLeftIconClick?: () => void;
}

export const DownloadBulkUploadTemplatesModal: FC<DownloadBulkUploadTemplatesModalProps> =
    ({ open = false, onClose, leftIcon, onLeftIconClick }) => {
        const downloadTemplateMutation = useDownloadTemplateMutation();
        const [progress, setProgress] = useState(0);
        const onDownload = async () => {
            const filename = "opportunities_template.xlsx";
            const response = await downloadTemplateMutation.mutateAsync({
                filename,
                onDownloadProgress(event) {
                    const { loaded, total = 1 } = event;
                    const percentage = Number(
                        ((loaded * 100) / total).toFixed(0)
                    );
                    setProgress(percentage);
                },
            });
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${filename}`);
            document.body.appendChild(link);
            link.click();
        };

        const status = downloadTemplateMutation.status;

        return (
            <SimpleHeaderModal
                title={
                    status === "idle"
                        ? "Download bulk upload templates"
                        : status === "success"
                        ? "Downloaded"
                        : status === "error"
                        ? "Download failed"
                        : "Downloading..."
                }
                open={open}
                onClose={onClose}
                {...(status === "idle" ? { leftIcon, onLeftIconClick } : {})}
            >
                <div className="w-[752px] h-[258px] flex flex-col gap-6">
                    {status === "success" ? (
                        <div className="w-full h-[262px] flex flex-col gap-6 items-center justify-center">
                            <div className="flex flex-col gap-4 items-center">
                                <CheckCircleBaseIcon />
                                <Typography
                                    className="text-center"
                                    color="gray-700"
                                >{`Template successfully downloaded`}</Typography>
                            </div>
                            <Button label="Done" onClick={onClose} />
                        </div>
                    ) : (
                        <DownloadItem
                            expanded
                            filename="Trinity.xlsx"
                            fileSize={5.5 * 1024}
                            renderFileIcon={() => <MSExcelIcon />}
                            onDownload={onDownload}
                            status={status}
                            progress={progress}
                            onTryAgain={onDownload}
                        />
                    )}
                </div>
            </SimpleHeaderModal>
        );
    };

export default DownloadBulkUploadTemplatesModal;
