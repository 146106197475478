import { Typography } from "trinity-components-library";
import clsx from "clsx";
import { FC } from "react";

interface TabPaginationProps {
  totalCount: number;
  current: number;
  maxCount: number;
  onChange?: (item: number) => void;
}

export const TabPagination: FC<TabPaginationProps> = ({
  totalCount,
  current,
  maxCount,
  onChange,
}) => {
  const length = Math.min(totalCount, maxCount);
  const truncatedCurrent =
    Math.floor((current - 1) / (maxCount ? maxCount : 1)) * maxCount;
  const leftLimit = truncatedCurrent + 1;
  const rightLimit = truncatedCurrent + length;
  const showPrevious = leftLimit > 1;
  const showNext = rightLimit < totalCount;
  return (
    <div className="p-1.5 flex rounded-lg border border-gray-100 bg-gray-50">
      {showPrevious && (
        <div
          onClick={() => onChange?.(current - 1)}
          className={clsx(
            "flex-1 h-[44px] px-3.5 py-2.5 flex justify-center items-center cursor-pointer"
          )}
        >
          <Typography color="gray-500">Previous</Typography>
        </div>
      )}
      {Array.from({ length }).map((_, index) => {
        const isSelected = current === leftLimit + index;
        return (
          <div
            onClick={() => onChange?.(leftLimit + index)}
            className={clsx(
              "flex-1 h-[44px] px-3.5 py-2.5 flex justify-center items-center cursor-pointer",
              isSelected && "bg-white shadow-sm"
            )}
          >
            <Typography color={isSelected ? "gray-700" : "gray-500"}>
              {leftLimit + index}
            </Typography>
          </div>
        );
      })}
      {showNext && (
        <div
          onClick={() => onChange?.(current + 1)}
          className={clsx(
            "flex-1 h-[44px] px-3.5 py-2.5 flex justify-center items-center cursor-pointer"
          )}
        >
          <Typography color="gray-500">Next</Typography>
        </div>
      )}
    </div>
  );
};

export default TabPagination;
