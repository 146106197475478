import { useEffect } from "react";
import { useIntercom } from "react-use-intercom";
import { useUserInfoQuery } from "../../apis/user.api";

export const Intercom = () => {
    const { result: user } = useUserInfoQuery();
    const { boot } = useIntercom();
    useEffect(() => {
        if (
            user.isSuccess &&
            user.data?.name &&
            user.data?.email &&
            user.data?.id
        ) {
            boot({
                name: user.data.name,
                email: user.data.email,
                userId: user.data.id,
            });
        }
    }, [boot, user]);
    return null;
};

export default Intercom;
