import { FC, ReactNode } from "react";
import { Typography } from "trinity-components-library";

interface FormItemHeaderProps {
    title: string;
    helperText?: string;
}

interface FormFieldProps extends FormItemHeaderProps {
    children: ReactNode;
}

export const FormItemHeader: FC<FormItemHeaderProps> = ({
    title,
    helperText,
}) => {
    return (
        <div>
            <Typography size="sm" color="gray-700">
                {title}
            </Typography>
            {helperText && (
                <Typography size="sm" color="gray-500">
                    {helperText}
                </Typography>
            )}
        </div>
    );
};

export const FormField: FC<FormFieldProps> = ({ children, ...headerProps }) => {
    return (
        <div className="flex flex-col lg:flex-row gap-4 lg:gap-8">
            <div className="w-72">
                <FormItemHeader {...headerProps} />
            </div>
            <div className="w-full lg:w-[512px]">{children}</div>
        </div>
    );
};

export default FormField;
