import { FC } from "react";
import { Controller, useController, UseFormReturn } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
    Button,
    PasswordInputField,
    Typography,
} from "trinity-components-library";
import FormItem from "../../components/form-item";

export interface PasswordItemProps {
    form: UseFormReturn<{
        changePassword: boolean;
        currentPassword: string;
        newPassword: string;
        confirmNewPassword: string;
        serverError: string;
    }>;
}

const PasswordItem: FC<PasswordItemProps> = ({ form }) => {
    const navigate = useNavigate();
    const { field: changePasswordField } = useController({
        name: "changePassword",
        control: form.control,
    });
    const changePassword = changePasswordField.value;
    const errors = form.formState.errors;

    return !changePassword ? (
        <FormItem title="Password">
            <Button
                label="Change Password"
                onClick={() => changePasswordField.onChange(true)}
            />
        </FormItem>
    ) : (
        <div className="flex flex-col gap-5">
            <FormItem
                title="Current Password"
                helperText="Enter your current password."
            >
                <Controller
                    name="currentPassword"
                    control={form.control}
                    rules={{
                        required: true,
                    }}
                    render={({ field }) => (
                        <div className="flex flex-col gap-1.5">
                            <PasswordInputField
                                fullWidth
                                placeholder="Enter the old password"
                                value={field.value}
                                onChange={value => {
                                    field.onChange(value);
                                    form.clearErrors("serverError");
                                }}
                                hasError={!!errors.currentPassword}
                            />
                            {errors.currentPassword &&
                                (errors.currentPassword.type === "required" ? (
                                    <Typography color="error-500" size="sm">
                                        Required
                                    </Typography>
                                ) : (
                                    <Typography color="error-500" size="sm">
                                        Wrong password,{" "}
                                        <u
                                            className="cursor-pointer"
                                            onClick={() => {
                                                navigate("/reset-password");
                                            }}
                                        >
                                            forgot password
                                        </u>
                                    </Typography>
                                ))}
                        </div>
                    )}
                />
            </FormItem>
            <FormItem
                title="New Password"
                helperText="Make sure your password includes at least 10 characters, 1 uppercase letter, 1 number, and 1 symbol such as !, %, @, *, etc."
            >
                <Controller
                    name="newPassword"
                    control={form.control}
                    rules={{
                        minLength: {
                            value: 8,
                            message: "Must be at least 8 characters",
                        },
                        pattern: {
                            value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/i,
                            message: "Pattern not matching",
                        },
                        required: true,
                    }}
                    render={({ field }) => (
                        <PasswordInputField
                            fullWidth
                            placeholder="Enter a new password"
                            value={field.value}
                            onChange={value => {
                                field.onChange(value);
                                form.clearErrors("serverError");
                            }}
                            error={
                                errors.newPassword?.message?.toString?.() ||
                                (errors.newPassword?.type === "required"
                                    ? "Required"
                                    : "")
                            }
                        />
                    )}
                />
            </FormItem>
            <FormItem
                title="Confirm Password"
                helperText="Re enter your password for verification."
            >
                <div className="flex flex-col gap-5">
                    <Controller
                        name="confirmNewPassword"
                        control={form.control}
                        rules={{
                            required: true,
                            validate: pass => {
                                if (form.getValues().newPassword !== pass) {
                                    return "Password not matching";
                                }
                            },
                        }}
                        render={({ field }) => (
                            <PasswordInputField
                                fullWidth
                                placeholder="Re enter the new password"
                                value={field.value}
                                onChange={val => {
                                    field.onChange(val);
                                    form.clearErrors("serverError");
                                }}
                                error={
                                    errors.confirmNewPassword?.message?.toString?.() ||
                                    (errors.confirmNewPassword?.type ===
                                    "required"
                                        ? "Required"
                                        : "")
                                }
                            />
                        )}
                    />
                    {errors.serverError && (
                        <Typography color="error-500" size="sm">
                            {errors.serverError.message ??
                                "Failed to change password."}
                        </Typography>
                    )}
                </div>
            </FormItem>
        </div>
    );
};

export default PasswordItem;
