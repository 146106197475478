import { Divider } from "trinity-components-library";
import getLoggedInCompanyId from "../../libs/getLoggedInCompanyId";
import BrandingItem from "./components/branding-item";
import ColorPaletteItem from "./components/color-palette-item";
import CompanyBrandingItem from "./components/company-branding-item";
import CompanyNameItem from "./components/company-name-item";
import FormHeader from "../components/form-header";
import IndustryItem from "./components/industry-item";
import LocationItem from "./components/location-item";
import useBrandingForm from "./hooks/useBrandingForm";
import useCompanyForm from "./hooks/useCompanyForm";
import useCompanyLogoForm from "./hooks/useCompanyLogoForm";

const CompanyProfileForm = () => {
    const companyId = getLoggedInCompanyId() ?? "";
    const company = useCompanyForm({ companyId });
    const branding = useBrandingForm({ companyId });
    const logo = useCompanyLogoForm({ companyId });
    const isAnyFormDirty =
        company.form.formState.isDirty ||
        branding.form.formState.isDirty ||
        logo.form.formState.isDirty;
    const onCancel = async () => {
        company.form.reset();
        branding.form.reset();
        logo.form.reset();
    };
    const onSave = async () => {
        if (company.form.formState.isDirty) {
            await company.submit();
        }
        if (branding.form.formState.isDirty) {
            await branding.submit();
        }
        if (logo.form.formState.isDirty) {
            await logo.submit();
        }
    };
    return (
        <div>
            <FormHeader
                title="Company Profile"
                description="Update your company photo and details here."
                showActions={isAnyFormDirty}
                onSave={onSave}
                onCancel={onCancel}
            />
            <Divider className="mt-5 mb-6" />
            <div className="flex flex-col gap-5">
                <CompanyNameItem form={company.form} />
                <Divider />
                <LocationItem form={company.form} />
                <Divider />
                <IndustryItem form={company.form} />
                <Divider />
                <CompanyBrandingItem form={logo.form} companyId={companyId} />
                <Divider />
                <ColorPaletteItem form={branding.form} />
                <Divider />
                <BrandingItem form={branding.form} />
            </div>
        </div>
    );
};

export default CompanyProfileForm;
