import { FC } from "react";
import { Typography, Divider } from "trinity-components-library";
import { QuestionTypes } from "../../../apis/types/survey-questionnaire";
import Response from "../../report-info/components/response";
import Answer from "../answer/answer";

export interface QuestionProps {
    id: string;
    question: string;
    type: QuestionTypes;
    options: string[];
    answers: {
        reviewerName: string;
        postedAt: string;
        value: string | any[];
        attachments: {
            value: string;
            mimetype: string;
        }[];
    }[];
}

export const Question: FC<QuestionProps> = ({
    id,
    question = "",
    type,
    options,
    answers = [],
}) => {
    const questionText = question.replace("[reviewer_name], ", "");

    return (
        <div className="">
            <div className="flex flex-col">
                <div className="flex items-center gap-2">
                    <div className="relative flex items-center justify-center min-w-[32px] min-h-[32px] rounded-full bg-primary-600">
                        <Typography size="sm" color="white">
                            Q{id}
                        </Typography>
                    </div>
                    <div className="flex-1">
                        <Typography>{questionText}</Typography>
                    </div>
                </div>
                <Divider className="my-4" />
                <div className="pl-10 flex flex-col gap-4">
                    {answers.map(answer => {
                        return (
                            <Response
                                author={answer.reviewerName}
                                avatar=""
                                postedAt={answer.postedAt}
                            >
                                <Answer
                                    type={type}
                                    options={options}
                                    answer={answer}
                                />
                            </Response>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default Question;
