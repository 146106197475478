/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
    CalendarRange,
    CalendarValue,
    getCalendarRange,
    parseCalendarDate,
    Typography,
    useCalendarRange,
} from "trinity-components-library";
import { usePatchAssociateUserToTeamMutation } from "../../apis/user.api";
import ReviewTable from "../../sections/review-table/review-table";
import Analytics from "./components/analytics";
import { useCalendarRangeUserPreference } from "../opportunities/hooks/useCalendarRangeUserPreference";
import DateRangeSelector from "../components/date-range-selector.tsx/dater-range-selector";
import { localStorageKeys } from "../../data/localStorageKeys";
import { getInvitedUserData } from "../../libs/getInvitedUserData";
import { useAuth } from "../../libs/auth/auth";
import getLoggedInCompanyId from "../../libs/getLoggedInCompanyId";
import { useGetLastLoginReviews } from "../../apis/last-reviews.api";
import useToast from "../../hooks/useToast";

export default function Home() {
    const {
        setValue: setDateRange,
        rangeName,
        value,
    } = useCalendarRange({ defaultValue: getCalendarRange("lastWeek") });
    const { user } = useAuth();
    // const { result: user } = useUserInfoQuery();
    const { result: LastLoginReviews } = useGetLastLoginReviews();
    const { createToast } = useToast();
    const companyId = getLoggedInCompanyId() || "";
    const [userPreferences, setUserPreferences] =
        useState<CalendarRange>(value);

    const { setCalendarRangeUserPreference, getTimeRangeFilterValues } =
        useCalendarRangeUserPreference();

    const invitedUserData = getInvitedUserData();
    const associateUserToTeamMutation = usePatchAssociateUserToTeamMutation();

    useEffect(() => {
        const associateUser = async () => {
            try {
                await associateUserToTeamMutation.mutateAsync({
                    email: invitedUserData?.user?.email || user?.email,
                    state: invitedUserData?.state || invitedUserData?.id,
                });
                localStorage.removeItem(localStorageKeys.INVITED_USER);
                window.location.reload();
            } catch (e: any) {
                createToast({
                    message:
                        e?.response?.data?.message ||
                        "Something went wrong, please try again later",
                    type: "error",
                });
            }
        };
        if (invitedUserData?.isInvitedUser) {
            associateUser();
        }
    }, [
        invitedUserData?.id,
        invitedUserData?.state,
        invitedUserData.isInvitedUser,
        invitedUserData.user?.email,
        user,
    ]);

    useEffect(() => {
        const preferences = getTimeRangeFilterValues({
            companyId,
            path: "/home",
        });
        if (preferences.startDate && preferences.endDate) {
            setUserPreferences({
                from: preferences.startDate,
                to: preferences.endDate,
            });
        }
    }, [companyId]);

    useEffect(() => {
        if (userPreferences) {
            setDateRange(userPreferences);
        }
    }, [userPreferences]);

    useEffect(() => {
        if (value && userPreferences && value !== userPreferences) {
            setCalendarRangeUserPreference({
                companyId,
                path: "/home",
                startDate: parseCalendarDate(value.from)?.toISOString?.(),
                endDate: parseCalendarDate(value.to)?.toISOString?.(),
                calendarRangeName: rangeName,
            });
        }
    }, [value]);

    const handleDropdownPickerChange = (value: CalendarValue) => {
        if (typeof value !== "string") {
            setDateRange(value);
        }
    };

    return (
        <div className="flex flex-col gap-8 p-8">
            <div className="flex flex-col lg:flex-row lg:items-end gap-4">
                <div className="flex flex-col gap-1 grow">
                    <Typography type="display" size="sm" color="gray-900">
                        Welcome back, {user?.name ?? ""}
                    </Typography>
                    <Typography size="sm" color="gray-500">
                        Trinity has completed{" "}
                        {LastLoginReviews.data?.reviewsCount} Win Loss Reviews
                        since your last login
                    </Typography>
                </div>
                <div className="flex flex-wrap gap-3 items-end">
                    <DateRangeSelector
                        value={value}
                        onChange={handleDropdownPickerChange}
                    />
                </div>
            </div>
            <Analytics dateRange={value} />
            <ReviewTable dateRange={value} />
        </div>
    );
}
