import { useQueryClient, useQuery, useMutation } from "react-query";
import Axios from "../axios-instance";
import { UseQueryReturnType } from "./api";
import { ContactPerson, ContactStatus } from "./types/contact";

export const useGetCompanyContactsQuery = ({
    forCompanyId,
    likeSearch,
    companyName,
}: {
    forCompanyId: string;
    likeSearch?: boolean;
    companyName?: string;
}): UseQueryReturnType => {
    const queryKey = [
        "company-contacts",
        forCompanyId,
        companyName,
        likeSearch,
    ];
    const queryClient = useQueryClient();
    const invalidate = () => queryClient.invalidateQueries(queryKey);
    const result = useQuery(
        queryKey,
        async () => {
            const response = await Axios.get("/main/contact", {
                params: {
                    for_company_id: forCompanyId,
                    like_search: likeSearch,
                    company_name: companyName,
                },
            });
            return response.data;
        },
        { refetchOnWindowFocus: false }
    );
    return { invalidate, result };
};

export const useUpdateContactMutation = () => {
    return useMutation(
        async ({
            companyName,
            contactPersons,
            id,
            ...rest
        }: {
            id: string;
            companyName: string;
            website?: string;
            contactPersons: Partial<ContactPerson>[];
            status: ContactStatus;
        }) => {
            const response = await Axios.put(`/main/contact/${id}`, {
                id,
                company_name: companyName,
                contact_persons: JSON.stringify(contactPersons),
                ...rest,
            });
            return response.data;
        }
    );
};
