import { FC } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { Upload } from "trinity-components-library";
import { UserAvatar } from "../../../components";
import { useReadFileContent } from "../../../hooks/file";
import { useAuth } from "../../../libs/auth/auth";
import FormItem from "../../components/form-item";

export interface ProfilePictureItemProps {
    form: UseFormReturn<any>;
}

const ProfilePictureItem: FC<ProfilePictureItemProps> = ({ form }) => {
    const { user } = useAuth();
    const logoFileContent = useReadFileContent({
        file: form.getValues().profilePictureFile,
        readAs: "data-url",
    });
    const imageSource = logoFileContent
        ? logoFileContent
        : user?.profile_picture_url ?? "";

    return (
        <FormItem
            title="Profile Photo"
            helperText="This will be displayed on your profile."
        >
            <Controller
                name="profilePictureFile"
                control={form.control}
                render={({ field }) => (
                    <div className="flex flex-col flex-wrap lg:flex-row gap-8">
                        <div>
                            <UserAvatar src={imageSource} size="2xl" />
                        </div>
                        <div className="grow">
                            <Upload
                                accept={{
                                    "image/jpeg": [".jpeg"],
                                    "image/png": [".png"],
                                }}
                                onChange={files => {
                                    if (files && files.length) {
                                        const selectedFile = files[0];
                                        field.onChange(selectedFile);
                                    }
                                }}
                                maxSize={10 * 1024 * 1024}
                            />
                        </div>
                    </div>
                )}
            />
        </FormItem>
    );
};

export default ProfilePictureItem;
