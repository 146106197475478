import Axios from "../axios-instance";
import { useQuery, useQueryClient } from "react-query";
import { UseQueryReturnType } from "./api";

interface GetLastReviewsResponse {
    reviewsCount: number;
}

export const useGetLastLoginReviews =
    (): UseQueryReturnType<GetLastReviewsResponse> => {
        const queryKey = ["last-reviews"];
        const queryClient = useQueryClient();
        const invalidate = () => queryClient.invalidateQueries(queryKey);
        const result = useQuery(
            queryKey,
            async () => {
                const resp = await Axios.get(
                    `/main/deals/login/get-last-reviews`
                );
                return resp.data;
            },
            { refetchOnWindowFocus: false }
        );
        return {
            result,
            invalidate,
        };
    };
