import { FC } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { Typography, SelectInputField } from "trinity-components-library";
import { MemberScope } from "../../../apis/types/team-members.api.types";
import FormItem from "../../components/form-item";

export interface UserRoleItemProps {
    form: UseFormReturn<any>;
}

const UserRoleItem: FC<UserRoleItemProps> = ({ form }) => {
    const userRolesArray: { id: MemberScope; role: string }[] = [
        {
            id: MemberScope.ADMIN,
            role: "Admin",
        },
        {
            id: MemberScope.SALES_LEADER,
            role: "Sales Leader",
        },
        {
            id: MemberScope.SALES_REP,
            role: "Sales Rep",
        },
    ];
    return (
        <FormItem title="Role Selection">
            <Controller
                name="userRole"
                control={form.control}
                render={({ field, formState }) => (
                    <SelectInputField
                        placeholder="Select user role"
                        items={userRolesArray}
                        fullWidth
                        chevronLeft
                        value={userRolesArray.find(
                            ({ id }) => id === field.value
                        )}
                        renderItem={item => (
                            <Typography variant="p">{item.role}</Typography>
                        )}
                        onChange={({ id }) => field.onChange(id)}
                        error={
                            formState.errors?.userRole?.type === "required"
                                ? "Required"
                                : ""
                        }
                    />
                )}
            />
        </FormItem>
    );
};

export default UserRoleItem;
