import { IconButton, Typography } from "trinity-components-library";
import { FC } from "react";

interface FindingsAndRecommendationsSimplePaginationProps {
  current: number;
  totalCount: number;
  onChange?: (page: number) => void;
}

export const FindingsAndRecommendationsSimplePagination: FC<
  FindingsAndRecommendationsSimplePaginationProps
> = ({ current, totalCount, onChange }) => {
  return (
    <div className="flex items-center">
      <IconButton
        icon="arrow-left"
        variant="outlined"
        color="gray"
        onClick={() => onChange?.(current - 1)}
        disabled={current <= 1}
      />
      <div className="flex-1 flex justify-center">
        <Typography>
          Findings & Recommendations {current} of {totalCount}
        </Typography>
      </div>
      <IconButton
        icon="arrow-right"
        variant="outlined"
        color="gray"
        onClick={() => onChange?.(current + 1)}
        disabled={current >= totalCount}
      />
    </div>
  );
};

export default FindingsAndRecommendationsSimplePagination;
