import { FC, useState } from "react";
import clsx from "clsx";
import { ColumnDef } from "@tanstack/react-table";
import {
    Table,
    Icon,
    Typography,
    Badge,
    Button,
    Pagination,
} from "trinity-components-library";
import { capitalize } from "../../utils";
import { useNavigate } from "react-router-dom";

export interface TemplatesTableProps {
    templates?: {
        id: string;
        categoryId: string;
        categoryType:
            | "win_loss"
            | "value_realisation"
            | "market_research"
            | "exit_interview"
            | "uncategorised";
        name: string;
        type: "digital" | "consulting" | "uncategorised";
        isActivated: boolean;
        isDefault: boolean;
        isCustom: boolean;
        companyLogo: string;
        primaryColor: string;
    }[];
}

export interface TemplateTableData {
    template: {
        name: string;
        companyLogo: string;
        type: "digital" | "consulting" | "uncategorised";
    };
    templateType: {
        type: "digital" | "consulting" | "uncategorised";
        isCustom: boolean;
    };
    status: "active" | "default" | "off";
    actions: {
        onOpen: () => void;
    };
}

export const TemplatesTable: FC<TemplatesTableProps> = ({ templates = [] }) => {
    const [page, setPage] = useState(0);
    const navigate = useNavigate();
    const data: TemplateTableData[] = [
        ...templates
            .filter(
                ({ categoryType }) =>
                    categoryType === "win_loss" ||
                    categoryType === "value_realisation" ||
                    categoryType === "market_research" ||
                    categoryType === "exit_interview" ||
                    categoryType === "uncategorised"
            )
            .map(
                ({
                    type,
                    name,
                    id,
                    isDefault,
                    isActivated,
                    companyLogo,
                    isCustom,
                }) => {
                    return {
                        template: {
                            name,
                            companyLogo,
                            type,
                        },
                        templateType: { type, isCustom },
                        status: !isActivated
                            ? "off"
                            : isDefault
                            ? "default"
                            : "active",
                        actions: {
                            onOpen: () =>
                                navigate(
                                    `/templates/${
                                        isCustom ? "custom" : "global"
                                    }/${id}`
                                ),
                            type,
                        },
                    } as TemplateTableData;
                }
            ),
    ];
    return (
        <Table
            columns={columns as any}
            data={data}
            rowProps={({ table, row }) => ({
                className: clsx({
                    "bg-gray-50":
                        (table.options.meta as any)?.mouseOverRowIndex ===
                        row.index,
                }),
            })}
            footer={() => (
                <div className="px-6 py-4">
                    <Pagination
                        count={Math.ceil(data.length / 10)}
                        page={page}
                        onChange={setPage}
                    />
                </div>
            )}
        />
    );
};

export default TemplatesTable;

const columns: ColumnDef<TemplateTableData, any>[] = [
    {
        id: "template",
        header: "Template",
        accessorKey: "template",
        cell: ({ getValue }) => {
            const { name, companyLogo, type } = getValue();
            const color =
                type === "digital"
                    ? "success"
                    : type === "consulting"
                    ? "purple"
                    : "gray";
            return (
                <div className="w-80">
                    <div className={clsx("flex items-center gap-3")}>
                        {companyLogo ? (
                            <div className="w-10 h-10 rounded-full bg-gray-50">
                                <img
                                    src={companyLogo}
                                    alt="company-logo"
                                    className="w-full h-full bg-gray-50 rounded-full object-contain"
                                />
                            </div>
                        ) : (
                            <div
                                className={clsx(
                                    "flex justify-center items-center rounded-full w-10 h-10",
                                    `bg-${color}-50`
                                )}
                            >
                                <Icon
                                    color={
                                        color === "success"
                                            ? "success-600"
                                            : "purple-600"
                                    }
                                    name="phone-01"
                                    size={24}
                                />
                            </div>
                        )}

                        <Typography color="gray-700">{name}</Typography>
                    </div>
                </div>
            );
        },
    },
    {
        id: "templateType",
        header: "Template Type",
        accessorKey: "templateType",
        cell: ({ getValue }) => {
            const {
                type,
                isCustom,
            }: {
                type: "digital" | "consulting" | "uncategorised";
                isCustom: boolean;
            } = getValue();

            return (
                <div className="w-40">
                    <Badge
                        label={`${isCustom ? "Custom " : ""}${capitalize(
                            type
                        )}`}
                        color={
                            type === "digital"
                                ? "success"
                                : type === "consulting"
                                ? "purple"
                                : "gray"
                        }
                    />
                </div>
            );
        },
    },
    {
        id: "status",
        header: "Status",
        accessorKey: "status",
        cell: ({ getValue }) => {
            const status = getValue();
            return (
                <div className="w-40">
                    <Badge
                        label={capitalize(status)}
                        color={status === "off" ? "gray" : "success"}
                    />
                </div>
            );
        },
    },
    {
        id: "actions",
        header: "",
        accessorKey: "actions",
        cell: ({ row, table, getValue }: any) => {
            const isRowHovered =
                row.index === table.options.meta.mouseOverRowIndex;
            const { onOpen, type } = getValue();
            return (
                <Button
                    label="Open"
                    variant="outlined"
                    color="gray"
                    className={
                        isRowHovered && type !== "consulting"
                            ? "visible"
                            : "invisible"
                    }
                    onClick={onOpen}
                />
            );
        },
    },
];
