import { Checkbox, Typography } from "trinity-components-library";
import clsx from "clsx";
import React, { FC } from "react";

interface CheckboxCardProps {
    checked: boolean;
    onChange: (checked: boolean) => void;
    title: string;
    description?: string;
    className?: string;
}

export const CheckboxCard: FC<CheckboxCardProps> = ({
    checked,
    onChange,
    title,
    description,
    className,
}) => {
    return (
        <div
            className={clsx(
                "px-6 py-4 flex gap-2 rounded-lg border cursor-pointer",
                checked
                    ? "bg-primary-50 border-primary-600"
                    : "bg-white border-gray-200",
                className
            )}
            onClick={() => onChange(!checked)}
        >
            <Checkbox checked={checked} onChange={onChange} />
            <div>
                <Typography
                    size="sm"
                    color={checked ? "primary-800" : "gray-800"}
                >
                    {title}
                </Typography>
                {description && (
                    <Typography
                        size="sm"
                        color={checked ? "primary-600" : "gray-600"}
                    >
                        {description}
                    </Typography>
                )}
            </div>
        </div>
    );
};

export default CheckboxCard;
