import { useForm } from "react-hook-form";
import {
    useGetCompanyBrandingQuery,
    usePatchCompanyLogoMutation,
} from "../../../apis/branding.api";

export interface UseCompanyLogoFormParams {
    companyId: string;
}

export const useCompanyLogoForm = ({ companyId }: UseCompanyLogoFormParams) => {
    const branding = useGetCompanyBrandingQuery({ companyId });
    const updateLogo = usePatchCompanyLogoMutation();

    const defaultValues = {
        logoFile: null,
    };

    const form = useForm<{ logoFile: File | null }>({
        defaultValues,
    });

    const submit = form.handleSubmit(async ({ logoFile }) => {
        if (logoFile) {
            await updateLogo.mutateAsync({ companyId, logoFile });
            await branding.invalidate();
            form.reset();
        }
    });
    return {
        form,
        submit,
    };
};

export default useCompanyLogoForm;
