import { FC, useState } from "react";
import { RowSelectionState } from "@tanstack/react-table";
import {
    parseCalendarDate,
    CalendarValue,
    Typography,
    Button,
    isValidCalendarRange,
} from "trinity-components-library";
import OpportunitiesTable from "../../sections/opportunities-table/opportunities-table";
import {
    DealsSortBy,
    DealsSortOrder,
    useDealsBulkDeleteMutation,
    useDealsQuery,
    useDeleteDealMutation,
} from "../../apis/deal.api";
import { ReviewType } from "../../apis/types/review-type";
import AddOpportunityModal from "./components/add-opportunity-modal";
import DeleteModal from "./components/delete-modal";
import BulkScheduleReview from "./components/bulk-schedule-review";
import DateRangeSelector from "../components/date-range-selector.tsx/dater-range-selector";
import { useNavigate, useOutletContext } from "react-router-dom";
import ScheduleReviewModal from "./components/schedule-review-modal";
import AddOpportunityFormModal from "./components/add-opportunity-form-modal";
import { Deal } from "../../apis/types/deal";
import useOpportunities from "../root/hooks/useOpportunities";
import getLoggedInCompanyId from "../../libs/getLoggedInCompanyId";
import useToast from "../../hooks/useToast";

export const Opportunities: FC<{
    status?: "scheduled" | "unscheduled" | "all";
}> = ({ status = "scheduled" }) => {
    const navigate = useNavigate();
    const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
    const [showContacts, setShowContacts] = useState<boolean>(false);
    const [modal, setModal] =
        useState<
            | "none"
            | "bulkDelete"
            | "bulkSchedule"
            | "addOpportunity"
            | "deleteOpportunity"
            | "singleReview"
            | "editOpportunity"
        >("none");
    const [editDeal, setEditDeal] = useState<Deal | null>(null);
    const [scheduleDeal, setScheduleDeal] = useState<Deal | null>(null);
    const [deleteDealId, setDeleteDealId] = useState("");
    const deleteMutation = useDeleteDealMutation();
    const bulkDeleteMutation = useDealsBulkDeleteMutation();
    const { createToast } = useToast();

    const { opportunities } =
        useOutletContext<{
            opportunities: ReturnType<typeof useOpportunities>;
        }>();
    const {
        setDateRange,
        sorting,
        setSorting,
        pageIndex,
        setPageIndex,
        defaultPageSize,
        range,
        columnSort,
    } = opportunities;
    const { result: deals, invalidate } = useDealsQuery({
        companyId: getLoggedInCompanyId() || undefined,
        page: pageIndex + 1,
        limit: defaultPageSize,
        sortBy: columnSort ? sortIdToSortByMap[columnSort.id] : undefined,
        sortOrder: columnSort
            ? ((columnSort.desc ? "DESC" : "ASC") as DealsSortOrder)
            : undefined,
        ...(range &&
            isValidCalendarRange(range) && {
                startDate: parseCalendarDate(range.from).toISOString(),
                endDate: parseCalendarDate(range.to).toISOString(),
            }),
        reviewTypes:
            status === "all"
                ? undefined
                : status === "scheduled"
                ? [
                      ReviewType.DIGITAL,
                      ReviewType.MANUAL,
                      ReviewType.NOT_TO_BE_REVIEWED,
                  ]
                : [ReviewType.NOT_YET_REVIEWED],
    });

    const data: any[] = (deals.isSuccess ? deals.data.items : []).map(deal => {
        const {
            id,
            name,
            contact,
            updated_at: lastUpdated,
            value,
            review_type: reviewType,
        } = deal;
        const commonActions = [
            {
                id: "edit",
                title: "Edit",
                icon: "edit-05",
                onClick: () => {
                    setEditDeal(deal);
                    setModal("editOpportunity");
                },
            },
            {
                id: "delete",
                title: "Delete",
                icon: "trash-03",
                onClick: () => {
                    setDeleteDealId(id);
                    setModal("deleteOpportunity");
                },
            },
        ];
        return {
            id,
            company: {
                name: contact?.company_name,
                avatar: contact?.logo,
                subTitle: name,
            },
            value,
            contact: contact?.contact_persons?.length
                ? contact.contact_persons
                : [],
            lastUpdated,
            reviewType: reviewType,
            actions:
                reviewType === ReviewType.NOT_YET_REVIEWED
                    ? [
                          {
                              id: "schedule",
                              title: "Schedule review",
                              icon: "calendar",
                              onClick: () => {
                                  setScheduleDeal(deal);
                                  setModal("singleReview");
                              },
                          },
                          ...commonActions,
                      ]
                    : [
                          {
                              id: "reschedule",
                              title: "Reschedule review",
                              icon: "calendar",
                              onClick: () => {
                                  setScheduleDeal(deal);
                                  setModal("singleReview");
                              },
                          },
                          {
                              id: "copy",
                              title: "Copy review link",
                              icon: "copy-04",
                              onClick: () => setShowContacts(!showContacts),
                              iconEnd: showContacts
                                  ? "chevron-up"
                                  : "chevron-down",
                              showContacts,
                              items:
                                  contact?.contact_persons?.map(
                                      (dataItem: any, index: number) => ({
                                          showContacts,
                                          id: index,
                                          badgeValue:
                                              dataItem.primary_contact &&
                                              "Primary",
                                          badgeColor:
                                              dataItem.primary_contact &&
                                              "primary",
                                          title: dataItem.name,
                                          reviewID: dataItem.review_id,
                                          icon: "copy-04",
                                          onClick: () => {
                                              if (dataItem.review_id != null) {
                                                  navigator.clipboard.writeText(
                                                      `${process.env.REACT_APP_REVIEW_APP}/review/${dataItem.review_id}`
                                                  );
                                                  createToast({
                                                      type: "info",
                                                      message: `Boom! You Just Copied the ${dataItem.name} Review Link`,
                                                      icon: "copy-04",
                                                  });
                                              }
                                          },
                                      })
                                  ) ?? [],
                          },
                          ...commonActions,
                      ],
        };
    });

    const totalItems = deals.isSuccess
        ? deals.data?.meta?.totalItems
        : data.length;

    const handleDropdownPickerChange = (value: CalendarValue) => {
        if (typeof value !== "string") {
            setDateRange(value);
        }
    };

    return (
        <div className="flex flex-col gap-8 p-8">
            <div className="flex flex-col lg:flex-row lg:items-end gap-4">
                <div className="flex flex-col gap-1 grow">
                    <Typography type="display" size="sm" color="gray-900">
                        Opportunities
                    </Typography>
                    <Typography size="sm" color="gray-500">
                        {`Trinity currently has ${totalItems} ${
                            status !== "all" ? status : ""
                        } opportunities`}
                    </Typography>
                </div>
                {Object.keys(rowSelection).length ? (
                    <div className="flex flex-wrap gap-3 items-end">
                        {status !== "scheduled" && (
                            <Button
                                label="Bulk schedule"
                                icon="calendar-plus-02"
                                onClick={() => {
                                    setModal("bulkSchedule");
                                }}
                            />
                        )}
                        <Button
                            label="Delete"
                            icon="trash-03"
                            color="error"
                            onClick={() => {
                                setModal("bulkDelete");
                            }}
                        />
                    </div>
                ) : (
                    <div className="flex flex-wrap gap-3 items-end">
                        <DateRangeSelector
                            value={range}
                            onChange={handleDropdownPickerChange}
                        />
                        <Button
                            label="Add Opportunity"
                            icon="bookmark-add"
                            onClick={() => setModal("addOpportunity")}
                        />
                    </div>
                )}
            </div>
            <OpportunitiesTable
                data={data}
                status={status}
                onStatusChange={newStatus => {
                    setPageIndex(0);
                    navigate(`/opportunities/${newStatus}`);
                }}
                sorting={sorting}
                onSortingChange={newSorting => {
                    setSorting(newSorting);
                }}
                defaultPageSize={defaultPageSize}
                pageIndex={pageIndex}
                onPageIndexChange={page => setPageIndex(page)}
                totalItems={totalItems}
                rowSelection={rowSelection}
                onRowSelectionChange={updater => {
                    if (typeof updater === "function") {
                        setRowSelection(updater(rowSelection));
                    } else {
                        setRowSelection(updater);
                    }
                }}
            />
            <DeleteModal
                title="Delete data"
                description="Are you sure you want to delete these opportunities? This action cannot be undone."
                open={modal === "bulkDelete"}
                onClose={() => setModal("none")}
                onDelete={async () => {
                    try {
                        await bulkDeleteMutation.mutateAsync({
                            dealsToDelete: data
                                .filter((_, index) => rowSelection[`${index}`])
                                .map(({ id }) => id),
                        });
                        invalidate();
                    } finally {
                        setRowSelection({});
                        setModal("none");
                    }
                }}
                isDeleting={bulkDeleteMutation.isLoading}
            />
            <DeleteModal
                title="Delete Opportunity Confirmation"
                description="Please confirm that you want to delete the opportunity. This action also deletes any completed reviews and cannot be undone."
                open={modal === "deleteOpportunity"}
                onClose={() => {
                    setDeleteDealId("");
                    setModal("none");
                }}
                onDelete={async () => {
                    await deleteMutation.mutateAsync({ dealId: deleteDealId });
                    await invalidate();
                    setDeleteDealId("");
                    setModal("none");
                }}
                isDeleting={deleteMutation.isLoading}
            />
            {modal === "bulkSchedule" && (
                <BulkScheduleReview
                    open={modal === "bulkSchedule"}
                    onClose={() => {
                        invalidate();
                        setModal("none");
                    }}
                    selectedDeals={(deals.data?.items ?? []).filter(
                        (_, index) => rowSelection[`${index}`]
                    )}
                    onScheduleComplete={() => {
                        setRowSelection({});
                    }}
                />
            )}
            {modal === "addOpportunity" && (
                <AddOpportunityModal
                    open={modal === "addOpportunity"}
                    onClose={() => setModal("none")}
                />
            )}
            {modal === "editOpportunity" && (
                <AddOpportunityFormModal
                    isOpen={modal === "editOpportunity"}
                    setIsOpen={open =>
                        setModal(open ? "editOpportunity" : "none")
                    }
                    deal={editDeal}
                    type="edit"
                    openPreviousModal={() => setModal("none")}
                />
            )}
            {modal === "singleReview" && (
                <ScheduleReviewModal
                    open={modal === "singleReview"}
                    onClose={() => {
                        invalidate();
                        setModal("none");
                    }}
                    deal={scheduleDeal}
                />
            )}
        </div>
    );
};

export default Opportunities;

const sortIdToSortByMap: { [key: string]: DealsSortBy } = {
    company: "companyName",
    type: "type",
    dateTime: "creationTime",
    reviewRating: "reviewRating",
    value: "value",
    lastUpdated: "updatedTime",
    name: "dealName",
};
