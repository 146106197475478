import React, { FC, useState } from "react";
import {
    Button,
    Typography,
    Modal,
    FeaturedIcon,
} from "trinity-components-library";
import { useDeleteUserProfile } from "../../../apis/user.api";
import { useAuth } from "../../../libs/auth/auth";
import FormItem from "../../components/form-item";

export interface DeleteAccountItemProps {}

const DeleteAccountItem: FC<DeleteAccountItemProps> = () => {
    const [open, setOpen] = useState(false);
    const deleteProfileMutation = useDeleteUserProfile();
    const { onLogout } = useAuth();

    const onDelete = async () => {
        try {
            await deleteProfileMutation.mutateAsync();
            onLogout();
        } catch {}
    };

    return (
        <FormItem title="Delete">
            <div>
                <Button
                    label="Delete Account"
                    color="error"
                    variant="link"
                    className="px-0"
                    onClick={() => setOpen(true)}
                />
                <Typography size="sm" color="gray-500">
                    A confirmation email will be sent. Please be aware that all
                    created data will be permanently erased. Consider backing up
                    or exporting any important information.
                </Typography>
            </div>
            <Modal open={open} onClose={() => setOpen(false)}>
                <div className="bg-white rounded-xl w-[400px] p-6 flex flex-col gap-8">
                    <div className="flex flex-col gap-5 items-center">
                        <FeaturedIcon size="lg" name="trash-01" color="error" />
                        <div className="flex flex-col gap-1 items-center">
                            <Typography
                                size="lg"
                                color="gray-900"
                                className="text-center"
                            >
                                Delete Account Confirmation
                            </Typography>
                            <Typography
                                size="sm"
                                color="gray-500"
                                className="text-center"
                            >
                                Please confirm that you want to delete your
                                account. This action cannot be undone.
                            </Typography>
                        </div>
                    </div>
                    <div className="flex gap-3">
                        <Button
                            label="Cancel"
                            variant="outlined"
                            color="gray"
                            className="flex-1"
                            onClick={() => setOpen(false)}
                            disabled={deleteProfileMutation.isLoading}
                        />
                        <Button
                            label={
                                deleteProfileMutation.isLoading
                                    ? "Deleting"
                                    : "Delete"
                            }
                            color="error"
                            className="flex-1"
                            onClick={onDelete}
                            disabled={deleteProfileMutation.isLoading}
                        />
                    </div>
                </div>
            </Modal>
        </FormItem>
    );
};

export default DeleteAccountItem;
