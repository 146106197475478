import React, { FC } from "react";
import { Modal, Icon, Typography, Button } from "trinity-components-library";

export interface DeleteModalProps {
    open: boolean;
    onClose?: () => void;
    onDelete?: () => void;
    title: string;
    description: string;
    isDeleting?: boolean;
}

const DeleteModal: FC<DeleteModalProps> = ({
    open,
    onClose,
    onDelete,
    title,
    description,
    isDeleting = false,
}) => {
    return (
        <Modal open={open} onClose={onClose}>
            <div className="w-[400px] bg-white rounded-xl p-6 flex flex-col gap-8">
                <div className="flex flex-col items-center gap-5">
                    <div className="w-12 h-12 flex items-center justify-center bg-error-100 border-8 border-error-50 rounded-full">
                        <Icon name="trash-01" color="error-600" />
                    </div>
                    <div className="flex flex-col gap-3 items-center">
                        <Typography size="lg" color="gray-900">
                            {title}
                        </Typography>
                        <Typography
                            className="text-center"
                            size="sm"
                            color="gray-500"
                        >
                            {description}
                        </Typography>
                    </div>
                </div>
                <div className="flex gap-3">
                    <Button
                        label="Cancel"
                        variant="outlined"
                        color="gray"
                        fullWidth
                        size="lg"
                        onClick={onClose}
                    />
                    <Button
                        label={isDeleting ? "Deleting" : "Delete"}
                        color="error"
                        fullWidth
                        size="lg"
                        onClick={onDelete}
                        disabled={isDeleting}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default DeleteModal;
