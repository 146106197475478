import { FC, useEffect, useRef, useState } from "react";
import { Typography } from "trinity-components-library";
import WaveSurfer from "wavesurfer.js";
import { PauseIcon, PlayIcon } from "../../assets/misc-icons";

export interface AudioWaveProps {
    url: string;
}

export const AudioWave: FC<AudioWaveProps> = ({ url }) => {
    const waveformRef = useRef(null);
    const [waveSurfer, setWaveSurfer] = useState<WaveSurfer | null>(null);
    const [status, setStatus] = useState<"playing" | "paused">("paused");
    const [{ duration, currentTime }, setTimes] = useState({
        duration: 0,
        currentTime: 0,
    });

    useEffect(() => {
        let waveSurfer: WaveSurfer | null = null;
        if (waveformRef.current) {
            waveSurfer = WaveSurfer.create({
                container: waveformRef.current,
                waveColor: "#9EC0FC",
                progressColor: "#3D82F9",
                barWidth: 4,
                barRadius: 5,
                barGap: 8,
                barMinHeight: 8,
                hideCursor: true,
                cursorWidth: 0,
                responsive: true,
                height: 35,
                normalize: true,
                fillParent: true,
            });
            waveSurfer.on("ready", () => {
                if (waveSurfer) {
                    waveSurfer.setVolume(0.5);
                }
            });
            waveSurfer.on("audioprocess", () => {
                if (waveSurfer && waveSurfer.isPlaying()) {
                    const durr = Math.round(waveSurfer.getDuration());
                    const curr = Math.round(waveSurfer.getCurrentTime());
                    setTimes({
                        duration: durr,
                        currentTime: curr,
                    });
                }
            });
            waveSurfer.on("finish", () => {
                setStatus("paused");
                setTimes(prev => ({ ...prev, currentTime: 0 }));
            });
            setWaveSurfer(waveSurfer);
        }
        return () => {
            setWaveSurfer(null);
            waveSurfer?.destroy?.();
        };
    }, []);

    useEffect(() => {
        if (waveSurfer && url) {
            waveSurfer.load(url);
        }
    }, [waveSurfer, url]);

    return (
        <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-4">
                {status === "paused" ? (
                    <PlayIcon
                        onClick={() => {
                            setStatus("playing");
                            waveSurfer?.play?.();
                        }}
                        className="cursor-pointer"
                    />
                ) : (
                    <PauseIcon
                        onClick={() => {
                            setStatus("paused");
                            waveSurfer?.pause?.();
                        }}
                        className="cursor-pointer"
                    />
                )}
                <div ref={waveformRef} className="flex flex-col" />
            </div>
            <Typography size="xs" color="gray-600" weight="medium">
                {`${Math.floor(duration / 60)}:${duration % 60}/${Math.floor(
                    currentTime / 60
                )}:${currentTime % 60}`}
            </Typography>
        </div>
    );
};

export default AudioWave;
