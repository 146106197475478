import { useState } from "react";
import { useForm } from "react-hook-form";
import { Divider } from "trinity-components-library";
import FormHeader from "../components/form-header";
import FirstNameItem from "./components/first-name-item";
import UserRoleItem from "./components/user-role-item";
import LastNameItem from "./components/last-name-item";
import ProfilePictureItem from "./components/profile-picture-item";
import EmailItem from "./components/email-item";
import DeleteAccountItem from "./components/delete-account-item";
import getLoggedInCompanyId from "../../libs/getLoggedInCompanyId";
import {
    useUserInfoQuery,
    useUserInfoUpdateMutation,
    useUserProfilePictureUpdateMutation,
} from "../../apis/user.api";
import { MemberScope } from "../../apis/types/team-members.api.types";
import { UploadAction } from "../../apis/types/upload-action";
import PasswordItem from "./components/password-item";
import { useAuth } from "../../libs/auth/auth";

export const UserProfileForm = () => {
    const [isSaving, setIsSaving] = useState(false);
    const { changePassword } = useAuth();
    const userQuery = useUserInfoQuery();
    const updateUserInfoMutation = useUserInfoUpdateMutation();
    const updateUserProfilePictureMutation =
        useUserProfilePictureUpdateMutation();

    const user =
        userQuery.result.isSuccess && userQuery.result.data
            ? userQuery.result.data
            : null;

    const defaultValues = {
        firstName: "",
        lastName: "",
        userRole: "",
        email: "",
    };
    const companyId = getLoggedInCompanyId();
    const selectedCompany = user?.companies.find(
        (company: any) => company.id === companyId
    );
    const name = user?.name ?? "";
    const firstName = name.split(" ")[0];
    const lastName = name.split(" ").slice(1).join(" ");

    const values = user
        ? {
              firstName,
              lastName,
              userRole: selectedCompany?.scopes ?? "",
              email: user?.email ?? "",
          }
        : defaultValues;

    const infoForm = useForm({
        defaultValues,
        values,
    });

    const profilePictureForm = useForm<{ profilePictureFile: File | null }>({
        defaultValues: {
            profilePictureFile: null,
        },
    });

    const passwordForm = useForm({
        defaultValues: {
            changePassword: false,
            currentPassword: "",
            newPassword: "",
            confirmNewPassword: "",
            serverError: "",
        },
        mode: "onChange",
        criteriaMode: "all",
    });

    const onInfoSave = infoForm.handleSubmit(
        async ({ firstName, lastName, userRole }) => {
            try {
                if (infoForm.formState.isDirty) {
                    await updateUserInfoMutation.mutateAsync({
                        id: user?.id ?? "",
                        name: `${firstName} ${lastName}`,
                        companies: {
                            ...selectedCompany,
                            scopes: userRole,
                        },
                    });
                    userQuery.invalidate();
                }
            } catch {}
        }
    );

    const onProfilePictureSave = profilePictureForm.handleSubmit(
        async ({ profilePictureFile: file }) => {
            try {
                if (file && profilePictureForm.formState.isDirty) {
                    const reader = new FileReader();

                    reader.onloadend = async () => {
                        const payload = {
                            name: file.name,
                            mimetype: file.type,
                            action: UploadAction.UPLOAD,
                            data: (reader.result as string) ?? "",
                        };
                        await updateUserProfilePictureMutation.mutateAsync(
                            payload
                        );
                        userQuery.invalidate();
                    };

                    reader.readAsDataURL(file);
                }
            } catch {}
        }
    );

    const onPasswordSave = passwordForm.handleSubmit(
        async ({ currentPassword, newPassword }) => {
            try {
                if (passwordForm.formState.isDirty) {
                    await changePassword(currentPassword, newPassword);
                }
            } catch (error: any) {
                if (error?.code === "NotAuthorizedException") {
                    passwordForm.setError("currentPassword", {
                        message: "Incorrect password.",
                        type: "value",
                    });
                } else if (error?.code === "LimitExceededException") {
                    passwordForm.setError("serverError", {
                        message:
                            error?.message ??
                            "Attempt limit exceeded, please try after some time.",
                        type: "value",
                    });
                }
                throw error;
            }
        }
    );

    const onCancel = () => {
        infoForm.reset();
        profilePictureForm.reset();
        passwordForm.reset();
    };

    const onSave = async () => {
        setIsSaving(true);
        try {
            await Promise.all([
                onInfoSave(),
                onProfilePictureSave(),
                onPasswordSave(),
            ]);
            infoForm.reset({}, { keepValues: true });
            profilePictureForm.reset({}, { keepValues: true });
            passwordForm.reset();
        } finally {
            setIsSaving(false);
        }
    };

    const adminScopes = [MemberScope.TRINITY_ADMIN, MemberScope.ADMIN];
    const isAdmin = adminScopes.includes(selectedCompany?.scopes);

    const isAnyFormDirty =
        infoForm.formState.isDirty ||
        profilePictureForm.formState.isDirty ||
        passwordForm.formState.isDirty;

    return (
        <div>
            <FormHeader
                title="Profile"
                description="Update your photo and personal details here."
                showActions={isAnyFormDirty}
                onSave={onSave}
                onCancel={onCancel}
                isSaving={isSaving}
            />
            <Divider className="mt-5 mb-6" />
            <div className="flex flex-col gap-5">
                <FirstNameItem form={infoForm} />
                <Divider />
                <LastNameItem form={infoForm} />
                <Divider />
                {isAdmin && <UserRoleItem form={infoForm} />}
                {isAdmin && <Divider />}
                <ProfilePictureItem form={profilePictureForm} />
                <Divider />
                <EmailItem form={infoForm} />
                <Divider />
                <PasswordItem form={passwordForm} />
                <Divider />
                <DeleteAccountItem />
            </div>
        </div>
    );
};

export default UserProfileForm;
