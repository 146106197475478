const industryData = [
    {
        label: "Financial Services",
        id: "financial-services",
    },
    {
        label: "Telecommunications",
        id: "telecommunications",
    },
    {
        label: "Professional Services",
        id: "professional-services",
    },
    {
        label: "Government",
        id: "government",
    },
    {
        label: "High Tech",
        id: "high-tech",
    },
    {
        label: "Consumer & Retail",
        id: "consumer-retail",
    },
    {
        label: "Health Care",
        id: "healthcare",
    },
    {
        label: "Manufacturing",
        id: "manufacturing",
    },
    {
        label: "Media & Entertainment",
        id: "media-entertainment",
    },
    {
        label: "Energy & Utilities",
        id: "energy-utilities",
    },
    {
        label: "Transportation",
        id: "transportation",
    },
    {
        label: "Mining & Construction",
        id: "mining-construction",
    },
    {
        label: "Other",
        id: "other",
    },
];

export default industryData;
