import React, { useState } from "react";
import { Controller, useController, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
    FeaturedIcon,
    Typography,
    MailInputField,
    Button,
} from "trinity-components-library";
import { useAddTeamMemberMutation } from "../../apis/team-members.api";
import { MemberScope } from "../../apis/types/team-members.api.types";
import UserRoleInputField from "./components/user-role-input";

const InviteTeam = () => {
    const [isInvitationSent, setIsInvitationSent] = useState(false);
    const navigate = useNavigate();
    const form = useForm({
        defaultValues: {
            name: "",
            role: "",
            email: "",
            serverError: "",
        },
        mode: "onChange",
    });
    const userRole = useController({
        name: "role",
        control: form.control,
        rules: { required: "User role required." },
    });
    const addTeamMemberMutation = useAddTeamMemberMutation();

    const sendInvitation = form.handleSubmit(async ({ name, role, email }) => {
        try {
            await addTeamMemberMutation.mutateAsync({
                name,
                scopes: role as MemberScope,
                email,
            });
            form.clearErrors("serverError");
            setIsInvitationSent(true);
            setTimeout(() => setIsInvitationSent(false), 3000);
            form.reset();
        } catch (error: any) {
            form.setError("serverError", {
                message:
                    error?.response?.data?.error?.description ||
                    error?.response?.data?.error ||
                    "Error in sending invitation",
                type: "value",
            });
        }
    });
    return (
        <div className="py-[80px] flex justify-center">
            <div className="w-[360px] flex flex-col items-center gap-8">
                <FeaturedIcon name="users-01" size="lg" />
                <div className="flex flex-col items-center gap-3">
                    <Typography
                        type="display"
                        color="gray-700"
                        weight="semibold"
                        size="sm"
                    >
                        Invite team members
                    </Typography>
                    <Typography color="gray-500">
                        Please provide your team information
                    </Typography>
                </div>
                <div className="flex flex-col gap-6 w-full">
                    <Controller
                        name="name"
                        control={form.control}
                        rules={{
                            required: "Required",
                        }}
                        render={({ field, fieldState }) => (
                            <UserRoleInputField
                                label="Full name*"
                                fullWidth
                                error={
                                    fieldState.error?.message ||
                                    userRole.fieldState.error?.message
                                }
                                value={{
                                    value: field.value,
                                    role: userRole.field.value,
                                }}
                                onChange={value => {
                                    field.onChange(value.value);
                                    userRole.field.onChange(value.role);
                                    form.clearErrors("serverError");
                                }}
                            />
                        )}
                    />
                    <Controller
                        name="email"
                        control={form.control}
                        rules={{
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "Invalid email address",
                            },
                            required: "Required",
                        }}
                        render={({ field, fieldState }) => (
                            <MailInputField
                                label="Email address*"
                                hideIcon
                                fullWidth
                                error={fieldState.error?.message}
                                value={field.value}
                                onChange={value => {
                                    field.onChange(value);
                                    form.clearErrors("serverError");
                                }}
                            />
                        )}
                    />
                    <div className="flex flex-col gap-3">
                        {isInvitationSent && (
                            <Typography color="success-600" size="sm">
                                Invitation sent successfully!
                            </Typography>
                        )}
                        {form.formState.errors.serverError && (
                            <Typography color="error-600" size="sm">
                                {form.formState.errors.serverError.message}
                            </Typography>
                        )}
                        <Button
                            label={
                                addTeamMemberMutation.isLoading
                                    ? "Sending"
                                    : "Send invitation"
                            }
                            color="primary"
                            size="xl"
                            fullWidth
                            disabled={
                                !form.formState.isDirty ||
                                !form.formState.isValid ||
                                addTeamMemberMutation.isLoading
                            }
                            onClick={sendInvitation}
                        />
                    </div>
                    <Button
                        label="Go to dashboard"
                        variant="outlined"
                        color="gray"
                        size="xl"
                        fullWidth
                        onClick={() => navigate("/")}
                    />
                </div>
            </div>
        </div>
    );
};

export default InviteTeam;
