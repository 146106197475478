import { FC } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { useCountries } from "../../../apis/utils.api";
import LocationInputField from "../../../components/location-input/location-input";
import FormItem from "../../components/form-item";

export interface LocationItemProps {
    form: UseFormReturn<any>;
}

const LocationItem: FC<LocationItemProps> = ({ form }) => {
    const countries = useCountries();
    const cities = countries.result.isSuccess
        ? countries.result.data.reduce(
              (cityList: string[], country) => [
                  ...cityList,
                  ...country.cities.map(city => `${city}, ${country.country}`),
              ],
              []
          )
        : [];
    return (
        <FormItem
            title="Location"
            helperText="This will be displayed on your profile."
        >
            <Controller
                name="location"
                control={form.control}
                rules={{ validate: value => cities.includes(value) }}
                render={({ field }) => (
                    <LocationInputField
                        fullWidth
                        placeholder="Enter location details"
                        value={field.value}
                        onChange={field.onChange}
                    />
                )}
            />
        </FormItem>
    );
};

export default LocationItem;
