import { useState } from "react";
import {
    Typography,
    Button,
    SelectInputField,
} from "trinity-components-library";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useUserInfoQuery } from "../../apis/user.api";
import { Company } from "../../types";
import { localStorageKeys } from "../../data/localStorageKeys";
import { TrinityLogoIcon } from "../../assets/images";

export type TrinityForm = {
    company: Company;
};

function SelectCompany() {
    const { result: user } = useUserInfoQuery();
    const navigate = useNavigate();
    const [companyId, setCompanyId] = useState<any>(null);
    const form = useForm<TrinityForm>({
        defaultValues: {
            company: {
                name: "",
                id: "",
            },
        },
        criteriaMode: "all",
        mode: "onChange",
    });

    const handelSubmit = () => {
        localStorage.setItem(localStorageKeys.LOGGED_IN_COMPANY_ID, companyId);
        navigate("/");
    };

    return (
        <div className="flex items-center justify-center h-screen flex-col">
            <div className="content-center">
                <TrinityLogoIcon className="w-full content-center text-center" />
                <Typography
                    className="text-center"
                    variant="h1"
                    size="md"
                    type="display"
                    weight="bold"
                >
                    Admin
                </Typography>
                <Typography
                    className="text-center mb-5"
                    variant="p"
                    size="md"
                    weight="normal"
                    color="gray-800"
                >
                    Admin log in company select
                </Typography>
            </div>
            <Controller
                name="company"
                control={form.control}
                rules={{
                    required: true,
                }}
                render={({ field }) => (
                    <SelectInputField
                        label="Company*"
                        placeholder="Select Company"
                        items={user?.data?.companies}
                        value={user?.data?.companies?.find(
                            (item: any) => item.name === field.value
                        )}
                        renderItem={item => (
                            <Typography>{item?.name}</Typography>
                        )}
                        onChange={value => {
                            field.onChange(value as unknown as Company);
                            setCompanyId(value?.id);
                        }}
                        error={""}
                    />
                )}
            />
            <div className="mt-3">
                <Button
                    className="w-90 px-36"
                    label="Next"
                    color="primary"
                    variant="contained"
                    fullWidth
                    disabled={companyId == null}
                    onClick={handelSubmit}
                />
            </div>
        </div>
    );
}

export default SelectCompany;
