import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
    FeaturedIcon,
    Typography,
    Button,
    TextInputField,
    SelectInputField,
} from "trinity-components-library";
import { useCompanyCreateMutation } from "../../apis/company.api";
import { useUserInfoQuery } from "../../apis/user.api";
import LocationInputField from "../../components/location-input/location-input";
import industryData from "../../data/industryData";

export const CreateCompanyForm = () => {
    const form = useForm({
        defaultValues: {
            name: "",
            location: "",
            industry: "",
            serverError: "",
        },
    });
    const navigate = useNavigate();
    const createCompanyMutation = useCompanyCreateMutation();
    const { invalidate: invalidateOnboardingStatus } = useUserInfoQuery({
        enabled: false,
    });

    const onNextClick = form.handleSubmit(
        async ({ name, location, industry }) => {
            try {
                await createCompanyMutation.mutateAsync({
                    name,
                    location,
                    industry:
                        industryData.find(({ id }) => industry === id)?.label ??
                        industry,
                });
                invalidateOnboardingStatus();
                navigate("/onboarding/connect-crm");
            } catch (err: any) {
                if (err?.response?.data?.error?.description) {
                    form.setError("serverError", {
                        message: err?.response?.data?.error?.description,
                        type: "value",
                    });
                }
            }
        }
    );

    return (
        <div className="pt-28 pb-12 flex flex-col items-center">
            <div className="w-[360px]">
                <div className="flex flex-col gap-8 items-center">
                    <FeaturedIcon name="users-01" size="lg" />
                    <div className="flex flex-col items-center gap-3">
                        <Typography color="gray-700" type="display" size="sm">
                            Company Information
                        </Typography>
                        <Typography color="gray-600">
                            Please provide your company information
                        </Typography>
                    </div>
                    <div className="w-full flex flex-col gap-6">
                        <div className="flex flex-col gap-3">
                            <div className="flex flex-col gap-5">
                                <Controller
                                    name="name"
                                    control={form.control}
                                    rules={{
                                        required: "Required",
                                    }}
                                    render={({ field, fieldState }) => (
                                        <TextInputField
                                            label="Company Name*"
                                            placeholder="Enter company name"
                                            fullWidth
                                            value={field.value}
                                            onChange={(value: any) => {
                                                field.onChange(value);
                                                form.clearErrors("serverError");
                                            }}
                                            error={fieldState.error?.message}
                                        />
                                    )}
                                />
                                <Controller
                                    name="industry"
                                    control={form.control}
                                    rules={{
                                        required: "Required",
                                    }}
                                    render={({ field, fieldState }) => (
                                        <SelectInputField
                                            label="Industry*"
                                            items={industryData}
                                            placeholder="Select an industry"
                                            renderItem={item => item.label}
                                            fullWidth
                                            value={industryData.find(
                                                ({ id }) => id === field.value
                                            )}
                                            onChange={item =>
                                                field.onChange(item.id)
                                            }
                                            error={fieldState.error?.message}
                                        />
                                    )}
                                />
                                <Controller
                                    name="location"
                                    control={form.control}
                                    rules={{
                                        required: "Required",
                                    }}
                                    render={({ field, fieldState }) => (
                                        <LocationInputField
                                            label="Location*"
                                            placeholder="Select a location"
                                            fullWidth
                                            value={
                                                field.value
                                                    ? field.value
                                                    : undefined
                                            }
                                            onChange={value => {
                                                field.onChange(value);
                                                form.clearErrors("serverError");
                                            }}
                                            error={fieldState.error?.message}
                                        />
                                    )}
                                />
                            </div>
                            {form.formState.errors.serverError && (
                                <Typography color="error-600" size="sm">
                                    {form.formState.errors.serverError.message}
                                </Typography>
                            )}
                        </div>
                        <Button
                            disabled={
                                !form.formState.isDirty ||
                                !form.formState.isValid
                            }
                            onClick={onNextClick}
                            label="Next"
                            size="xl"
                            fullWidth
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateCompanyForm;
