import { Company } from "../../types";
import { Branding } from "./branding";
import { QuestionTypes } from "./survey-questionnaire";

export interface CustomTemplate {
    id: string;
    type: string;
    name: string;
    totalDuration: number;
    default: boolean;
    activated: boolean;
    company: Company;
    templateCategory: TemplateCategory;
    templateSections: TemplateSection[];
    createdAt: string;
    updatedAt: string;
    branding: Branding;
}

export interface TemplateCategory {
    id: string;
    referenceKey: string;
    name: string;
    customTemplates: CustomTemplate[];
    globalTemplates: GlobalTemplate[];
    createdAt: string;
    updatedAt: string;
}

export interface GlobalTemplate {
    id: string;
    version: number;
    type: string;
    name: string;
    activated: boolean;
    totalDuration: number;
    templateCategory: TemplateCategory;
    templateSections: TemplateSection[];
    createdAt: string;
    updatedAt: string;
}
export interface TemplateSection {
    id: string;
    name: string;
    duration: number;
    description: string;
    templateId: string;
    customTemplate: CustomTemplate;
    globalTemplate: GlobalTemplate;
    templateQuestions: TemplateQuestion[];
    createdAt: string;
    updatedAt: string;
}

export interface TemplateQuestion {
    id: string;
    type: QuestionTypes;
    options: string[];
    question: string;
    hasAudioButton: boolean;
    hasVideoButton: boolean;
    minAnswerCount: number;
    templateSection: TemplateSection;
    templateQuestionCategory: TemplateQuestionCategory;
    createdAt: string;
    updatedAt: string;
    sampleAnswers:
        | {
              value: string | any[];
              attachments: { value: string; mimetype: string }[];
          }[]
        | null;
}

export interface TemplateQuestionCategory {
    id: string;
    referenceKey: string;
    name: string;
    templateQuestions: TemplateQuestion[];
    createdAt: string;
    updatedAt: string;
}

export enum TemplateCategoryKey {
    UNCATEGORISED = "uncategorised",
    WIN_LOSS = "win_loss",
    VALUE_REALIZATION = "value_realisation",
    MARKET_RESEARCH = "market_research",
    EXIT_INTERVIEW = "exit_interview",
}

export enum TemplateType {
    UNCATEGORISED = "uncategorised",
    DIGITAL = "digital",
    CONSULTING = "consulting",
}

export interface CommonTemplate {
    id: string;
    type: TemplateType;
    activated: boolean;
    totalDuration: number;
    templateCategory: TemplateCategory;
    templateSections?: TemplateSection[];
}
