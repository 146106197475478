import axios, { AxiosProgressEvent } from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { UseQueryReturnType } from "./api";
import {
    CommonTemplate,
    CustomTemplate,
    GlobalTemplate,
} from "./types/template";
import Axios from "../axios-instance";

export const useDownloadTemplateMutation = () => {
    return useMutation(
        async ({
            filename,
            onDownloadProgress,
        }: {
            filename: string;
            onDownloadProgress?: (event: AxiosProgressEvent) => void;
        }) => {
            const response = await axios.get(`/templates/${filename}`, {
                onDownloadProgress,
                responseType: "blob",
            });
            return response.data;
        }
    );
};

export const useGetCustomTemplatesQuery = (
    {
        companyId,
        type,
        categoryReferenceKey,
        name,
    }: {
        companyId: string;
        type?: "consulting" | "digital";
        categoryReferenceKey?:
            | "win_loss"
            | "value_realisation"
            | "market_research"
            | "exit_interview"
            | "uncategorised";
        name?: string;
    },
    options: { enabled?: boolean } = {}
): UseQueryReturnType<CustomTemplate[]> => {
    const queryClient = useQueryClient();
    const queryKey = [
        "custom-templates",
        companyId,
        type,
        categoryReferenceKey,
        name,
    ];
    const invalidateKey = ["custom-templates"];

    const invalidate = () => queryClient.invalidateQueries(invalidateKey);

    const result = useQuery(
        queryKey,
        async () => {
            const response = await Axios.get(
                "/main/template/custom-templates",
                {
                    params: { companyId, type, categoryReferenceKey, name },
                }
            );
            return response.data;
        },
        { ...options, refetchOnWindowFocus: false }
    );

    return {
        invalidate,
        result,
    };
};

export const useGetGlobalTemplatesQuery = (
    {
        type,
        categoryReferenceKey,
    }: {
        type?: "consulting" | "digital";
        categoryReferenceKey?:
            | "win_loss"
            | "value_realisation"
            | "market_research"
            | "exit_interview"
            | "uncategorised";
    },
    options: { enabled?: boolean } = {}
): UseQueryReturnType<GlobalTemplate[]> => {
    const queryClient = useQueryClient();
    const queryKey = ["global-templates", type, categoryReferenceKey];
    const invalidateKey = ["global-templates"];

    const invalidate = () => queryClient.invalidateQueries(invalidateKey);

    const result = useQuery(
        queryKey,
        async () => {
            const response = await Axios.get(
                "/main/template/global-templates",
                {
                    params: { type, categoryReferenceKey },
                }
            );
            return response.data;
        },
        options
    );

    return {
        invalidate,
        result,
    };
};

export const useGetCustomTemplateQuery = (
    {
        id,
    }: {
        id: string;
    },
    options: { enabled?: boolean } = {}
): UseQueryReturnType<CustomTemplate> => {
    const queryClient = useQueryClient();
    const queryKey = ["custom-template", id];
    const invalidateKey = ["custom-template"];

    const invalidate = () => queryClient.invalidateQueries(invalidateKey);

    const result = useQuery(
        queryKey,
        async () => {
            const response = await Axios.get(
                `/main/template/custom-templates/${id}`
            );
            return response.data;
        },
        options
    );

    return {
        invalidate,
        result,
    };
};

export const useGetGlobalTemplateQuery = (
    {
        id,
    }: {
        id: string;
    },
    options: { enabled?: boolean } = {}
): UseQueryReturnType<GlobalTemplate> => {
    const queryClient = useQueryClient();
    const queryKey = ["global-template", id];
    const invalidateKey = ["global-template"];

    const invalidate = () => queryClient.invalidateQueries(invalidateKey);

    const result = useQuery(
        queryKey,
        async () => {
            const response = await Axios.get(
                `/main/template/global-templates/${id}`
            );
            return response.data;
        },
        options
    );

    return {
        invalidate,
        result,
    };
};

export const usePatchCustomTemplateDefaultStatus = () => {
    return useMutation(
        async ({
            templateCategoryId,
            id,
        }: {
            id: string;
            templateCategoryId: string;
        }) => {
            const response = await Axios.patch(
                `/main/template/custom-templates/${id}/default`,
                { template_category_id: templateCategoryId }
            );
            return response.data as CustomTemplate;
        }
    );
};

export const usePatchCustomTemplateActivatedStatus = () => {
    return useMutation(
        async ({ activated, id }: { id: string; activated: boolean }) => {
            const response = await Axios.patch(
                `/main/template/custom-templates/${id}/activated`,
                { activated }
            );
            return response.data as CustomTemplate;
        }
    );
};

export const usePatchGlobalTemplateActivatedStatus = () => {
    return useMutation(
        async ({ activated, id }: { id: string; activated: boolean }) => {
            const response = await Axios.patch(
                `/main/template/global-templates/${id}/activated`,
                { activated }
            );
            return response.data as GlobalTemplate;
        }
    );
};

export const useGetCommonTemplateQuery = (
    {
        id,
    }: {
        id: string;
    },
    options: { enabled?: boolean } = {}
): UseQueryReturnType<CommonTemplate> => {
    const queryClient = useQueryClient();
    const queryKey = ["common-template", id];
    const invalidate = () => queryClient.invalidateQueries(queryKey);
    const result = useQuery(
        queryKey,
        async () => {
            const response = await Axios.get(
                `/main/template/common-templates/${id}`
            );
            return response.data;
        },
        options
    );
    return { invalidate, result };
};
