import { FC } from "react";
import { Table } from "@tanstack/react-table";
import {
    Typography,
    Badge,
    ButtonGroup,
    BadgeProps,
} from "trinity-components-library";

export type OpportunityStatus = "scheduled" | "unscheduled" | "all";

interface TableHeaderProps {
    table: Table<any>;
    status: OpportunityStatus;
    onStatusChange?: (status: OpportunityStatus) => void;
    totalItems: number;
}

const TableHeader: FC<TableHeaderProps> = ({
    table,
    status,
    onStatusChange,
    totalItems,
}) => {
    return (
        <div className="px-6 py-5 flex flex-col lg:flex-row lg:items-center flex-wrap gap-5">
            <div className="flex items-center flex-wrap flex-1 gap-2">
                <Typography size="lg" color="gray-900">
                    {`${statusBadgeProps[status].label}`}
                </Typography>
                <Badge
                    label={`${totalItems} Opportunities`}
                    color={statusBadgeProps[status].color}
                />
            </div>
            <div>
                <ButtonGroup
                    variant="outlined"
                    color="gray"
                    items={buttonGroupItems.map(({ id, label }) => ({
                        label,
                        selected: id === status,
                        onClick: () => onStatusChange?.(id),
                    }))}
                />
            </div>
        </div>
    );
};

export default TableHeader;

const buttonGroupItems: { id: OpportunityStatus; label: string }[] = [
    {
        id: "unscheduled",
        label: "Unscheduled",
    },
    {
        id: "scheduled",
        label: "Scheduled",
    },
    {
        id: "all",
        label: "View All",
    },
];
const statusBadgeProps: { [key: string]: BadgeProps } = {
    scheduled: { label: "Scheduled", color: "success" },
    unscheduled: { label: "Unscheduled", color: "error" },
    all: { label: "All", color: "gray" },
};
