import React, { FC } from "react";
import clsx from "clsx";
import {
    ModalProps,
    IconType,
    Modal,
    Icon,
    Typography,
    IconButton,
} from "trinity-components-library";

export interface SimpleHeaderModalProps extends ModalProps {
    title: string;
    leftIcon?: IconType;
    onLeftIconClick?: () => void;
}

const SimpleHeaderModal: FC<SimpleHeaderModalProps> = ({
    open,
    onClose,
    title,
    leftIcon,
    onLeftIconClick,
    children,
}) => {
    return (
        <Modal open={open}>
            <div className="flex items-center p-5 border-b rounded-t">
                <div className="flex flex-grow items-center gap-4">
                    {leftIcon && (
                        <div
                            className={clsx(
                                "rounded-full bg-gray-200 p-2 w-12 h-12 flex justify-center items-center",
                                onLeftIconClick && "cursor-pointer"
                            )}
                        >
                            <Icon
                                size={24}
                                name={leftIcon}
                                color="gray-500"
                                onClick={onLeftIconClick}
                            />
                        </div>
                    )}
                    <Typography
                        type="text"
                        variant="h3"
                        size="md"
                        color="gray-700"
                    >
                        {title}
                    </Typography>
                </div>
                <IconButton
                    variant="outlined"
                    color="gray"
                    icon="x-close"
                    onClick={() => onClose?.("backdropClick")}
                />
            </div>
            <div className="p-6">{children}</div>
        </Modal>
    );
};

export default SimpleHeaderModal;
