import { HTMLAttributes } from "react";
import { Avatar, IconType } from "trinity-components-library";
import { useDownloadAttachmentQuery } from "../../apis/attachment.api";

export interface UserAvatarProps extends HTMLAttributes<HTMLImageElement> {
    className?: string;
    status?: "online" | "offline" | "busy" | "away";
    onClick?: () => void;
    shape?: "circle" | "square";
    size?: "xs" | "sm" | "md" | "lg" | "xl" | "2xl";
    placeholderType?: "name" | "placeholder";
    name?: string;
    src: string;
    placeholderIcon?: IconType;
}

export function UserAvatar({ src = "", ...restProps }: UserAvatarProps) {
    const isDataUrl = (src ?? "").startsWith("data:");
    const avatarImage = useDownloadAttachmentQuery(
        {
            url: src,
        },
        {
            enabled: !!src && !isDataUrl,
        }
    );

    return (
        <Avatar
            src={isDataUrl ? src : avatarImage.result.data}
            {...restProps}
        />
    );
}

export default UserAvatar;
