import { useQueryClient, useQuery, useMutation } from "react-query";
import Axios from "../axios-instance";
import { UseQueryReturnType } from "./api";

export const useGetSalesforceReviewsConfigQuery = ({
    companyId,
    serviceProvider,
}: {
    companyId: string;
    serviceProvider: "salesforce";
}): UseQueryReturnType => {
    const queryKey = ["salesforce-reviews-config", companyId, serviceProvider];
    const queryClient = useQueryClient();
    const invalidate = () => queryClient.invalidateQueries(queryKey);
    const result = useQuery(
        queryKey,
        async () => {
            const response = await Axios.get(
                "/main/auth/salesforce/reviews/config",
                {
                    params: {
                        companyId,
                        serviceProvider,
                    },
                }
            );
            return response.data;
        },
        { refetchOnWindowFocus: false }
    );
    return { invalidate, result };
};

export const useUpdateSalesforceReviewsConfigMutation = () => {
    return useMutation(
        async ({
            companyId,
            serviceProvider,
            reviewsConfig,
        }: {
            companyId: string;
            serviceProvider: "salesforce";
            reviewsConfig: {
                dealStatus: string[];
                dateRange: string;
                valueThreshold: number;
                schedulingReviews: string[];
                defaultReviews: string[];
            };
        }) => {
            const response = await Axios.put(
                `/main/auth/salesforce/reviews/config`,
                {
                    companyId,
                    serviceProvider,
                    reviewsConfig: reviewsConfig,
                }
            );
            return response.data;
        }
    );
};
