import { FC } from "react";
import { ModalFirstVideo, Typography } from "trinity-components-library";
import { useDownloadAttachmentQuery } from "../../../apis/attachment.api";

export interface VideoAttachmentProps {
    url: string;
}

export const VideoAttachment: FC<VideoAttachmentProps> = ({ url }) => {
    const { result } = useDownloadAttachmentQuery({
        url,
        mimetype: "video/mp4",
        withAuthorization: true,
    });

    switch (result.status) {
        case "success":
            return <ModalFirstVideo url={result.data} />;
        case "error":
            return (
                <Typography color="error-700" size="sm">
                    Failed to load video
                </Typography>
            );
        case "loading":
            return (
                <Typography color="gray-700" size="sm">
                    Loading video...
                </Typography>
            );
        default:
            return (
                <Typography color="gray-700" size="sm">
                    Loading video...
                </Typography>
            );
    }
};

export default VideoAttachment;
