import { Typography } from "trinity-components-library";
import { Dispatch, FC } from "react";
import { Actions, ActionType, ModeType } from "../reducers/summaryAndFindings";

import FindingsAndRecommendationsSimplePagination from "./findings-and-recommendations-simple-pagination";
import NoDataState from "./no-data-state";
import TabPagination from "./tab-pagination";
import { ReportRecommendation } from "../../../apis/types/report";
import parse from "html-react-parser";

interface FindingsAndRecommendationsProps {
    items: ReportRecommendation[];
    mode: ModeType;
    currentIndex: number;
    dispatch: Dispatch<ActionType>;
}

export const FindingsAndRecommendations: FC<FindingsAndRecommendationsProps> =
    ({ items, dispatch, mode, currentIndex }) => {
        const currentItem =
            currentIndex < items.length ? items[currentIndex] : null;

        const isEmptyMode = mode === ModeType.EMPTY;
        return (
            <div className="flex flex-col gap-6 mb-16">
                <div className="flex items-center">
                    <div className="flex-1">
                        <Typography color="gray-900" weight="medium">
                            Findings & Recommendations
                        </Typography>
                    </div>
                </div>
                {isEmptyMode && (
                    <div className="flex justify-center pt-6 pb-14">
                        <NoDataState
                            title="No Findings or Recommendations found"
                            description="Contact admin to include new Findings and Recommendations."
                        />
                    </div>
                )}
                {!isEmptyMode && items.length > 1 && (
                    <TabPagination
                        current={currentIndex + 1}
                        maxCount={6}
                        totalCount={items.length}
                        onChange={i =>
                            dispatch({
                                type: Actions.ITEMS_INDEX_CHANGE,
                                payload: i - 1,
                            })
                        }
                    />
                )}
                {mode === ModeType.VIEW && currentItem && (
                    <div className="flex flex-col gap-6">
                        <div className="w-full flex flex-col gap-4">
                            <div>
                                <Typography color="gray-900" weight="medium">
                                    Finding
                                </Typography>
                                <Typography color="gray-500">
                                {parse(currentItem?.finding)}
                                </Typography>
                            </div>
                            <div>
                                <Typography color="gray-900" weight="medium">
                                    Recommendation
                                </Typography>
                                <Typography color="gray-500">
                                {parse(currentItem?.recommendation)}
                                </Typography>
                            </div>
                        </div>
                        <FindingsAndRecommendationsSimplePagination
                            current={currentIndex + 1}
                            totalCount={items.length}
                            onChange={value =>
                                dispatch({
                                    type: Actions.ITEMS_INDEX_CHANGE,
                                    payload: value - 1,
                                })
                            }
                        />
                    </div>
                )}
            </div>
        );
    };

export default FindingsAndRecommendations;
