import { useEffect, useState } from "react";

interface UseReadFileContentParams {
    file: Blob | null;
    readAs?: "data-url" | "text";
}
export const useReadFileContent = ({
    file,
    readAs = "text",
}: UseReadFileContentParams) => {
    const [content, setContent] = useState("");
    useEffect(() => {
        if (file) {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setContent(
                    typeof reader.result === "string" ? reader.result : ""
                );
            });
            if (readAs === "data-url") {
                reader.readAsDataURL(file);
            } else {
                reader.readAsText(file);
            }
        } else {
            setContent("");
        }
    }, [file, readAs]);
    return content;
};
