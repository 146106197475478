import {  FeaturedIcon, Typography } from "trinity-components-library";
import { FC } from "react";

interface NoDataStateProps {
  title: string;
  description?: string;
  showSummaryButton?: boolean;
  showFindingsButton?: boolean;
  onAddSummaryClick?: () => void;
  onAddFindingsClick?: () => void;
}

export const NoDataState: FC<NoDataStateProps> = ({
  title,
  description,
  
}) => {
  return (
    <div className="w-[308px] flex flex-col gap-6">
      <div className="flex flex-col items-center gap-4">
        <FeaturedIcon size="lg" name="search-lg" color="primary" />
        <div className="flex flex-col items-center text-center">
          <Typography color="gray-900">{title}</Typography>
          <Typography size="sm" color="gray-500">
            {description}
          </Typography>
        </div>
      </div>
      <div className="flex flex-col gap-3">
      
      </div>
    </div>
  );
};

export default NoDataState;
