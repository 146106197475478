import React, { FC } from "react";
import { Icon, Typography, Button } from "trinity-components-library";
import ReviewLink from "./review-link";

interface ScheduleReviewModalDoneStateProps {
    reviews: {
        reviewerName: string;
        reviewId: string;
    }[];
    onDone: () => void;
}

const ScheduleReviewModalDoneState: FC<ScheduleReviewModalDoneStateProps> = ({
    reviews,
    onDone,
}) => {
    return (
        <div className="flex flex-col pt-6 gap-5 w-[754px]">
            <div className="flex flex-col gap-12">
                <div className="flex flex-col gap-5 items-center justify-center">
                    <div className="flex p-2 rounded-full bg-success-100 border-8 border-success-50">
                        <Icon name="check" size={20} color="success-600" />
                    </div>
                    <div className="flex flex-col gap-2 items-center">
                        <Typography
                            type="text"
                            variant="h6"
                            size="lg"
                            weight="medium"
                            color="gray-900"
                        >
                            Congrats! Your review has been sent!
                        </Typography>
                        <Typography
                            type="text"
                            variant="h6"
                            size="sm"
                            color="gray-500"
                        >
                            You can also invite your contact using the link
                            below.
                        </Typography>
                    </div>
                </div>
                <div className="flex flex-col gap-5">
                    <div className="flex flex-col gap-3">
                        {/* group item */}
                        {reviews.map(review => (
                            <ReviewLink {...review} />
                        ))}
                    </div>
                    <div className="flex flex-col gap-2 w-full">
                        <Button
                            label="Done"
                            variant="contained"
                            color="primary"
                            className="py-[10px] px-[18px]"
                            onClick={onDone}
                        />
                    </div>
                </div>
            </div>
            <div className="flex justify-center items-center gap-4">
                <div className="w-2 h-2 rounded-md bg-gray-100"></div>
                <div className="w-2 h-2 rounded-md bg-gray-100"></div>
                <div className="w-2 h-2 rounded-md bg-primary-600"></div>
            </div>
        </div>
    );
};

export default ScheduleReviewModalDoneState;
