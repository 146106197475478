import { FC, useEffect, useMemo } from "react";
import { Navigate, useLocation } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { useAuth } from "../../libs/auth/auth";
import { getInvitedUserData } from "../../libs/getInvitedUserData";
import Axios from "../../axios-instance";
import { useUserInfoQuery } from "../../apis/user.api";
import getLoggedInCompanyId from "../../libs/getLoggedInCompanyId";
import Loading from "../../components/loading/Loading";
import { localStorageKeys } from "../../data/localStorageKeys";

const RootCheck: FC<{ children: JSX.Element }> = ({ children }) => {
    const { isAuthenticated, onLogout, token, isTrinityAdmin } = useAuth();
    const loggedInCompanyId = getLoggedInCompanyId();
    const invitedUserData = getInvitedUserData();
    const location = useLocation();
    const searchParams = useMemo(
        () => new URLSearchParams(location.search),
        [location.search]
    );
    const {
        result: { status: onboardingStatus },
    } = useUserInfoQuery({ retry: false });

    useEffect(() => {
        Axios.interceptors.response.use(
            res => res,
            err => {
                const status = err?.response?.status;
                if (status === 401 && isAuthenticated) {
                    onLogout();
                } else {
                    return Promise.reject(err);
                }
            }
        );
        let timeoutId: any;
        if (token) {
            const jwt: any = jwtDecode(token);
            timeoutId = setTimeout(onLogout, jwt.exp * 1000 - Date.now());
        }
        return () => {
            if (timeoutId !== undefined) {
                clearTimeout(timeoutId);
            }
        };
    }, [isAuthenticated, onLogout, token]);

    if (searchParams.has("token")) {
        if (searchParams.has("accessAccount") || searchParams.has("token")) {
            if (searchParams.has("accessAccount")) {
                localStorage.setItem(
                    localStorageKeys.LOGGED_IN_COMPANY_ID,
                    searchParams.get("accessAccount") ?? ""
                );
                searchParams.delete("accessAccount");
            }
            if (searchParams.has("token")) {
                localStorage.setItem(
                    localStorageKeys.AUTH_TOKEN,
                    searchParams.get("token") ?? ""
                );
                searchParams.delete("token");
            }
            window.location.href = window.location.href.split("?")[0];
        }
    }

    if (isAuthenticated && isTrinityAdmin && loggedInCompanyId === null) {
        return <Navigate to="/select-company" />;
    }

    if (!isAuthenticated && !location.pathname.includes("login")) {
        return <Navigate to="/login" state={{ redirectTo: location }} />;
    }

    if (!invitedUserData?.user) {
        if (onboardingStatus === "loading") {
            return (
                <div className="flex justify-center">
                    <Loading />
                </div>
            );
        } else if (onboardingStatus === "error") {
            return <Navigate to="/onboarding/create-company" />;
        }
    }

    return children;
};

export default RootCheck;
