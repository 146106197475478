import { FC, Fragment } from "react";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import {
    Controller,
    useFieldArray,
    UseFieldArrayRemove,
    UseFieldArrayUpdate,
    UseFormReturn,
    useFormState,
} from "react-hook-form";
import {
    Button,
    Typography,
    Badge,
    VirtualizedSearchInputField,
    MailInputField,
    SelectInputField,
    countryCodesMap,
    ISO2Code,
    PhoneInputField,
} from "trinity-components-library";
import { OpportunityForm } from "./add-opportunity-form-modal";
import { MemberRole } from "../../../apis/types/team-members.api.types";

type AddContactFormPartProps = {
    form: UseFormReturn<OpportunityForm, any>;
    contactPersons: any[];
    status?: "success" | "loading" | "error";
};

const ContactFormPart: FC<AddContactFormPartProps> = ({
    form,
    contactPersons,
    status = "loading",
}) => {
    const { fields, append, remove, update } = useFieldArray({
        control: form.control,
        name: "contactDetails",
    });

    return (
        <Fragment>
            <div className="flex flex-col">
                {fields.map((item, index) => (
                    <Fragment>
                        <ContactForm
                            form={form}
                            index={index}
                            key={item.id}
                            remove={remove}
                            update={update}
                            contactPersons={contactPersons}
                            status={status}
                        />
                    </Fragment>
                ))}
            </div>
            <div className="flex p-6 border-b">
                {fields.length === 1 ? (
                    <Button
                        icon="plus"
                        variant="outlined"
                        color="gray"
                        size="lg"
                        label="Add additional contact"
                        onClick={() => {
                            append({
                                name: "",
                                title: "",
                                email: "",
                                phone: { value: "", code: "AU" },
                            });
                        }}
                    />
                ) : (
                    <div>
                        <Button
                            icon="plus"
                            variant="outlined"
                            className="mr-2"
                            color="gray"
                            size="lg"
                            label="Add additional contact"
                            onClick={() => {
                                append({
                                    name: "",
                                    title: "",
                                    email: "",
                                    phone: { value: "", code: "AU" },
                                });
                            }}
                        />
                        <Button
                            icon="trash-03"
                            variant="contained"
                            size="lg"
                            color="error"
                            label="Delete additional contact"
                            onClick={() => {
                                remove(fields.length - 1);
                            }}
                        />
                    </div>
                )}
            </div>
        </Fragment>
    );
};

interface ContactFormProps {
    form: UseFormReturn<OpportunityForm, any>;
    index: number;
    remove: UseFieldArrayRemove;
    update: UseFieldArrayUpdate<OpportunityForm, "contactDetails">;
    contactPersons: any[];
    status?: "success" | "loading" | "error";
}

export const ContactForm: FC<ContactFormProps> = ({
    index,
    remove,
    update,
    contactPersons,
    form,
    status,
}) => {
    const formState = useFormState({
        control: form.control,
        name: "contactDetails",
    });

    const { errors } = formState;
    const values = form.getValues();

    return (
        <div className="flex flex-col p-6 border-b">
            <div className="flex mb-6">
                <div className="flex flex-grow items-center content-center">
                    <Typography
                        type="text"
                        variant="h6"
                        size="md"
                        color="gray-800"
                        className="mr-3"
                    >
                        {index === 0
                            ? "Contact Details"
                            : "Additional Contact Details"}
                    </Typography>
                    {index === 0 ? (
                        <Badge label="Primary" color="primary" />
                    ) : null}
                </div>
            </div>
            <div className="grid grid-cols-2 gap-3">
                <div className="grid grid-rows-2 gap-6">
                    <Controller
                        name={`contactDetails.${index}.name`}
                        control={form.control}
                        rules={{ required: true }}
                        render={({ field, formState }) => (
                            <VirtualizedSearchInputField
                                label="Contact person's name"
                                placeholder="John Doe"
                                value={field.value}
                                onChange={(value: string) => {
                                    field.onChange(value ?? "");
                                }}
                                error={
                                    errors.contactDetails?.[index]?.name
                                        ?.type === "required"
                                        ? "Required"
                                        : ""
                                }
                                items={contactPersons.map(item => ({
                                    ...item,
                                    id: item?.email ?? "",
                                    value: item?.name ?? "",
                                }))}
                                dropdownHeight={270}
                                itemHeight={45}
                                hideNotFoundText
                                status={status}
                                onItemSelect={item => {
                                    if (item !== null) {
                                        const phoneNumber = isValidPhoneNumber(
                                            item?.phone_number ?? ""
                                        )
                                            ? parsePhoneNumber(
                                                  item?.phone_number ?? ""
                                              )
                                            : {
                                                  country: "",
                                                  formatNational: () => "",
                                              };
                                        form.setValue(
                                            `contactDetails.${index}`,
                                            {
                                                email: item?.email ?? "",
                                                title:
                                                    contactRolesArray.find(
                                                        ({ id }) =>
                                                            id === item?.title
                                                    )?.id ?? "",

                                                name: field.value,
                                                phone: {
                                                    code:
                                                        (phoneNumber.country as ISO2Code) ||
                                                        "AU",
                                                    value: (
                                                        phoneNumber.formatNational() ||
                                                        ""
                                                    )
                                                        .split(" ")
                                                        .join(""),
                                                },
                                            }
                                        );
                                    }
                                }}
                            />
                        )}
                    />
                    <Controller
                        name={`contactDetails.${index}.email`}
                        control={form.control}
                        rules={{
                            required: true,
                            pattern: {
                                value: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i,
                                message: "Invalid email address",
                            },
                        }}
                        render={({ field }) => (
                            <MailInputField
                                label="Contact email address"
                                placeholder="john.doe@gmail.com"
                                value={field.value}
                                onChange={field.onChange}
                                error={
                                    errors.contactDetails?.[index]?.email
                                        ?.message ||
                                    (errors.contactDetails?.[index]?.email
                                        ?.type === "required"
                                        ? "Required"
                                        : "")
                                }
                            />
                        )}
                    />
                </div>
                <div className="grid grid-rows-2 gap-6">
                    <Controller
                        name={`contactDetails.${index}.title`}
                        control={form.control}
                        rules={{ required: true }}
                        render={({ field }) => {
                            return (
                                <SelectInputField
                                    label="Contact role/Job Title"
                                    placeholder="Select"
                                    items={contactRolesArray}
                                    value={
                                        contactRolesArray.find(
                                            ({ id }) => id === field.value
                                        ) ?? { id: "", jobTitle: "" }
                                    }
                                    renderItem={item => (
                                        <Typography variant="p">
                                            {item.jobTitle}
                                        </Typography>
                                    )}
                                    onChange={({ id }) => field.onChange(id)}
                                    error={
                                        errors.contactDetails?.[index]?.title
                                            ?.type === "required"
                                            ? "Required"
                                            : ""
                                    }
                                />
                            );
                        }}
                    />
                    <Controller
                        name={`contactDetails.${index}.phone`}
                        control={form.control}
                        rules={{
                            validate: ({ value, code }) => {
                                const countryCode =
                                    countryCodesMap[
                                        code as unknown as keyof typeof countryCodesMap
                                    ];
                                if (
                                    value &&
                                    !/^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/i.test(
                                        `+${
                                            countryCode.code
                                        } ${value?.replaceAll(" ", "")}`
                                    )
                                ) {
                                    return "Invalid phone number";
                                }
                            },
                        }}
                        render={({ field }) => {
                            values.contactDetails[index].phone.value =
                                values.contactDetails[
                                    index
                                ].phone?.value?.replaceAll(" ", "") ?? "";
                            return (
                                <PhoneInputField
                                    label="Contact phone number"
                                    placeholder="9104629429"
                                    value={values.contactDetails[index].phone}
                                    onChange={({ value, code }) => {
                                        value = value.replace(/[^0-9]/g, "");
                                        field.onChange({ value, code });
                                    }}
                                    error={
                                        errors.contactDetails?.[index]?.phone
                                            ?.message ||
                                        (errors.contactDetails?.[index]?.phone
                                            ?.type === "required"
                                            ? "Required"
                                            : "")
                                    }
                                />
                            );
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default ContactFormPart;

const contactRolesArray: { id: MemberRole; jobTitle: string }[] = [
    { id: MemberRole.SENIOR_MANAGER, jobTitle: "Senior Manager" },
    { id: MemberRole.C_SUITE_LEADER, jobTitle: "C-Suite Leader" },
    { id: MemberRole.PROCUREMENT, jobTitle: "Procurement" },
    { id: MemberRole.FINANCE, jobTitle: "Finance" },
    {
        id: MemberRole.PROJECT_MANAGER_DELIVERY,
        jobTitle: "Project Manager/Delivery",
    },
    { id: MemberRole.MARKETING, jobTitle: "Marketing" },
    { id: MemberRole.SALES, jobTitle: "Sales" },
    { id: MemberRole.IT, jobTitle: "IT" },
    { id: MemberRole.BOARD, jobTitle: "Board" },
];
