import React, { FC } from "react";
import { Icon, IconType, Typography } from "trinity-components-library";

export interface DetailItemProps {
    icon: IconType;
    name: string;
    value?: string;
}

export const DetailItem: FC<DetailItemProps> = ({ icon, name, value }) => {
    return (
        <div className="flex gap-2">
            <div className="flex">
                <Icon name={icon} color="gray-700" size={16} />
            </div>
            <div className="flex flex-col gap-2">
                <Typography type="text" color="gray-500" size="sm">
                    {name}
                </Typography>
                {value && (
                    <Typography type="text" size="sm" color="gray-700">
                        {value}
                    </Typography>
                )}
            </div>
        </div>
    );
};

export default DetailItem;
