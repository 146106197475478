import { FC } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { SelectInputField } from "trinity-components-library";
import industryData from "../../../data/industryData";
import FormItem from "../../components/form-item";

export interface IndustryItemProps {
    form: UseFormReturn<any>;
}

const IndustryItem: FC<IndustryItemProps> = ({ form }) => {
    return (
        <FormItem
            title="Industry"
            helperText="A quick snapshot of your company."
        >
            <Controller
                name="industry"
                control={form.control}
                render={({ field }) => (
                    <SelectInputField
                        items={industryData}
                        placeholder="Select an industry"
                        renderItem={item => item.label}
                        fullWidth
                        value={industryData.find(
                            ({ id }) => id === field.value
                        )}
                        onChange={item => field.onChange(item.id)}
                        chevronLeft
                    />
                )}
            />
        </FormItem>
    );
};

export default IndustryItem;
