import { useState } from "react";
import { AxiosProgressEvent } from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Axios from "../axios-instance";
import {
    BulkReviewRequest,
    ReviewRequest,
} from "../pages/opportunities/hooks/useDeals";
import { UseQueryReturnType } from "./api";
import { CycleDuration } from "./types/cycle-duration";
import { Deal } from "./types/deal";
import { DealOutcome } from "./types/deal-outcome";
import { DealRelations } from "./types/deal-relations";
import { ReviewType } from "./types/review-type";
import { SalesProcess } from "./types/sales-process";
import { TemplateCategoryKey } from "./types/template";

export type DealsSortBy =
    | "companyName"
    | "type"
    | "creationTime"
    | "reviewRating"
    | "dealName"
    | "value"
    | "updatedTime";

export type DealsSortOrder = "ASC" | "DESC";

export interface UseDealsQueryParams {
    startDate?: string;
    endDate?: string;
    company?: string;
    companyId?: string;
    page?: number;
    limit?: number;
    routePath?: string;
    reviewStatus?: "complete" | "incomplete";
    reviewTypes?: ReviewType[];
    filterBy?: string;
    sortBy?: DealsSortBy;
    sortOrder?: DealsSortOrder;
    outcomes?: DealOutcome[];
    includeTemplates?: boolean;
    templateCategoryKey?: TemplateCategoryKey[];
}

export const useDealsQuery = (
    {
        startDate,
        endDate,
        company,
        companyId,
        reviewStatus,
        reviewTypes,
        filterBy,
        page,
        limit,
        sortBy,
        sortOrder,
        outcomes,
        includeTemplates,
        templateCategoryKey,
    }: UseDealsQueryParams = {},

    options: { enabled?: boolean } = {}
): UseQueryReturnType<{
    items: Deal[];
    meta: any;
}> => {
    const queryKey = [
        "deals",
        companyId,
        reviewTypes,
        reviewStatus,
        company,
        startDate,
        endDate,
        filterBy,
        page,
        limit,
        sortBy,
        sortOrder,
        outcomes,
        includeTemplates,
        templateCategoryKey,
    ];
    const invalidateQueryKey = ["deals", companyId];
    const invalidateMetadataKey = ["deals-metadata", companyId];
    const queryClient = useQueryClient();
    const invalidate = () => {
        queryClient.invalidateQueries(invalidateQueryKey);
        queryClient.invalidateQueries(invalidateMetadataKey);
    };
    const result = useQuery({
        queryKey,
        refetchOnWindowFocus: false,
        queryFn: async () => {
            const resp = await Axios.get("/main/deals", {
                params: {
                    "start-date": startDate,
                    "end-date": endDate,
                    company,
                    "company-id": companyId,
                    "review-status": reviewStatus,
                    ...(reviewTypes?.length && {
                        "review-types": reviewTypes.join(","),
                    }),
                    ...(outcomes?.length && {
                        outcomes: outcomes.join(","),
                    }),
                    "filter-by": filterBy,
                    page,
                    limit,
                    "sort-by": sortBy,
                    "sort-order": sortOrder,
                    "include-templates": includeTemplates,
                    "template-category-key": templateCategoryKey?.join?.(","),
                },
            });
            return resp.data;
        },
        keepPreviousData: true,
        ...options,
    });
    return {
        result,
        invalidate,
    };
};

export const useDealsBulkDeleteMutation = () => {
    return useMutation(
        async ({ dealsToDelete }: { dealsToDelete: string[] }) => {
            return Axios.delete("/main/deals", {
                data: {
                    dealsToDelete,
                },
            });
        }
    );
};

export const useSendDealsBulkReviewMutation = () => {
    return useMutation(
        async ({
            bulkReviewRequests,
            getByReview,
        }: {
            bulkReviewRequests: BulkReviewRequest[];
            getByReview?: boolean;
        }) => {
            return Axios.post("/reviews/bulk", bulkReviewRequests, {
                params: {
                    getByReview,
                },
            });
        }
    );
};

export const useSendDealsReviewMutation = () => {
    return useMutation(
        async ({ reviewRequest }: { reviewRequest: ReviewRequest }) => {
            return Axios.post("/reviews", reviewRequest);
        }
    );
};

export const useDealsReviewProgressQuery = ({
    bulkReviewId,
}: {
    bulkReviewId: string;
}) => {
    const queryKey = ["deals-review-progress-id", bulkReviewId];
    const queryClient = useQueryClient();
    const invalidate = () => queryClient.invalidateQueries(queryKey);
    const result = useQuery({
        queryKey,
        queryFn: async () => {
            const resp = await Axios.get(`/reviews/progress/${bulkReviewId}`);
            return resp.data;
        },
        refetchInterval: data => {
            if (data?.success.length + data?.failed.length === data?.total) {
                return false;
            }
            return 1000;
        },
    });
    return {
        result,
        invalidate,
    };
};

export const useDeleteDealMutation = () => {
    return useMutation(async ({ dealId }: { dealId: string }) => {
        return Axios.delete(`/main/deals/${dealId}`);
    });
};

export const useGetSurveyIdQuery = () => {
    const queryKey = ["survey-id"];
    const queryClient = useQueryClient();
    const invalidate = () => queryClient.invalidateQueries(queryKey);
    const result = useQuery({
        queryKey,
        queryFn: async () => {
            const resp = await Axios.get(
                "/main/survey-questionnaire?type=DEFAULT"
            );
            return resp.data.items[0].id;
        },
    });
    return {
        result,
        invalidate,
    };
};

type UseCreateCompleteDealMutationParams = {
    source: string;
    value: number;
    sales_process: SalesProcess;
    review_type: ReviewType;
    cycle_duration: CycleDuration;
    outcome: DealOutcome;
    owner_id: string;
    location: string;
    company_id: string;
    name: string;
    contact: {
        for_company_id: string;
        company_name: string;
        contact_persons: string;
        website: string;
        contact_logo: string | "";
    };
};

export const useCreateDealMutation = () => {
    return useMutation(async (newDeal: UseCreateCompleteDealMutationParams) => {
        return Axios.post(`/main/deals/complete`, {
            ...newDeal,
        });
    });
};

export const useUploadDealsMutation = () => {
    return useMutation(
        async ({
            file,
            companyId,
            onUploadProgress,
        }: {
            file: File;
            companyId: string;
            onUploadProgress?: (event: AxiosProgressEvent) => void;
        }) => {
            const formData = new FormData();
            const params = { company_id: companyId };
            formData.append("file", file);
            const response = await Axios.post("/main/deals/upload", formData, {
                params,
                headers: {
                    "Content-Type": "multipart/form-data; charset=utf-8",
                },
                onUploadProgress,
            });
            return response.data;
        }
    );
};

export const useGetDealQuery = ({
    id,
    include,
}: {
    id: string;
    include: DealRelations[];
}): UseQueryReturnType<Exclude<Deal, "template_id">> => {
    const queryKey = ["deal", id, include];
    const invalidateKey = ["deal", id];
    const queryClient = useQueryClient();
    const invalidate = () => queryClient.invalidateQueries(invalidateKey);
    const result = useQuery(
        queryKey,
        async () => {
            const response = await Axios.get(`/main/deals/${id}`, {
                params: {
                    include: include.join(","),
                },
            });
            return response.data;
        },
        { enabled: id !== "" }
    );
    return { result, invalidate };
};

export const usePutDealMutation = () => {
    return useMutation(
        async ({
            id,
            contactId,
            cycleDuration,
            ownerId,
            ...rest
        }: {
            id: string;
            contactId: string;
            value: number;
            cycleDuration: CycleDuration;
            outcome: string;
            name: string;
            ownerId: string;
            location: string;
        }) => {
            const response = await Axios.put(`/main/deals/${id}`, {
                id,
                contact_id: contactId,
                cycle_duration: cycleDuration,
                owner_id: ownerId,
                ...rest,
            });
            return response.data;
        }
    );
};

export const usePatchDealMutation = () => {
    return useMutation(
        async ({
            dealId,
            reviewType,
        }: {
            dealId: string;
            reviewType: ReviewType;
        }) => {
            const response = await Axios.patch(`/main/deals/${dealId}`, {
                review_type: reviewType,
            });
            return response.data;
        }
    );
};

export const useUploadReportPdfMutation = () => {
    const [controller, setController] = useState(new AbortController());
    const mutation = useMutation(
        async ({ dealId, file }: { dealId: string; file: File }) => {
            const formData = new FormData();
            formData.append("file", file);
            const url = `/main/deals/${dealId}/reports`;
            const response = await Axios.post(url, formData, {
                headers: {
                    "Content-Type": "multipart/form-data; charset=utf-8",
                },
                signal: controller.signal,
            });
            return response.data;
        }
    );
    return {
        mutation,
        abort: () => {
            controller.abort();
            setController(new AbortController());
        },
    };
};

export const useGetDealsMetaDataQuery = ({
    userId,
    companyId,
    startDate,
    endDate,
    reviewStatus,
    reviewTypes = [],
}: {
    userId?: string;
    companyId?: string;
    startDate?: string;
    endDate?: string;
    reviewStatus?: "complete" | "incomplete";
    reviewTypes?: ReviewType[];
}): UseQueryReturnType<{
    scheduled: number;
    unscheduled: number;
    all: number;
}> => {
    const queryClient = useQueryClient();
    const queryKey = [
        "deals-metadata",
        companyId,
        userId,
        startDate,
        endDate,
        reviewStatus,
        reviewTypes.join(","),
    ];
    const invalidateKey = ["deals-metadata", companyId];
    const invalidate = () => queryClient.invalidateQueries(invalidateKey);
    const result = useQuery(queryKey, async () => {
        const response = await Axios.get("/main/deals/metadata", {
            params: {
                userId,
                companyID: companyId,
                "start-date": startDate,
                "end-date": endDate,
                "review-status": reviewStatus,
                reviewTypes,
            },
        });
        return response.data;
    });
    return { invalidate, result };
};
