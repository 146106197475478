import React, { FC } from "react";
import { Icon, Typography } from "trinity-components-library";
import DetailItem, { DetailItemProps } from "./detail-item";
import TextDropdown from "./text-dropdown";

interface ReportDetailsProps {
    detailItems: DetailItemProps[];
    recipients: { name: string; status: "Sent" | "Responded" }[];
}

export const ReportDetails: FC<ReportDetailsProps> = ({
    detailItems,
    recipients,
}) => {
    return (
        <div className="border-gray-200 rounded-lg border grid grid-cols-1 lg:grid-cols-2 p-8 gap-y-8">
            {detailItems.map(itemProps => (
                <DetailItem {...itemProps} />
            ))}
            <div className="flex gap-2">
                <div className="flex">
                    <Icon name="user-check-02" size={16} color="gray-700" />
                </div>
                <div className="flex flex-col gap-2">
                    <Typography type="text" color="gray-500" size="sm">
                        Recipients
                    </Typography>
                    <TextDropdown items={recipients} />
                </div>
            </div>
        </div>
    );
};

export default ReportDetails;
