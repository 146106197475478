import React from "react";
import { Typography } from "trinity-components-library";
import UserProfileForm from "../../sections/user-profile-form/user-profile-form";

export const ProfileSettings = () => {
    return (
        <div className="px-8 pt-8 pb-16 h-full flex flex-col overflow-auto bg-gray-25">
            <div className="mb-6">
                <Typography type="display" size="sm" color="gray-900">
                    Your Details
                </Typography>
            </div>
            <div className="grow">
                <UserProfileForm />
            </div>
        </div>
    );
};

export default ProfileSettings;
