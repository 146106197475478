import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Typography, ButtonGroup } from "trinity-components-library";

export function Settings() {
    const navigate = useNavigate();
    const location = useLocation();
    const pathBreakdown = location.pathname.split("/");
    const selectedSection =
        pathBreakdown.length >= 3 ? pathBreakdown[2] : "general";

    const sectionItems = [
        {
            id: "general",
            label: "General",
        },
        {
            id: "users",
            label: "Users",
        },
        {
            id: "billing",
            label: "Billing",
        },
        {
            id: "integrations",
            label: "Integrations",
        },
    ];

    return (
        <div className="px-8 pt-8 pb-16 h-full flex flex-col overflow-auto">
            <div className="mb-6">
                <Typography type="display" size="sm" color="gray-900">
                    Company Settings
                </Typography>
            </div>
            <div className="mb-8">
                <ButtonGroup
                    items={sectionItems.map(({ id, label }) => ({
                        label,
                        onClick: () => navigate(id),
                        selected: selectedSection === id,
                    }))}
                    variant="outlined"
                    color="gray"
                />
            </div>
            <div className="grow">
                <Outlet />
            </div>
        </div>
    );
}

export default Settings;
