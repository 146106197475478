import { useMutation, useQuery, useQueryClient } from "react-query";
import Axios from "../axios-instance";
import { UserPreference } from "../types";
import { UseQueryReturnType } from "./api";
import { CompanyStatus } from "./types/company-status";

export type CompanyType = {
    id: string;
    name: string;
    website: string | null;
    industry: string;
    location: string;
    status: "ACTIVE" | "INACTIVE";
    created_at: string;
    updated_at: string;
};

interface UseCompanyQueryParams {
    companyId: string;
}

export const useCompanyQuery = ({
    companyId,
}: UseCompanyQueryParams): UseQueryReturnType<CompanyType> => {
    const queryKey = ["company", companyId];
    const queryClient = useQueryClient();
    const invalidate = () => queryClient.invalidateQueries(queryKey);
    const result = useQuery(
        queryKey,
        async () => {
            const resp = await Axios.get(`/main/company/${companyId}`);
            return resp.data;
        },
        { refetchOnWindowFocus: false }
    );
    return {
        result,
        invalidate,
    };
};

export const useCompanyMutation = () => {
    return useMutation((company: any) => {
        return Axios.put(`/main/company/${company.id}`, {
            ...company,
            website: company.website || null,
        });
    });
};

export const useCompanyUserPreferenceMutation = () => {
    return useMutation((preference: UserPreference) => {
        return Axios.put(
            `main/company/${preference.company_id}/team-members/${preference.team_member_id}/preference/${preference.id}`,
            { ...preference }
        );
    });
};

export const useCompanyCreateMutation = () => {
    return useMutation(
        (company: {
            name: string;
            website?: string;
            industry: string;
            location: string;
        }) => {
            return Axios.post("/main/company", company);
        }
    );
};

export interface UseGetCompaniesQueryParams {
    status?: CompanyStatus;
    name?: string;
    page?: number;
    limit?: number;
}

export const useGetCompaniesQuery = ({
    status,
    name,
    page,
    limit,
}: UseGetCompaniesQueryParams): UseQueryReturnType => {
    const queryKey = ["companies", status, name, page, limit];
    const invalidateQueryKey = ["companies", status, name];
    const queryClient = useQueryClient();
    const invalidate = () => queryClient.invalidateQueries(invalidateQueryKey);
    const result = useQuery(queryKey, async () => {
        const response = await Axios.get("/main/company", {
            params: {
                status,
                name,
                page,
                limit,
            },
        });
        return response.data;
    });
    return { invalidate, result };
};
