import {
    useMutation,
    useQuery,
    useQueryClient,
    UseQueryOptions,
} from "react-query";
import Axios from "../axios-instance";
import { UseQueryReturnType } from "./api";
import {
    MemberScope,
    MemberStatus,
    MemberRole,
    MemberSortOrder,
    MemberSortKeys,
} from "./types/team-members.api.types";

type TeamMemberType = {
    id: string;
    user_id: string;
    email: string;
    name: string;
    scopes: MemberScope;
    company_id: string;
    status: MemberStatus;
    role: MemberRole;
    profile_picture_url: string;
    created_at: string;
    updated_at: string;
    last_logged_in_at: string;
    scheduledReviews: number;
    completedReviews: number;
};

export interface UseGetTeamMembersQueryParams {
    statuses: MemberStatus[];
    scopes: MemberScope[];
    sortOrder: MemberSortOrder;
    sortBy: MemberSortKeys[];
    companyId: string;
}

export const useGetTeamMembersQuery = (
    {
        companyId,
        statuses,
        scopes,
        sortOrder,
        sortBy,
    }: UseGetTeamMembersQueryParams,
    options: Omit<
        UseQueryOptions<any, unknown, any, string[]>,
        "queryKey" | "queryFn"
    > = {}
): UseQueryReturnType<TeamMemberType[]> => {
    const queryKey = ["team-members", companyId];
    const queryClient = useQueryClient();
    const invalidate = () => queryClient.invalidateQueries(queryKey);
    const result = useQuery(
        queryKey,
        async () => {
            const resp = await Axios.get(`/main/team-members/`, {
                params: {
                    statuses: statuses?.join?.(","),
                    scopes: scopes?.join?.(","),
                    "company-id": companyId,
                    "sort-order": sortOrder,
                    "sort-by": sortBy?.join?.(","),
                },
            });
            return resp.data;
        },

        { ...options, refetchOnWindowFocus: false }
    );
    return {
        result,
        invalidate,
    };
};

export const useDeleteTeamMemberMutation = () => {
    return useMutation(
        ({ id, companyId }: { id: string; companyId: string }) => {
            return Axios.delete(`/main/team-members/${id}`, {
                params: {
                    "company-id": companyId,
                },
            });
        }
    );
};

export const useAddTeamMemberMutation = () => {
    return useMutation(
        async ({
            name,
            email,
            role,
            scopes,
        }: {
            name: string;
            email: string;
            role?: MemberRole;
            scopes?: MemberScope;
        }) => {
            const response = await Axios.post("/main/team-members", {
                name,
                email,
                role,
                scopes,
            });
            return response.data;
        }
    );
};

export const useBulkAddTeamMembersMutation = () => {
    return useMutation(
        async ({
            companyId,
            teamMembers,
        }: {
            companyId: string;
            teamMembers: {
                name: string;
                email: string;
                role?: MemberRole;
                scopes?: MemberScope;
            }[];
        }) => {
            const response = await Axios.post(
                `/main/team-members/bulk`,
                teamMembers,
                {
                    params: {
                        "company-id": companyId,
                    },
                }
            );
            return response.data;
        }
    );
};

export const useUpdateTeamMemberScopeMutation = () => {
    return useMutation(
        async ({
            companyId,
            updaingDetails,
        }: {
            companyId: string;
            updaingDetails: {
                memberId: string;
                scopes: string;
            };
        }) => {
            const response = await Axios.patch(
                `/main/team-members/${updaingDetails.memberId}`,
                updaingDetails,
                {
                    params: {
                        "company-id": companyId,
                    },
                }
            );
            return response.data;
        }
    );
};

export type TeamMemberInviteProgressData = {
    name: string;
    email: string;
    reason?: string;
};

interface BulkInviteProgressReturnType {
    failed: TeamMemberInviteProgressData[];
    inProgress: TeamMemberInviteProgressData[];
    success: TeamMemberInviteProgressData[];
    total: number;
}

export const useBulkAddTeamMembersProgressQuery = (
    {
        id,
    }: {
        id: string;
    },
    options: Omit<
        UseQueryOptions<any, unknown, any, string[]>,
        "queryKey" | "queryFn"
    > = {}
): UseQueryReturnType<BulkInviteProgressReturnType> => {
    const queryClient = useQueryClient();
    const queryKey = ["team-members-bulk-invite-progress", id];
    const invalidate = () => queryClient.invalidateQueries(queryKey);
    const result = useQuery(
        queryKey,

        async () => {
            const response = await Axios.get(
                `/main/team-members/progress/${id}`
            );
            return response.data;
        },
        {
            ...options,
            refetchInterval: data => {
                if (
                    data?.success.length + data?.failed.length ===
                    data?.total
                ) {
                    return false;
                }
                return 1000;
            },
        }
    );
    return { result, invalidate };
};
