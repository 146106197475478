import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { IntercomProvider } from "react-use-intercom";
import { ThemeProvider } from "trinity-components-library";
import App from "./App";
import { AuthProvider } from "./libs/auth/auth";
import "./index.css";

const queryClient = new QueryClient();
const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID || "";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <AuthProvider>
                    <IntercomProvider appId={INTERCOM_APP_ID}>
                        <ThemeProvider>
                            <App />
                        </ThemeProvider>
                    </IntercomProvider>
                </AuthProvider>
            </BrowserRouter>
        </QueryClientProvider>
    </React.StrictMode>
);
