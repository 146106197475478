import React, { FC } from "react";
import { Pagination } from "trinity-components-library";
import ReportCard from "./components/report-card";
import { ReportItem } from "./types";
import { TemplateCategoryKey } from "../../apis/types/template";

interface ReportsGridViewProps {
    reports: ReportItem[];
    page: number;
    pageCount: number;
    onPageChange?: (page: number) => void;
    templateCategory?: TemplateCategoryKey;
}

const ReportsGridView: FC<ReportsGridViewProps> = ({
    templateCategory,
    reports,
    pageCount,
    page,
    onPageChange,
}) => {

    return (
        <div className="flex flex-col gap-6">
            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6">
                {reports.map(report => (
                    <ReportCard
                        {...report}
                        templateCategory={templateCategory}
                    />
                ))}
            </div>
            <Pagination count={pageCount} page={page} onChange={onPageChange} />
        </div>
    );
};

export default ReportsGridView;
