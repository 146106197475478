import React, { FC } from "react";
import {
    ProgressSteps,
    Divider,
    Typography,
    Slider,
} from "trinity-components-library";
import { QuestionTypes } from "../../../apis/types/survey-questionnaire";
import AudioAttachment from "../audio-attachment/audio-attachment";
import VideoAttachment from "../video-attachment/video-attachment";

export interface AnswerProps {
    type: QuestionTypes;
    options?: string[];
    answer: {
        value: string | any[];
        attachments: { value: string; mimetype: string }[];
    };
}

const removePrefixIcon = (option: string) => {
    let pattern = /<\/?[^>]+>/i;
    let splitOption = option && option.split(pattern);
    const processedOption =
        splitOption &&
        (splitOption.length > 1 ? splitOption[1] : splitOption[0]);

    return processedOption;
};

export const Answer: FC<AnswerProps> = ({
    type: answerType,
    options = [],
    answer,
}) => {
    const type = answerType.toUpperCase();

    if (answer) {
        if (
            typeof answer.value !== "string" &&
            (type === "MCQ" || type === "SCQ" || type === "ORDER")
        ) {
            const value = answer?.value?.length ? answer.value : [];
            const orderValues = value.filter(
                index => typeof index === "number"
            );
            const otherValue = value.find(val => typeof val === "string");
            return (
                <div className="flex flex-col gap-4">
                    {orderValues.length && (
                        <ProgressSteps
                            steps={orderValues.map((answerValue: number) => ({
                                title: removePrefixIcon(options[answerValue]),
                            }))}
                            type="number"
                            current={orderValues.length}
                            titleFontWeight="medium"
                        />
                    )}
                    {otherValue && <Typography>{otherValue}</Typography>}
                    {answer?.attachments?.length > 0 && (
                        <AudioAttachment url={answer.attachments[0].value} />
                    )}
                </div>
            );
        } else if (type === "RATING") {
            return (
                <div className="flex flex-col gap-4">
                    {Array.isArray(answer?.value) && answer.value.length > 0 && (
                        <div className="flex flex-col gap-4">
                            {options.map((option: string, index: number) => {
                                return (
                                    <div className="flex flex-col gap-4">
                                        {index > 0 && <Divider />}
                                        <Typography size="sm" color="gray-700">
                                            {option}
                                        </Typography>
                                        <div className="mb-6 mt-4">
                                            <Slider
                                                step={1}
                                                max={10}
                                                value={Number(
                                                    answer.value[index]
                                                )}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                    {answer?.attachments?.length > 0 && (
                        <AudioAttachment url={answer.attachments[0].value} />
                    )}
                </div>
            );
        } else if (type === "TEXT") {
            const audioData = (answer?.attachments ?? []).find(
                (i: any) => i.mimetype === "audio/webm"
            );
            const videoData = (answer?.attachments ?? []).find(
                ({ mimetype }: { mimetype: string }) =>
                    mimetype.startsWith("video")
            );
            return (
                <div className="flex flex-col gap-4">
                    {typeof answer?.value === "string" && answer.value && (
                        <Typography color="gray-900">{answer.value}</Typography>
                    )}
                    {!!audioData && <AudioAttachment url={audioData.value} />}
                    {!!videoData && <VideoAttachment url={videoData.value} />}
                </div>
            );
        } else if (type === "MEDIA") {
            const audioData = (answer?.attachments ?? []).find(
                (i: any) => i.mimetype === "audio/webm"
            );
            const videoData = (answer?.attachments ?? []).find(
                (i: any) => i.mimetype === "video/mp4"
            );
            return (
                <div className="flex flex-col gap-4">
                    {audioData && <AudioAttachment url={audioData.value} />}
                    {videoData && <VideoAttachment url={videoData.value} />}
                </div>
            );
        }
        return (
            <Typography color="gray-700">It doesn't match the type</Typography>
        );
    }
    return <Typography color="gray-700">Review in progress</Typography>;
};

export default Answer;
