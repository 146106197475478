import React, { FC } from "react";
import {
    FeaturedIconProps,
    ButtonProps,
    FeaturedIcon,
    CircularGradientProgress,
    Typography,
    Button,
} from "trinity-components-library";

export interface ModalBodyProps {
    featuredIcon?: FeaturedIconProps;
    title: string;
    description: string;
    actions: ButtonProps[];
    children?: React.ReactNode;
    showProgress?: boolean;
    progress?: number;
}

export const ModalBody: FC<ModalBodyProps> = ({
    children,
    featuredIcon,
    title,
    description,
    actions,
    showProgress = false,
    progress = 0,
}) => {
    return (
        <div className="p-6 flex flex-col ">
            <div className="flex flex-col items-center gap-5 mb-12">
                {featuredIcon && <FeaturedIcon {...featuredIcon} />}
                {showProgress && (
                    <CircularGradientProgress
                        percentage={progress}
                        size={75}
                        strokeWidth={10}
                    />
                )}
                <div className="flex flex-col text-center gap-2">
                    <Typography color="gray-900" size="lg">
                        {title}
                    </Typography>
                    <Typography color="gray-500" size="sm">
                        {description}
                    </Typography>
                </div>
            </div>
            {children && (
                <div className="flex flex-col gap-5 mb-8">{children}</div>
            )}
            <div className="flex gap-3">
                {actions.map(props => (
                    <Button {...props} />
                ))}
            </div>
        </div>
    );
};

export default ModalBody;
