export const range = (start: number, stop: number, step = 1) =>
    Array(Math.ceil((stop - start) / step))
        .fill(start)
        .map((x, y) => x + y * step);

export const toShortNumber = (value: number) => {
    const lowerLimitForThousand = 999;
    const upperLimitForThousand = 1000000;

    const lowerLimitForMillion = 999999;
    const upperLimitForMillion = 1000000000;

    const lowerLimitForBillion = 999999999;
    const upperLimitForBillion = 1000000000000;

    const lowerLimitForTrillion = 999999999999;

    if (value > lowerLimitForThousand && value < upperLimitForThousand) {
        const leadingNumber = Math.trunc(value / 1000);
        return `${leadingNumber}k`;
    } else if (value > lowerLimitForMillion && value < upperLimitForMillion) {
        const leadingNumber = Math.trunc(value / 1000000);
        return `${leadingNumber}m`;
    } else if (value > lowerLimitForBillion && value < upperLimitForBillion) {
        const leadingNumber = Math.trunc(value / 1000000000);
        return `${leadingNumber}b`;
    } else if (value > lowerLimitForTrillion) {
        const leadingNumber = Math.trunc(value / 1000000000000);
        return `${leadingNumber}t`;
    }
    return String(value);
};

export const capitalize = (text: string) => {
    return text.slice(0, 1).toUpperCase() + text.slice(1).toLowerCase();
};

export const snakeCaseToCapitalCase = (text: string) => {
    return text.split("_").map(capitalize).join(" ");
};
