import { Oval } from "react-loader-spinner";

export function Loading() {
    return (
        <div className="flex h-screen">
            <div className="m-auto">
                <Oval
                    height={50}
                    width={50}
                    color="#72C3FE"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#72C3FE"
                    strokeWidth={5}
                    strokeWidthSecondary={5}
                />
            </div>
        </div>
    );
}

export default Loading;
