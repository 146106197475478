import { useQuery, useQueryClient, UseQueryOptions } from "react-query";
import Axios from "../axios-instance";
import { UseQueryReturnType } from "./api";
import { SurveyQuestionnaire } from "./types/survey-questionnaire";

export const useGetSurveyQuestionnaireQuery = (
    {
        id,
    }: {
        id: string;
    },
    options: Omit<
        UseQueryOptions<any, unknown, any, string[]>,
        "queryKey" | "queryFn"
    > = {}
): UseQueryReturnType<SurveyQuestionnaire> => {
    const queryKey = ["survey-questionnaire", id];
    const queryClient = useQueryClient();
    const invalidate = () => queryClient.invalidateQueries(queryKey);

    const result = useQuery(
        queryKey,

        async () => {
            const response = await Axios.get(
                `/main/survey-questionnaire/${id}`
            );

            return response.data;
        },
        {
            ...options,
            refetchOnWindowFocus: false,
            enabled: (options.enabled ?? true) && id !== "",
        }
    );
    return { invalidate, result };
};
