import {
    useMutation,
    useQuery,
    useQueryClient,
    UseQueryOptions,
} from "react-query";
import Axios from "../axios-instance";
import { UseQueryReturnType } from "./api";
import { MemberRole, MemberScope } from "./types/team-members.api.types";
import { UploadAction } from "./types/upload-action";

export const useUserInfoQuery = (
    options: Omit<
        UseQueryOptions<any, unknown, any, "user-info">,
        "queryKey" | "queryFn"
    > = {}
): UseQueryReturnType => {
    const queryKey = "user-info";
    const queryClient = useQueryClient();
    const invalidate = () => queryClient.invalidateQueries(queryKey);
    const result = useQuery(
        queryKey,
        async () => {
            const resp = await Axios.get("/main/me");
            return resp.data;
        },
        { ...options, refetchOnWindowFocus: false }
    );
    return {
        result,
        invalidate,
    };
};

export const useUserInfoUpdateMutation = () => {
    return useMutation(
        async (payload: {
            id: string;
            name?: string;
            companies?: {
                role?: {
                    title?: MemberRole;
                };
                scopes?: MemberScope;
            };
        }) => {
            const response = await Axios.patch("/main/me", payload);
            return response.data;
        }
    );
};

export const useUserProfilePictureUpdateMutation = () => {
    return useMutation(
        async (payload: {
            name?: string;
            mimetype?: string;
            data?: string;
            action?: UploadAction;
        }) => {
            const response = await Axios.post("/main/me/upload", payload);
            return response.data;
        }
    );
};

export const useDeleteUserProfile = () => {
    return useMutation(async () => {
        await Axios.delete("/main/me");
    });
};

export const usePatchAssociateUserToTeamMutation = () => {
    return useMutation(
        async (payload: { state: string; email: string; name?: string }) => {
            const response = await Axios.patch("/main/me/associate", payload);
            return response.data;
        }
    );
};
