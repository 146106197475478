import { FC, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
    Modal,
    Button,
    MailInputField,
    TextInputField,
    SelectInputField,
} from "trinity-components-library";
import {
    useBulkAddTeamMembersMutation,
    useBulkAddTeamMembersProgressQuery,
} from "../../../apis/team-members.api";
import { MemberScope } from "../../../apis/types/team-members.api.types";
import UserProfile from "../../../components/user-profile/user-profile";
import getLoggedInCompanyId from "../../../libs/getLoggedInCompanyId";
import ModalBody from "./modal-body";

interface InviteUserModalProps {
    open: boolean;
    onClose?: () => void;
}

export const InviteUserModal: FC<InviteUserModalProps> = ({
    open,
    onClose,
}) => {
    const [modalState, setModalState] =
        useState<"form" | "loading" | "success" | "failure">("form");
    const [invites, setInvites] = useState<
        { name: string; email: string; scope: MemberScope }[]
    >([]);
    const [progressPercentage, setProgressPercentage] = useState(0);
    const [progressId, setProgressId] = useState("");
    const form = useForm({
        defaultValues: {
            name: "",
            email: "",
            scope: { id: "", name: "" },
        },
        mode: "onChange",
    });
    const { isDirty, isValid, errors } = form.formState;

    const companyId = getLoggedInCompanyId() ?? "";
    const inviteBulkTeamMembersMutation = useBulkAddTeamMembersMutation();
    useBulkAddTeamMembersProgressQuery(
        { id: progressId },
        {
            enabled: modalState === "loading" && !!progressId,
            onSuccess: (data: any) => {
                const { success = [], failed = [], total = 0 } = data ?? {};
                const settled = success.length + failed.length;
                const percentage = Math.round(
                    ((settled ?? 0) / (total || 1)) * 100
                );
                setProgressPercentage(percentage);
                if (settled === total) {
                    form.reset();
                    setProgressId("");
                    if (failed.length > 0) {
                        setTimeout(() => {
                            setModalState("failure");
                            const failedEmails = failed.map(
                                ({ email }: { email: string }) => email
                            );
                            setInvites(prev =>
                                prev.filter(({ email }) =>
                                    failedEmails.includes(email)
                                )
                            );
                        }, 200);
                    } else {
                        setTimeout(() => {
                            setModalState("success");
                            setInvites([]);
                        }, 200);
                    }
                }
            },
        }
    );

    const onSend = async () => {
        try {
            setProgressPercentage(0);
            setModalState("loading");
            const id = await inviteBulkTeamMembersMutation.mutateAsync({
                companyId,
                teamMembers: invites.map(({ name, email, scope }) => ({
                    name,
                    email,
                    scopes: scope,
                })),
            });
            setProgressId(id);
        } catch (error: any) {
            setModalState("failure");
            setProgressId("");
        }
    };

    const onAdd = form.handleSubmit(async ({ name, email, scope }) => {
        setInvites(prev => [
            ...prev,
            { name, email, scope: scope.id as MemberScope },
        ]);
        form.reset();
    });

    const closeModal = () => {
        form.reset();
        setInvites([]);
        setModalState("form");
        onClose?.();
    };

    return (
        <Modal open={open} onClose={closeModal}>
            <div className="w-[800px]">
                {modalState === "loading" && (
                    <ModalBody
                        title="Inviting..."
                        description="Please wait until the process is complete."
                        showProgress
                        progress={progressPercentage}
                        actions={[
                            {
                                label: "Cancel",
                                variant: "outlined",
                                color: "gray",
                                onClick: closeModal,
                                className: "flex-1",
                            },
                            {
                                label: "Done",
                                color: "primary",
                                disabled: true,
                                className: "flex-1",
                            },
                        ]}
                    />
                )}
                {modalState === "failure" && (
                    <ModalBody
                        title="Failed Invitation"
                        description="Invitation failed. Please try again later."
                        featuredIcon={{
                            size: "xl",
                            name: "alert-triangle",
                            color: "error",
                        }}
                        actions={[
                            {
                                label: "Cancel",
                                variant: "outlined",
                                color: "gray",
                                onClick: closeModal,
                                className: "flex-1",
                            },
                            {
                                label: "Try again",
                                color: "error",
                                onClick: () => {
                                    setModalState("form");
                                    onSend();
                                },
                                className: "flex-1",
                            },
                        ]}
                    />
                )}
                {modalState === "success" && (
                    <ModalBody
                        title="Successful"
                        description="Your invitation successfully send."
                        featuredIcon={{
                            size: "xl",
                            name: "check",
                            color: "success",
                        }}
                        actions={[
                            {
                                label: "Back",
                                variant: "outlined",
                                color: "gray",
                                onClick: () => setModalState("form"),
                                className: "flex-1",
                            },
                            {
                                label: "Done",
                                onClick: closeModal,
                                className: "flex-1",
                            },
                        ]}
                    />
                )}
                {modalState === "form" && (
                    <ModalBody
                        title="Invite User"
                        description="The following users have access to this project:"
                        featuredIcon={{
                            size: "xl",
                            name: "user-plus-01",
                            color: "primary",
                        }}
                        actions={[
                            {
                                label: "Cancel",
                                variant: "outlined",
                                color: "gray",
                                className: "flex-1",
                                onClick: closeModal,
                            },
                            isDirty
                                ? {
                                      label: "Add",
                                      className: "flex-1",
                                      onClick: onAdd,
                                      disabled: !isValid,
                                  }
                                : {
                                      label: isDirty ? "Add" : "Send",
                                      className: "flex-1",
                                      onClick: isDirty ? onAdd : onSend,
                                      disabled: invites.length === 0,
                                  },
                        ]}
                    >
                        {invites.length > 0 && (
                            <div className="flex flex-col my-12 gap-3">
                                {invites.map(({ name, email }) => (
                                    <div className="flex items-center gap-5 justify-between">
                                        <UserProfile
                                            avatar=""
                                            name={name}
                                            subTitle={email}
                                        />
                                        <Button
                                            label="Remove"
                                            variant="link"
                                            color="error"
                                            onClick={() => {
                                                setInvites(prev =>
                                                    prev.filter(
                                                        item =>
                                                            !(
                                                                item.email ===
                                                                email
                                                            )
                                                    )
                                                );
                                            }}
                                        />
                                    </div>
                                ))}
                            </div>
                        )}
                        <div className="flex gap-3">
                            <Controller
                                control={form.control}
                                name="name"
                                rules={{
                                    required: true,
                                }}
                                render={({ field }) => {
                                    return (
                                        <TextInputField
                                            label="Full Name"
                                            placeholder="Enter full name"
                                            value={field.value}
                                            onChange={field.onChange}
                                            error={
                                                errors.name?.message ||
                                                (errors.name?.type ===
                                                "required"
                                                    ? "Required"
                                                    : "")
                                            }
                                            fullWidth
                                            className="flex-1"
                                        />
                                    );
                                }}
                            />
                            <Controller
                                control={form.control}
                                name="scope"
                                rules={{
                                    required: true,
                                }}
                                render={({ field }) => {
                                    return (
                                        <SelectInputField
                                            label="User Role"
                                            placeholder="Select user role"
                                            items={userRoles}
                                            renderItem={({ name }) => name}
                                            value={field.value}
                                            onChange={({ id, name }) =>
                                                field.onChange({ id, name })
                                            }
                                            error={
                                                errors.scope?.message ||
                                                (errors.scope?.type ===
                                                "required"
                                                    ? "Required"
                                                    : "")
                                            }
                                            fullWidth
                                            className="flex-1"
                                        />
                                    );
                                }}
                            />
                        </div>
                        <div className="flex flex-col gap-4">
                            <Controller
                                name="email"
                                control={form.control}
                                rules={{
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "Invalid email address",
                                    },
                                    required: true,
                                    validate: mail => {
                                        if (
                                            invites
                                                .map(({ email }) => email)
                                                .includes(mail)
                                        ) {
                                            return "The email is already added to the list.";
                                        }
                                    },
                                }}
                                render={({ field }) => (
                                    <MailInputField
                                        label="Email"
                                        fullWidth
                                        hideIcon
                                        placeholder="Enter invite participant's email"
                                        onChange={value => {
                                            field.onChange(value);
                                        }}
                                        value={field.value}
                                        error={
                                            errors.email?.message ||
                                            (errors.email?.type === "required"
                                                ? "Required"
                                                : "")
                                        }
                                    />
                                )}
                            />
                        </div>
                    </ModalBody>
                )}
            </div>
        </Modal>
    );
};

export default InviteUserModal;

const userRoles = [
    {
        id: MemberScope.ADMIN,
        name: "Admin",
    },
    {
        id: MemberScope.SALES_LEADER,
        name: "Sales Leader",
    },
    {
        id: MemberScope.SALES_REP,
        name: "Sales Rep",
    },
];
