import { Table } from "@tanstack/react-table";
import React, { FC } from "react";
import { Typography, Badge, Button } from "trinity-components-library";

interface TableHeaderProps {
    table: Table<any>;
    onAddUserClick?: () => void;
}

const TableHeader: FC<TableHeaderProps> = ({ table, onAddUserClick }) => {
    return (
        <div className="px-6 py-5 flex flex-wrap gap-5">
            <div className="flex flex-col gap-1 grow">
                <div className="flex items-center flex-1 gap-2">
                    <Typography size="lg" color="gray-900">
                        Users
                    </Typography>
                    <Badge
                        label={`${table.options.data.length} Users`}
                        color="success"
                    />
                </div>
                <Typography size="sm" color="gray-500">
                    Manage your team members and their account permissions here.
                </Typography>
            </div>
            <div className="flex gap-3 items-start">
                <Button
                    label="Download CSV"
                    icon="download-cloud-02"
                    variant="outlined"
                    color="gray"
                    onClick={() => {
                        const rows = table.getRowModel().rows;
                        const values = rows.map(row => {
                            const cells = row.getAllCells();
                            const values = cells.map(value => value.getValue());
                            return values;
                        });
                        const csvContent =
                            "data:text/csv;charset=utf-8,name,email,avatar,status,role,reviews,last login\n" +
                            values
                                .map(
                                    ([
                                        user,
                                        status,
                                        role,
                                        reviews,
                                        lastLogin,
                                    ]: any) => {
                                        return `${user.name},${user.email},${
                                            user.avatar
                                        },${status.label},${role},${reviews},"${
                                            lastLogin
                                                ? new Date(
                                                      lastLogin
                                                  ).toLocaleDateString(
                                                      "en-US",
                                                      {
                                                          month: "short",
                                                          hour: "2-digit",
                                                          hour12: true,
                                                          minute: "2-digit",
                                                          day: "numeric",
                                                          year: "numeric",
                                                      }
                                                  )
                                                : ""
                                        }"`;
                                    }
                                )
                                .join("\n");
                        var encodedUri = encodeURI(csvContent);
                        var link = document.createElement("a");
                        link.setAttribute("href", encodedUri);
                        link.setAttribute("download", "users.csv");
                        link.click();
                    }}
                />
                <Button label="Add user" icon="plus" onClick={onAddUserClick} />
            </div>
        </div>
    );
};

export default TableHeader;
