import { FC, useState } from "react";
import {
    Modal,
    Typography,
    Button,
    IconBadge,
    CircularProgress,
} from "trinity-components-library";
import { CSVLink } from "react-csv";
import useDeals from "../hooks/useDeals";
import getLoggedInCompanyId from "../../../libs/getLoggedInCompanyId";
import { Deal } from "../../../apis/types/deal";

type BulkScheduleReviewProps = {
    open: boolean;
    onClose: () => void;
    selectedDeals: Deal[];
    onScheduleComplete?: () => void;
};

const BulkScheduleReview: FC<BulkScheduleReviewProps> = ({
    open,
    onClose,
    selectedDeals,
    onScheduleComplete,
}) => {
    const [stage, setStage] = useState<number>(0);
    const { progress, bulkProgressReport, sendBulkReviewRequest } = useDeals(
        getLoggedInCompanyId() || ""
    );
    const circumference = 2 * Math.PI * 80;

    const handleClose = (callback?: Function) => {
        if (callback) {
            callback();
        }
        onClose();
    };

    const handleReview = () => {
        sendBulkReviewRequest(selectedDeals)
            .then(() => {
                setStage(1);
                onScheduleComplete?.();
            })
            .catch(() => {});
    };

    if (stage === 1) {
        return (
            <Modal open={open} onClose={() => handleClose()}>
                <div className="p-6 flex flex-col gap-8">
                    <div className="flex w-full">
                        <div className="flex items-center justify-center w-full">
                            <CircularProgress
                                circumference={circumference}
                                progress={progress}
                                radius={72}
                                className="h-52 -rotate-90"
                            />
                            <div className="absolute flex flex-col items-center">
                                <Typography
                                    type="display"
                                    size="xs"
                                    variant="p"
                                    color="gray-900"
                                    weight="semibold"
                                >
                                    {progress}%
                                </Typography>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-center text-center">
                        <Typography
                            type="text"
                            size="sm"
                            variant="p"
                            color="gray-900"
                            weight="medium"
                        >
                            Scheduling Reviews
                        </Typography>
                    </div>
                    <div className="grid grid-cols-3 gap-4">
                        <div className="flex flex-col border gap-2 border-gray-300 rounded-lg p-4 justify-center items-center">
                            <Typography
                                type="text"
                                size="2xl"
                                variant="p"
                                color="gray-900"
                                weight="medium"
                                className="text-d-xs"
                            >
                                {bulkProgressReport?.success?.length ?? 0}
                            </Typography>
                            <Typography
                                type="text"
                                size="sm"
                                variant="h4"
                                color="gray-500"
                                weight="medium"
                            >
                                Scheduled
                            </Typography>
                        </div>
                        <div className="flex flex-col gap-2 border border-gray-300 rounded-lg p-4 justify-center items-center">
                            <Typography
                                type="text"
                                size="2xl"
                                variant="p"
                                color="gray-900"
                                weight="medium"
                                className="text-d-xs"
                            >
                                {bulkProgressReport?.alreadyScheduled?.length ??
                                    0}
                            </Typography>
                            <Typography
                                type="text"
                                size="sm"
                                variant="h4"
                                color="gray-500"
                                weight="medium"
                            >
                                Already Scheduled
                            </Typography>
                        </div>
                        <div className="flex flex-col gap-2 border border-gray-300 rounded-lg p-4 justify-center items-center">
                            <Typography
                                type="text"
                                size="2xl"
                                variant="p"
                                color="gray-900"
                                weight="medium"
                                className="text-d-xs"
                            >
                                {bulkProgressReport?.failed?.length ?? 0}
                            </Typography>
                            <Typography
                                type="text"
                                size="sm"
                                variant="h4"
                                color="gray-500"
                                weight="medium"
                            >
                                Failed
                            </Typography>
                        </div>
                    </div>
                    {bulkProgressReport.inProgress.length === 0 &&
                        bulkProgressReport.failed.length > 0 && (
                            <div>
                                <Typography
                                    type="text"
                                    variant="h4"
                                    color="gray-500"
                                    size="sm"
                                >
                                    You've {bulkProgressReport?.failed?.length}{" "}
                                    failed opportunities,{" "}
                                    <span
                                        style={{
                                            color: "#FF3C3C",
                                            textDecoration: "underline",
                                            cursor: "pointer",
                                        }}
                                    >
                                        <CSVLink
                                            data={
                                                bulkProgressReport?.failed || [
                                                    {
                                                        id: "",
                                                        reason: "",
                                                    },
                                                ]
                                            }
                                            filename={
                                                "failed_opportunities_details.csv"
                                            }
                                        >
                                            View Details
                                        </CSVLink>
                                    </span>
                                </Typography>
                            </div>
                        )}
                    <div className="flex justify-center">
                        <Button
                            label={progress === 100 ? "Done" : "Cancel"}
                            color={progress === 100 ? "primary" : "gray"}
                            variant={
                                progress === 100 ? "contained" : "outlined"
                            }
                            className="py-2.5 px-8 w-full"
                            onClick={() => {
                                handleClose();
                            }}
                        />
                    </div>
                </div>
            </Modal>
        );
    }

    return (
        <Modal open={open} onClose={() => handleClose()}>
            <div className="p-6 w-[400px]">
                <div className="flex items-center justify-center">
                    <IconBadge
                        icon="check"
                        size="xl"
                        color="success"
                        className="!bg-success-100 !border-8 !border-success-50 !h-12 !w-12"
                    />
                </div>
                <Typography
                    size="lg"
                    weight="medium"
                    className="mt-5 text-center"
                >
                    {Object.keys(selectedDeals).length} Opportunities Selected
                </Typography>
                <Typography
                    size="sm"
                    weight="normal"
                    className="mt-2 text-center"
                    color="gray-500"
                >
                    This will schedule a digital win loss review for all
                    opportunities yet to be reviewed.
                </Typography>
                <div className="flex justify-around w-full gap-3 mt-8">
                    <Button
                        label="Cancel"
                        color="gray"
                        variant="outlined"
                        fullWidth
                        onClick={() => handleClose()}
                    />
                    <Button
                        label="Schedule Reviews"
                        color="primary"
                        variant="contained"
                        fullWidth
                        onClick={handleReview}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default BulkScheduleReview;
