import { Typography, Icon } from "trinity-components-library";
import React, { FC, useState } from "react";
import { UserAvatar } from "../../../components";

interface ReviewLinkProps {
    reviewerName: string;
    reviewId: string;
}

const ReviewLink: FC<ReviewLinkProps> = ({ reviewerName, reviewId }) => {
    const [copied, setCopied] = useState(false);

    const handleCopy = (reviewId: string) => {
        setCopied(true);
        navigator.clipboard.writeText(
            `${process.env.REACT_APP_REVIEW_APP_URL}/review/${reviewId}`
        );
    };
    return (
        <div className="flex">
            <div
                className={`flex w-full border ${
                    copied ? "border-primary-600" : "border-gray-200"
                } rounded-lg`}
            >
                <div
                    className={`flex gap-3 p-4 rounded-lg flex-grow ${
                        copied ? "bg-primary-50" : ""
                    }`}
                >
                    <UserAvatar src="" />
                    <div className="flex-grow flex-col gap-[2px]">
                        <Typography
                            type="text"
                            variant="h6"
                            size="md"
                            weight="medium"
                            color="gray-700"
                        >
                            {reviewerName}
                        </Typography>
                        <Typography
                            type="text"
                            variant="h6"
                            size="md"
                            color={copied ? "primary-600" : "gray-500"}
                        >
                            {`${process.env.REACT_APP_REVIEW_APP_URL}/review/${reviewId}`}
                        </Typography>
                    </div>
                </div>
                <div
                    className={`flex justify-center items-center py-[8px] px-[14px] gap-2 border-l rounded-tr-lg rounded-br-lg shadow-sm hover:cursor-pointer  ${
                        copied
                            ? "bg-primary-50 border-primary-600"
                            : "border-gray-300"
                    }`}
                    onClick={() => handleCopy(reviewId)}
                >
                    <Icon
                        name="copy-01"
                        color={copied ? "primary-600" : "gray-700"}
                    />
                    <Typography
                        type="text"
                        variant="h6"
                        size="sm"
                        weight="medium"
                        color={copied ? "primary-600" : "gray-500"}
                    >
                        {copied ? "Copied" : "Copy"}
                    </Typography>
                </div>
            </div>
        </div>
    );
};

export default ReviewLink;
