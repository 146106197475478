import { useQuery, useQueryClient, UseQueryOptions } from "react-query";
import axios from "axios";
import Axios from "../axios-instance";
import { UseQueryReturnType } from "./api";

export const useDownloadAttachmentQuery = (
    {
        url,
        mimetype,
        withAuthorization = false,
    }: {
        url: string;
        mimetype?: string;
        withAuthorization?: boolean;
    },
    options: Omit<
        UseQueryOptions<string, unknown, string, string[]>,
        "queryKey" | "queryFn"
    > = {}
): UseQueryReturnType => {
    const queryKey = ["file", url];
    const queryClient = useQueryClient();
    const invalidate = () => queryClient.invalidateQueries(queryKey);

    const result = useQuery(
        queryKey,
        async () => {
            const response = await (withAuthorization ? Axios : axios).get(url);
            const finalMimetype =
                mimetype ||
                response.headers["x-amz-meta-mimetype"] ||
                mimetype ||
                response.headers["content-type"] ||
                "application/octet-stream";
            return typeof response.data === "string" &&
                response.data.startsWith("data:")
                ? response.data
                : `data:${finalMimetype};base64,${response.data}`;
        },
        { ...options, refetchOnWindowFocus: false }
    );
    return { result, invalidate };
};
