import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ColumnDef, ColumnSort, SortingState } from "@tanstack/react-table";
import {
    CalendarRange,
    parseCalendarDate,
    Table,
    Badge,
    ProgressBar,
    Typography,
    Button,
    Pagination,
} from "trinity-components-library";
import { DealsSortBy, useDealsQuery } from "../../apis/deal.api";
import { SecondaryColorType } from "../../types/colors";
import TableHeader from "./components/table-header";
import getLoggedInCompanyId from "../../libs/getLoggedInCompanyId";
import UserProfile from "../../components/user-profile/user-profile";
import { isValid, min } from "date-fns";

interface ReviewTableProps {
    dateRange: CalendarRange;
}

const ReviewTable: FC<ReviewTableProps> = ({ dateRange }) => {
    const [status, setStatus] =
        useState<"complete" | "incomplete" | "all">("complete");
    const [pageIndex, setPageIndex] = useState(0);
    const navigate = useNavigate();
    const defaultColumnSort: ColumnSort = {
        id: "dateTime",
        desc: true,
    };
    const [sorting, setSorting] = useState<SortingState>([defaultColumnSort]);
    const sortIdToSortByMap: { [key: string]: DealsSortBy } = {
        company: "companyName",
        type: "type",
        dateTime: "creationTime",
        reviewRating: "reviewRating",
    };
    const [columnSort] = sorting;
    const defaultPageSize = 10;
    const companyId = getLoggedInCompanyId() ?? "";
    const { result: deals } = useDealsQuery({
        filterBy: "reviews",
        reviewStatus: status === "all" ? undefined : status,
        page: pageIndex + 1,
        limit: defaultPageSize,
        sortBy: columnSort ? sortIdToSortByMap[columnSort.id] : undefined,
        sortOrder: columnSort ? (columnSort.desc ? "DESC" : "ASC") : undefined,
        companyId,
        startDate: parseCalendarDate(dateRange.from).toISOString(),
        endDate: parseCalendarDate(dateRange.to).toISOString(),
    });

    const data = (deals.isSuccess ? deals.data.items : []).map(
        ({ review_type, contact, reviews, id, name }: any) => {
            const completedAtArray = reviews
                .map(
                    ({ completed_at }: { completed_at: string }) =>
                        new Date(completed_at)
                )
                .filter((date: Date) => isValid(date));
            const updatedAtArray = reviews
                .map(
                    ({ updated_at }: { updated_at: string }) =>
                        new Date(updated_at)
                )
                .filter((date: Date) => isValid(date));

            const earliestCompletedAt = min(completedAtArray);
            const earliestUpdatedAt = min(updatedAtArray);
            const dateTime = isValid(earliestCompletedAt)
                ? earliestCompletedAt.toISOString()
                : isValid(earliestUpdatedAt)
                ? earliestUpdatedAt.toISOString()
                : "";
            return {
                dateTime,
                company: {
                    name: contact.company_name,
                    avatar: contact.logo,
                    subTitle: name,
                },
                type: review_type,
                reviewRating:
                    reviews && reviews.length
                        ? reviews.reduce(
                              (
                                  acc: number,
                                  { review_rating }: { review_rating: number }
                              ) => acc + Number(review_rating ?? 0),
                              0
                          ) / reviews.length
                        : 0,
                viewReportAction: {
                    onClick: () => navigate(`/reports/win-loss/${id}`),
                },
            };
        }
    );
    // console.log(deals.isSuccess && data);
    const totalItems = deals.isSuccess
        ? deals.data?.meta?.totalItems
        : data.length;
    return (
        <div>
            <Table
                data={data}
                columns={columns as any}
                enableSorting
                defaultSorting={[defaultColumnSort]}
                sorting={sorting}
                onSortingChange={newSorting => {
                    setSorting(newSorting);
                }}
                header={table => (
                    <TableHeader
                        table={table as any}
                        reviewStatus={status}
                        setReviewStatus={status => {
                            setPageIndex(0);
                            setStatus(status);
                        }}
                        totalItems={totalItems}
                    />
                )}
                footer={() => (
                    <div className="px-6 py-4">
                        <Pagination
                            page={pageIndex}
                            count={Math.ceil(totalItems / defaultPageSize)}
                            onChange={page => setPageIndex(page)}
                        />
                    </div>
                )}
            />
        </div>
    );
};

export default ReviewTable;

const reviewTypes = {
    DIGITAL: {
        label: "Digital",
        color: "blue-light",
    },
    MANUAL: {
        label: "Consulting",
        color: "purple",
    },
    NOT_YET_REVIEWED: {
        label: "Not Yet Reviewed",
        color: "blue-light",
    },
    NOT_TO_BE_REVIEWED: {
        label: "Not To Be Reviewed",
        color: "purple",
    },
};

const columns: ColumnDef<any>[] = [
    {
        id: "company",
        header: "Company",
        accessorKey: "company",
        sortingFn: (row1: any, row2: any, columnId: any) => {
            const company1 = row1.getValue(columnId) ?? { name: "" };
            const company2 = row2.getValue(columnId) ?? { name: "" };
            const a = company1.name.toLowerCase();
            const b = company2.name.toLowerCase();
            return a > b ? 1 : a < b ? -1 : 0;
        },
        cell: ({ getValue }: any) => (
            <div className="w-52">
                <UserProfile
                    avatar={getValue().avatar}
                    name={getValue().name}
                    subTitle={getValue().subTitle}
                    placeholderType="placeholder"
                    placeholderIcon="building-05"
                />
            </div>
        ),
    },
    {
        id: "type",
        header: "Type",
        accessorKey: "type",
        sortingFn: (firstRow: any, secondRow: any, columnId: any) => {
            const firstType = firstRow.getValue(columnId) ?? "";
            const secondType = secondRow.getValue(columnId) ?? "";
            const a = firstType.toLowerCase();
            const b = secondType.toLowerCase();
            return a > b ? 1 : a < b ? -1 : 0;
        },
        cell: ({ getValue }: any) => {
            const reviewType =
                reviewTypes[getValue() as keyof typeof reviewTypes];
            return (
                <div className="w-36">
                    <Badge
                        label={reviewType.label as string}
                        color={reviewType.color as SecondaryColorType}
                    />
                </div>
            );
        },
    },
    {
        id: "reviewRating",
        header: "Review Rating",
        accessorKey: "reviewRating",
        cell: ({ getValue }: any) => {
            return (
                <ProgressBar
                    className="min-w-[192px]"
                    value={getValue() as number}
                    showPercentage
                    color="primary-900"
                />
            );
        },
    },
    {
        id: "dateTime",
        header: "Date & Time",
        accessorFn: (row: any) => new Date(row.dateTime).getTime(),
        cell: ({ getValue }: any) => {
            const d = new Date(getValue() as string);
            return (
                <div className="w-40">
                    {isValid(d) ? (
                        <>
                            <Typography
                                className="whitespace-nowrap"
                                size="sm"
                                color="gray-900"
                            >{`${d.toLocaleDateString("en-US", {
                                month: "short",
                            })} ${d.getDate()}, ${d.getFullYear()}`}</Typography>
                            <Typography
                                className="whitespace-nowrap"
                                size="sm"
                                color="gray-500"
                            >{`${d.toLocaleString("en-US", {
                                hour: "2-digit",
                                hour12: true,
                                minute: "2-digit",
                            })}`}</Typography>
                        </>
                    ) : (
                        <div className="flex justify-center">
                            <Typography>-</Typography>
                        </div>
                    )}
                </div>
            );
        },
    },
    {
        id: "viewReportAction",
        accessorKey: "viewReportAction",
        header: "",
        cell: ({ getValue }: any) => {
            const { onClick } = getValue() ?? {};
            return (
                <Button
                    label="View Report"
                    icon="file-07"
                    variant="outlined"
                    color="gray"
                    onClick={onClick}
                />
            );
        },
    },
];
