import { Controller, useForm } from "react-hook-form";
import {
    Modal,
    IconBadge,
    Typography,
    MailInputField,
    Button,
} from "trinity-components-library";
import { useShareReport } from "../../../apis/share-report.api";

type ShareReportModalProps = {
    dealId: string;
    open: boolean;
    setOpen: (arg0: boolean) => void;
};

const ShareReportModal = ({ dealId, open, setOpen }: ShareReportModalProps) => {
    const form = useForm({
        defaultValues: {
            email: "",
            serverError: "",
        },
        mode: "onChange",
        criteriaMode: "all",
    });
    const {
        formState: { errors, isDirty, isValid },
    } = form;

    const shareUser = useShareReport();
    const handleClose = (callback?: Function) => {
        if (callback) callback();
        form.reset();
        setOpen(false);
    };

    const handleSubmit = form.handleSubmit(async ({ email }) => {
        const payload = { senderEmail: email, dealId };

        try {
            await shareUser.mutateAsync(payload);
            handleClose();
        } catch (err: any) {
            if (err?.response?.data?.error?.description) {
                form.setError("serverError", {
                    message: err?.response?.data?.error?.description,
                    type: "value",
                });
            }
        }
    });

    return (
        <Modal open={open} onClose={() => handleClose()}>
            <div className="p-6 w-[400px]">
                <div className="flex items-center justify-center">
                    <IconBadge
                        icon="share-06"
                        size={"xl"}
                        color="primary"
                        className="!bg-primary-100 !border-8 !border-primary-50 !h-12 !w-12"
                    />
                </div>
                <Typography
                    size="lg"
                    weight="medium"
                    className="mt-5 text-center"
                >
                    Share Report
                </Typography>
                <Typography
                    size="sm"
                    weight="normal"
                    className="mt-2 text-center"
                    color="gray-500"
                >
                    Send the PDF version of this Report by entering the
                    recipients email address below.
                </Typography>
                <div className="mt-5">
                    <Controller
                        name="email"
                        control={form.control}
                        rules={{
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "Invalid email address",
                            },
                            required: true,
                        }}
                        render={({ field }) => (
                            <MailInputField
                                label="Email address"
                                fullWidth
                                placeholder="you@trinity.com"
                                onChange={field.onChange}
                                value={field.value}
                                error={
                                    errors.email?.message ||
                                    (errors.email?.type === "required"
                                        ? "Required"
                                        : "")
                                }
                            />
                        )}
                    />
                </div>
                <div className="flex justify-around w-full gap-3 mt-8">
                    <Button
                        label="Cancel"
                        color="gray"
                        variant="outlined"
                        fullWidth
                        onClick={() => handleClose()}
                    />
                    <Button
                        label="Share"
                        color="primary"
                        variant="contained"
                        fullWidth
                        disabled={!isValid || !isDirty || shareUser.isLoading}
                        onClick={handleSubmit}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default ShareReportModal;
