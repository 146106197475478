import clsx from "clsx";
import { FC, useState } from "react";
import { Typography } from "trinity-components-library";
import { usePatchDealMutation } from "../../../apis/deal.api";
import { Deal } from "../../../apis/types/deal";
import { ReviewType } from "../../../apis/types/review-type";
import {
    CustomTemplate,
    GlobalTemplate,
    TemplateType,
} from "../../../apis/types/template";
import TemplateCard, {
    TemplateCardProps,
} from "../../../components/template-card/template-card";
import getLoggedInCompanyId from "../../../libs/getLoggedInCompanyId";
import useGetDefaultTemplates from "../hooks/useGetDefaultTemplates";

interface ScheduleReviewModalStartStateProps {
    deal?: Deal | null;
    onTemplateSelect: (value: {
        templateId: string;
        reviewType: ReviewType;
    }) => void;
    onClose?: () => void;
    onError?: () => void;
}

export const ScheduleReviewModalStartState: FC<ScheduleReviewModalStartStateProps> =
    ({ deal, onTemplateSelect, onClose, onError }) => {
        const companyId = getLoggedInCompanyId() ?? "";
        const isRescheduling =
            deal?.review_type === ReviewType.DIGITAL ||
            deal?.review_type === ReviewType.MANUAL;
        const [tab, setTab] = useState<"digital" | "consulting">(
            isRescheduling
                ? deal?.review_type === ReviewType.MANUAL
                    ? "consulting"
                    : "digital"
                : "digital"
        );
        const defaultTemplates = useGetDefaultTemplates({ companyId });
        const patchDealMutation = usePatchDealMutation();

        const getTemplateCardPropsFromTemplate = (
            template?:
                | (CustomTemplate & { isCustom: true })
                | (GlobalTemplate & { isCustom: false })
        ): TemplateCardProps => {
            const {
                activated: isActivated = false,
                id = "",
                name = "",
                type = "digital",
                templateCategory: {
                    id: categoryId,
                    referenceKey: categoryType,
                    name: categoryName,
                } = { id: "", referenceKey: "win_loss", name: "Win Loss" },
            } = template ?? {};
            const primaryColor =
                template?.isCustom && template?.branding?.primary_colors?.length
                    ? template.branding.primary_colors[0]
                    : "#000000";
            return {
                categoryId,
                categoryType: categoryType as
                    | "win_loss"
                    | "value_realisation"
                    | "exit_interview"
                    | "market_research",
                companyLogo: template?.isCustom
                    ? template?.branding?.logo_url ?? ""
                    : "",
                id,
                isActivated,
                isCustom: template?.isCustom ?? false,
                isDefault: true,
                name: name || categoryName,
                primaryColor,
                type: type as TemplateType.DIGITAL | TemplateType.CONSULTING,
                expanded: false,
                description: "",
                hideActiveToggle: true,
                className: "w-[370px]",
            };
        };

        function checkForUndefinedDefaultTemplates(obj: any): string[] {
            let templateArray = [];
            for (let key in obj) {
                if (obj[key] === undefined) {
                    templateArray.push(key);
                }
            }
            return templateArray;
        }
        const checkForUndefinedDefaultTemplate =
            checkForUndefinedDefaultTemplates(defaultTemplates.digital);
        const reportCardStyle =
            checkForUndefinedDefaultTemplate.length === 0
                ? "grid-rows-2"
                : "grid-rows-1";

        const onContactUsClick = async () => {
            try {
                if (!isRescheduling) {
                    await patchDealMutation.mutateAsync({
                        dealId: deal?.id ?? "",
                        reviewType: ReviewType.MANUAL,
                    });
                }
                window.open(`mailto:cian@usetrinity.com`);
                onClose?.();
            } catch {
                onError?.();
            }
        };

        const changeTab = (val: "digital" | "consulting") => {
            if (!isRescheduling) {
                setTab(val);
            }
        };

        return (
            <div className="w-[754px] flex flex-col">
                <div
                    className={clsx(
                        "flex rounded-lg p-[6px] gap-3 mb-[10px]",
                        isRescheduling ? "bg-gray-100" : "bg-gray-50"
                    )}
                >
                    <div
                        className={`flex-1 text-center py-[10px] px-[14px] rounded-md ${
                            tab === "digital"
                                ? isRescheduling
                                    ? "bg-gray-50 shadow-sm"
                                    : "bg-white shadow-sm"
                                : isRescheduling
                                ? "cursor-not-allowed"
                                : "cursor-pointer"
                        }`}
                        onClick={() => changeTab("digital")}
                    >
                        <Typography
                            type="text"
                            variant="h6"
                            size="md"
                            color={tab === "digital" ? "gray-700" : "gray-500"}
                            weight="medium"
                        >
                            Digital
                        </Typography>
                    </div>
                    <div
                        className={`flex-1 text-center py-[10px] px-[14px] rounded-md ${
                            tab === "consulting"
                                ? isRescheduling
                                    ? "bg-gray-50 shadow-sm"
                                    : "bg-white shadow-sm"
                                : isRescheduling
                                ? "cursor-not-allowed"
                                : "cursor-pointer"
                        }`}
                        onClick={() => changeTab("consulting")}
                    >
                        <Typography
                            type="text"
                            variant="h6"
                            size="md"
                            color={
                                tab === "consulting" ? "gray-700" : "gray-500"
                            }
                            weight="medium"
                        >
                            Consulting
                        </Typography>
                    </div>
                </div>
                {defaultTemplates.customTemplatesResult.isLoading ||
                defaultTemplates.globalTemplatesResult.isLoading ? (
                    <div className="flex justify-center items-center h-[354px]">
                        <Typography>Loading...</Typography>
                    </div>
                ) : tab === "digital" ? (
                    <div className={`grid ${reportCardStyle} gap-3`}>
                        <div className="grid grid-cols-2 gap-3">
                            {!checkForUndefinedDefaultTemplate.includes(
                                "winLoss"
                            ) && (
                                <TemplateCard
                                    {...getTemplateCardPropsFromTemplate(
                                        defaultTemplates.digital.winLoss
                                    )}
                                    showUseButton
                                    onUseClick={() =>
                                        onTemplateSelect({
                                            templateId:
                                                defaultTemplates.digital.winLoss
                                                    ?.id ?? "",
                                            reviewType: ReviewType.DIGITAL,
                                        })
                                    }
                                    description="Provides an in-depth understanding of the feedback and learning's provided by prospects against a variety of key criteria via a digital review."
                                />
                            )}
                            {!checkForUndefinedDefaultTemplate.includes(
                                "valueRealisation"
                            ) && (
                                <TemplateCard
                                    {...getTemplateCardPropsFromTemplate(
                                        defaultTemplates.digital
                                            .valueRealisation
                                    )}
                                    showUseButton
                                    onUseClick={() =>
                                        onTemplateSelect({
                                            templateId:
                                                defaultTemplates.digital
                                                    .valueRealisation?.id ?? "",
                                            reviewType: ReviewType.DIGITAL,
                                        })
                                    }
                                    description="Provides an in-depth understanding of the feedback and learning's provided by prospects against a variety of key criteria via a digital review."
                                />
                            )}
                        </div>
                        {!checkForUndefinedDefaultTemplate.includes(
                            "marketResearch"
                        ) && (
                            <div className="grid grid-cols-2 gap-3">
                                <TemplateCard
                                    {...getTemplateCardPropsFromTemplate(
                                        defaultTemplates.digital.marketResearch
                                    )}
                                    showUseButton
                                    onUseClick={() =>
                                        onTemplateSelect({
                                            templateId:
                                                defaultTemplates.digital
                                                    .marketResearch?.id ?? "",
                                            reviewType: ReviewType.DIGITAL,
                                        })
                                    }
                                    description="Provides an in-depth understanding of the feedback and learning's provided by prospects against a variety of key criteria via a digital review."
                                />
                            </div>
                        )}
                        {!checkForUndefinedDefaultTemplate.includes(
                            "exitInterview"
                        ) && (
                            <TemplateCard
                                {...getTemplateCardPropsFromTemplate(
                                    defaultTemplates.digital.exitInterview
                                )}
                                showUseButton
                                onUseClick={() =>
                                    onTemplateSelect({
                                        templateId:
                                            defaultTemplates.digital
                                                .exitInterview?.id ?? "",
                                        reviewType: ReviewType.DIGITAL,
                                    })
                                }
                                description="Provides an in-depth understanding of the feedback and learning's provided by prospects against a variety of key criteria via a digital review."
                            />
                        )}
                    </div>
                ) : (
                    <div className="grid grid-rows-2 gap-3">
                        <div className="grid grid-cols-2 gap-3">
                            <TemplateCard
                                {...getTemplateCardPropsFromTemplate(
                                    defaultTemplates.consulting.winLoss
                                )}
                                showContactUsButton
                                onContactUsClick={onContactUsClick}
                                disableContactUs={patchDealMutation.isLoading}
                                description="Provides an in-depth understanding of the feedback and learning's provided by prospects against a variety of key criteria via a digital review."
                            />
                            <TemplateCard
                                {...getTemplateCardPropsFromTemplate(
                                    defaultTemplates.consulting.valueRealisation
                                )}
                                showContactUsButton
                                onContactUsClick={onContactUsClick}
                                disableContactUs={patchDealMutation.isLoading}
                                description="Provides an in-depth understanding of the feedback and learning's provided by prospects against a variety of key criteria via a digital review."
                            />
                        </div>
                        <div className="grid grid-cols-2 gap-3">
                            <TemplateCard
                                {...getTemplateCardPropsFromTemplate(
                                    defaultTemplates.consulting.marketResearch
                                )}
                                showContactUsButton
                                onContactUsClick={onContactUsClick}
                                disableContactUs={patchDealMutation.isLoading}
                                description="Provides an in-depth understanding of the feedback and learning's provided by prospects against a variety of key criteria via a digital review."
                            />
                        </div>
                        <div className="grid grid-cols-2 gap-3">
                            <TemplateCard
                                {...getTemplateCardPropsFromTemplate(
                                    defaultTemplates.consulting.exitInterview
                                )}
                                showContactUsButton
                                onContactUsClick={onContactUsClick}
                                disableContactUs={patchDealMutation.isLoading}
                                description="Provides an in-depth understanding of the feedback and learning's provided by prospects against a variety of key criteria via a digital review."
                            />
                        </div>
                    </div>
                )}
            </div>
        );
    };

export default ScheduleReviewModalStartState;
