import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
    Typography,
    PasswordInputField,
    Button,
    TextInputField,
} from "trinity-components-library";
import { TrinityLogoIcon } from "../../assets/images";
import { useAuth } from "../../libs/auth/auth";
import PasswordRules from "../components/password-rules/password-rules";

export const CreatePassword = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [searchParams] = useSearchParams();
    const email = searchParams.get("email") ?? "";
    const form = useForm({
        defaultValues: {
            verificationCode: "",
            newPassword: "",
            confirmPassword: "",
            serverError: "",
        },
        mode: "onChange",
        criteriaMode: "all",
    });
    const {
        formState: { errors, isValid, isDirty },
    } = form;

    const navigate = useNavigate();

    const { confirmPassword, forgotPassword } = useAuth();

    const onSubmit = form.handleSubmit(
        async ({ verificationCode, newPassword }) => {
            setIsLoading(true);
            try {
                await confirmPassword(email, verificationCode, newPassword);
                navigate("/login");
            } catch (err: any) {
                if (err?.code === "CodeMismatchException") {
                    form.setError("verificationCode", {
                        message: "Invalid code.",
                        type: "value",
                    });
                } else if (err?.message) {
                    form.setError("serverError", {
                        type: "value",
                        message: err.message,
                    });
                }
            } finally {
                setIsLoading(false);
            }
        }
    );

    return (
        <div className="w-full h-full m-0 p-0 flex">
            <div className="flex-1 w-full h-full overflow-auto">
                <div className="flex flex-col justify-center items-center my-24 m-auto content-center">
                    <div className="flex flex-col justify-center items-center">
                        <TrinityLogoIcon className="w-32" />
                    </div>
                    <div className="flex flex-col items-center gap-3 mt-8">
                        <Typography
                            variant="h1"
                            size="md"
                            type="display"
                            weight="bold"
                        >
                            Create new password
                        </Typography>
                        <Typography
                            variant="p"
                            size="md"
                            weight="normal"
                            color="gray-800"
                            className="text-center w-[360px]"
                        >
                            Please enter the verification code we emailed to{" "}
                            {email}
                        </Typography>
                    </div>
                    <div className="w-[360px]">
                        <div className="flex flex-col gap-5 mt-8 mb-3">
                            <Controller
                                name="verificationCode"
                                control={form.control}
                                rules={{
                                    required: true,
                                }}
                                render={({ field }) => (
                                    <div className="flex flex-col gap-1.5">
                                        <TextInputField
                                            label="Verification Code"
                                            fullWidth
                                            placeholder="Enter the verification code"
                                            onChange={value => {
                                                field.onChange(value);
                                                form.clearErrors("serverError");
                                            }}
                                            value={field.value}
                                            hasError={!!errors.verificationCode}
                                        />
                                        {errors.verificationCode &&
                                            (errors.verificationCode.type ===
                                            "required" ? (
                                                <Typography
                                                    color="error-500"
                                                    size="sm"
                                                >
                                                    Required
                                                </Typography>
                                            ) : (
                                                <Typography
                                                    color="error-500"
                                                    size="sm"
                                                >
                                                    Wrong code, try again or{" "}
                                                    <u
                                                        className="cursor-pointer"
                                                        onClick={() => {
                                                            forgotPassword(
                                                                email
                                                            );
                                                        }}
                                                    >
                                                        resend code
                                                    </u>
                                                </Typography>
                                            ))}
                                    </div>
                                )}
                            />
                            <Controller
                                name="newPassword"
                                control={form.control}
                                rules={{
                                    minLength: 8,
                                    pattern:
                                        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
                                    required: true,
                                }}
                                render={({ field, fieldState }) => {
                                    const password = field.value;
                                    const isPasswordDirty = fieldState.isDirty;
                                    return (
                                        <div>
                                            <PasswordInputField
                                                label="New Password"
                                                fullWidth
                                                placeholder="Enter a new password"
                                                onChange={value => {
                                                    field.onChange(value);
                                                    form.clearErrors(
                                                        "serverError"
                                                    );
                                                }}
                                                value={password}
                                                hasError={
                                                    isPasswordDirty &&
                                                    fieldState.invalid
                                                }
                                            />
                                            {isPasswordDirty && (
                                                <div className="mt-3">
                                                    <PasswordRules
                                                        password={field.value}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    );
                                }}
                            />
                            <Controller
                                name="confirmPassword"
                                control={form.control}
                                rules={{
                                    required: true,
                                    validate: pass => {
                                        if (
                                            form.getValues().newPassword !==
                                            pass
                                        ) {
                                            return "Your passwords do not match";
                                        }
                                    },
                                }}
                                render={({ field }) => (
                                    <PasswordInputField
                                        label="Confirm New Password"
                                        fullWidth
                                        placeholder="Reenter the new password"
                                        onChange={value => {
                                            field.onChange(value);
                                            form.clearErrors("serverError");
                                        }}
                                        value={field.value}
                                        error={
                                            errors.confirmPassword?.message ||
                                            (errors.confirmPassword?.type ===
                                            "required"
                                                ? "Required"
                                                : "")
                                        }
                                    />
                                )}
                            />
                            <Controller
                                name="serverError"
                                control={form.control}
                                render={({ fieldState }) => (
                                    <Typography size="sm" color="error-600">
                                        {fieldState.error?.message}
                                    </Typography>
                                )}
                            />
                        </div>
                        <Button
                            color="primary"
                            label={
                                isLoading
                                    ? "Resetting Password"
                                    : "Reset Password"
                            }
                            size="lg"
                            disabled={!isValid || !isDirty || isLoading}
                            onClick={onSubmit}
                            fullWidth
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreatePassword;
