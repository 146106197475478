import { useMutation, useQuery, useQueryClient } from "react-query";
import Axios from "../axios-instance";
import { UseQueryReturnType } from "./api";

interface UseGetBrandingQueryParams {
    companyId: string;
}

interface GetBrandingResponse {
    id: string;
    company_id?: string;
    primary_colors?: string[];
    secondary_colors?: string[];
    logo_url?: string | null;
    logo_file_name?: string | null;
    review_welcome_text?: string | null;
}

export const useGetCompanyBrandingQuery = ({
    companyId,
}: UseGetBrandingQueryParams): UseQueryReturnType<GetBrandingResponse> => {
    const queryKey = ["company-branding", companyId];
    const queryClient = useQueryClient();
    const invalidate = () => queryClient.invalidateQueries(queryKey);
    const result = useQuery(
        queryKey,
        async () => {
            const resp = await Axios.get(`/main/company/${companyId}/branding`);
            return resp.data;
        },
        { refetchOnWindowFocus: false }
    );
    return {
        result,
        invalidate,
    };
};

interface UsePatchCompanyBrandingMutationCallbackParams {
    companyId: string;
    primaryColors?: string[];
    secondaryColors?: string[];
    logoUrl?: string | null;
}

export const usePatchCompanyBrandingMutation = () => {
    return useMutation(
        async ({
            companyId,
            primaryColors,
            secondaryColors,
            logoUrl,
        }: UsePatchCompanyBrandingMutationCallbackParams) => {
            await Axios.patch(`/main/company/${companyId}/branding`, {
                primaryColors,
                secondaryColors,
                logoUrl,
            });
        }
    );
};

export const usePatchCompanyLogoMutation = () => {
    return useMutation(
        async ({
            companyId,
            logoFile,
        }: {
            companyId: string;
            logoFile: File;
        }) => {
            const formData = new FormData();
            formData.append("file", logoFile);
            await Axios.patch(
                `/main/company/${companyId}/branding/upload`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
        }
    );
};
