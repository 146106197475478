import { Typography } from "trinity-components-library";
import clsx from "clsx";
import { FC } from "react";

interface RadioCardProps {
    checked: boolean;
    onChange: () => void;
    title: string;
    description?: string;
    className?: string;
}

export const RadioCard: FC<RadioCardProps> = ({
    checked,
    onChange,
    title,
    description,
    className,
}) => {
    return (
        <div
            className={clsx(
                "px-6 py-4 flex gap-2 items-center rounded-lg border cursor-pointer",
                checked
                    ? "bg-primary-50 border-primary-600"
                    : "bg-white border-gray-200",
                className
            )}
            onClick={onChange}
        >
            {checked ? (
                <svg
                    width="16"
                    height="16"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect
                        x="0.5"
                        y="0.5"
                        width="19"
                        height="19"
                        rx="9.5"
                        className="fill-gray-25"
                    />
                    <circle
                        cx="10"
                        cy="10"
                        r="4"
                        className="fill-primary-600"
                    />
                    <rect
                        x="0.5"
                        y="0.5"
                        width="19"
                        height="19"
                        rx="9.5"
                        className="stroke-primary-600"
                    />
                </svg>
            ) : (
                <svg
                    width="16"
                    height="16"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect
                        x="0.5"
                        y="0.5"
                        width="19"
                        height="19"
                        rx="9.5"
                        fill="white"
                    />
                    <rect
                        x="0.5"
                        y="0.5"
                        width="19"
                        height="19"
                        rx="9.5"
                        stroke="#D0D5DD"
                    />
                </svg>
            )}

            <div>
                <Typography
                    size="sm"
                    color={checked ? "primary-800" : "gray-800"}
                >
                    {title}
                </Typography>
                {description && (
                    <Typography
                        size="sm"
                        color={checked ? "primary-600" : "gray-600"}
                    >
                        {description}
                    </Typography>
                )}
            </div>
        </div>
    );
};

export default RadioCard;
