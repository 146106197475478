import { Routes, Route, Outlet } from "react-router-dom";
import Home from "./pages/home";
import Root from "./pages/root/Root";
import Settings from "./pages/settings";
import CompanyProfileForm from "./sections/company-profile-form/company-profile-form";
import EmptySection from "./components/empty-section/empty-section";
import UserTable from "./sections/user-table/user-table";
import Opportunities from "./pages/opportunities";
import Login from "./pages/login";
import Signup from "./pages/signup";
import RootCheck from "./routes/components/root-check";
import AuthCheck from "./routes/components/auth-check";
import Onboarding from "./pages/onboarding/onboarding";
import CreateCompanyForm from "./sections/create-company-form/create-company-form";
import { EmailVerification } from "./pages/email-verification";
import InviteTeam from "./sections/invite-team/invite-team";
import ConnectCrm from "./sections/connect-crm/connect-crm";
import SalesforceHook from "./sections/connect-crm/sales-force-hook";
import { Reports } from "./pages/reports";
import ReportInfo from "./pages/report-info/report-info";
import ComingSoon from "./pages/components/coming-soon";
import { Templates } from "./pages/templates";
import SelectCompany from "./pages/select-company/SelectCompany";
import TemplateInfo from "./pages/template-info/template-info";
import { ProfileSettings } from "./pages/profile-settings";
import { ResetPassword } from "./pages/reset-password";
import { CreatePassword } from "./pages/create-password";
import OnboardingCheck from "./routes/components/onboarding-check";
import { TemplateCategoryKey } from "./apis/types/template";
import Integrations from "./sections/integrations/integrations";

function App() {
    return (
        <Routes>
            <Route
                path="/"
                element={
                    <RootCheck>
                        <Root />
                    </RootCheck>
                }
            >
                <Route index element={<Home />} />
                <Route path="settings" element={<Settings />}>
                    <Route index element={<CompanyProfileForm />} />
                    <Route path="general" element={<CompanyProfileForm />} />
                    <Route path="users" element={<UserTable />} />
                    <Route path="integrations" element={<Integrations />} />
                    <Route path="*" element={<EmptySection />} />
                </Route>
                <Route path="profile-settings" element={<ProfileSettings />} />
                <Route path="opportunities">
                    <Route index element={<Opportunities />} />
                    <Route path="scheduled" element={<Opportunities />} />
                    <Route
                        path="unscheduled"
                        element={<Opportunities status="unscheduled" />}
                    />
                    <Route
                        path="all"
                        element={<Opportunities status="all" />}
                    />
                </Route>
                <Route path="reports">
                    <Route
                        index
                        element={
                            <Reports
                                templateCategory={TemplateCategoryKey.WIN_LOSS}
                            />
                        }
                    />
                    <Route
                        path="win-loss"
                        element={
                            <Reports
                                templateCategory={TemplateCategoryKey.WIN_LOSS}
                            />
                        }
                    />
                    <Route
                        path="win-loss/:id"
                        element={
                            <ReportInfo
                                templateCategory={TemplateCategoryKey.WIN_LOSS}
                            />
                        }
                    />
                    <Route
                        path="value-realisation"
                        element={
                            <Reports
                                templateCategory={
                                    TemplateCategoryKey.VALUE_REALIZATION
                                }
                            />
                        }
                    />
                    <Route
                        path="value-realisation/:id"
                        element={
                            <ReportInfo
                                templateCategory={
                                    TemplateCategoryKey.VALUE_REALIZATION
                                }
                            />
                        }
                    />
                    <Route
                        path="market-research"
                        element={
                            <Reports
                                templateCategory={
                                    TemplateCategoryKey.MARKET_RESEARCH
                                }
                            />
                        }
                    />
                    <Route
                        path="exit-interview"
                        element={
                            <Reports
                                templateCategory={
                                    TemplateCategoryKey.EXIT_INTERVIEW
                                }
                            />
                        }
                    />
                    <Route
                        path="market-research/:id"
                        element={
                            <ReportInfo
                                templateCategory={
                                    TemplateCategoryKey.MARKET_RESEARCH
                                }
                            />
                        }
                    />
                    <Route
                        path="exit-interview/:id"
                        element={
                            <ReportInfo
                                templateCategory={
                                    TemplateCategoryKey.EXIT_INTERVIEW
                                }
                            />
                        }
                    />
                    <Route
                        path="trend"
                        element={
                            <ComingSoon description="We're finishing building our new Trends feature. Soon, you'll get live data and trends from your Win Loss, and Value Realization Reviews." />
                        }
                    />
                </Route>
                <Route
                    path="templates"
                    element={
                        process.env.REACT_APP_ENABLE_TEMPLATES_PAGE ===
                        "true" ? (
                            <Outlet />
                        ) : (
                            <ComingSoon variant="gray" />
                        )
                    }
                >
                    <Route index element={<Templates />} />
                    <Route path="global/:id" element={<TemplateInfo />} />
                    <Route
                        path="custom/:id"
                        element={<TemplateInfo isCustom />}
                    />
                    <Route path="*" element={<EmptySection />} />
                </Route>
                <Route
                    path="insights"
                    element={
                        <ComingSoon description="We're finishing building our new Insights feature. Soon, you'll get live data and trends from your Win Loss, and Value Realization Reviews." />
                    }
                />
                <Route path="support" element={<EmptySection />} />
            </Route>
            <Route path="reset-password" element={<ResetPassword />} />
            <Route path="create-password" element={<CreatePassword />} />
            <Route
                path="login"
                element={
                    <AuthCheck>
                        <Login />
                    </AuthCheck>
                }
            />
            <Route
                path="signup"
                element={
                    <AuthCheck>
                        <Outlet />
                    </AuthCheck>
                }
            >
                <Route index element={<Signup />} />
                <Route path="team-member" element={<Signup />} />
            </Route>
            <Route path="select-company" element={<SelectCompany />} />
            <Route
                path="email-verification"
                element={
                    <AuthCheck>
                        <EmailVerification />
                    </AuthCheck>
                }
            />
            <Route
                path="/main/auth/salesforce/callback"
                element={
                    <RootCheck>
                        <SalesforceHook />
                    </RootCheck>
                }
            />
            <Route
                path="onboarding"
                element={
                    <OnboardingCheck>
                        <Onboarding />
                    </OnboardingCheck>
                }
            >
                <Route path="create-company" element={<CreateCompanyForm />} />
                <Route path="connect-crm" element={<ConnectCrm />} />
                <Route path="invite-team" element={<InviteTeam />} />
            </Route>
            <Route path="*" element={<EmptySection />} />
        </Routes>
    );
}

export default App;
