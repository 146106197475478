import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSalesforceAuthorizationMutation } from "../../../apis/auth.api";
import { localStorageKeys } from "../../../data/localStorageKeys";

const useAuthorizeSalesforce = () => {
    const salesforceAuthMutation = useSalesforceAuthorizationMutation();
    const navigate = useNavigate();
    const [params] = useSearchParams();
    const code = params.get("code") ?? "";
    const state = params.get("state") ?? "";

    useEffect(() => {
        const authorize = async () => {
            try {
                const response = await salesforceAuthMutation.mutateAsync({
                    code,
                    state,
                });
                if (response.data) {
                    const isLoggedIn = localStorage.getItem(
                        localStorageKeys.IS_LOGGED_IN
                    );

                    if (isLoggedIn === "true") {
                        navigate("/settings/integrations");
                    } else {
                        navigate("/onboarding/connect-crm");
                    }
                }
            } catch {}
        };
        authorize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

export default useAuthorizeSalesforce;
