import { FC } from "react";
import { Typography, Button } from "trinity-components-library";

export interface FormHeaderProps {
    showActions: boolean;
    onSave: () => void;
    onCancel: () => void;
    title: string;
    description: string;
    isSaving?: boolean;
}

export const FormHeader: FC<FormHeaderProps> = ({
    showActions,
    onCancel,
    onSave,
    title,
    description,
    isSaving = false,
}) => {
    return (
        <div className="flex flex-col lg:flex-row gap-4 lg:gap-8">
            <div className="grow">
                <Typography size="lg" color="gray-900">
                    {title}
                </Typography>
                <Typography size="sm" color="gray-500">
                    {description}
                </Typography>
            </div>
            {showActions && (
                <div className="flex flex-row items-start flex-wrap gap-3">
                    <Button
                        label="Cancel"
                        color="gray"
                        variant="outlined"
                        onClick={onCancel}
                        disabled={isSaving}
                    />
                    <Button
                        label={isSaving ? "Saving" : "Save"}
                        color="primary"
                        onClick={onSave}
                        disabled={isSaving}
                    />
                </div>
            )}
        </div>
    );
};

export default FormHeader;
