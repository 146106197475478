import {
    useGetCustomTemplatesQuery,
    useGetGlobalTemplatesQuery,
} from "../../../apis/template.api";
import {
    CustomTemplate,
    GlobalTemplate,
    TemplateCategoryKey,
} from "../../../apis/types/template";

export const useGetDefaultTemplates = ({
    companyId,
}: {
    companyId: string;
}) => {
    const { result: customTemplatesResult } = useGetCustomTemplatesQuery({
        companyId,
    });
    const { result: globalTemplatesResult } = useGetGlobalTemplatesQuery({});

    const customTemplates: (CustomTemplate & { isCustom: true })[] = (
        customTemplatesResult.data ?? []
    )
        .filter(({ activated }) => activated)
        .map(item => ({ ...item, isCustom: true }));
    const globalTemplates: (GlobalTemplate & { isCustom: false })[] = (
        globalTemplatesResult.data ?? []
    )
        .sort((a, b) =>
            a.version === b.version ? 0 : a.version < b.version ? -1 : 1
        )
        .filter(({ activated }) => activated)
        .map(item => ({ ...item, isCustom: false }));

    const getDefaultType = (
        type: "digital" | "consulting",
        category: TemplateCategoryKey
    ) =>
        customTemplates.find(
            item =>
                item.type === type &&
                item.templateCategory.referenceKey === category &&
                item.default
        ) ??
        customTemplates.find(
            item =>
                item.type === type &&
                item.templateCategory.referenceKey === category
        ) ??
        globalTemplates.find(
            item =>
                item.type === type &&
                item.templateCategory.referenceKey === category
        );

    return {
        digital: {
            winLoss: getDefaultType("digital", TemplateCategoryKey.WIN_LOSS),
            valueRealisation: getDefaultType(
                "digital",
                TemplateCategoryKey.VALUE_REALIZATION
            ),
            marketResearch: getDefaultType(
                "digital",
                TemplateCategoryKey.MARKET_RESEARCH
            ),
            exitInterview: getDefaultType(
                "digital",
                TemplateCategoryKey.EXIT_INTERVIEW
            ),
        },
        consulting: {
            winLoss: getDefaultType("consulting", TemplateCategoryKey.WIN_LOSS),
            valueRealisation: getDefaultType(
                "consulting",
                TemplateCategoryKey.VALUE_REALIZATION
            ),
            marketResearch: getDefaultType(
                "consulting",
                TemplateCategoryKey.MARKET_RESEARCH
            ),
            exitInterview: getDefaultType(
                "consulting",
                TemplateCategoryKey.EXIT_INTERVIEW
            ),
        },
        customTemplatesResult,
        globalTemplatesResult,
    };
};

export default useGetDefaultTemplates;
