import React, { FC } from "react";
import { Typography } from "trinity-components-library";

interface LastLoginDateFormatProps {
    iso: string;
}

const LastLoginDateFormat: FC<LastLoginDateFormatProps> = ({ iso }) => {
    const date = new Date(iso);
    return (
        <div>
            <Typography
                className="whitespace-nowrap"
                size="sm"
                color="gray-900"
            >
                {date.toLocaleDateString("en-US", {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                })}
            </Typography>
            <Typography
                className="whitespace-nowrap"
                size="sm"
                color="gray-500"
            >
                {date.toLocaleString("en-US", {
                    hour: "2-digit",
                    hour12: true,
                    minute: "2-digit",
                })}
            </Typography>
        </div>
    );
};

export default LastLoginDateFormat;
