import React, { FC } from "react";
import clsx from "clsx";
import {
    ColorType,
    IconType,
    Icon,
    ColorVariantType,
    Typography,
    Button,
} from "trinity-components-library";

export interface ModalCardOptionProps {
    title: string;
    description: string;
    color: ColorType;
    onSelect?: () => void;
    fullWidth?: boolean;
    icon?: IconType;
}

export const ModalCardOption: FC<ModalCardOptionProps> = ({
    color,
    description,
    onSelect,
    title,
    fullWidth = false,
    icon = "file-plus-03",
}) => {
    return (
        <div
            className={clsx(
                "flex flex-col rounded-lg border border-gray-200 hover:shadow-sm h-64",
                fullWidth ? "w-full" : "w-60"
            )}
        >
            {/* card header section */}
            <div className="p-2 flex items-center border-b justify-between border-gray-200">
                <div className="flex flex-grow items-center">
                    <Icon
                        size={44}
                        name={icon}
                        color={`${color}-600` as ColorVariantType}
                        className={clsx(
                            "rounded-full p-2.5 m-2",
                            `bg-${color}-50`
                        )}
                    />
                    <Typography
                        type="text"
                        variant="p"
                        size="md"
                        color="gray-700"
                    >
                        {title}
                    </Typography>
                </div>
            </div>
            {/* card body section */}
            <div className="p-2 flex gap-2 justify-center items-center border-b border-gray-200 flex-grow">
                <div className="flex items-start gap-2 p-2">
                    <div className="flex w-[20px]">
                        <Icon name="alert-circle" size={20} color="gray-500" />
                    </div>
                    <Typography
                        size="sm"
                        variant="span"
                        color="gray-500"
                        className="flex flex-wrap"
                    >
                        {description}
                    </Typography>
                </div>
            </div>
            {/* card footer section */}
            <div className="p-2 flex items-center justify-end border-gray-200">
                <Button
                    label="Select"
                    data-modal-target="medium-modal"
                    data-modal-toggle="medium-modal"
                    size="lg"
                    className="mx-2"
                    onClick={onSelect}
                />
            </div>
        </div>
    );
};

export default ModalCardOption;
