import { FC, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Modal, SelectInputField } from "trinity-components-library";
import {
    useGetTeamMembersQuery,
    useUpdateTeamMemberScopeMutation,
} from "../../../apis/team-members.api";
import {
    MemberScope,
    MemberSortKeys,
    MemberSortOrder,
    MemberStatus,
} from "../../../apis/types/team-members.api.types";
import getLoggedInCompanyId from "../../../libs/getLoggedInCompanyId";
import ModalBody from "./modal-body";

interface UpdateUserRolesModalProps {
    open: boolean;
    memeberId: string;
    onClose?: () => void;
}

export const UpdateUserRolesModal: FC<UpdateUserRolesModalProps> = ({
    open,
    memeberId,
    onClose,
}) => {
    const [scopeValue, setScopeValue] = useState("");
    const form = useForm({
        defaultValues: {
            scope: { id: "", name: "" },
        },
        mode: "onChange",
    });
    const { errors } = form.formState;
    const companyId = getLoggedInCompanyId() ?? "";
    const users = useGetTeamMembersQuery({
        companyId,
        statuses: [MemberStatus.INVITED, MemberStatus.ACTIVE],
        sortBy: [MemberSortKeys.SCOPES, MemberSortKeys.NAME],
        sortOrder: MemberSortOrder.ASCENDING,
        scopes: [
            MemberScope.ADMIN,
            MemberScope.SALES_LEADER,
            MemberScope.SALES_REP,
        ],
    });
    const updateUserTeamMembersMutation = useUpdateTeamMemberScopeMutation(); // tochange to update userModal
    let { scope } = form.getValues();

    const onSend = async () => {
        try {
            await updateUserTeamMembersMutation.mutateAsync({
                companyId,
                updaingDetails: { memberId: memeberId, scopes: scope.id },
            });
            users.invalidate();
            onClose?.();
        } catch (error: any) {
            console.log(error);
        }
    };

    const closeModal = () => {
        form.reset();
        onClose?.();
    };

    return (
        <Modal open={open} onClose={closeModal}>
            <div className="w-[800px]">
                <ModalBody
                    title="Update User Roles"
                    description="The following users have access to this project:"
                    featuredIcon={{
                        size: "xl",
                        name: "user-plus-01",
                        color: "primary",
                    }}
                    actions={[
                        {
                            label: "Cancel",
                            variant: "outlined",
                            color: "gray",
                            className: "flex-1",
                            onClick: closeModal,
                        },
                        {
                            label: "Update",
                            className: "flex-1",
                            onClick: onSend,
                            disabled: scopeValue === "",
                        },
                    ]}
                >
                    <div className="flex gap-3">
                        <Controller
                            control={form.control}
                            name="scope"
                            rules={{
                                required: true,
                            }}
                            render={({ field }) => {
                                return (
                                    <SelectInputField
                                        label="User Role"
                                        placeholder="Select user role"
                                        items={userRoles}
                                        renderItem={({ name }) => name}
                                        value={field.value}
                                        onChange={({ id, name }) => {
                                            field.onChange({ id, name });
                                            setScopeValue(id);
                                        }}
                                        error={
                                            errors.scope?.message ||
                                            (errors.scope?.type === "required"
                                                ? "Required"
                                                : "")
                                        }
                                        fullWidth
                                        className="flex-1"
                                    />
                                );
                            }}
                        />
                    </div>
                </ModalBody>
            </div>
        </Modal>
    );
};

export default UpdateUserRolesModal;

const userRoles = [
    {
        id: MemberScope.ADMIN,
        name: "Admin",
    },
    {
        id: MemberScope.SALES_LEADER,
        name: "Sales Leader",
    },
    {
        id: MemberScope.SALES_REP,
        name: "Sales Rep",
    },
];
