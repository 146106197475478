import clsx from "clsx";
import { Dropdown, Icon, Typography, Badge } from "trinity-components-library";

function DropDown({
    table,
    row,
    actions,
}: {
    table: any;
    row: any;
    actions: any;
}) {
    return (
        <div className="w-[50px]">
            {table.options.meta.mouseOverRowIndex === row.index && (
                <div className={clsx("flex justify-end")}>
                    <Dropdown fixedPosition>
                        <Dropdown.Button>
                            <Icon
                                name="dots-vertical"
                                color="gray-400"
                                className="cursor-pointer"
                            />
                        </Dropdown.Button>
                        <Dropdown.Panel>
                            <div className="my-2 shadow-lg rounded-lg overflow-hidden w-[240px] bg-white border border-gray-100">
                                {actions.map(
                                    ({
                                        id,
                                        title,
                                        icon,
                                        onClick,
                                        items,
                                        iconEnd,
                                        showContacts,
                                    }: any) => {
                                        return (
                                            <>
                                                <div
                                                    key={id}
                                                    onClick={onClick}
                                                    className="px-4 py-2.5 w-[240px] flex items-center gap-3 bg-white hover:bg-gray-50 cursor-pointer"
                                                >
                                                    <Icon
                                                        color="gray"
                                                        name={
                                                            items
                                                                ? "link-01"
                                                                : icon
                                                        }
                                                        size={20}
                                                    />
                                                    <div className="flex-1">
                                                        <Typography
                                                            color="gray-700"
                                                            size="sm"
                                                        >
                                                            {title}
                                                        </Typography>
                                                    </div>
                                                    {items && (
                                                        <Icon
                                                            color="gray"
                                                            name={iconEnd}
                                                            size={20}
                                                        />
                                                    )}
                                                </div>
                                                {showContacts && (
                                                    <>
                                                        {items?.map(
                                                            (data: any) => {
                                                                return (
                                                                    <div
                                                                        className={
                                                                            data.reviewID !=
                                                                            null
                                                                                ? `px-4 ml-7 py-2.5 gap-3 bg-white hover:bg-gray-50 cursor-pointer flex flex-wrap`
                                                                                : `px-4 ml-7 py-2.5 gap-3 bg-white hover:bg-gray-50 cursor-not-allowed`
                                                                        }
                                                                        onClick={
                                                                            data.onClick
                                                                        }
                                                                    >
                                                                        <div className="flex items-center">
                                                                            <div className="mr-2">
                                                                                <Icon
                                                                                    color="gray"
                                                                                    name={
                                                                                        icon
                                                                                    }
                                                                                    size={
                                                                                        15
                                                                                    }
                                                                                />
                                                                            </div>
                                                                            <div className="flex-1">
                                                                                <Typography
                                                                                    color="gray-700"
                                                                                    size="sm"
                                                                                >
                                                                                    {
                                                                                        data.title
                                                                                    }
                                                                                </Typography>
                                                                            </div>
                                                                            {data.badgeValue && (
                                                                                <Badge
                                                                                    label={
                                                                                        data.badgeValue
                                                                                    }
                                                                                    color={
                                                                                        data.badgeColor
                                                                                    }
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        );
                                    }
                                )}
                            </div>
                        </Dropdown.Panel>
                    </Dropdown>
                </div>
            )}
        </div>
    );
}

export default DropDown;
