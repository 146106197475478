/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from "react";
import {
    CalendarRangeName,
    getCalendarRange,
} from "trinity-components-library";
import { useUserInfoQuery } from "../../../apis/user.api";
import { User } from "../../../types";
import { useCompanyUserPreferenceMutation } from "../../../apis/company.api";
import { capitalize } from "../../../utils";

type ArgsTypeOfSetDateRange = {
    companyId: string | null;
    path: string;
    startDate: string;
    endDate: string;
    calendarRangeName: CalendarRangeName;
};

type ArgsTypeOfGetDateRange = {
    companyId: string | null;
    path: string;
};

export const useCalendarRangeUserPreference = () => {
    const { result: userQuery, invalidate: invalidateUserQuery } =
        useUserInfoQuery();
    const updateCompanyUserPreference = useCompanyUserPreferenceMutation();

    const setCalendarRangeUserPreference = ({
        companyId,
        path,
        startDate,
        endDate,
        calendarRangeName,
    }: ArgsTypeOfSetDateRange) => {
        if (userQuery.isLoading || !userQuery.data) return;
        const companyIndex = (userQuery.data as User).companies.findIndex(
            company => company.id === companyId
        );
        if (userQuery.isSuccess && companyIndex !== -1) {
            const preferenceIndex = (userQuery.data as User).companies[
                companyIndex
            ]?.user_preferences.findIndex(
                preference => preference.path === path
            );

            if (preferenceIndex !== -1) {
                const preference = (userQuery.data as User).companies[
                    companyIndex
                ].user_preferences[preferenceIndex];
                const filter = [
                    ...preference.filter.filter(
                        preferenceFilter => preferenceFilter.key !== "DateRange"
                    ),
                    {
                        key: "DateRange",
                        dates:
                            calendarRangeName === "custom" &&
                            startDate &&
                            endDate
                                ? `${startDate},${endDate}`
                                : "",
                        value: calendarRangeName.startsWith("last")
                            ? calendarRangeName.slice(4)
                            : "custom",
                    },
                ];
                updateCompanyUserPreference
                    .mutateAsync({
                        ...preference,
                        filter,
                    })
                    .then(() => {
                        invalidateUserQuery();
                    })
                    .catch(() => {});
            }
        }
    };

    const getTimeRangeFilterValues = useCallback(
        ({ companyId, path }: ArgsTypeOfGetDateRange) => {
            if (!userQuery.data || userQuery.isLoading)
                return { startDate: "", endDate: "", dateRangeName: "" };
            const userPreferences = (userQuery.data as User).companies.find(
                company => company.id === companyId
            )?.user_preferences;
            const filters = userPreferences?.find(
                preference => preference.path === path
            )?.filter;
            const timeRangeFilter = filters?.find(
                filter => filter.key === "DateRange"
            );
            if (timeRangeFilter) {
                if (timeRangeFilter.value !== "custom") {
                    const calendarRangeName: CalendarRangeName =
                        `last${capitalize(
                            timeRangeFilter.value
                        )}` as CalendarRangeName;
                    const { from, to } = getCalendarRange(calendarRangeName);
                    return { startDate: from, endDate: to, calendarRangeName };
                } else {
                    const dates = timeRangeFilter?.dates?.split(",");
                    if (dates?.length === 2) {
                        return {
                            startDate: dates[0],
                            endDate: dates[1],
                            calendarRangeName: "custom",
                        };
                    }
                }
            }
            return { startDate: "", endDate: "", dateRangeName: "" };
        },
        [userQuery.data?.companies]
    );
    return { setCalendarRangeUserPreference, getTimeRangeFilterValues };
};
