import React, { FC } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { TextInputField } from "trinity-components-library";
import FormItem from "../../components/form-item";

export interface CompanyNameItemProps {
    form: UseFormReturn<any>;
}

const CompanyNameItem: FC<CompanyNameItemProps> = ({ form }) => {
    return (
        <FormItem
            title="Company Name"
            helperText="This will be displayed on your profile."
        >
            <Controller
                name="name"
                control={form.control}
                render={({ field }) => (
                    <TextInputField
                        fullWidth
                        placeholder="Enter company name"
                        value={field.value}
                        onChange={field.onChange}
                    />
                )}
            />
        </FormItem>
    );
};

export default CompanyNameItem;
