import React, { FC } from "react";
import clsx from "clsx";
import Color from "color";

export interface TemplateCardBackgroundProps {
    children: React.ReactNode;
    colorHashCode: string;
    className?: string;
    isCustom?: boolean;
    expanded?: boolean;
}

export const TemplateCardBackground: FC<TemplateCardBackgroundProps> = ({
    children,
    colorHashCode = "#000000",
    className,
    isCustom = false,
    expanded = false,
}) => {
    const color = Color(colorHashCode || "#000000");
    return (
        <div className={clsx("relative rounded-lg overflow-hidden", className)}>
            {isCustom && (
                <div className="w-full h-full absolute rounded-lg overflow-hidden">
                    {expanded ? (
                        <svg
                            width="100%"
                            height="100%"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clip-path="url(#clip0_10474_227071)">
                                <rect
                                    width="100%"
                                    height="100%"
                                    className="min-h-[500px]"
                                    fill={color.hex()}
                                />
                                <path
                                    d="M2064 0H0V25.8128L2064 162V0Z"
                                    fill={color.lighten(0.5).hex()}
                                />
                                <path
                                    d="M46.2934 28.6925L-112.418 19.1468L-110.872 43.1381L47.8387 52.6838L46.2934 28.6925Z"
                                    fill={color.lighten(0.4).hex()}
                                />
                                <path
                                    d="M1089.21 71.9604L930.678 59.7455L931.82 83.7594L1090.35 95.9743L1089.21 71.9604Z"
                                    fill={color.lighten(0.75).hex()}
                                />
                                <path
                                    d="M1116.15 97.887L957.618 85.6722L958.759 109.686L1117.29 121.901L1116.15 97.887Z"
                                    fill={color.lighten(0.4).hex()}
                                />
                                <path
                                    d="M-1.78713 230.858L155.191 364.118L156.036 340.092L-0.942193 314.831L-1.78713 230.858Z"
                                    fill={color.lighten(0.4).hex()}
                                />
                                <path
                                    d="M-57.7539 305.697L99.2245 330.957L100.069 306.931L-56.909 281.671L-57.7539 305.697Z"
                                    fill={color.lighten(0.75).hex()}
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_10474_227071">
                                    <rect
                                        width="100%"
                                        height="100%"
                                        fill="white"
                                    />
                                </clipPath>
                            </defs>
                        </svg>
                    ) : (
                        <svg
                            width="100%"
                            height="100%"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clip-path="url(#clip0_10474_227060)">
                                <rect
                                    width="100%"
                                    height="100%"
                                    className="min-h-[500px]"
                                    fill={color.hex()}
                                />
                                <path
                                    d="M338 -3.05176e-05H7.62939e-06V25.6842L400 91.5416V-3.05176e-05Z"
                                    fill={color.lighten(0.5).hex()}
                                />
                                <path
                                    d="M46.5897 33.3158L-109.778 4.5138L-111.165 28.5147L45.2019 57.3168L46.5897 33.3158Z"
                                    fill={color.lighten(0.4).hex()}
                                />
                                <path
                                    d="M420.772 81.6167L264.277 53.5179L262.997 77.5248L419.492 105.624L420.772 81.6167Z"
                                    fill={color.lighten(0.75).hex()}
                                />
                                <path
                                    d="M444.967 110.122L288.472 82.0228L287.192 106.03L443.687 134.129L444.967 110.122Z"
                                    fill={color.lighten(0.4).hex()}
                                />
                                <path
                                    d="M-3.00512 341.241L153.341 370.157L154.746 346.157L-1.59992 317.241L-3.00512 341.241Z"
                                    fill={color.lighten(0.4).hex()}
                                />
                                <path
                                    d="M-58.1829 306.784L98.1635 335.699L99.5687 311.699L-56.7777 282.784L-58.1829 306.784Z"
                                    fill={color.lighten(0.75).hex()}
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_10474_227060">
                                    <rect
                                        width="100%"
                                        height="100%"
                                        fill="white"
                                    />
                                </clipPath>
                            </defs>
                        </svg>
                    )}
                </div>
            )}
            <div className="w-full h-full relative z-10">{children}</div>
        </div>
    );
};

export default TemplateCardBackground;
