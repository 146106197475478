import React, { FC } from "react";
import {
    BadgeProps,
    Typography,
    Badge,
    DirectionMetric,
} from "trinity-components-library";

export interface MetricProps {
    className?: string;
    title: string;
    value: string;
    description: string;
    valueItems?: { label: string; color: BadgeProps["color"] }[];
    change: string;
    isIncreasing?: boolean;
}

const Metric: FC<MetricProps> = ({
    className,
    title,
    value,
    description,
    valueItems = [],
    change,
    isIncreasing = false,
}) => {
    return (
        <div
            className={`flex flex-col min-w-[300px] border border-gray-200 rounded-lg shadow-sm ${className}`}
        >
            <div className="p-6 flex flex-col grow">
                <div className="grow">
                    <Typography size="sm" color="gray-500">
                        {title}
                    </Typography>
                    <Typography
                        size="md"
                        type="display"
                        color="gray-900"
                        className="mt-2 break-all"
                        weight="semibold"
                    >
                        {value}
                    </Typography>
                </div>
                <div className="flex flex-wrap gap-2 mt-4">
                    {valueItems.map(itemProps => (
                        <Badge {...itemProps} />
                    ))}
                </div>
            </div>
            <div className="px-6 py-4 flex gap-2 border-t border-gray-200">
                <div className="grow shrink">
                    <Typography size="sm" color="gray-500">
                        {description}
                    </Typography>
                </div>
                <DirectionMetric
                    value={isIncreasing ? 1 : -1}
                    limit={0}
                    displayValue={change}
                />
            </div>
        </div>
    );
};

export default Metric;
