import { FC, useState } from "react";
import { Accordion, Divider } from "trinity-components-library";
import Question, { QuestionProps } from "../question/question";

interface QuestionSectionProps {
    sections: {
        questions: QuestionProps[];
        name: string;
    }[];
}

export const QuestionSection: FC<QuestionSectionProps> = ({ sections }) => {
    const [active, setActive] = useState(0);

    return (
        <div className="flex flex-col p-8 gap-4 border border-gray-200 rounded-lg">
            {sections.map(({ name, questions }, index) => {
                return (
                    <>
                        <Accordion
                            label={name}
                            endLabel={`${questions.length} questions`}
                            onClick={() =>
                                setActive(prev => (prev === index ? -1 : index))
                            }
                            active={active === index}
                        >
                            <div className="flex flex-col gap-4">
                                {questions.map((question, index) => (
                                    <Question
                                        {...question}
                                        id={`${index + 1}`}
                                    />
                                ))}
                            </div>
                        </Accordion>
                        {index < sections.length - 1 && (
                            <Divider className="my-4" />
                        )}
                    </>
                );
            })}
        </div>
    );
};

export default QuestionSection;
