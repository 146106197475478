import { FC } from "react";
import { IconType, Profile } from "trinity-components-library";
import { useDownloadAttachmentQuery } from "../../apis/attachment.api";

export interface UserProfileProps {
    name: string;
    subTitle?: string;
    avatar?: string;
    status?: "online" | "offline" | "busy" | "away";
    size?: "sm" | "md" | "lg" | "xl";
    placeholderIcon?: IconType;
    placeholderType?: "name" | "placeholder";
}

export const UserProfile: FC<UserProfileProps> = ({ avatar, ...restProps }) => {
    const isDataUrl = (avatar ?? "").startsWith("data:");
    const avatarImage = useDownloadAttachmentQuery(
        {
            url: avatar ?? "",
        },
        {
            enabled: !!avatar && !isDataUrl,
        }
    );
    return (
        <Profile
            avatar={isDataUrl ? avatar : avatarImage.result.data ?? ""}
            {...restProps}
        />
    );
};

export default UserProfile;
