import React from "react";
import { Typography } from "trinity-components-library";
import useAuthorizeSalesforce from "./hooks/useAuthorizeSalesforce";

const SalesforceHook = () => {
    useAuthorizeSalesforce();
    return (
        <div className="flex justify-center">
            <Typography>Loading...</Typography>
        </div>
    );
};

export default SalesforceHook;
