import { FC } from "react";
import { Table } from "@tanstack/react-table";
import {
    Typography,
    Badge,
    ButtonGroup,
    BadgeProps,
} from "trinity-components-library";

export type ReviewStatus = "complete" | "incomplete" | "all";

interface TableHeaderProps {
    table: Table<any>;
    reviewStatus: ReviewStatus;
    setReviewStatus: (status: ReviewStatus) => void;
    totalItems?: number;
}

const TableHeader: FC<TableHeaderProps> = ({
    table,
    reviewStatus,
    setReviewStatus,
    totalItems = 0,
}) => {
    return (
        <div className="px-6 py-5 flex flex-col lg:flex-row lg:items-center flex-wrap gap-5">
            <div className="flex items-center flex-wrap flex-1 gap-2">
                <Typography size="lg" color="gray-900">
                    {`${reviewStatusBadgeProps[reviewStatus].label} Win Loss Reviews`}
                </Typography>
                <Badge
                    label={`${totalItems} ${reviewStatusBadgeProps[reviewStatus].label}`}
                    color={reviewStatusBadgeProps[reviewStatus].color}
                />
            </div>
            <div>
                <ButtonGroup
                    variant="outlined"
                    color="gray"
                    items={buttonGroupItems.map(({ id, label }) => ({
                        label,
                        selected: id === reviewStatus,
                        onClick: () => setReviewStatus(id),
                    }))}
                />
            </div>
        </div>
    );
};

export default TableHeader;

const buttonGroupItems: { id: ReviewStatus; label: string }[] = [
    {
        id: "complete",
        label: "Completed",
    },
    {
        id: "incomplete",
        label: "Scheduled",
    },
    {
        id: "all",
        label: "View All",
    },
];
const reviewStatusBadgeProps: { [key: string]: BadgeProps } = {
    complete: { label: "Completed", color: "success" },
    incomplete: { label: "Scheduled", color: "error" },
    all: { label: "Total", color: "gray" },
};
