import React, { FC } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { TextInputField } from "trinity-components-library";
import FormItem from "../../components/form-item";

export interface LastNameItemProps {
    form: UseFormReturn<any>;
}

const LastNameItem: FC<LastNameItemProps> = ({ form }) => {
    return (
        <FormItem title="Last Name">
            <Controller
                name="lastName"
                control={form.control}
                render={({ field }) => (
                    <TextInputField
                        fullWidth
                        placeholder="Enter last name"
                        value={field.value}
                        onChange={field.onChange}
                    />
                )}
            />
        </FormItem>
    );
};

export default LastNameItem;
