import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "trinity-components-library";
import { ReviewType } from "../../../apis/types/review-type";
import { useAuth } from "../../../libs/auth/auth";
import useUploadReportPdf from "../../hooks/useUploadReportPdf";
import { TemplateCategoryKey } from "../../../apis/types/template";

interface ActionsProps {
    dealId: string;
    reviewType: ReviewType;
    isRowHovered: boolean;
    templateCategory: TemplateCategoryKey;
}

export const Actions: FC<ActionsProps> = ({
    reviewType,
    isRowHovered = false,
    dealId,
    templateCategory,
}) => {
    const navigate = useNavigate();
    const { isTrinityAdmin } = useAuth();

    const { uploadPdfMutation, uploadReportPdf, cancelUpload } =
        useUploadReportPdf();

    let templateCategoryUrlPath = "win-loss";
    switch (templateCategory) {
        case TemplateCategoryKey.WIN_LOSS:
            templateCategoryUrlPath = "win-loss";
            break;

        case TemplateCategoryKey.VALUE_REALIZATION:
            templateCategoryUrlPath = "value-realisation";
            break;

        case TemplateCategoryKey.MARKET_RESEARCH:
            templateCategoryUrlPath = "market-research";
            break;
        case TemplateCategoryKey.EXIT_INTERVIEW:
            templateCategoryUrlPath = "exit-interview";
            break;
    }

    return (
        <div className="w-32 flex justify-end items-center">
            {reviewType === ReviewType.DIGITAL &&
                (isTrinityAdmin ? (
                    uploadPdfMutation.isLoading && isRowHovered ? (
                        <Button
                            label="Cancel"
                            color="error"
                            onClick={cancelUpload}
                        />
                    ) : (
                        <Button
                            icon="upload-01"
                            label={
                                uploadPdfMutation.isLoading
                                    ? "Uploading..."
                                    : "Upload pdf"
                            }
                            variant="outlined"
                            color="gray"
                            loading={uploadPdfMutation.isLoading}
                            disabled={uploadPdfMutation.isLoading}
                            onClick={() => uploadReportPdf(dealId)}
                            className={
                                (
                                    uploadPdfMutation.isLoading
                                        ? !isRowHovered
                                        : isRowHovered
                                )
                                    ? "visible"
                                    : "invisible"
                            }
                        />
                    )
                ) : (
                    <Button
                        label="View"
                        className={isRowHovered ? "visible" : "invisible"}
                        onClick={() =>
                            navigate(
                                `/reports/${templateCategoryUrlPath}/${dealId}`
                            )
                        }
                    />
                ))}
        </div>
    );
};

export default Actions;
