import { FC, useState } from "react";
import {
    Typography,
    IconButtonGroup,
    TextInputField,
} from "trinity-components-library";
import { ReportItem } from "../../sections/reports-grid-view/types";
import ReportsGridView from "../../sections/reports-grid-view/reports-grid-view";
import ReportsTable from "../../sections/reports-table/reports-table";
import DateRangeSelector from "../components/date-range-selector.tsx/dater-range-selector";
import FilterSelector from "./components/filter-selector";
import { useOutletContext } from "react-router-dom";
import useReports from "../root/hooks/useReports";
import { TemplateCategoryKey } from "../../apis/types/template";

interface ReportsProps {
    templateCategory: TemplateCategoryKey;
}

export const Reports: FC<ReportsProps> = ({ templateCategory }) => {
    const [view, setView] = useState<"grid" | "list">("grid");
    const {
        winLossReports,
        valueRealisationReports,
        marketResearchReports,
        exitInterviewReports,
    } =
        useOutletContext<{
            winLossReports: ReturnType<typeof useReports>;
            valueRealisationReports: ReturnType<typeof useReports>;
            marketResearchReports: ReturnType<typeof useReports>;
            exitInterviewReports: ReturnType<typeof useReports>;
        }>();
    const {
        deals,
        company,
        setCompany,
        filterValue,
        setFilterValue,
        calendarRange,
        page,
        setPage,
    } =
        templateCategory === TemplateCategoryKey.VALUE_REALIZATION
            ? valueRealisationReports
            : templateCategory === TemplateCategoryKey.MARKET_RESEARCH
            ? marketResearchReports
            : templateCategory === TemplateCategoryKey.EXIT_INTERVIEW
            ? exitInterviewReports
            : winLossReports;

    const reports: ReportItem[] = deals.isSuccess
        ? deals.data.items.map(
              ({
                  id,
                  review_type,
                  updated_at,
                  contact,
                  outcome,
                  reviews,
              }: any) => ({
                  id,
                  companyName: contact?.company_name ?? "",
                  companyLogo: contact?.logo ?? "",
                  reviewType: review_type,
                  responder: reviews?.length ? reviews[0].reviewer_name : "",
                  respondedDate: updated_at,
                  resultType: outcome === "CLOSED WON" ? "win" : "loss",
              })
          )
        : [];

    return (
        <div className="p-8 flex flex-col gap-8">
            <div className="flex items-start gap-5 flex-wrap">
                <Typography
                    type="display"
                    size="sm"
                    color="gray-900"
                    className="flex-1"
                >
                    {templateCategory === TemplateCategoryKey.VALUE_REALIZATION
                        ? "Value Realisation Reports"
                        : templateCategory ===
                          TemplateCategoryKey.MARKET_RESEARCH
                        ? "Market Research Reports"
                        : templateCategory ===
                          TemplateCategoryKey.EXIT_INTERVIEW
                        ? "Exit Interview Reports"
                        : "Win Loss Reports"}
                </Typography>
                <div className="flex gap-3 items-start flex-wrap">
                    <div className="w-44">
                        <TextInputField
                            icon="search-sm"
                            fullWidth
                            placeholder="Search"
                            onChange={setCompany}
                            value={company}
                        />
                    </div>
                    <FilterSelector
                        value={filterValue}
                        onChange={setFilterValue}
                    />
                    <DateRangeSelector
                        value={calendarRange.value}
                        onChange={calendarRange.setValue}
                    />
                    <IconButtonGroup
                        color="gray"
                        variant="outlined"
                        items={[
                            {
                                id: "list",
                                icon: "list",
                                selected: view === "list",
                                onClick: () => setView("list"),
                            },
                            {
                                id: "grid",
                                icon: "grid-01",
                                selected: view === "grid",
                                onClick: () => setView("grid"),
                            },
                        ]}
                    />
                </div>
            </div>
            <div>
                {view === "grid" ? (
                    <ReportsGridView
                        templateCategory={templateCategory}
                        reports={reports}
                        page={page}
                        pageCount={deals.data?.meta?.totalPages ?? 0}
                        onPageChange={setPage}
                    />
                ) : (
                    <ReportsTable
                        templateCategory={templateCategory}
                        reports={reports}
                        page={page}
                        pageCount={deals.data?.meta?.totalPages ?? 0}
                        onPageChange={setPage}
                    />
                )}
            </div>
        </div>
    );
};

export default Reports;
